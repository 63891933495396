import React from 'react'
import { useDispatch } from 'react-redux';
import { settingChanged, SPELL_CHECK_CHECKBOX } from '../../actions/preferencesActions';
import uiString from '../../constants/uiString';
import { useAppSelector } from '../../helpers/hooks/useAppSelector.hook';
import { UnderlinedTitle } from '../PresentationComponents/UnderlinedTitle';
import { Box, makeStyles } from '@material-ui/core';
import ToggledRadioButton from '@components/PresentationComponents/FormComponents/ToggledRadioButton';


/**
 * Draw the Controls sub page
 */
 const Controls: React.FC = () => {
  const autoSpellCheck = useAppSelector(state => state.preferences.autoSpellCheck)
  const dispatch = useDispatch()
  const classes = useStyles();

  return (
    <Box>
      <UnderlinedTitle>
        {uiString.preferences.CONTROLS}
      </UnderlinedTitle>
      <div style={{ padding: "15px 0 10px" }}>
        <div
          style={{ padding: "5px 0", display: "flex" }}
          className="none-select-text"
        >
          {uiString.preferences.TURN_ON_SPELL_CHECK}
          <ToggledRadioButton
            className={classes.button}
            onChange={() =>
              dispatch(settingChanged(SPELL_CHECK_CHECKBOX, !autoSpellCheck))
            }
            checked={autoSpellCheck}
          />
        </div>
      </div>
    </Box>
  );
};

  const useStyles = makeStyles({
    button: {
      marginLeft: '25px',
    },
  })

  export default Controls
import React, { useState } from "react";
import {
  AudioMapTypeToCode,
} from "./constants";
import AudioFileDescriptionForm from "./AudioFileDescriptionForm";
import { AudioTypes, IAudioFile, IAudioFileDescription } from "@models/AudioFiles.models";
import AudioFilesService from "@services/AudioFiles.service";
import Dialog from '../../PresentationComponents/Dialog/Dialog';
import uiString, { ApiErrorMessages } from "@constants/uiString";
import DialogActions from "../Dialog/DialogActions";
import DialogContent from "../Dialog/DialogContent";
import DialogButton from "../Dialog/DialogButton";
import { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { useToasts } from "react-toast-notifications";

const AudioFilesStrings = uiString.SETTINGS_AND_PREF.AUDIO_FILES;

const { UpdateOne, DeleteOne } = AudioFilesService();

interface IProps {
  open: boolean;
  close: () => void;
  audioFile: IAudioFile;
  onEdit: (file: IAudioFile) => void;
}

export const AudioFileEditDialog = ({
  open,
  close,
  audioFile,
  onEdit,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editForm, setEditForm] = useState<IAudioFileDescription>();
  const [formError, setFormError] = useState<string | null>(null)
  const { addToast } = useToasts();
  const handleEdit = async (id?: string) => {
    try {
      if (!isSubmitting && id) {
        if (editForm?.name.length === 0)
          throw Error(AudioFilesStrings.NAME_REQUIRED_ERROR)
        if (editForm!.name.length > 50)
          throw Error(AudioFilesStrings.NAME_CHAR_LIMIT_ERROR)
        setIsSubmitting(true);
        const body = {
          type: AudioMapTypeToCode[editForm?.type as AudioTypes],
          name: editForm?.name
        } as IAudioFileDescription;

        const { data } = await UpdateOne(id, body);

        onEdit(data);
        setIsSubmitting(false);
        close();
      }
    } catch (error) {
      setIsSubmitting(false);
      if ((error as any).message === AudioFilesStrings.NAME_REQUIRED_ERROR || (error as any).message === AudioFilesStrings.NAME_CHAR_LIMIT_ERROR)
        setFormError((error as any).message);
      else if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {setFormError(null); close()}}
      maxWidth="xs"
      fullWidth
      aria-labelledby="audio-files-edit-dialog-title"
      title={AudioFilesStrings.EDIT_DIALOG.TITLE}
    >
      <DialogContent>
        <AudioFileDescriptionForm
          onChange={(form) => {setFormError(null); setEditForm(form)}}
          defaultValues={audioFile}
          error={formError}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="secondary"
          onClick={() => handleEdit(audioFile?.id)}
          fullWidth
          disabled={isSubmitting}
          isLoading={isSubmitting}
          label={uiString.SAVE_CHANGES}
          data-testid="dialog-button"
        />
      </DialogActions>
    </Dialog>
  );
};

import { SvgIcon, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React from "react";

export const ReadIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = (
  props
) => (
  <SvgIcon viewBox="0 0 640 640" {...props}>
    <g id="icomoon-ignore"></g>
    <path d="M560 192h-512c-26.467 0-48 21.533-48 48v288c0 26.468 21.533 48 48 48h512c26.468 0 48-21.532 48-48v-288c0-26.467-21.532-48-48-48zM560 224c0.946 0 1.869 0.099 2.771 0.257l-241.020 160.681c-9.289 6.192-26.211 6.192-35.501 0l-241.021-160.681c0.902-0.158 1.825-0.257 2.771-0.257h512zM560 544h-512c-8.822 0-16-7.178-16-16v-274.104l236.5 157.667c9.953 6.636 22.727 9.953 35.5 9.953s25.548-3.317 35.501-9.953l236.499-157.667v274.104c0 8.822-7.178 16-16 16z"></path>
  </SvgIcon>
);

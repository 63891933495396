import { ErrorWrapperMessages } from '@constants/uiString';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react'

type IProps = {
    children: JSX.Element;
    errorMessage: ErrorWrapperMessages | null;
    onRetry: () => any;
}

export const ErrorWrapper = ({ children, errorMessage, onRetry }: IProps) => {
    const classes = useStyles();
    return errorMessage ? (
        <>
            <div className={classes.center}>
                <Typography variant="h5">
                    {errorMessage}
                    {errorMessage !== ErrorWrapperMessages.NOT_AUTHORIZED_TO_VIEW && <Button color="primary" onClick={() => onRetry()}>
                        Retry
                    </Button>}
                </Typography>
            </div>
        </>
    ) : (
        children
    )
}


const useStyles = makeStyles((theme: Theme) => ({
    center: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
    }
})
);

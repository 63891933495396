import { logout, setAccessTokenValidity, setUserTokenNotReady } from "@actions/userActions"
import { RefreshTokenStatus } from "@reducers/authenticationReducer"
import _ from "lodash"

export const handleAuthorizationErrors = async (dispatch, auth) => {
    if (auth && auth.refreshToken && auth.refreshTokenStatus === RefreshTokenStatus.VALID_TOKEN_AVAILABLE) {
    // dispatch(setAccessTokenValidity(false));

    } else {
      // dispatch(logout())
    }
  }
  
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import createKeyIndex from "@helpers/createKeyIndex";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  makeStyles,
  Theme,
  Tooltip,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IScheduledMeetingDetailed } from "@models/Moxtra.models";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import UserInfoBox from "./UserInfoBox";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import BrokenImageIcon from "@material-ui/icons/BrokenImageOutlined";
import ViewAgendaIcon from "@material-ui/icons/ViewAgendaOutlined";
import MyLocationIcon from "@material-ui/icons/MyLocationOutlined";
import PhoneIcon from "@material-ui/icons/PhoneIphoneOutlined";
import PeopleIcon from "@material-ui/icons/PeopleAltOutlined";
import { routeUrlBuilders } from "@constants/routes";
import { useToasts } from "react-toast-notifications";
import { MEETING_LINK_COPIED } from "./constants";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import useInterval from "@helpers/hooks/useInterval.hook";
import { JOIN_MEET_BEFORE_MINUTES } from "@constants/index";

const RECORD_MEETING = "This meeting will be recorded.";

type Props = {
  open: boolean;
  data: IScheduledMeetingDetailed | null;
  exitMeeting: () => void;
  enterMeeting: () => void;
  isRecorded?: boolean;
  isGuest: boolean;
  displayErrorMessage: string | null;
} & Omit<DialogProps, "title">;

/**
 *
 * @param data All the Meeting's information data
 * @param enterMeeting Submit function to enter current meeting
 * @param isRecorded Sets a flag if the meeting is being recorded or not
 * @param participantCount Number of participants in meeting
 * @returns JSX Pre-Join Meeting Dialog
 */
const MeetingInfoDialog: React.FC<Props> = ({
  open,
  data,
  exitMeeting,
  enterMeeting,
  isRecorded,
  isGuest,
  displayErrorMessage,
}) => {

  const participantCount = _.uniqBy(data ? data.participants : [], "rosterId").length
  const classes = useStyles();
  const { addToast } = useToasts();
  const [disableEnterBtn, setDisableEnterBtn] = useState(true);
  const userId = useAppSelector(state => state.authentication.userId);

  useInterval(() => {
    if (data && disableEnterBtn) {
      const meetStartTime = new Date(data.scheduledStarts).getTime();
      const thirtyMinutesBeforeStartTime = meetStartTime - JOIN_MEET_BEFORE_MINUTES * 60 * 1000;
      const findHost = data.invitees?.find(invitee => invitee.host);
      const meetHasStarted = data.status === "SESSION_STARTED" || (thirtyMinutesBeforeStartTime < Date.now() && data.status !== "SESSION_ENDED");
      const isHostTryingToJoin = findHost?.uniqueId === String(userId);
      if (meetHasStarted || isHostTryingToJoin) {
        setDisableEnterBtn(false);
      }
      
    }
  }, open ? 100 : null)

  return (
    <Dialog open={open} className={classes.root} fullWidth>
      <DialogTitle>
        <b>{_.capitalize(data?.topic)}</b>
      </DialogTitle>
      {isRecorded && (
        <Alert style={{ padding: "6px 24px" }} severity="info">
          {RECORD_MEETING}
        </Alert>
      )}
      <DialogContent className={classes.content}>
        <Grid>
          <Tooltip style={{ cursor: "pointer" }} placement="right" title="Copy">
            <DialogContentText
              onClick={() => {
                navigator.clipboard.writeText(
                  routeUrlBuilders.JOIN_MEETING_LINK(String(data?.sessionKey))
                );
                addToast(MEETING_LINK_COPIED, { appearance: 'success', autoDismiss: true });
              }}
              className={classes.alignedCenter}
            >
              <LinkOutlinedIcon className={classes.startIcon} />
              <span className={classes.boldText}>{"Invitation link:"}</span>
              {data?.sessionKey}
              <FileCopyIcon className={classes.copyIcon} />
            </DialogContentText>
          </Tooltip>
          <DialogContentText className={classes.alignedCenter}>
            <BrokenImageIcon className={classes.startIcon} />
            <span className={classes.boldText}>{"Status: "}</span>
            {data?.status
              ? _.capitalize(data?.status.replace(new RegExp("_", "gi"), " "))
              : "empty"}
          </DialogContentText>
          {/* <DialogContentText className={classes.alignedCenter}>
            <ViewAgendaIcon className={classes.startIcon} />
            <span className={classes.boldText}>{"Agenda: "}</span>
            {data.agenda ? data.agenda : "empty"}
          </DialogContentText> */}
          {data?.dialinNumbers && (
            <>
              <DialogContentText className={classes.alignedCenter}>
                <MyLocationIcon className={classes.startIcon} />
                <span className={classes.boldText}>{"Location: "}</span>
                {data.dialinNumbers && data.dialinNumbers[0]?.location}
              </DialogContentText>
              <DialogContentText className={classes.alignedCenter}>
                <PhoneIcon className={classes.startIcon} />
                <span className={classes.boldText}>{"Phone number: "}</span>
                {data.dialinNumbers && data.dialinNumbers[0]?.phoneNumber}
              </DialogContentText>
            </>
          )}
        </Grid>
        <Grid>
        {!isGuest && <DialogContentText className={classes.alignedCenter}>
            <PeopleIcon className={classes.startIcon} />
            {participantCount !== 0 && `${participantCount} ${participantCount > 1 ? "Participants" : "Participant"}`}
            {participantCount === 0 && "No one is here"}
          </DialogContentText>}
          {data?.invitees?.map((item, index) => {
            const isAvailable: boolean =
              data.participants !== undefined
                ? data.participants.some(
                    (participant) => participant.id === item.id
                  )
                : false;
            return (
              <UserInfoBox
                user={item}
                key={createKeyIndex(index, item.id)}
                isAvailable={isAvailable}
              />
            );
          })}
          {data?.participants &&
            data.participants.map((item, index) => {
              const isAvailable = data.invitees?.some(
                (invitee) => invitee.id === item.id
              );
              return (
                !isAvailable && (
                  <UserInfoBox
                    isAvailable={true}
                    user={item}
                    key={createKeyIndex(index, item.id)}
                  />
                )
              );
            })}
        </Grid>
      </DialogContent>
      {displayErrorMessage &&<DialogContent>
       
          <Alert severity="error">
            {displayErrorMessage}
           </Alert>
        </DialogContent>
      }
      <DialogActions className={classes.actions}>
        <InputButton variant="outlined" label="Cancel" onClick={exitMeeting} />
        <InputButton
          color="primary"
          label="Enter meeting"
          minWidth="100px"
          onClick={enterMeeting}
          disabled={disableEnterBtn}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "fit-content",
      padding: "10px 0",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(4),
  },
  actions: {
    padding: theme.spacing(1, 2),
  },
  abzatz: {
    paddingLeft: theme.spacing(2),
  },
  alignedCenter: {
    display: "flex",
    alignItems: "center",
  },
  boldText: {
    fontWeight: "bold",
    marginRight: "4px",
  },
  copyIcon: {
    marginLeft: theme.spacing(2),
  },
  startIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default React.memo(MeetingInfoDialog);



import userActions from "@actions/userActions";
import MoxtraService, { IScheduleMeetingOptions } from "@services/Moxtra.service";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector.hook";
import useService from "./useService.hook";

export const useMoxtra = () => {
  const moxtraService = useService(MoxtraService);
  const userId = useAppSelector((state) => state.authentication.userId);
  const isGuest = !Boolean(userId);
  const refreshToken = useAppSelector(
    (state) => state.authentication.refreshToken
  );
  const dispatch = useDispatch();

  const requestAccessToken = async () => {
    try {
      if (!userId)
        throw new Error("Error requesting Moxtra Token. Not authenticated.");
      const { access_token, expires_in } = await moxtraService.GetAccessToken(
        String(userId)
      );
      const expirationDate =
        new Date().getTime() + Number(expires_in) * 1000 - 30 * 1000;

      dispatch(userActions.setMoxtraAccessToken(access_token, expirationDate));
    } catch (error) {
      throw error;
    }
  };

  const requestAccessTokenForGuest = async () => {
    try {
      const {
        access_token,
        expires_in,
      } = await moxtraService.GetAccessTokenForGuest();
      const expirationDate =
        new Date().getTime() + Number(expires_in) * 1000 - 30 * 1000;

      dispatch(userActions.setMoxtraAccessToken(access_token, expirationDate));
    } catch (error) {
      throw error;
    }
  };
  return {
    requestAccessToken,
    requestAccessTokenForGuest,
    isGuest,
  };
};

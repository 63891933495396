import apiConstants from "@constants/apiConstants";
import HttpClient from "./HttpClient";
import {
  IApiResponseWithPagination,
  IApiResponse,
} from "../models/IPagination";
import {
  IContact,
  IContactBody,
  IContactPhoneNumberBody,
  IContactPhoneNumber,
} from "@models/Contacts.models";
import { GET_TEN_THOUSAND_CONTACTS } from "@components/Contacts/constants";

const ContactsService = () => {
  const url = apiConstants().endpoints.contacts.BASE_URL;
  const addPhoneNumberUrl = apiConstants().endpoints.contacts.ADD_PHONE_NUMBER;
  const { Get, Delete, Put, Post } = HttpClient();

  const GetContacts = async (
    page?: number,
    pageSize?: number,
    contactName?: string
  ) => {
    try {
      const params = new URLSearchParams();
      params.set("page", String(page ?? 1));
      params.set("pageSize", String(pageSize ?? GET_TEN_THOUSAND_CONTACTS));
      contactName && params.set("contactName", contactName);
      const response = await Get<IApiResponseWithPagination<IContact[]>>({
        url,
        params,
      });
      return { data: response.data.data, pagination: response.data.pagination };
    } catch (error) {
      throw error;
    }
  };

  const CreateOne = async (body: IContactBody) => {
    try {
      const { data } = await Post<IApiResponse<IContact>, IContactBody>({
        url,
        data: body,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  // const UpdatePhoneNumber = async (
  //   id: string,
  //   body: IContactPhoneNumberBody
  // ) => {
  //   try {
  //     const { data } = await Post<
  //       IApiResponse<IContactPhoneNumber>,
  //       IContactPhoneNumberBody
  //     >({
  //       url: addPhoneNumberUrl(id),
  //       data: body,
  //     });
  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const UpdatePhoneNumber = async (contactId: string, id: string, body: IContactPhoneNumberBody) => {
    try {
        return await Put<IApiResponse<IContactPhoneNumber>, IContactPhoneNumberBody>({
            url: addPhoneNumberUrl(contactId),
            data: body,
            id
        });
    } catch (error) {
        throw error
    };
};

  const UpdateOne = async (id: string, body: IContactBody) => {
    try {
      return await Put<IApiResponse<IContact>, IContactBody>({
        url,
        id,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  };

  const AddPhoneToContact = async (
    contactId: string,
    body: IContactPhoneNumberBody
  ) => {
    try {
      return await Post<
        IApiResponse<IContactPhoneNumber>,
        IContactPhoneNumberBody
      >({
        url: addPhoneNumberUrl(contactId),
        data: body,
      });
    } catch (error) {
      throw error;
    }
  };

  const DeleteExistingPhoneNumber = async (contactId: string, id: string) => {
    try {
      return await Delete({
        url: addPhoneNumberUrl(contactId),
        id,
      });
    } catch (error) {
      throw error;
    }
  };

  const DeleteOne = async (contactId: string) => {
    try {
      return await Delete({
        url,
        id: contactId,
      });
    } catch (error) {
      throw error;
    }
  };

  return {
    GetContacts,
    CreateOne,
    UpdatePhoneNumber,
    UpdateOne,
    DeleteOne,
    AddPhoneToContact,
    DeleteExistingPhoneNumber,
  };
};

export default ContactsService;

import { isContactPhoneNumberValid, requiredField, companyLength,roleLength } from "@helpers/formHelpers/formValidatorHelpers";
import { emailValidator } from "@helpers/functions/contacts";
import { ContactPhoneTypeEnum, IContactFormData } from "@models/Contacts.models";

export const FIRST_CONTACTS_INDEX = 0;
export const CONTACTS_INITIAL_PAGE = 1;
export const CREATE_A_NEW_CONTACT = "Create a New Contact";
export const UPDATE_EXISTING_CONTACT = "Update Existing Contact";
export const CONTACT_NAME = "Contact Name";
export const PHONE_NUMBER = "Phone Number";
export const EMAIL_ADDRESS = "Email Address";
export const COMPANY = "Company";
export const ROLE = "Role";
export const URL = "URL";
export const SAVE_CONTACT = "Create Contact";
export const UPDATE_CONTACT = "Update Contact";
export const ADD_CONTACT_TOOLTIP_LABEL = "Add Contact";
export const ADD_NUMBER = "Add Number";
export const ADD_EMAIL = "Add Email";
export const ADD_URL = "Add URL";
export const ADD_CONTACT = "Update Contact";
export const ADD_CONTACT_BTN_LABEL = "Add Contact";
export const MAX_FIELD_COUNT = 3;
export const CONTACTS_NAME_PLACEHOLDER = "John Smith";
export const CONTACTS_COMPANY_PLACEHOLDER = "Company name";
export const CONTACTS_ROLE_PLACEHOLDER = "Role";
export const CONTACTS_URL_PLACEHOLDER = "www.talkroute.com";
export const CONTACTS_PHONE_NUMBER_PLACEHOLDER = "1 (234) 555-7979";
export const CONTACTS_EMAIL_PLACEHOLDER = "johnsmith@talkroute.com";
export const NO_PHONE_NUMBER_AVAILABLE = "No phone number available.";
export const FAST_DIAL = "Fast Dial";
export const GET_TEN_THOUSAND_CONTACTS = 10000;

export const contactDropdownOptions: { text: ContactPhoneTypeEnum, value: number }[] = [
  { text: ContactPhoneTypeEnum.MOBILE, value: 0 },
  { text: ContactPhoneTypeEnum.OFFICE, value: 1 },
  { text: ContactPhoneTypeEnum.HOME, value: 2 },
  { text: ContactPhoneTypeEnum.OTHER, value: 3 },
];

export const phoneTypeOptions: { text: ContactPhoneTypeEnum, value: ContactPhoneTypeEnum }[] = 
[
  { text: ContactPhoneTypeEnum.MOBILE, value: ContactPhoneTypeEnum.MOBILE },
  { text: ContactPhoneTypeEnum.OFFICE, value: ContactPhoneTypeEnum.OFFICE },
  { text: ContactPhoneTypeEnum.HOME, value: ContactPhoneTypeEnum.HOME },
  { text: ContactPhoneTypeEnum.OTHER, value: ContactPhoneTypeEnum.OTHER },
];

export const contactFormInitialValues = {
  name: { initialValue: "", validators: [requiredField()] },
  phoneNumbers: { 
    initialValue: [{ phoneNumber: "", type: ContactPhoneTypeEnum.MOBILE, id: "" }], 
    validators: [isContactPhoneNumberValid()] 
  },
  phoneNumberFieldsCount: { initialValue: 1, validators: [] },
  emails: { initialValue: [""], validators: [emailValidator] },
  emailAddressFieldsCount: { initialValue: 1, validators: [] },
  company: { initialValue: "", validators: [companyLength()] },
  notes: { initialValue: "", validators: [] },
  role: { initialValue: "", validators: [roleLength()] },
  url: { initialValue: "", validators: [] },
  id: { initialValue: "", validators: [] }
};

export const contactInitialValues: IContactFormData = {
  name: "",
  phoneNumbers: [{ phoneNumber: "", type: ContactPhoneTypeEnum.MOBILE, id: "" }],
  phoneNumberFieldsCount: 1,
  emails: [""],
  emailAddressFieldsCount: 1,
  id: "",
  company: "",
  notes: "",
  role: "",
  url: "",
};
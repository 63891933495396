export const SET_IS_MIC_BLOCKED = "SET_IS_MIC_BLOCKED";
export const OPEN_MIC_UNAVAILABLE_MODAL = "OPEN_MIC_UNAVAILABLE_MODAL";
export const CLOSE_MIC_UNAVAILABLE_MODAL = "CLOSE_MIC_UNAVAILABLE_MODAL";
export const START_MIC_CHECK = "START_MIC_CHECK"
export const FINISH_MIC_CHECK = "FINISH_MIC_CHECK"

export const openMicUnavailableModal = () => ({
  type: OPEN_MIC_UNAVAILABLE_MODAL,
});

export const closeMicUnavailableModal = () => ({
  type: CLOSE_MIC_UNAVAILABLE_MODAL,
});

export const startMicCheck = () => ({
  type: START_MIC_CHECK,
});

export const finishMicCheck = () => ({
  type: FINISH_MIC_CHECK,
});

export const setIsMicBlocked = (payload) => ({
  type: SET_IS_MIC_BLOCKED,
  payload,
});

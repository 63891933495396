import {
  DialogContent,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Theme,
} from "@material-ui/core";
import {
  AudioTypes,
  IAudioFile,
  IAudioFileBody,
  IAudioFileDescription,
} from "@models/AudioFiles.models";
import AudioFilesService from "@services/AudioFiles.service";
import React, { useCallback, useEffect, useState } from "react";
import uiString, { ApiErrorMessages } from "../../../constants/uiString";
import InputButton from "../FormComponents/InputButton";
import AudioFileRecorder from "./AudioFileRecorder";
import Dialog from "../../PresentationComponents/Dialog/Dialog";
import DialogActions from "../../PresentationComponents/Dialog/DialogActions";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import AudioFileDescriptionForm from "./AudioFileDescriptionForm";
import { InputErrorText } from "../FormComponents/InputErrorText";
import { AUDIO_FILE_SIZE_ERROR } from "./constants";
import { useToasts } from "react-toast-notifications";

const AudioFilesStrings = uiString.SETTINGS_AND_PREF.AUDIO_FILES;

type IProps = {
  open: boolean;
  close: () => any;
  onSubmit: (item: IAudioFile) => any;
  descriptionDefaultValues?: IAudioFileDescription;
};

const getSteps = () => {
  return [
    AudioFilesStrings.ADD_FILE_DIALOG.FORM_STEP_ONE,
    AudioFilesStrings.ADD_FILE_DIALOG.FORM_STEP_TWO,
  ];
};

export const AudioFileRecordingDialog = ({
  open,
  close,
  onSubmit,
  descriptionDefaultValues,
}: IProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [audioForm, setAudioForm] = useState<IAudioFileBody>({
    name: "",
    type: AudioTypes.ANNOUNCEMENT,
    audio: null,
  });
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const { CreateOne } = useService(AudioFilesService);
  const { addToast } = useToasts()
  const steps = getSteps();

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setFormError(null);
      setError(false);
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    error && setAudioForm((prevForm) => ({ ...prevForm, audio: null }));
  }, [error]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      if (audioForm.name.length === 0)
        throw Error(AudioFilesStrings.NAME_REQUIRED_ERROR);
      if (audioForm.name.length > 50)
        throw Error(AudioFilesStrings.NAME_CHAR_LIMIT_ERROR);
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("audio", audioForm.audio as File);
      formData.append("name", audioForm.name);
      formData.append("type", audioForm.type);
      formData.append("isDefault", "0");
      const { data } = await CreateOne(formData);
      onSubmit(data);
      setIsSubmitting(false);
      close();
      resetForm();
    } catch (error) {
      setIsSubmitting(false);
      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  const resetForm = () => {
    setAudioForm({
      name: "",
      type: AudioTypes.ANNOUNCEMENT,
      audio: null,
    });
  };

  const onDialogClose = useCallback(() => close(), []);

  const handleFormChange = useCallback((form: IAudioFileDescription) => {
    setFormError(null);
    setAudioForm((prevForm) => ({
      ...prevForm,
      name: form.name,
      type: form.type as AudioTypes,
    }));
  }, []);

  const onRecordSuccess = useCallback((file: File | null, _url: string) => {
    setAudioForm((prevForm) => ({ ...prevForm, audio: file }));
  }, []);

  const onRecordAbort = useCallback(() => {
    setAudioForm((prevForm) => ({ ...prevForm, audio: null }));
    setIsRecording(false);
  }, []);

  const onRecordStart = useCallback(() => setIsRecording(true), []);

  const onRecordStop = useCallback(() => setIsRecording(false), []);

  const onErrorUpload = useCallback(
    (isError: boolean) => setError(isError),
    []
  );

  return (
    <Dialog
      title={AudioFilesStrings.ADD_FILE_DIALOG.TITLE}
      open={open}
      onClose={onDialogClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent className={classes.extendContent}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className={classes.progressIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>
          {activeStep === 0 && (
            <AudioFileRecorder
              onRecord={onRecordSuccess}
              onAbort={onRecordAbort}
              onStart={onRecordStart}
              onStop={onRecordStop}
              onError={onErrorUpload}
              audioFile={audioForm.audio}
            />
          )}
          {activeStep === 1 && (
            <AudioFileDescriptionForm
              error={formError}
              onChange={handleFormChange}
              defaultValues={descriptionDefaultValues}
            />
          )}
        </div>
        {error && (
          <InputErrorText
            standalone
            style={{ fontSize: "0.75rem", marginTop: 5 }}
          >
            {AUDIO_FILE_SIZE_ERROR}
          </InputErrorText>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep === 1 && (
          <>
            {/* <InputButton
              color="primary"
              onClick={close}
              label={uiString.confirmModal.CANCEL}
              variant="outlined"
            /> */}
            <InputButton
              color="primary"
              onClick={handleBack}
              label={uiString.initialModal.BACK}
              variant="outlined"
            />
          </>
        )}
        {activeStep === 0 && (
          <>
            <InputButton
              color="inherit"
              onClick={close}
              label={uiString.confirmModal.CANCEL}
            />

            <InputButton
              disabled={!audioForm.audio || isRecording || error}
              onClick={handleNext}
              label={uiString.initialModal.NEXT}
              variant="outlined"
              color="primary"
            />
          </>
        )}
        {activeStep === 1 && (
          <InputButton
            disabled={isSubmitting}
            color="secondary"
            onClick={handleSubmit}
            label={uiString.SAVE_CHANGES}
            isLoading={isSubmitting}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

// used so that whenever the next button's state is updated to disabled or enabled
// the background color remains white instead of pumpkin orange
const nextButtonBackgroundColor = "#fff";

const useStyles = makeStyles((theme: Theme) => ({
  paperOverride: {
    background: "transparent",
    height: 130,
    boxShadow: "none",
  },
  extendContent: {
    minHeight: 254,
  },
  progressIcon: {
    "& > .MuiStepLabel-iconContainer > .MuiStepIcon-active": {
      color: theme.palette.common.pumpkinOrange,
    },
    "& > .MuiStepLabel-iconContainer > .MuiStepIcon-completed": {
      color: theme.palette.common.pumpkinOrange,
    },
  },
}));

import { SET_IS_MIC_BLOCKED, OPEN_MIC_UNAVAILABLE_MODAL, CLOSE_MIC_UNAVAILABLE_MODAL, START_MIC_CHECK, FINISH_MIC_CHECK } from "@actions/micActions"

export interface IMicReducer {
    micIsBlocked: boolean;
    showMicUnavailableModal: boolean;
    micCheckInProgress: boolean;
}

const initialState = {
    micIsBlocked: false,
    showMicUnavailableModal: false,
    micCheckInProgress: false
}

export default function micStatus(state: IMicReducer = initialState, action): IMicReducer {
    switch(action.type) {
        case SET_IS_MIC_BLOCKED:
            return {...state, micIsBlocked: action.payload}
        case OPEN_MIC_UNAVAILABLE_MODAL:
            return {...state, showMicUnavailableModal: true}
        case CLOSE_MIC_UNAVAILABLE_MODAL:
            return {...state, showMicUnavailableModal: false}
        case START_MIC_CHECK:
            return {...state, micCheckInProgress: true}
        case FINISH_MIC_CHECK:
            return {...state, micCheckInProgress: false}    
        default:
            return state
    }
}

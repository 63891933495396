import { requiredField, validateEmail } from "@helpers/formHelpers/formValidatorHelpers";
import { AudioTypes, IAudioFile, IAudioFileDescription } from "@models/AudioFiles.models";

export const INPUT_DESCRIPTION_PLACEHOLDER = "Sales Voicemail Box";
export const INPUT_DESCRIPTION_LABEL = "Description";
export const INPUT_DESCRIPTION_REQUIRED_ERROR = "Description is required";
export const INPUT_GREETING_PLACEHOLDER = "Select Greeting";
export const INPUT_DISPLAY_TRANSCRIPTS_LABEL = "Show Voice Message Transcripts";
export const INPUT_SHOW_TRANSCRIPTS_LABEL = "Show Transcripts in Email";
export const INPUT_ATTACH_AUDIO_LABEL = "Attach Audio File to Email";

export const INPUT_EMAIL_LABEL = "Voicemail to E-Mail";
export const INPUT_EMAIL_PLACEHOLDER = "example@company.com";
export const INPUT_EMAIL_ERROR = "Please enter a valid email address";

export const GENERIC_ERROR_MESSAGE = "There was a problem handling your request";
export const GENERIC_SUCCESS_MESSAGE = "Your action has completed successfully!";
export const MANAGE_VOICEMAILS = "Voicemail";
export const THIS_ACTION_IS_NOT_SUPPORTED = "This action is not supported by your subscription plan. Visit the ";
export const PAGE_FOR_MORE_DETAILS = " page for more details.";
export const PLANS_AND_PRICING = "Plans & Pricing";

export const GREETING_AUDIO_ID = "greetingAudioId";

export const vboxFormInitialValues = {
  id: { initialValue: "", validators: [] },
  addTranscriptToEmail: { initialValue: false, validators: [] },
  displayTranscripts: { initialValue: false, validators: [] },
  addAudioToEmail: { initialValue: false, validators: [] },
  greetingAudioId: { initialValue: "", validators: [requiredField()] },
  description: { initialValue: "", validators: [requiredField(INPUT_DESCRIPTION_REQUIRED_ERROR)] },
  email: { initialValue: "", validators: [requiredField(), validateEmail(INPUT_EMAIL_ERROR)] },
};

export const vboxInitialValues = {
  id: "",
  addTranscriptToEmail: false,
  displayTranscripts: false,
  addAudioToEmail: false,
  greetingAudioId: "",
  description: "",
  email: "",
}

export const AudioFileRecordingDefaultValues: Omit<IAudioFile, "createdAt"> = {
  id: '',
  link: '',
  name: '',
  type: AudioTypes.VOICEMAIL_GREETING,
  isDefault: true,
};

export const defaultVoicemailValues: IAudioFileDescription = {
  name: '',
  type: AudioTypes.VOICEMAIL_GREETING,
};

import { ComponentDimensions } from '@constants/appConstants'
import { makeStyles, ListItem } from '@material-ui/core'
import React from 'react'

type IProps = {
  id: string
  onClick: (value: string) => void
  activeItem: string
  value: string
  disabled?: boolean
}

/**
 * Renders a list item in the navigation menu
 * @param onClick sets the activeItem to the current value
 * @param activeItem is the current active navigation page
 */
const PreferenceNavItem: React.FC<IProps> = (
  { id, onClick, activeItem, value, disabled }
) => {
  const classes = useStyles()
  const listItemStyle = 
    { fontSize: ComponentDimensions.PREFERENCES_NAVIGATION_LINK_FONT_SIZE, padding: '8px 16px' }

  return (
    <ListItem
      disabled={disabled}
      button
      id={id}
      disableGutters
      onClick={() => onClick(value)}
      className={
        activeItem === value
          ? `text-normal ${classes.activeItem}`
          : `text-normal ${classes.noneActive}`
      }
      style={listItemStyle}
    >
      {value}
    </ListItem>
  )
}

const useStyles = makeStyles({
  activeItem: {
    backgroundColor: 'var(--color-navy-blue)',
    color: 'white',

    '&:hover': {
      backgroundColor: 'var(--color-navy-blue)',
    }
  },
  noneActive: {
    '&:hover': {
      backgroundColor: 'white',
    }
  },
})

export default PreferenceNavItem
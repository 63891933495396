import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import Loader from "@components/PresentationComponents/Loader";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import {
  TableDeleteButton,
  TableEditButton,
} from "@components/PresentationComponents/TableComponents/TableHelpers";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { ComponentDimensions } from "@constants/appConstants";
import uiString, { ApiErrorMessages } from "@constants/uiString";
import useAccessBlocker from "@helpers/hooks/useAccessBlocker.hook";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { FeatureIdsEnum } from "@models/Account.models";
import { IAudioFile } from "@models/AudioFiles.models";
import {
  IHoursOfOperation,
  IHoursOfOperationBody,
  IOffHoursOptionsBody,
} from "@models/HoursOfOperation.models";
import { IPagination } from "@models/IPagination";
import { IMenu } from "@models/Menu.models";
import { IVoicemailBox } from "@models/Voicemailbox.models";
import SettingsSvg from "@resources/icons/main-nav-preferences.svg";
import AudioFilesService from "@services/AudioFiles.service";
import HoursOfOperationService from "@services/HoursOfOperation.service";
import MenuService from "@services/MenuService";
import VoicemailboxService from "@services/Voicemailbox.service";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import InputButton from "../PresentationComponents/FormComponents/InputButton";
import AddTimeSlotDialog from "./AddTimeSlotDialog";
import CallResponseDialog from "./CallResponseDialog";
import {
  ADD_TIME_SLOT,
  hoursOfOperationHelpers,
  IHoursOfOperationForm,
  noneAudioFile,
  TimeClockFormat,
} from "./constants";

const {
  formatDayColumn,
  formatTimeColumn,
  formToBody,
} = hoursOfOperationHelpers;

const HoursStrings = uiString.SETTINGS_AND_PREF.HOURS_OF_OPERATION;

const HoursOfOperation = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState<IHoursOfOperation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [formData, setFormData] = useState<IHoursOfOperation | null>(null);
  const [clockFormat, setClockFormat] = useState<TimeClockFormat>(
    TimeClockFormat.BAD
  );
  const [settings, setSettings] = useState<IOffHoursOptionsBody | null>(null);
  const [openDeletionDialog, setOpenDeletionDialog] = useState<number | null>(
    null
  );

  const [voicemailboxOptions, setVoicemailboxOptions] = useState<
    IVoicemailBox[]
  >([]);
  const [audiofileOptions, setAudiofileOptions] = useState<IAudioFile[]>([]);
  const [menuOptions, setMenuOptions] = useState<IMenu[]>([]);
  const [error, setError] = useState<object | null>(null);
  const rowsPerPage = useAppSelector(
    (state) => state.preferences.tableSize.hoursOfOperations
  );
  const [componentIsLoaded, setComponentIsLoaded] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addToast } = useToasts();
  const hoursOfOperationService = useService(HoursOfOperationService);
  const voicemailboxService = useService(VoicemailboxService);
  const audioFilesService = useService(AudioFilesService);
  const menuService = useService(MenuService);
  const { GuardDialog, permissionGuard } = useAccessBlocker(
    FeatureIdsEnum.HOURS_OF_OPERATION
  );

  useEffect(() => {
    return () => setComponentIsLoaded(false);
  }, []);

  useEffect(() => {
    initialize(!firstLoad);
  }, [rowsPerPage]);

  const initialize = async (onlyGetNumbers: boolean) => {
    try {
      setIsLoading(true);
      null && setError(null);
      if (onlyGetNumbers) {
        await GetTimeslots();
      } else {
        await Promise.all([
          GetTimeslots(),
          audioFileOptionsGET(),
          voicemailOptionsGET(),
          menuOptionsGET(),
          settingsGET(),
        ]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const GetTimeslots = async (page?: number) => {
    try {
      const { data, pagination } = await hoursOfOperationService.GetByPage(
        page ?? pageState?.currentPage ?? 1,
        rowsPerPage
      );
      if (componentIsLoaded) {
        setTableData(data);
        setPageState(pagination);
        setFirstLoad(false);
      }
    } catch (err) {
      console.error(err);
      setError(err as object);
      setFirstLoad(false);
    }
  };

  const AddTimeslot = async (formData: IHoursOfOperationBody) => {
    try {
      const body = formData;
      const { data } = await hoursOfOperationService.CreateOne(body);
      if (componentIsLoaded) {
        await GetTimeslots();
        setOpenDialog(false);
      }
    } catch (error) {
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  };

  const EditTimeslot = async (formData: IHoursOfOperationBody, id: number) => {
    try {
      const body = formData;
      const { data } = await hoursOfOperationService.UpdateOne(
        String(id),
        body
      );
      if (componentIsLoaded) {
        setTableData((list) =>
          list.map((item) => (item.id !== id ? item : data))
        );
        setOpenDialog(false);
      }
    } catch (error) {
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  };

  const toggleDialog = permissionGuard(() => {
    setFormData(null);
    setOpenDialog(true);
  });

  const DeleteTimeslot = async (id: number) => {
    try {
      setIsDeleting(true);
      await hoursOfOperationService.DeleteOne(String(id));
      if (componentIsLoaded) {
        await GetTimeslots();
        setOpenDialog(false);
        setIsDeleting(false);
      }
      setOpenDeletionDialog(null);
    } catch (error) {
      setOpenDeletionDialog(null);
      setIsDeleting(false);
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  };

  const audioFileOptionsGET = async () => {
    try {
      const { data, pagination } = await audioFilesService.GetByPage(
        1,
        100,
        null
      );
      componentIsLoaded && setAudiofileOptions([...data, noneAudioFile]);
    } catch (error) {
      throw error;
    }
  };

  const voicemailOptionsGET = async () => {
    try {
      const { data } = await voicemailboxService.GetByPage(1, 100);
      componentIsLoaded && setVoicemailboxOptions(data);
    } catch (error) {
      throw error;
    }
  };

  const menuOptionsGET = async () => {
    try {
      const { data } = await menuService.GetByPage(1, 1000);
      componentIsLoaded &&
        setMenuOptions(
          data.filter(
            (menu) =>
              menu.enabled && menu.parentId === null && !menu.announceOnly
          )
        );
    } catch (error) {
      throw error;
    }
  };

  const settingsGET = async () => {
    try {
      const { data } = await hoursOfOperationService.GetSettings();
      componentIsLoaded && setSettings(data);
    } catch (error) {
      throw error;
    }
  };

  const onSettingsSave = (settings: IOffHoursOptionsBody) => {
    setSettings(settings);
  };

  const refreshAudioOptions = audioFileOptionsGET;

  const handleFormSubmit = (form: IHoursOfOperationForm) => {
    const body = formToBody(form);
    return formData ? EditTimeslot(body, formData.id) : AddTimeslot(body);
  };

  const handlePageChange = (page: number) => GetTimeslots(page);

  return (
    <>
      <Loader open={isLoading} />
      <UnderlinedTitle>
        {uiString.hoursOfOperations.HOURS_OF_OPERATION}
      </UnderlinedTitle>
      {!firstLoad && (
        <Grid className={classes.content}>
          <Grid className={classes.controls}>
            <ToggleButtonGroup
              exclusive
              aria-label="Clock format"
              className={classes.toggleGroup}
              value={clockFormat}
              onChange={(_e, value: TimeClockFormat) => setClockFormat(value)}
            >
              <ToggleButton
                className={classes.toggleButton}
                color="primary"
                value={TimeClockFormat.BAD}
              >
                {TimeClockFormat.BAD}
              </ToggleButton>
              <ToggleButton
                className={classes.toggleButton}
                color="primary"
                value={TimeClockFormat.GOOD}
              >
                {TimeClockFormat.GOOD}
              </ToggleButton>
            </ToggleButtonGroup>
            <Grid>
              <InputButton
                onClick={toggleDialog}
                className={classes.addTimeBtn}
                color="primary"
                minWidth={ComponentDimensions.PREFERENCES_ADD_BUTTON_WIDTH}
                plusIcon
              >
                {ADD_TIME_SLOT}
              </InputButton>
              <AddTimeSlotDialog
                open={openDialog}
                close={() => setOpenDialog(false)}
                formData={formData}
                onSubmit={handleFormSubmit}
                clockFormat={clockFormat}
              />
              <Tooltip
                styling="standard"
                placement="top"
                title={uiString.tooltips.SETTINGS_BUTTON}
              >
                <Button
                  className={classes.settingsButton}
                  onClick={() => setOpenResponseDialog(true)}
                >
                  <SettingsSvg style={{ width: "23px", height: "23px" }} />
                </Button>
              </Tooltip>
              <CallResponseDialog
                open={openResponseDialog}
                close={() => setOpenResponseDialog(false)}
                voicemailboxOptions={voicemailboxOptions}
                audiofileOptions={audiofileOptions}
                menuOptions={menuOptions}
                refreshAudioOptions={refreshAudioOptions}
                defaultValues={settings}
                onSave={onSettingsSave}
              />
            </Grid>
          </Grid>
          <>
            {!isLoading && (tableData.length > 0 || isLoading) ? (
              <CustomTablePagination
                pagination={pageState}
                handlePageChange={handlePageChange}
                storeKey="hoursOfOperations"
              >
                <PlainTable
                  data={tableData}
                  selectColumns={["days", "start time", "end time"]}
                  columnSize={["28%", "130px", "auto"]}
                  dataMapper={(hourofop) => ({
                    ...hourofop,
                    days: formatDayColumn(hourofop.dayStart, hourofop.dayEnd),
                    "start time": formatTimeColumn(
                      hourofop.hourStart,
                      hourofop.minuteStart,
                      clockFormat
                    ),
                    "end time": formatTimeColumn(
                      hourofop.hourEnd,
                      hourofop.minuteEnd,
                      clockFormat
                    ),
                  })}
                  actions={[
                    {
                      colLabel: uiString.SETTINGS,
                      width:
                        ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                      ActionComponent: (row) => (
                        <TableEditButton
                          row={row}
                          handler={permissionGuard((element) => {
                            setFormData(element);
                            setOpenDialog(true);
                          })}
                        />
                      ),
                    },
                    {
                      colLabel: uiString.DELETE,
                      width:
                        ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                      ActionComponent: (row) => (
                        <TableDeleteButton
                          row={row}
                          handler={(element) =>
                            setOpenDeletionDialog(element.id)
                          }
                        />
                      ),
                    },
                  ]}
                  fullWidth
                />
              </CustomTablePagination>
            ) : (
              <Grid className={classes.center}>
                <Typography variant="h5">
                  {error
                    ? HoursStrings.ERROR_ON_FETCHING
                    : HoursStrings.EMPTY_RESPONSE_MESSAGE}{" "}
                  {error && (
                    <Button color="primary" onClick={() => initialize(false)}>
                      {uiString.RETRY}
                    </Button>
                  )}
                </Typography>
              </Grid>
            )}
          </>
        </Grid>
      )}
      <ConfirmDialog
        header={uiString.DELETE_TIMESLOT_CONFIRMATION_HEADER}
        content={uiString.DELETE_TIMESLOT_CONFIRMATION_BODY}
        open={Boolean(openDeletionDialog)}
        confirmButtonLabel={uiString.DELETE}
        loading={isDeleting}
        onCancel={() => setOpenDeletionDialog(null)}
        onConfirm={() =>
          openDeletionDialog && DeleteTimeslot(openDeletionDialog)
        }
      />
      <GuardDialog />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "100%",
    },
    controls: {
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    toggleButton: {
      fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
      padding: "6px 20px",
      lineHeight: "normal",
      height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
    },
    toggleGroup: {
      height: "fit-content",
    },
    addTimeBtn: {
      fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
      height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
    },
    settingsButton: {
      marginLeft: theme.spacing(1),
    },
  })
);

export default HoursOfOperation;

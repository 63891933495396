import { AUDIO_INPUT, AUDIO_OUTPUT, TEST_AUDIO_OUTPUT } from "@constants/audioConstants";
import { DeviceTypes } from "@models/DeviceTypes.models";
import { NotificationSounds } from "@models/NotificationSounds";

export const MicLevelVisualizerBarColors = {
  active: "#69ce2b",
  inactive: "#e6e7e8"
};
export const testSoundFilePath = TEST_AUDIO_OUTPUT;
export const deviceTypes: DeviceTypes = {
  INPUT: AUDIO_INPUT,
  OUTPUT: AUDIO_OUTPUT,
};
export const textNotificationOptionName = NotificationSounds.TEXT_NOTIFICATION;
export const callNotificationOptionName = NotificationSounds.CALL_NOTIFICATION;
import { CallEventType } from "@models/Callhistory.models";
import moment, { Moment } from "moment";

// events: [{id: "63211", type: "talkroute_number", description: " <+17086698634>",…},…]
// 0: {id: "63211", type: "talkroute_number", description: " <+17086698634>",…}
// createdAt: "2021-01-26T23:47:50+00:00"
// description: " <+17086698634>"
// id: "63211"
// type: "talkroute_number"
// 1: {id: "1", type: "voicemail", description: "Default Mailbox", createdAt: "2021-01-26T23:48:33+00:00"}
// createdAt: "2021-01-26T23:48:33+00:00"
// description: "Default Mailbox"
// id: "1"
// type: "voicemail"
// 2: {id: "84863092", type: "call_end", description: "missed", createdAt: "2021-01-26T23:48:33+00:00"}

// createdAt: "2021-01-26T23:48:33+00:00"
// description: "missed"
// id: "84863092"
// type: "call_end"


export class CallDataEvent {
  public id: number;
  public type: CallEventType;
  public createdAt: Moment;
  public description: string;

  constructor(opts) {
    this.id = opts.id;
    this.type = opts.type;
    this.createdAt = opts.createdAt;
    this.description = opts.description;
  }

  getDescription = () => {
    return this.description;
  }
}

export const callHistoryEventsJSONParser = (eventsData: any[]) => {
  const events = eventsData.map((eventData) => {
    return new CallDataEvent(eventData);
  });
  return events;
};

import { IScheduledMeeting, IScheduledMeetingDetailed } from "@models/Moxtra.models";
import kingcobraApi from "./kingcobraApi"
import apiConstants from "@constants/apiConstants";
import { IApiResponse } from "@models/IPagination";
import { mutationFilter } from "@helpers/index";
import mergeMeetLists from "@helpers/mergeMeetLists";
import { original } from "immer";
import { isAxiosError } from "@utils";
import { localStorageItems } from "@helpers/hooks/useLocalStorage.hook";

type IScheduledMeetingRecurrence = {
    frequency?: string;
    endTime?: string;
    daily?: {
        interval: number;
    };
    weekly?: {
        interval: number;
        weekDays: "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"[];
    };
    monthly?: {
        monthDays: number;
    };
};

type IScheduleMeetingOptions = {
    name: string;
    starts?: string;
    ends?: string;
    startTime?: number;
    endTime?: number;
    agenda?: string;
    originalBinderId?: string;
    autoRecording?: boolean;
    hideRecordingCtrl?: boolean;
    joinBeforeMinutes?: number;
    recordMultipleVideoChannel?: boolean;
    tags?: [
        {
            name: string;
            value: string;
        }
    ];
    notificationOff?: boolean;
    hostVideoOn?: boolean;
    participantVideoOn?: boolean;
    recurrence?: IScheduledMeetingRecurrence;
    host: string;
};

const _apiConstants = apiConstants();
const sendEmailInviteUrl = _apiConstants.endpoints.moxtrav2
    .SEND_EMAIL_INVITATION;

// used to save meetings to localstorage 
// should be removed once a better persistence method is implemented
class MeetCacheManager {
    private static _instance: MeetCacheManager;
    private constructor() { }
    static get instance() {
        if (!MeetCacheManager._instance) {
            MeetCacheManager._instance = new MeetCacheManager();
        }
        return MeetCacheManager._instance;
    }
    deleteBySessionKey(sessionKey: string) {
        const cache = localStorage.getItem(localStorageItems.meetCache);
        if (cache) {
            const parsedCachedMeetings = JSON.parse(cache) as IScheduledMeeting[];
            const filteredMeetings = parsedCachedMeetings.filter(
                (meet) => meet.session_key !== sessionKey
            );
            localStorage.setItem(
                localStorageItems.meetCache,
                JSON.stringify(filteredMeetings)
            );
        }
    }
    deleteSpecificSession(meeting: IScheduledMeeting) {
        const cache = localStorage.getItem(localStorageItems.meetCache);
        if (cache) {
            const parsedCachedMeetings = JSON.parse(cache) as IScheduledMeeting[];
            const filteredMeetings = parsedCachedMeetings.filter(
                (meet) =>
                    meet.session_key !== meeting.session_key &&
                    meet.scheduled_starts !== meeting.scheduled_starts
            );
            localStorage.setItem(
                localStorageItems.meetCache,
                JSON.stringify(filteredMeetings)
            );
        }
    }
    
    deleteAll() {
        localStorage.removeItem(localStorageItems.meetCache);
    }

    save(meeting: IScheduledMeeting) {
        const cache = localStorage.getItem(localStorageItems.meetCache);
        let cachedMeetingsList: IScheduledMeeting[] = [];
        if (cache) {
            const parsedCachedMeetings = JSON.parse(cache) as IScheduledMeeting[];
            cachedMeetingsList.push(...parsedCachedMeetings);
        }
        cachedMeetingsList.push(meeting);
        localStorage.setItem(
            localStorageItems.meetCache,
            JSON.stringify(cachedMeetingsList)
        );
    }

    replaceMeetings(meetings: IScheduledMeeting[]) {
        localStorage.setItem(
            localStorageItems.meetCache,
            JSON.stringify(meetings)
        );
    }

    getMeetings() {
        const cache = localStorage.getItem(localStorageItems.meetCache);
        if (cache) {
            const parsedCachedMeetings = JSON.parse(cache) as IScheduledMeeting[];
            return parsedCachedMeetings;
        }
        return [];
    }
}

const moxtraSlice = kingcobraApi.injectEndpoints({
    endpoints: builder => ({
        getAllMeetings: builder.query<IScheduledMeeting[], void>({
            query: () => ({
                url: _apiConstants.endpoints.moxtrav2.BASE,
                method: "GET",
            }),
            transformResponse: (response: IApiResponse<IScheduledMeeting[]>, meta, arg) => response.data,
            async onQueryStarted(_unused, { dispatch, queryFulfilled, ...res }) {
                try {
                    const { data } = await queryFulfilled;
                    const _data = [...data];
                    const patchResult = dispatch(moxtraSlice.util.updateQueryData("getAllMeetings", undefined, draft => {
                        let cachedMeetingsList: IScheduledMeeting[] = MeetCacheManager.instance.getMeetings();

                        _data.push(...cachedMeetingsList);
                        /**
                         * `draft` is an immer writable draft with proxies as elements, so 
                         * we must extract the original meetings array before doing computations with it
                         */
                        const extractOriginal = original(draft) || [];

                        const mergedListOfMeets = mergeMeetLists(_data, extractOriginal)
                        draft.splice(0, draft.length);
                        draft.push(...mergedListOfMeets);
                        MeetCacheManager.instance.replaceMeetings(mergedListOfMeets);
                    }))
                } catch (error) {
                    throw error;
                }
            },
            providesTags: ["Meetings"]
        }),
        getMeeting: builder.query<IScheduledMeetingDetailed, {
            meetIdentifier: string;
            moxtraAccessToken?: string | null;
            skipAuth?: boolean;
        }>({
            query: ({meetIdentifier, moxtraAccessToken, skipAuth}) => (
                {
                    url: `${_apiConstants.endpoints.moxtrav2.BASE}/${meetIdentifier}${moxtraAccessToken ? '?moxtraAccessToken=' + moxtraAccessToken : ''}`,
                    method: "GET",
                    usePublicApi: skipAuth
                }
            ),
            transformResponse: (res: IApiResponse<IScheduledMeetingDetailed>) => res.data
        }),
        deleteMeeting: builder.mutation<IScheduledMeeting, {
            meeting: IScheduledMeeting,
            deleteSpecificSession?: boolean,
            skip?: boolean,
        }>({
            query: ({
                meeting,
                deleteSpecificSession = false,
                skip
            }) => (
                {
                    url: `${_apiConstants.endpoints.moxtrav2.BASE}/${meeting.session_key}`,
                    method: "DELETE",
                    skipRequest: skip
                }),
            async onQueryStarted({meeting, skip, deleteSpecificSession}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(moxtraSlice.util.updateQueryData("getAllMeetings", undefined, (draft) => {
                    if (deleteSpecificSession) {
                        mutationFilter(draft, meet => meet.session_key !== meeting.session_key && meet.scheduled_starts !== meeting.scheduled_starts);
                    } else {
                        mutationFilter(draft, meet => meet.session_key !== meeting.session_key);
                    }
                    if (deleteSpecificSession) {
                        MeetCacheManager.instance.deleteSpecificSession(meeting);
                    } else {
                        MeetCacheManager.instance.deleteBySessionKey(meeting.session_key);
                    }
                }))
                try {
                    await queryFulfilled;
                } catch (error) {
                    const err = (error as any).error;
                    if (isAxiosError(err) && err.response?.status === 400) {
                        // do nothing, we don't need to undo in this case
                    } else {
                        patchResult.undo();
                        throw error
                    }
                }
            }
        }),
        sendEmailInvite: builder.mutation<void, { invitees: string[], sessionKey: string }>({
            query: (inviteOptions) => ({
                url: sendEmailInviteUrl(inviteOptions.sessionKey),
                method: "POST",
                data: {
                    invitees: inviteOptions.invitees
                }
            })
        }),
        scheduleMeeting: builder.mutation<{
            sessionKey: string
            binderId: string
        }, IScheduleMeetingOptions>({
            query: (meetOptions) => ({
                url: _apiConstants.endpoints.moxtrav2.BASE,
                method: "POST",
                data: meetOptions
            }),
            // invalidatesTags: ["Meetings"],
            async onQueryStarted(meetOptions, { dispatch, queryFulfilled }) {
                try {
                    const response = await queryFulfilled
                    if (meetOptions.recurrence) {
                        dispatch(moxtraSlice.util.invalidateTags(["Meetings"]));
                    } else {
                        const patchResult = dispatch(moxtraSlice.util.updateQueryData("getAllMeetings", undefined, draft => {
                            const meet: IScheduledMeeting = {
                                session_key: response.data.sessionKey,
                                binder_id: response.data.binderId,
                                scheduled_starts: String(meetOptions.starts),
                                scheduled_ends: String(meetOptions.ends),
                                topic: String(meetOptions.name),
                                host: {
                                    email: "",
                                    id: meetOptions.host
                                }
                            }
                            draft.push(meet);
                            MeetCacheManager.instance.save(meet);
                        }))
                    }
                } catch (error) {
                    throw error;
                }
            }
        })
    })
})

export const {
    useGetMeetingQuery,
    useGetAllMeetingsQuery,
    useDeleteMeetingMutation,
    useScheduleMeetingMutation,
    useSendEmailInviteMutation
} = moxtraSlice;

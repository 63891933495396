
import { appConfig } from "../index";


export enum LOCALSTORAGE_ITEM_KEYS {
  FOOTER_BANNER_CLOSED = 'downloadFooterBanner',
  INITIAL_LOADER_CLOSED = 'initialLoaderClosed'
}

interface LocalStorageManagerProps {
  key: LOCALSTORAGE_ITEM_KEYS
}

class LocalStorageManager {
  public key: LOCALSTORAGE_ITEM_KEYS;
  constructor(opts: LocalStorageManagerProps) {
    this.key = opts.key
  }

  get = () => {
    return appConfig.clientType === "web" &&
      !localStorage.getItem(this.key)
  }

  set = (value: any) => {
    localStorage.setItem(this.key, value);
  }
}

export default LocalStorageManager

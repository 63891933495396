import { RootState } from "@helpers/store/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector.hook";

function useLocalStorage<T>(key: string, initialValue: T, connectToStore?: {
  state: (state: RootState) => T;
  action: (value: T) => any;
} ) {
  // State to store our value
  // if connectToStore is passed, we store value to redux store
  const storedItemFromState = (connectToStore && useAppSelector(connectToStore.state)) as T;
  const dispatch = useDispatch();

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!connectToStore) {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue
    } else {
      return initialValue
    }
  });

  useEffect(() => {
    if (connectToStore) {
      try {
        const item = window.localStorage.getItem(key);
        const parseItem = item ? JSON.parse(item) : initialValue;
        dispatch(connectToStore.action(parseItem))
        
      } catch (error) {
        console.error(error);
        dispatch(connectToStore.action(initialValue))
      }
    } else {
      try {
        const item = window.localStorage.getItem(key);
        setStoredValue(item ? JSON.parse(item) : initialValue)
      } catch (error) {
        console.error(error);
      }
    }
  }, [])


  /**
   * Sets the passed value to either to local state or redux store 
   * @param value 
   */
  const setValue = (value: T) => {
    try {
      // Save state
      connectToStore ? dispatch(connectToStore.action(value)) : setStoredValue(value);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [connectToStore ? storedItemFromState : storedValue, setValue] as const;
}

export default useLocalStorage;

export const localStorageItems = {
  metadata: "metadata",
  lastLoggedInUser: "lastLoggedInUser" ,
  moxtraToken: "moxtraToken",
  moxtraTokenExp: "moxtraTokenExp",
  meetCache: "meetCache",
}

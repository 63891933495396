import getApiConstants from "@constants/apiConstants";
import {
  AudioTypes,
  IAudioFile,
  IAudioFileBody,
  IAudioFileDescription,
} from "@models/AudioFiles.models";
import {
  IApiResponseWithPagination,
  IApiResponse,
} from "../models/IPagination";
import HttpClient from "./HttpClient";

export default function AudioFilesService() {
  const url = getApiConstants().endpoints.audiofilesv2.AUDIOFILES_BASE;

  const { Get, Put, Post, Delete } = HttpClient();

  const GetByPage = async (
    page: number,
    pageSize: number,
    type: AudioTypes | null,
    includeDefaults: boolean = true
  ) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      type && params.set("type", type)
      !includeDefaults && params.set("isDefault", "0")
      const { data } = await Get<IApiResponseWithPagination<IAudioFile[]>>({
        url,
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const GetOne = async (id: string) => {
    try {
      const { data } = await Get<IApiResponse<IAudioFile>>({ url, id });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const CreateOne = async (body: FormData) => {
    try {
      const { data } = await Post<IApiResponse<IAudioFile>, FormData>({
        url,
        data: body,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const DeleteOne = async (id: string) => {
    try {
      await Delete<void>({ url, id });
    } catch (err) {
      throw err;
    }
  };

  const UpdateOne = async (id: string, body: IAudioFileDescription) => {
    try {
      const { data } = await Put<
        IApiResponse<IAudioFile>,
        IAudioFileDescription
      >({ url, id, data: body });
      return data;
    } catch (err) {
      throw err;
    }
  };

  return {
    GetByPage,
    GetOne,
    CreateOne,
    DeleteOne,
    UpdateOne,
  };
}

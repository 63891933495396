/**
 * Because sometimes you do want to mutate the original.
 * Useful when filtering arrays within RTK Query slices
 * @param {T[]} arr - array to be filtered
 * @param {(elem: T) => boolean} callback - callback function to filter array
 * @returns {T[]} - filtered array
 * @example
 * const arr = [1, 2, 3, 4, 5];
 * const filteredArr = mutationFilter(arr, (elem) => elem % 2 === 0);
 * console.log(filteredArr); // [2, 4]
 * console.log(arr); // [2, 4]
 */
export function mutationFilter<T>(arr: T[], callback: (elem: T) => boolean) {
  for (let l = arr.length - 1; l >= 0; l -= 1) {
    if (!callback(arr[l])) arr.splice(l, 1);
  }
  return arr;
}

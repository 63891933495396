import InputButton from '@components/PresentationComponents/FormComponents/InputButton'
import InputField from '@components/PresentationComponents/FormComponents/InputField'
import { MeetingsRouteActions, routeUrlBuilders } from '@constants/routes'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl } from '@material-ui/core'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

type IProps = {
    open: boolean;
    onClose: () => void;
}
const MAX_INPUT_LENGTH = 15;
export const JoinMeetingDialog = ({open, onClose}: IProps) => {
    const [sessionKey, setSessionKey] = useState<string>("")
    const dispatch = useDispatch()
    const joinMeeting = (sessionKey?: string) => {
        if (sessionKey) {
            dispatch(push(routeUrlBuilders.MEETINGS_IFRAME(MeetingsRouteActions.JOIN_MEETING, sessionKey)))
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Join Meeting
            </DialogTitle>
            <DialogContent>
                <FormControl style={{width: 244}}>
                    <InputField 
                        placeholder="Enter Meeting ID, (e.g. 123456789)"
                        value={sessionKey}
                        onChange={(e) => {
                            const isValid = _.isFinite(Number(e.target.value));
                            if ( isValid && e.target.value.length <= MAX_INPUT_LENGTH) {
                                setSessionKey(e.target.value);
                            }
                    }}/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <InputButton
                    label="Join"
                    style={{
                        height: "34px",
                        fontSize: "13px",
                    }}
                    fullWidth
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => joinMeeting(sessionKey)}
                />
            </DialogActions>
        </Dialog>            
    )
}

import { useEffect, useState } from "react";
import { useAppSelector } from "./useAppSelector.hook";
import Recorder from "opus-recorder";

let abortingRecording = false;
const getARandomId = () => Math.random().toString(36).substr(2, 5);

/**
 * Allows a component to use a simple audio recording interface. Supports three operations -
 * start, stop and abort recording. Also includes a variable which tracks whether or not a
 * recording is being done.
 *
 * @example
 * import React from 'react'
 * export const PlayButton = () => {
 *   const { startRecording, stopAudio, abortRecording, isRecording } = useAudioManager();
 *
 *    const handleMicRecording = () => {
 *      if (isRecording) {
 *        stopRecording()
 *      } else {
 *        startRecording().then(res => {
 *           res.blob // the audio blob which was recorded
 *           res.url // a url taken
 *        }).catch(err => {
 *          console.log(err.message); // incase we abort, err.message will signify that we've done so successfully
 *        });
 *      }
 *    };
 *
 *   return (
 *   <>
 *     <button onClick={handleMicRecording}>
 *      { isRecording ? "Stop" : "Record"}
 *     </button>
 *     <button onClick={abortRecording}>
 *       Abort Recording
 *     </button>
 *   </>
 *   )
 * }
 */
export const useRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const inputDeviceVolume = useAppSelector(state => state.preferences.defaultAudioInputVolume);
  const inputDevice = useAppSelector(state => state.preferences.defaultAudioInput);
  const [recorder, setRecorder] = useState<any>();

  useEffect(() => {
    const constraints = {
      audio: {
        deviceId: inputDevice
      }
    };
    const recorderConfig = {
      mediaTrackConstraints: constraints,
      recordingGain: inputDeviceVolume,
      encoderPath: __opusEncoderPath
    };
    const newRecorder = new Recorder(recorderConfig);
    setRecorder(newRecorder);

    return () => {
      if (isRecording)
        abortRecording();
    }
  }, []);

  const startRecording = (fileName: string = "audio-file") => new Promise<{
    file: File;
    url: string;
  }>((resolve, reject) => {
    abortingRecording = false;
    if (recorder) {
      recorder
        .start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e: any) => {
          reject(e);
        });
      recorder.ondataavailable = (array: Uint8Array) => {
        const file = new File([array.buffer], `${fileName}-${getARandomId()}.wav`, {
          type: "audio/wav",
          lastModified: Date.now()
        });

        abortingRecording
          ? reject({ message: "Aborting recording!" })
          : resolve({ file, url: window.URL.createObjectURL(file) });
      };
    }
  });

  const stopRecording = () => {
    recorder?.stop();
    setIsRecording(false);
  };

  const abortRecording = () => {
    abortingRecording = true;
    stopRecording();
  };

  return {
    stopRecording,
    startRecording,
    abortRecording,
    isRecording
  };
};

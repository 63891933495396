import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import CopyIcon from "@resources/icons/copy-icon.svg";
import React from "react";
import { useToasts } from "react-toast-notifications";

const TablePhoneNumberCell = ({phoneNumber, rawPhoneNumber} : {phoneNumber?: string, rawPhoneNumber?: string}) => {
  const { addToast } = useToasts();
  const handleCopy = (phoneNumber: string, rawPhoneNumber: string) => {
    addToast("Copied to clipboard!", { appearance: 'success', autoDismiss: true });
    navigator.clipboard.writeText(rawPhoneNumber);
  }
  return phoneNumber ? 
    <InputButton 
    label={phoneNumber} 
    onClick={() => handleCopy(phoneNumber, rawPhoneNumber as string)}
    variant="text"
    style={{
      padding: 12,
      color: "black",
      fontSize: 16
    }}
    endIcon={<CopyIcon />}
    /> : <></>
}

export default TablePhoneNumberCell
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import Loader from "@components/PresentationComponents/Loader";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import { TableDeleteButton, TableEditButton, TableViewContactButton } from "@components/PresentationComponents/TableComponents/TableHelpers";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import uiString from "@constants/uiString";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { IContact, IContactDefaultPhone, IContactPhoneNumber } from "@models/Contacts.models";
import { IPagination } from "@models/IPagination";
import SearchbarField from "@components/PresentationComponents/FormComponents/SearchbarField";
import CreateContactDialog from "./CreateContactDialog";
import ViewContactDialog from "./ViewContactDialog";
import { ADD_CONTACT_BTN_LABEL, CONTACTS_INITIAL_PAGE, GET_TEN_THOUSAND_CONTACTS } from "./constants";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import { ComponentDimensions } from "@constants/appConstants";
import { useDeleteContactMutation, useGetAllContactsQuery } from "@services/kingcobraApi";
import { initialPagination } from "@constants/initialStates";
import usePrevious from "@helpers/hooks/usePrevious.hook";
import { useDispatch } from "react-redux";
import { formatBothNationalInternational, formatBothNationalInternationalSpecial, formatNumberAsYouType } from "@helpers/functions/phoneNumberFormatter";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";



import TablePhoneNumberCell from "./TablePhoneNumberCell";

const ContactsManagement: FC = (props) => {
  // State
  const { data: _contacts, refetch, isFetching } = useGetAllContactsQuery({
    params: {
      pageSize: GET_TEN_THOUSAND_CONTACTS,
      page: CONTACTS_INITIAL_PAGE
    }
  });
  const rowsPerPage = useAppSelector((state) => state.preferences.tableSize.contacts);
  const prevRowsPerPage = usePrevious(rowsPerPage)
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewContactModalOpen, setIsViewContactModalOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchByContactName, setSearchByContactNames] = useState("");
  const [pagination, setPagination] = useState<IPagination>(initialPagination);
  const [currentContact, setCurrentContact] = useState<IContact | null>(null);
  const [removeContact, removeContactResult] = useDeleteContactMutation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLarge = useMediaQuery(theme.breakpoints.up('lg'));
  // mid ground between 'lg' and 'xl'
  const matchesExtraLarge = useMediaQuery('(min-width:1400px)');


  const contactsData = useMemo(() => _contacts ?? [], [_contacts]);

  const contactsByName = useMemo(() => searchByContactName
    ? contactsData.filter(contact => contact.name.toLowerCase().includes(searchByContactName.toLowerCase()))
    : []
    , [searchByContactName, _contacts])

  const displayedContacts = useMemo(() => {
    if (searchByContactName) {
      const totalPages = Math.ceil(contactsByName.length / rowsPerPage)
      const from = pagination.currentPage === 1
        ? contactsByName.length ? 1 : 0
        : (pagination.currentPage - 1) * rowsPerPage + 1;
      const to = pagination.currentPage < totalPages ? pagination.currentPage * rowsPerPage : contactsByName.length;

      setPagination(state => ({
        ...state,
        currentPage: rowsPerPage !== prevRowsPerPage ? CONTACTS_INITIAL_PAGE : state.currentPage,
        perPage: rowsPerPage,
        from,
        to,
        total: contactsByName.length,
        totalPages
      }))

      return contactsByName
        .map(contact => ({
          ...contact,
          "default phone number": Boolean(contact.phoneNumbers.length)
            ? formatBothNationalInternationalSpecial(contact.phoneNumbers[0].phoneNumber)
            : undefined,
          rawPhoneNumber: Boolean(contact.phoneNumbers.length) ? contact.phoneNumbers[0].phoneNumber : undefined

        }))
        .slice(from - 1, to) as IContactDefaultPhone[]
    } else {
      const totalPages = Math.ceil(contactsData.length / rowsPerPage)
      const from = pagination.currentPage === 1
        ? contactsData.length ? 1 : 0
        : (pagination.currentPage - 1) * rowsPerPage + 1;
      const to = pagination.currentPage < totalPages ? pagination.currentPage * rowsPerPage : contactsData.length;

      setPagination(state => ({
        ...state,
        currentPage: rowsPerPage !== prevRowsPerPage ? CONTACTS_INITIAL_PAGE : state.currentPage,
        perPage: rowsPerPage,
        from,
        to,
        total: contactsData.length,
        totalPages
      }))
      return contactsData
        .map(contact => ({
          ...contact,
          "default phone number": Boolean(contact.phoneNumbers.length)
            ? formatBothNationalInternationalSpecial(contact.phoneNumbers[0]?.phoneNumber)
            : undefined,
            rawPhoneNumber: Boolean(contact.phoneNumbers.length) ? contact.phoneNumbers[0].phoneNumber : undefined
        }))
        .slice(from - 1, to) as IContactDefaultPhone[]
    }
  }, [contactsData, contactsByName, pagination.currentPage, rowsPerPage])

  /**
   * Set initial pagination when contacts data gets updated
   */
  useEffect(() => {
    setPagination(state => ({
      ...state,
      perPage: rowsPerPage,
      to: contactsData.length < rowsPerPage ? contactsData.length : rowsPerPage,
      total: contactsData.length,
      totalPages: Math.ceil(contactsData.length / rowsPerPage)
    }))
    firstLoad && setFirstLoad(false);
  }, [contactsData]);

  const deleteContact = useCallback(async () => {
    try {
      const { id } = currentContact ?? { id: "" };
      await removeContact(id).unwrap();
      setIsDeleteModalOpen(false);
      // refetch();
    } catch (error) {
      setIsDeleteModalOpen(false);
      throw error;
    }
    /**
 * Remove the new contact from the store.
 * Note that this will trigger call history, voicemails and text conversations to update
 * and show the new contacts name inplace of phone numbers
 */
    //  currentContact && dispatch(removeContact(currentContact));
  }, [currentContact?.id])

  const openDeleteDialogHandler = useCallback((row: IContact) => {
    setCurrentContact(row);
    setIsDeleteModalOpen(true);
  }, []);

  const closeDeleteDialogHandler = useCallback(() => setIsDeleteModalOpen(false), []);

  const searchChangeHandler = (value) => {
    setSearchByContactNames(value);
    setPagination(state => ({ ...state, currentPage: CONTACTS_INITIAL_PAGE }))
  };

  const clearSearchbarHandler = () => setSearchByContactNames("");

  /**
   * On Reset button click, clear the search bar and set current page to first page
   */
  const resetHandler = useCallback(() => {
    clearSearchbarHandler();
    setPagination(prevState => ({ ...prevState, currentPage: CONTACTS_INITIAL_PAGE }));
  }, []);

  /**
   * Open empty form dialog to create a new contact.
   */
  const createContactHandler = useCallback(() => {
    setCurrentContact(null);
    setIsOpen(true);
  }, []);

  const closeCreateContactDialog = useCallback(() => setIsOpen(false), []);

  /**
   * Open the edit form dialog, filled with the current row data.
   * @param row Information about current user to view
   */
  const openEditDialog = useCallback((row: IContact) => {
    setCurrentContact(row);
    setIsOpen(true);
  }, []);

   /**
   * Open the view contact dialog, filled with the current row data.
   * @param row Information about current user to view
   */
   const openViewContactDialog = useCallback((row: IContact) => {
    setCurrentContact(row);
    setIsViewContactModalOpen(true);
  }, []);

  const closeViewContactDialog = useCallback(() => setIsViewContactModalOpen(false), []);


  /**
   * Update excisting contact data when new contact is created, or old one is updated. 
   */
  // const updateContactToData = useCallback(() => refetch(), []);

  /**
   * Truncate the Contacts datatable email column
   * @param an array of email strings
   * @returns an array of email strings or null
   */
  const truncateEmails = (emails: string[]) => {
    if(matchesExtraLarge) {
      if(emails.length > 0) {
        // 17 chars are the maximum before breaking to a new line
        return emails[0]?.length >= 25 ? emails[0].substring(0,24) + '...' : emails[0];
      }
      else {
        return emails;
      }
    }
    else if(matchesLarge){
     
      if(emails.length > 0) {
        return emails[0]?.length >= 25 ? emails[0].substring(0,24) + '...' : emails[0];
        //return emails[0];
      }
      else {
        return emails;
      }
    }
    else if(matchesMedium) {
      if(emails.length > 0) {
        return emails[0]?.length >= 15 ? emails[0].substring(0,14) + '...' : emails[0];
      }
      else {
        return emails;
      }
    }
    else if(matchesSmall) {
      if(emails.length > 0) {
        return emails[0]?.length >= 13 ? emails[0].substring(0,12) + '...' : emails[0];
      }
      else {
        return emails;
      }
    }
  }

    /**
   * Truncate the Contacts datatable name column
   * @param name: string
   * @returns name: string or null
   */
    const truncateName = (name: string) => {
      if(matchesExtraLarge) {
        if(name.length >= 20) {
        // 10 chars are the maximum before breaking to a new line
          return name.substring(0, 19) + '...';
 
        }
        else {
          return name;
        }
      }
      else if(matchesLarge){
        if(name.length >= 20) {
          return name.substring(0, 19) + '...';
          
        }
        else {
          return name;
        }
      }
      else if(matchesMedium) {
        if(name.length >= 20) {
          return name.substring(0, 19) + '...';
          
        }
        else {
          return name;
        }
      }
      else if(matchesSmall) {
        if(name.length >= 20) {
          return name.substring(0, 19) + '...';
          
        }
        else {
          return name;
        }
      }
    }



  const handlePageChange = (page: number) => setPagination(state => ({ ...state, currentPage: page }));
  const columnLabels = ["name", "default phone number", "emails"];
  

  return (
    <>
      <Loader open={isFetching} />
      <UnderlinedTitle>{uiString.CONTACTS.PAGE_HEADER}</UnderlinedTitle>
      <Grid style={{ marginBottom: '25px' }} className={classes.headingWrapper}>
        <Grid className={classes.headingWrapper}>
          <SearchbarField
            clearSearchbarHandler={clearSearchbarHandler}
            value={searchByContactName}
            className={classes.searchBar}
            name="searchByContactName"
            onChange={(e) => searchChangeHandler(e.target.value)}
            type="text"
          />
          <InputButton
            label={uiString.RESET}
            onClick={resetHandler}
            color="inherit"
            className={classes.resetButton}
          />
        </Grid>
        <InputButton
          onClick={createContactHandler}
          color="primary"
          plusIcon
          className={classes.addBtn}
          minWidth={ComponentDimensions.PREFERENCES_ADD_BUTTON_WIDTH}
          label={ADD_CONTACT_BTN_LABEL}
        />
      </Grid>
      {
        !firstLoad && <CustomTablePagination
          pagination={pagination}
          handlePageChange={handlePageChange}
          storeKey="contacts"
        >
          <PlainTable
            data={displayedContacts}
            contactsClassName={classes.contactsTable}
            selectColumns={columnLabels}
            columnSize={["20%", "15%", "auto"]}
            dataMapper={(row) => ({
              ...row,
              emails: row.emails ? truncateEmails(row.emails) : row.emails,
              name: row.name ? truncateName(row.name) : row.name,
              company: row.company?.length >= 50 ? row.company.substring(0, 49) + '...' : row.company,
              role: row.role?.length >= 50 ? row.role.substring(0, 49) + '...' : row.role,
              "default phone number": <TablePhoneNumberCell rawPhoneNumber={row.rawPhoneNumber} phoneNumber={row["default phone number"]}/>,
            })
            }
            actions={[
              {
                colLabel: "View",
                ActionComponent: (row) => (
                  <TableViewContactButton
                    key={row.id}
                    row={row}
                    handler={(row) => openViewContactDialog(row)}
                  />
                ),
              },
              {
                colLabel: "Edit",
                ActionComponent: (row) => (
                  <TableEditButton
                    key={row.id}
                    row={row}
                    handler={(row) => openEditDialog(row)}
                  />
                ),
              },
              {
                colLabel: "Delete",
                ActionComponent: (row) => (
                  <TableDeleteButton
                    key={row.id}
                    row={row}
                    handler={(row) => openDeleteDialogHandler(row)}
                  />
                ),
              },
            ]}
            fullWidth
          />
        </CustomTablePagination>
      }
      <CreateContactDialog
        open={isOpen}
        onClose={closeCreateContactDialog}
        contactData={currentContact}
      />
       <ViewContactDialog
        open={isViewContactModalOpen}
        onClose={closeViewContactDialog}
        contactData={currentContact as IContact}
      />
      <ConfirmDialog
        open={isDeleteModalOpen}
        confirmButtonLabel={uiString.DELETE}
        header={uiString.DELETE_CONTACT_CONFIRMATION_HEADER}
        content={uiString.DELETE_CONTACT_CONFIRMATION_BODY}
        onCancel={closeDeleteDialogHandler}
        onConfirm={deleteContact}
        loading={isFetching}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  uploadIcon: {
    '& path:first-child': {
      transform: 'translateY(-5px) rotate(180deg)',
      transformOrigin: 'center'
    },
  },
  resetButton: {
    margin: '0 12px'
  },
  headingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchBar: {
    width: '320px',
  },
  addBtn: {
    fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
    height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT
  },
  contactsTable : {
    "& > table > tbody > tr > td:first-child": {
      whiteSpace: 'nowrap'
    }
  }
}));

export default ContactsManagement;

import { makeStyles, Select, MenuItem, SelectProps } from '@material-ui/core'
import React from 'react'
import createKeyIndex from "@helpers/functions/createKeyIndex";
import { ComponentDimensions } from '@constants/appConstants';
import { OptionsType } from '@models/Additional.models';

type Props = {
  name?: string
  minWidth?: string | number
  options: OptionsType[]
} & Omit<SelectProps, "minWidth">

const OutlinedDropdown: React.FC<Props> = (
  { value, onChange, labelId, name, options, minWidth = '125px', defaultValue }
) => {
  const classes = useStyles()
  return (
    <Select
      labelId={labelId}
      defaultValue={defaultValue}
      style={{ minWidth: minWidth }}
      name={name}
      onChange={onChange}
      value={value}
      variant="outlined"
      MenuProps={{ 
      // disableScrollLock: true,
      //   anchorOrigin: {
      //   vertical: "bottom",
      //   horizontal: "left"
      // },
      // getContentAnchorEl: null,
      // transformOrigin: {
      //   vertical: "top",
      //   horizontal: "left"
      // },
      }}
      IconComponent={undefined}
      className={classes.select}
    >
      {options.map(({ text, value }, index) => {
        return (
          <MenuItem key={createKeyIndex(index, text)} value={value}>
            {text}
          </MenuItem>
        )
      })}
    </Select>
  )
}

const useStyles = makeStyles({
  select: {
    lineHeight: '12px',
    minWidth: '110px',
    height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
    fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {  
          borderColor: 'var(--color-pumpkin-orange)',
      },
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: '6px',
    },
    '& > div': {
      padding: '6px',
      textAlign: 'center',
    },
    '& > svg': {
      display: 'none',
    }
  },
})

export default OutlinedDropdown

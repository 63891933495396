import { AudioFileRecordingDialog } from "@components/PresentationComponents/AudioComponents/AudioFileRecordingDialog";
import PlayAudioBasicDialog from "@components/PresentationComponents/AudioComponents/PlayAudioBasicDialog";
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import { InputErrorText } from "@components/PresentationComponents/FormComponents/InputErrorText";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import VirtualizedSelector from "@components/PresentationComponents/VirtualizedSelector";
import uiString, { ApiErrorMessages } from "@constants/uiString";
import { requiredField } from "@helpers/formHelpers/formValidatorHelpers";
import { refactorOptions } from "@helpers/formHelpers/refactorOptionsArray";
import useAccessBlocker from "@helpers/hooks/useAccessBlocker.hook";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import {
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PublishIcon from "@material-ui/icons/Publish";
import { FeatureIdsEnum } from "@models/Account.models";
import { IAudioFile } from "@models/AudioFiles.models";
import {
  IOffHoursOptions,
  IOffHoursOptionsBody,
} from "@models/HoursOfOperation.models";
import { CallDestinationType, IMenu } from "@models/Menu.models";
import { IVoicemailBox } from "@models/Voicemailbox.models";
import HoursOfOperationService from "@services/HoursOfOperation.service";
import clsx from "clsx";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import useForm from "../../helpers/hooks/useForm.hook";
import DropdownComponent from "../PresentationComponents/DropdownComponent";
import CustomLabel from "../PresentationComponents/FormComponents/CustomLabel";
import {
  AUDIO_FILE,
  AUDIO_FILE_ID,
  CallResponseType,
  DESTINATION,
  DESTINATION_LABEL,
  MANAGE_OFF_HOUR,
  mapDestinationTypeToLabel,
  MENU_DESTINATION,
  noneAudioFile,
  removeGmtPrefixFromTimezone,
  returnGmtPrefixFromTimezone,
  TIMEZONE,
  TIMEZONES_ARRAY,
  TIMEZONE_LABEL,
  VOICEMAIL_BOX_DESTINATION,
  WHAT_CALLERS_WILL_HEAR,
  WHERE_CALLERS_WILL,
} from "./constants";

type IProps = {
  voicemailboxOptions: IVoicemailBox[];
  audiofileOptions: IAudioFile[];
  menuOptions: IMenu[];
  refreshAudioOptions: () => void;
  defaultValues: IOffHoursOptionsBody | null;
  open: boolean;
  close: () => void;
  onSave: (settings: IOffHoursOptionsBody) => void;
};

const CallResponseDialog = React.memo(
  ({
    voicemailboxOptions,
    audiofileOptions,
    menuOptions,
    refreshAudioOptions,
    defaultValues,
    open,
    close,
    onSave,
  }: IProps) => {
    const {
      form,
      formErrors,
      changeHandler,
      validateForm,
      validateField,
    } = useForm<CallResponseType>({
      audioFileId: {
        initialValue: noneAudioFile.id,
        validators: [requiredField()],
      },
      destination: {
        initialValue: { type: CallDestinationType.DISCONNECT, id: "0" },
        validators: [],
      },
      timezone: { initialValue: "", validators: [requiredField()] },
    });

    const classes = useStyles();
    const { UpdateSettings } = useService(HoursOfOperationService);
    const [isLoading, setIsLoading] = useState(false);
    const [openAudioDialog, setOpenAudioDialog] = useState(false);
    const [
      selectedAudioFile,
      setSelectedAudioFile,
    ] = useState<IAudioFile | null>(null);
    const [openRecordingDialog, setOpenRecordingDialog] = useState(false);

    // Dropdown fields option values
    const audioDropdownOptions = refactorOptions(audiofileOptions).sort(
      (a, b) => a.type?.localeCompare(b.type!) || a.text.localeCompare(b.text)
    );
    const vbOptions = refactorOptions(voicemailboxOptions);
    const mOptions = refactorOptions(menuOptions);
    const { GuardDialog, permissionGuard } = useAccessBlocker(
      FeatureIdsEnum.HOURS_OF_OPERATION,
      true,
      true
    );
    const { addToast } = useToasts();

    const destinationDropdownOptions = [
      {
        value: CallDestinationType.VOICEMAIL,
        text: mapDestinationTypeToLabel[CallDestinationType.VOICEMAIL],
      },
      {
        value: CallDestinationType.MENU,
        text: mapDestinationTypeToLabel[CallDestinationType.MENU],
      },
      {
        value: CallDestinationType.DISCONNECT,
        text: mapDestinationTypeToLabel[CallDestinationType.DISCONNECT],
      },
    ];

    // Sets the Visualized selector timezone value
    const timezone = useMemo(
      () =>
        form.timezone
          ? TIMEZONES_ARRAY.find((timezone) => form.timezone === timezone.value)
          : null,
      [form.timezone]
    );

    const audioChangeHandler = permissionGuard(
      (
        e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        validateOnChange?: boolean
      ) => {
        const fieldName = e.target.name as string;
        const fieldValue = e.target.value as string;
        const findFile = audiofileOptions.find(
          (file) => file.id === fieldValue
        );
        setSelectedAudioFile(findFile ?? noneAudioFile);
        changeHandler(fieldName, fieldValue);
        validateOnChange && validateField(fieldName, fieldValue);
      }
    );

    const destinationChangeHandler = permissionGuard(
      (
        e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        validateOnChange?: boolean
      ) => {
        // REFACTOR  THIS AFTER GET ENDPOINT IS AVAILABLE
        const value = e.target.value as CallDestinationType;
        let destination: string | number = "";
        if (value === CallDestinationType.MENU)
          destination = mOptions[0]?.value || "0";
        else if (value === CallDestinationType.VOICEMAIL)
          destination = vbOptions[0]?.value || "0";
        else destination = "0";

        changeHandler(e.target.name, {
          id: destination,
          type: value,
          validateOnChange,
        });
      }
    );

    const menuChangeHandler = permissionGuard(
      (
        e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        validateOnChange?: boolean
      ) => {
        changeHandler(
          e.target.name,
          { id: e.target.value, type: form.destination.type },
          validateOnChange
        );
      }
    );

    const submitHandler = permissionGuard(async () => {
      const isValid = validateForm();
      if (isValid as any) {
        setIsLoading(true);
        let _form = {};
        for (let field in form) {
          _form[field] = form[field];
        }
        try {
          const { data: newSettings } = await UpdateSettings(
            _form as IOffHoursOptions
          );
          onSave(newSettings);
          close();
        } catch (error) {
          if (
            error &&
            (error as any).response &&
            (error as any).response.status === ErrorStatusCode.FORBIDDEN
          ) {
            addToast(ApiErrorMessages.FORBIDDEN, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            addToast(ApiErrorMessages.UNEXPECTED, {
              appearance: "error",
              autoDismiss: true,
            });
            throw error;
          }
        }
      }
      setIsLoading(false);
    });

    useEffect(() => {
      if (open) {
        if (defaultValues) {
          defaultValues.closed.audioFileId &&
            changeHandler(AUDIO_FILE_ID, defaultValues.closed.audioFileId);
          changeHandler(DESTINATION, defaultValues.closed.destination);
          changeHandler(TIMEZONE, defaultValues.timezone);

          if (audiofileOptions.length > 0) {
            const findFile =
              audiofileOptions.find(
                (file) =>
                  String(file.id) === String(defaultValues.closed.audioFileId)
              ) ?? null;
            setSelectedAudioFile(findFile);
          }
        } else if (!selectedAudioFile && audiofileOptions.length) {
          setSelectedAudioFile(noneAudioFile);
        }
      }
    }, [defaultValues, open]);

    const Form = () => (
      <>
        <div className={classes.audioFileSelector}>
          <FormControl className={classes.formControl}>
            <DropdownComponent
              error={formErrors.audioFileId?.hasError}
              errorMessage={
                formErrors.audioFileId?.validatorResults.errorMessage
              }
              label={AUDIO_FILE}
              className={classes.selectInput}
              outlined
              smallIcon
              name={AUDIO_FILE_ID}
              value={form.audioFileId}
              onChange={(e) => audioChangeHandler(e)}
              id="hours-of-operations-settings-audio-file"
              aria-describedby="hours-of-operations-settings-audio-file-helper"
              options={audioDropdownOptions}
              helperText={WHAT_CALLERS_WILL_HEAR}
            />
          </FormControl>
          {form.audioFileId && form.audioFileId !== noneAudioFile.id && (
            <Tooltip
              styling="standard"
              disabled={!form.audioFileId}
              title="Play"
            >
              <IconButton
                onClick={() => setOpenAudioDialog(true)}
                disabled={!form.audioFileId}
                color="primary"
                className={classes.alignButtons}
              >
                <PlayCircleFilledIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip styling="standard" title="Upload">
            <IconButton
              onClick={() => setOpenRecordingDialog(true)}
              color="primary"
              className={classes.alignButtons}
            >
              <PublishIcon />
            </IconButton>
          </Tooltip>
        </div>
        <FormControl className={classes.formControl}>
          <DropdownComponent
            label={DESTINATION_LABEL}
            error={formErrors.destination?.hasError}
            errorMessage={formErrors.destination?.validatorResults.errorMessage}
            name={DESTINATION}
            options={destinationDropdownOptions}
            value={form.destination.type}
            onChange={(e) => destinationChangeHandler(e, false)}
            outlined
            smallIcon
            className={classes.selectInput}
            id="hours-of-operations-settings-response-type"
            aria-describedby="hours-of-operations-settings-response-type-helper"
            helperText={WHERE_CALLERS_WILL}
          />
        </FormControl>
        {form.destination.type === CallDestinationType.VOICEMAIL && (
          <FormControl className={classes.formControl}>
            <DropdownComponent
              label={VOICEMAIL_BOX_DESTINATION}
              options={vbOptions}
              name={DESTINATION}
              outlined
              smallIcon
              value={form.destination.id}
              onChange={(e) => menuChangeHandler(e, false)}
              className={classes.selectInput}
              id="hours-of-operations-settings-voicemails"
            />
          </FormControl>
        )}
        {form.destination.type === CallDestinationType.MENU && (
          <FormControl className={classes.formControl}>
            <DropdownComponent
              label={MENU_DESTINATION}
              className={classes.selectInput}
              value={form.destination.id}
              onChange={(e) => menuChangeHandler(e, false)}
              name={DESTINATION}
              options={mOptions}
              outlined
              smallIcon
              id="hours-of-operations-settings-menu"
            />
          </FormControl>
        )}
        <FormControl className={classes.formControl}>
          <CustomLabel label={TIMEZONE_LABEL} />
          <VirtualizedSelector
            id="timezone-selector-hours-of-operations"
            style={{ width: 250 }}
            className={classes.timezoneSelector}
            options={TIMEZONES_ARRAY}
            error={formErrors.timezone?.hasError}
            helperText={
              <InputErrorText standalone>
                {formErrors.timezone?.validatorResults.errorMessage}
              </InputErrorText>
            }
            value={timezone}
            groupBy={(option) => returnGmtPrefixFromTimezone(option.label)}
            transformLabel={removeGmtPrefixFromTimezone}
            onChange={permissionGuard((_e, value) => {
              changeHandler(TIMEZONE, value ? String(value.value) : "");
            })}
          />
        </FormControl>
      </>
    );

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={close}
        className={clsx(openRecordingDialog && classes.hide)}
        title={MANAGE_OFF_HOUR}
      >
        <DialogContent>
          <Form />
          {selectedAudioFile && (
            <PlayAudioBasicDialog
              open={openAudioDialog}
              close={() => setOpenAudioDialog(false)}
              audioSrc={selectedAudioFile.link}
              autoplay
            />
          )}
          <AudioFileRecordingDialog
            open={openRecordingDialog}
            close={() => setOpenRecordingDialog(false)}
            onSubmit={(item) => {
              refreshAudioOptions();
              setSelectedAudioFile(item);
              changeHandler(AUDIO_FILE_ID, item.id);
            }}
          />
        </DialogContent>
        <GuardDialog />
        <DialogActions>
          <DialogButton
            label={uiString.SAVE_CHANGES}
            fullWidth
            color="secondary"
            onClick={submitHandler}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </DialogActions>
      </Dialog>
    );
  }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hide: {
      display: "none",
    },
    formControl: {
      display: "block",
      margin: theme.spacing(1, 0),
    },
    selectInput: {
      minWidth: 200,
      height: "32px",
    },
    audioFileSelector: {
      display: "flex",
      alignItems: "center",
    },
    formButton: {
      maxWidth: "80px",
    },
    timezoneSelector: {
      "& .MuiInputBase-root": {
        height: "32px",
        padding: 0,
      },
      "& .MuiIconButton-label": {
        width: "8px",
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "4px",
        color: "rgba(0, 0, 0, 0.43)",
      },
    },
    alignButtons: {
      marginTop: theme.spacing(1),
    },
  })
);

export default CallResponseDialog;

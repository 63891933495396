import { Session, SessionState } from "sip.js";
import { CallComponentInstance } from "..";

import * as stateUtils from "./state-utils";

import validateArray from "../../../helpers/arrayValidator";

import { CallStateEnum } from "../../../constants/callConstants";

import { idleCall } from "./call-lifecycle";

let cleanUpAnyInternalStreams: () => void | undefined;

/**
 * Adds standard callback functions to a Sip.js incoming session
 *
 * @param callSession the session to add listeners to
 */
export const setSessionListenersForIncomingCall = (
  that: CallComponentInstance,
  callSession: Session
) => {
  if(!that._ismounted) return;
  sipjsOnInvite(that, callSession);
};

/**
 * Called when there is an incoming session invite
 * If there is no active session, ask the user to accept or cancel the session
 *
 * @param incomingSession the incoming session to process
 */
export const sipjsOnInvite = (that: CallComponentInstance, incomingSession) => {
  if(!that._ismounted) return;

  const { callSession } = that.state;

  if (!callSession) {
    // If there is no currently active session
    if (incomingSession) {
      const { request } = incomingSession;
      if (request) {
        const { from, headers } = request;

        if (from && headers) {
          const { uri } = from;
          const { "X-Dialed-Number": dialedNumberArray } = headers;

          if (uri && validateArray(dialedNumberArray)) {
            const { user } = uri;
            const { raw: dialedNumber } = dialedNumberArray[0];

            if (user && dialedNumber) {
              setBasicSessionListeners(that, incomingSession);
              stateUtils.setCallSession(that, incomingSession);

              const { incomingCall: incomingCallLocal } = that.props;
              const sanitizedNumber = user.replace("+", "");

              incomingCallLocal(sanitizedNumber, dialedNumber);
            }
          }
        }
      }
    }
  }
};

/**
 * Adds standard callback functions to a Sip.js outgoing session
 *
 * @param callSession the session to add listeners to
 */
export const setBasicSessionListeners = (
  that: CallComponentInstance,
  callSession: Session
) => {
  if(!that._ismounted) return;
  callSession.stateChange.addListener((newState: SessionState) => {
    switch (newState) {
      case SessionState.Establishing:
        break;
      case SessionState.Established:
        sipTrackAdded(that, callSession);
        sipjsOnSessionAccepted(that, callSession.data);
        break;
      case SessionState.Terminated:
        sipjsOnSessionTerminated(that);
        break;
      default:
        break;
    }
  });
};

/**
 * Called when a Sip.js session was accepted
 * Notifies the application internally a call has been successfully connected
 *
 * @param data the data associated with the accepted session. Currently unused
 */
export const sipjsOnSessionAccepted = (that: CallComponentInstance, data?) => {
  if(!that._ismounted) return;

  const {
    callConnected: callConnectedLocal,
    numberToCall,
    callState,
    incomingCallNumber,
  } = that.props;
  let phoneNumber = numberToCall;
  if (callState === CallStateEnum.INCOMING) {
    phoneNumber = incomingCallNumber;
  }

  stateUtils.setIsConnecting(that, false);
  callConnectedLocal(phoneNumber);
};

/**
 * Called when a session was either rejected or terminated. This is usually the last step in the process
 * of terminating a session. Updated the application internally that the current session has been terminated
 *
 * @param message contains the message details on why the session has failed
 * @param cause usually a 1 word string containing the cause
 */
export const sipjsOnSessionTerminated = (that: CallComponentInstance) => {
  if(!that._ismounted) return;

  stateUtils.setIsConnecting(that, false);
  stateUtils.clearCallSession(that);
  idleCall(that);

  if (cleanUpAnyInternalStreams) cleanUpAnyInternalStreams();
};

/**
 * Called when a session is ready for media tracks.
 * Starts the process of adding an audio element to the current session
 *
 * @param session session that needs media tracks
 */
export const sipTrackAdded = (that: CallComponentInstance, session) => {
  if(!that._ismounted) return;
  const { sessionDescriptionHandler } = session;

  if (sessionDescriptionHandler) {
    const { peerConnection } = sessionDescriptionHandler;

    if (peerConnection) {
      addAudioElement(that, peerConnection);
    }
  }
};

/**
 * Adds an audio element to a peer connection
 *
 * @param peerConnection the peer connection to add the audio element to
 */
export const addAudioElement = (
  that: CallComponentInstance,
  peerConnection
) => {
  if(!that._ismounted) return;
  const audioPlayerElement = document.getElementById("audio-remote");
  const { defaultAudioOutput, defaultAudioOutputVolume } = that.props;

  if (peerConnection) {
    const remoteStream = new MediaStream();
    peerConnection.getReceivers().forEach((receiver) => {
      const { track } = receiver;
      if (track) {
        remoteStream.addTrack(track);
      }
    });

    if ("setSinkId" in HTMLMediaElement.prototype) (audioPlayerElement as any).setSinkId(defaultAudioOutput);

    (audioPlayerElement as any).srcObject = remoteStream;
    (audioPlayerElement as any).volume = defaultAudioOutputVolume;
    cleanUpAnyInternalStreams = that.updateMicrophoneVolume();
  }
};

import { appConfig } from "../index";
import { AxiosRequestConfig, AxiosResponse } from "axios";
// const URL = getApiConstants().endpoints.voicemessagesv2.BASE;
interface IGet {
  headers?: { [key: string]: any };
  id?: string;
  params?: URLSearchParams;
  url: string;
  isPublic?: boolean;
}

interface IPut<T> {
  headers?: { [key: string]: any };
  data: T;
  id: string;
  params?: URLSearchParams;
  url: string;
  isPublic?: boolean;
}

interface IPatch<T> {
  headers?: { [key: string]: any };
  data: T;
  params?: URLSearchParams;
  url: string;
  isPublic?: boolean;
}

interface IPost<T> {
  headers?: { [key: string]: any };
  data: T;
  params?: URLSearchParams;
  url: string;
  isPublic?: boolean;
}

interface IDelete {
  headers?: { [key: string]: any };
  id?: string;
  params?: URLSearchParams;
  url: string;
  isPublic?: boolean;
}

/**
 * Type safe axios wrapper
 */
export function HttpClient() {
  const Get = async <T, R = AxiosResponse<T>>({
    url,
    headers,
    id,
    params,
    isPublic,
  }: IGet) => {
    const _url = id ? `${url}/${id}` : url;
    const config: AxiosRequestConfig = {
      headers,
      params,
    };
    return appConfig[isPublic ? "axiosPublic" : "axios"].get<T, R>(`${_url}`, config);
  };

  const Put = async <T, D, R = AxiosResponse<T>>({
    url,
    headers,
    id,
    params,
    data,
    isPublic
  }: IPut<D>) => {
    const _url = `${url}/${id}`;
    const config: AxiosRequestConfig = {
      headers,
      params,
    };
    return appConfig[isPublic ? "axiosPublic" : "axios"].put<T, R>(`${_url}`, data, config);
  };

  const Patch = async <T, D, R = AxiosResponse<T>>({
    url,
    headers,
    params,
    data,
    isPublic
  }: IPatch<D>) => {
    const _url = `${url}`;
    const config: AxiosRequestConfig = {
      headers,
      params,
    };
    return appConfig[isPublic ? "axiosPublic" : "axios"].patch<T, R>(`${_url}`, data, config);
  };

  const Post = <T, D, R = AxiosResponse<T>>({
    url,
    headers,
    params,
    data,
    isPublic
  }: IPost<D>) => {
    const _url = `${url}`;
    const config: AxiosRequestConfig = {
      headers,
      params,
    };
    return appConfig[isPublic ? "axiosPublic" : "axios"].post<T, R>(`${_url}`, data, config);
  };

  const Delete = async <T, R = AxiosResponse<T>>({
    url,
    headers,
    params,
    id,
    isPublic
  }: IDelete) => {
    const _url = id ? `${url}/${id}` : url;
    const config: AxiosRequestConfig = {
      headers,
      params,
    };
    return appConfig[isPublic ? "axiosPublic" : "axios"].delete<T, R>(`${_url}`, config);
  };

  return {
    Get,
    Put,
    Post,
    Delete,
    Patch
  };
}

export default HttpClient;

import { useEffect, useRef } from "react";

export default function useInterval(callback: () => void, delay: number | null) {
    const intervalRef = useRef<any>();
    const callbackRef = useRef(callback);
  
    useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
  
    useEffect(() => {
      if (delay) {
        intervalRef.current = setInterval(() => callbackRef.current(), delay);
  
        return () => clearInterval(intervalRef.current);
      }
    }, [delay]);
  
    return intervalRef;
  }
import React, { FC, memo, useCallback, useEffect, useState } from "react";

// MUI
import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

// Components
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import InputField from "@components/PresentationComponents/FormComponents/InputField";

// Models
import { IEmailSubscriber, IVirtualNumber } from "@models/TextMessaging.models";
import { IApiResponseWithPagination } from "@models/IPagination";

// Icons
import CrossIcon from "@resources/icons/cross-small.svg";
import { Dimmer, Loader } from "semantic-ui-react";

// Helpers
import useForm from "@helpers/hooks/useForm.hook";
import {
  inputHasDuplicates,
  validateEmail,
} from "@helpers/formHelpers/formValidatorHelpers";

// Constants
import keyConstants from "@constants/keyConstants";

type Props = {
  open: boolean;
  userEmails?: IEmailSubscriber[];
  virtualNumber: IVirtualNumber | null;
  onClose: () => void;
  addEmail: (
    email: string
  ) => Promise<IApiResponseWithPagination<IEmailSubscriber> | undefined>;
  deleteHandler: (id: string, virtualId: string) => Promise<void>;
};

const AddEmailDialog: FC<Props> = ({
  open,
  userEmails,
  virtualNumber,
  onClose,
  addEmail,
  deleteHandler,
}) => {
  const {
    form,
    formErrors,
    changeHandler,
    resetToDefault,
    validateForm,
  } = useForm({
    email: {
      initialValue: "",
      validators: [
        validateEmail(),
        inputHasDuplicates(userEmails?.map((item) => item.email) ?? []),
      ],
    },
  });
  const [areLoading, setAreLoading] = useState<string[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      resetToDefault();
    }
  }, [open]);

  const addEmailHandler = useCallback(async () => {
    try {
      if (validateForm()) {
        await addEmail(form.email);
        resetToDefault();
      }
    } catch (error) {
      throw error;
    }
  }, [form.email]);

  const deleteEmail = useCallback(
    async (id: string, virtualId: string) => {
      try {
        if (!areLoading.find((el) => el === id)) {
          setAreLoading((prevState) => [...prevState, id]);
          await deleteHandler(id, virtualId);
          setAreLoading((prevState) => prevState.filter((item) => item !== id));
        }
      } catch (err) {
        throw err;
      }
    },
    [areLoading]
  );

  const onKeyDown = (event) => {
    if (event.keyCode === keyConstants.CODE_ENTER) {
      addEmailHandler();
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      open={open}
      title="Add Email"
    >
      <DialogContent className={classes.wrapper}>
        <FormControl fullWidth className={classes.formControl}>
          <InputField
            type="text"
            name="email"
            placeholder="jsmith@company.email"
            onChange={(e) => changeHandler(e.target.name, e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
            value={form.email}
            className={classes.input}
            autoFocus
            error={formErrors.email?.hasError}
            errorMessage={formErrors.email?.validatorResults.errorMessage}
          />
          <InputButton
            onClick={addEmailHandler}
            className={classes.button}
            color="primary"
          >
            Add
          </InputButton>
        </FormControl>
        <Grid className={classes.emailList}>
          {userEmails &&
            userEmails.map((item) => {
              return (
                <Grid className={classes.email} key={item.id}>
                  <Typography>{item.email}</Typography>
                  <Button
                    className={classes.deleteButton}
                    onClick={() =>
                      deleteEmail(item.id, String(virtualNumber?.id))
                    }
                  >
                    {areLoading.find((el) => el === item.id) ? (
                      <Dimmer
                        style={{
                          backgroundColor: "transparent",
                          cursor: "default",
                        }}
                        active
                        inverted
                      >
                        <Loader inverted size="tiny" />
                      </Dimmer>
                    ) : (
                      <CrossIcon className={classes.deleteButtonIcon} />
                    )}
                  </Button>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
  },
  input: {
    height: 32,
    marginRight: theme.spacing(1),
  },
  button: {
    height: 32,
    alignSelf: "flex-start",
  },
  emailList: {
    "& > div:first-child": {
      borderTop: "1px solid var(--color-dark-grey)",
    },
    "& > div:nth-child(even)": {
      background: theme.palette.common.whiteTan,
    },
  },
  email: {
    margin: 0,
    padding: theme.spacing(1, 0),
    borderBottom: "1px solid var(--color-dark-grey)",
    display: "flex",
    flexDirection: "row",
  },
  deleteButton: {
    padding: theme.spacing(1),
    minWidth: 24,
    minHeight: 24,
    borderRadius: "6px",
    marginLeft: theme.spacing(2),
  },
  deleteButtonIcon: {
    width: 8,
    height: 8,
  },
}));

export default memo(AddEmailDialog);

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { styled } from "@material-ui/core/styles";

const horizontalMargin = "19px";

const ButtonsGrid = styled("div")(({ theme }) => ({
  width: '100%',
  maxHeight: "100vh",
  display: 'flex',
  [theme.breakpoints.down(1200)]: {
    maxWidth: 480,
    marginLeft: `-${horizontalMargin}`,
    marginRight: `-${horizontalMargin}`,
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center"
  },
  [theme.breakpoints.up(1200)]: {
    maxWidth: 680,
    marginLeft: `-${horizontalMargin}`,
    marginRight: `-${horizontalMargin}`,
  },
}));

const ButtonsGridItem = styled("div")(({ theme }) => ({
  padding: 0,

  [theme.breakpoints.down(1200)]: {
    // flex: 1,
    maxHeight: 230,
    maxWidth: 230,
    width: "100%",
    height: "auto",
    marginBottom: 50
  },
  // [theme.breakpoints.down("md")]: {
    //   flex: "0 0 50%",
    // },
    [theme.breakpoints.up(1200)]: {
      paddingLeft: horizontalMargin,
      paddingRight: horizontalMargin,
      flex: "0 0 33%",
      "& svg" : {
        width: 30,
        height: 30,
      }
  },
}));

const MeetingButtonText = styled("span")({
  display: "block",
  fontSize: "14px",
  letterSpacing: "0.29px",
  color: "var(--color-charcoal)",
  marginTop: "13px",
  fontWeight: 600
});

const MeetingButtonIcon = styled("span")({
  width: "100%",
  height: 0,
  paddingBottom: "100%",
  border: "1px solid var(--color-dark-grey)",
  display: "flex",
  borderRadius: 15,
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  transition: 'background-color 0.225s ease',
  background: "var(--color-white)",

  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },

  "& svg g": {
    transition: 'fill 0.225s ease'
  }
});

const MeetingButton = styled("button")({
  marginBottom: "50px",
  display: "block",
  appearance: "none",
  width: "100%",
  border: "0",
  background: "transparent",
  cursor: "pointer",
  lineHeight: "normal",
  padding: "5px 7px",

  "&:hover .icon-wrap": {
    backgroundColor: "var(--color-dark-grey)",
    color: "var(--color-white)",
    "& svg g": {   
      fill: "var(--color-white)"
    },
  }
});

export {
  MeetingButton,
  MeetingButtonIcon,
  ButtonsGrid,
  ButtonsGridItem,
  MeetingButtonText,
};

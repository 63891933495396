import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AudioFilesTable from "./GreetingsAndMusicTable";
import {
  AUDIO_FILES_GET_ERROR,
  AUDIO_FILES_GET_NO_RECORDS,
  DONT_FILTER_AUDIO_TYPE_VALUE,
  ADD_AUDIO_FILE,
  GREETINGS_AND_MUSIC,
  GREETINGS_INITAL_PAGE,
} from "./constants";
import { AudioFileRecordingDialog } from "../PresentationComponents/AudioComponents/AudioFileRecordingDialog";
import Loader from "@components/PresentationComponents/Loader";
import { AudioTypes, IAudioFile } from "@models/AudioFiles.models";
import AudioFilesService from "@services/AudioFiles.service";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import InputButton from "../PresentationComponents/FormComponents/InputButton";
import ToggledRadioButton from "../PresentationComponents/FormComponents/ToggledRadioButton";
import OutlinedDropdown from "../PresentationComponents/FormComponents/OutlinedDropdown";
import uiString, { ApiErrorMessages } from "../../constants/uiString";
import { ComponentDimensions } from "@constants/appConstants";
import { useToasts } from "react-toast-notifications";
import { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { IPagination } from "@models/IPagination";

const GreetingsAndMusic = () => {
  const classes = useStyles();
  const [componentLoaded, setComponentLoaded] = useState(true);
  const [audioFileList, setAudioFileList] = useState<IAudioFile[]>([]);
  const [openRecordingDialog, setOpenRecordingDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const [filterVal, setFilterVal] = useState<
    AudioTypes | typeof DONT_FILTER_AUDIO_TYPE_VALUE
  >(DONT_FILTER_AUDIO_TYPE_VALUE);
  const [showDefault, setShowDefault] = useState(true);
  const rowsPerPage = useAppSelector(state => state.preferences.tableSize.greetingsAudio);
  const [firstLoad, setFirstLoad] = useState(true);
  const { addToast } = useToasts();
  const getAudioFiles = async (page?: number) => {
    try {
      setIsLoading(true);
      setError(false);
      const { data, pagination } = await AudioFilesService().GetByPage(
        page ?? pageState?.currentPage ?? GREETINGS_INITAL_PAGE,
        rowsPerPage,
        filterVal !== DONT_FILTER_AUDIO_TYPE_VALUE ? filterVal : null,
        showDefault
      );
      if (componentLoaded) {
        setAudioFileList(data);
        setPageState(pagination);
        setIsLoading(false);
        setFirstLoad(false);
      }
    } catch (error) {
      setError(true);
      setIsLoading(false);
      setFirstLoad(false);
      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  useEffect(() => {
    if (componentLoaded && !firstLoad) getAudioFiles(GREETINGS_INITAL_PAGE);
  }, [filterVal, showDefault, rowsPerPage]);

  const onEditSuccess = (data: IAudioFile) => {
    setAudioFileList((list) =>
      list.map((item) => (item.id === data.id ? data : item))
      .sort((a, b) => a.type?.localeCompare(b.type!) || a.name.localeCompare(b.name))
    );
  };

  const onDeleteSuccess = (id: string) => {
    if (componentLoaded) getAudioFiles(GREETINGS_INITAL_PAGE);
  };

  const onFilter = async (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) =>
    setFilterVal(
      e.target.value as AudioTypes | typeof DONT_FILTER_AUDIO_TYPE_VALUE
    );

  const handlePageChange = (page: number) => getAudioFiles(page);

  const onSubmitSuccess = () => {
    if (componentLoaded) getAudioFiles(GREETINGS_INITAL_PAGE);
  };

  useEffect(() => {
    setComponentLoaded(true);
    getAudioFiles();
    () => {
      setComponentLoaded(false);
    };
  }, []);

  const dropdownOptions = [
    { value: DONT_FILTER_AUDIO_TYPE_VALUE, text: 'Type - All' },
    { value: AudioTypes.ANNOUNCEMENT, text: AudioTypes.ANNOUNCEMENT },
    { value: AudioTypes.MENU, text: AudioTypes.MENU },
    { value: AudioTypes.VOICEMAIL_GREETING, text: AudioTypes.VOICEMAIL_GREETING },
    { value: AudioTypes.WELCOME_GREETING, text: AudioTypes.WELCOME_GREETING },
    { value: AudioTypes.MUSIC_ON_HOLD, text: AudioTypes.MUSIC_ON_HOLD },
    { value: AudioTypes.RECORDED_CALL_NOTIF, text: AudioTypes.RECORDED_CALL_NOTIF },
  ]

  return (
    <>
      <Loader open={isLoading} />
      <UnderlinedTitle>{GREETINGS_AND_MUSIC}</UnderlinedTitle>
      {!firstLoad && <Grid className={classes.content}>
        <div className={classes.controls}>
          <FormControl className={classes.filter}>
            <OutlinedDropdown 
              labelId="audio-type-select"
              onChange={onFilter}
              value={filterVal}
              options={dropdownOptions}
              minWidth="155px"
            />
          </FormControl>
          
          <ToggledRadioButton 
            checked={showDefault}
            className={classes.toggleButton}
            onChange={() => setShowDefault(!showDefault)} 
            label="Display Defaults"
          />
         
          <div style={{ width: '100%' }}>
            <InputButton
              label={ADD_AUDIO_FILE}
              onClick={() => setOpenRecordingDialog(true)}
              minWidth={ComponentDimensions.PREFERENCES_ADD_BUTTON_WIDTH}
              className={classes.addAudioBtn}
              color="primary"
              plusIcon
            />
          </div>
        </div>
        {audioFileList.length || !isLoading ? (
          <>
            <CustomTablePagination
              pagination={pageState}
              handlePageChange={handlePageChange}
              storeKey="greetingsAudio"
            >
              <AudioFilesTable
                audioFileList={audioFileList}
                onEditSuccess={onEditSuccess}
                onDeleteSuccess={onDeleteSuccess}
              />
            </CustomTablePagination>
          </>
        ) : (
          <div className={classes.center}>
            <Typography variant="h5">
              {error ? AUDIO_FILES_GET_ERROR : AUDIO_FILES_GET_NO_RECORDS}{" "}
              {error && (
                <Button color="primary" onClick={() => getAudioFiles()}>
                  {uiString.RETRY}
                </Button>
              )}
            </Typography>
          </div>
        )}
        <AudioFileRecordingDialog
          open={openRecordingDialog}
          close={() => setOpenRecordingDialog(false)}
          onSubmit={onSubmitSuccess}
        />
      </Grid>}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: '100%',
  },
  filter: {
    marginRight: theme.spacing(2), 
    minWidth: 'fit-content'   
  },
  center: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    margin: '14px 0 16px',
  },
  addAudioBtn: {
    float: 'right',
    fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
    height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT
  },
  toggleButton: {
    marginLeft: 10,
    '& > span': {
      fontSize: '11px',
    }
  },
}));

export default GreetingsAndMusic;

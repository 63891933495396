import { SvgIcon, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React from "react";

export const DeselectIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = (
  props
) => (
  <SvgIcon viewBox="0 0 640 640" {...props}>
    <g id="icomoon-ignore"></g>
    <path d="M342.627 336l276.686-276.687c6.249-6.248 6.249-16.379 0-22.627s-16.379-6.249-22.627 0l-276.686 276.687-276.687-276.686c-6.248-6.249-16.379-6.249-22.627 0s-6.249 16.379 0 22.627l276.687 276.686-276.686 276.687c-6.249 6.248-6.249 16.379 0 22.627 3.124 3.124 7.218 4.686 11.313 4.686s8.189-1.562 11.313-4.687l276.687-276.686 276.687 276.687c3.124 3.124 7.218 4.686 11.313 4.686s8.189-1.562 11.313-4.687c6.249-6.248 6.249-16.379 0-22.627l-276.686-276.686z"></path>
  </SvgIcon>
);

import { FormValidatorCodes, IFormErrorField } from "@models/form.models";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";

export const DEFAULT_PHONE_NUMBER_ERR_MSG = "Invalid phone number!";

export const maxLength = (
  length: number,
  errorMessage = `Must be maximum of ${length} signs long!`
) => (input: string): IFormErrorField => {
  return {
    hasError: input.length >= length,
    validatorResults: { code: FormValidatorCodes.MAX_LENGTH, errorMessage },
  };
};

export const minLength = (
  length: number,
  errorMessage = `Must be at least ${length} signs!`
) => (input: string): IFormErrorField => {
  return {
    hasError: input.length <= length,
    validatorResults: { code: FormValidatorCodes.MIN_LENGTH, errorMessage },
  };
};

export const validateEmail = (errorMessage = "Invalid email!") => (
  email: string | null
): IFormErrorField => {
  const emailFormat = /^\w+([\.-]?\w+)*@[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+)$/;
  return email && email.length > 0
    ? {
        hasError: !emailFormat.test(email),
        validatorResults: {
          code: FormValidatorCodes.VALIDATE_EMAIL,
          errorMessage,
        },
      }
    : { hasError: false, validatorResults: { code: null, errorMessage: "" } };
};

export const inputHasDuplicates = (
  list: string[],
  errorMessage = "Duplicated value!"
) => (input: string): IFormErrorField => {
  return list.find((item) => item === input)
    ? {
        hasError: true,
        validatorResults: {
          code: FormValidatorCodes.DUPLICATED_INPUT,
          errorMessage,
        },
      }
    : { hasError: false, validatorResults: { code: null, errorMessage: "" } };
};

export const requiredField = (errorMessage = "Required field!") => (
  input: string
): IFormErrorField => {
  return {
    hasError: input === "",
    validatorResults: { code: FormValidatorCodes.REQURED_FIELD, errorMessage },
  };
};

export const isPhoneNumber = (errorMessage = DEFAULT_PHONE_NUMBER_ERR_MSG) => (
  input: string
): IFormErrorField => {
  const phoneRegEx = /1\s\(\d{3}\)\s\d{3}\-\d{4}/;
  return {
    hasError: !phoneRegEx.test(input),
    validatorResults: {
      code: FormValidatorCodes.IS_PHONE_NUMBER,
      errorMessage,
    },
  };
};

export const companyLength = (errorMessage = "Company name must not exceed 100 characters!") => (
  input: string
): IFormErrorField => {
  let longerThan100 = false;
  if(input.length > 100) {
    longerThan100 = true;
  }
  return {
    hasError: longerThan100,
    validatorResults: {
      code: FormValidatorCodes.COMPANY_LENGTH,
      errorMessage,
    },
  };
};

export const roleLength = (errorMessage = "Role must not exceed 100 characters!") => (
  input: string
): IFormErrorField => {
  let longerThan100 = false;
  if(input.length > 100) {
    longerThan100 = true;
  }
  return {
    hasError: longerThan100,
    validatorResults: {
      code: FormValidatorCodes.ROLE_LENGTH,
      errorMessage,
    },
  };
};


export const isContactPhoneNumberValid = (
  errorMessage = DEFAULT_PHONE_NUMBER_ERR_MSG,
  nullable: boolean = true
) => (
  input: { phoneNumber: string; type; id } | undefined
): IFormErrorField => {
  if (input) {
    if (nullable && !input.phoneNumber) {
      return {
        hasError: false,
        validatorResults: {
          code: FormValidatorCodes.IS_PHONE_NUMBER,
          errorMessage,
        },
      };
    }
    const country = parsePhoneNumber(input.phoneNumber, "US").country;
    const hasError = !isValidNumber(input.phoneNumber, country);
    return {
      hasError,
      validatorResults: {
        code: FormValidatorCodes.IS_PHONE_NUMBER,
        errorMessage,
      },
    };
  } else {
    return {
      hasError: false,
      validatorResults: {
        code: FormValidatorCodes.IS_PHONE_NUMBER,
        errorMessage,
      },
    };
  }
};

import {
  UserAgent,
  RegistererOptions,
  Registerer,
  Invitation,
  Session,
  RegistererState,
  SessionInfoOptions,
} from "sip.js";
import { OutgoingRequestDelegate } from "sip.js/lib/core";

import * as callLifecycle from "./call-lifecycle";
import * as sipSessionCallbacks from "./sip-session-callback";
import * as stateUtils from "./state-utils";

import { CallComponentInstance } from "..";

import DataState from "@constants/dataStateConstants";
import uiString from "@constants/uiString";
import { CallStateEnum } from "@constants/callConstants";
import ipcConstants from "@constants/ipcConstants";

import appConfig from "../../../config";

const SIP_REGISTER_TIMEOUT = 5 * 1000; // The timeout to retry to register in ms
const SIP_CONNECTION_TIMEOUT = 10; // The timeout to connect in second
const SIP_MAX_RECONNECTION_ATTEMPTS = 3; // The number of max reconnection attempts
const SIP_RECONNECTION_TIMEOUT = 10; // The timeout to reconnect in second

const ENDING_CALL_TIMEOUT = 1700; // The timeout to end the call in ms

/**
 * Initialize sip.js and start the UA (user agent)
 * Set up callbacks
 */
export const initSipJS = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  console.log("initializing sip.js");
  const { sipAgent } = that.state;
  const {
    connectionDataState,
    webrtcUserName,
    webrtcPassword,
    userToken,
  } = that.props;
  console.log("initializing sip.js", webrtcUserName, webrtcPassword, userToken);

  console.log("sipAgent", sipAgent);
  console.log(connectionDataState);

  if (
    !sipAgent &&
    connectionDataState === DataState.READY &&
    webrtcUserName &&
    webrtcPassword
  ) {
    const { impu, webSocketProxyUrl } = that.props;
    if (impu && webSocketProxyUrl) {
      const updatedImpu = `sip:${webrtcUserName}@${impu}`;
      const updatedWebSocketProxyUrl = `${webSocketProxyUrl}?webrtc_username=${webrtcUserName}&webrtc_password=${webrtcPassword}`;
      const userAgent = new UserAgent({

        uri: UserAgent.makeURI(updatedImpu), // sip:webrtcUserName@testing-webrtc.talkroute.com
        transportOptions: {

          server: updatedWebSocketProxyUrl, // wss://webrtc.talkroute.com/ws?webrtc_username=12345&webrtc_password=12345
          connectionTimeout: SIP_CONNECTION_TIMEOUT,
          reconnectionAttempts: SIP_MAX_RECONNECTION_ATTEMPTS,
          reconnectionDelay: SIP_RECONNECTION_TIMEOUT,
        },
        authorizationUsername: webrtcUserName, // webrtcUserName
        authorizationPassword: webrtcPassword, // webrtcPassword,
      });

      const registererOptions: RegistererOptions = {};
      const registerer = new Registerer(userAgent, registererOptions);

      registerer.stateChange.addListener(onRegisterStateChanged(that));
      userAgent.delegate = {
        onConnect: () => {
          sipjsOnConnected(that);
        },
        onDisconnect: (error?: Error) => {
          console.log("userAgent.delegate onDisconnect");
          registerer.unregister();
          sipjsOnDisconnected(that);
          // Only attempt to reconnect if network/server dropped the connection (if there is an error)
          if (error) {
            retryCreateSipUA(that);
          }
        },

        onRegister: () => { },

        onInvite(invitation: Invitation): void {
          console.log("set onInvite");
          // An Invitation is a Session
          const incomingSession: Session = invitation;
          sipSessionCallbacks.setSessionListenersForIncomingCall(
            that,
            incomingSession
          );
        },
      };
      setSIPStack(that, userAgent, registerer);
      userAgent.start().catch(() => {
        retryCreateSipUA(that);
      });
    }
  }
};

const onRegisterStateChanged = (that: CallComponentInstance) => (newState: RegistererState) => {
  if(!that._ismounted) return;
  console.log("registerer newState", newState);
  switch (newState) {
    case RegistererState.Registered:
      sipjsOnRegistered(that);
      break;
    case RegistererState.Terminated:
      that.removeToasts();
      break;
    case RegistererState.Unregistered:
      console.log("RegistererState.Unregistered");
      sipjsOnRegisterTimeout(that);
      break;
    default:
      break;
  }
}

/**
 * Called when sip.js has connected
 */
export const sipjsOnConnected = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  console.log("sipjsOnConnected");
  const { sipRegisterer } = that.state;
  //that.setState({
  //  isSipConnected: true
  //});
  const registerRequestDelegate = {
    onReject: () => {
      sipjsOnRegisterTimeout(that);
    }
  };
  // Register the user agent
  sipRegisterer?.register({
    requestDelegate: registerRequestDelegate
  });
};

/**
 * Called when sip.js has disconnected
 */
export const sipjsOnDisconnected = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { sipAgent } = that.state;

  that.setState({ isSipConnected: false });

  if (sipAgent) {
    that.showToast(`sip.js disconnected`);
  }
};

/**
 * Called when sip.js has registered
 */
export const sipjsOnRegistered = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const ipcRenderer = appConfig.electron?.ipcRenderer;
  const { sipAgent } = that.state;

  if (that.retryCreateSipTimer) {
    that.props.clearTimeout!(that.retryCreateSipTimer);
  }
  if (that.retrySipTimer) {
    that.props.clearTimeout!(that.retrySipTimer);
    that.retrySipTimer = undefined;
  }
  that.retryCreateSipTimer = undefined;

  that.setState({
    isSipConnected: true
  });

  if (sipAgent) {
    const { isSipRegistered } = that.state;

    if (!isSipRegistered) {
      ipcRenderer?.emit(ipcConstants.CLOSE_RECONNECTING_NOTIFICATION);
      ipcRenderer?.emit(ipcConstants.OPEN_CONNECTED_NOTIFICATION);
      that.removeToasts();
      that.showToast(uiString.CONNECTED_TO_SIP);
      that.setState({ isSipRegistered: true });
      if (that.retrySipTimer) that.props.clearTimeout!(that.retrySipTimer);
    }
  }
};

/**
 * Called when sip.js has unregistered
 *
 * @param response the complete unregistration response
 * @param cause usially a 1 word string containing the cause
 */
export const sipjsOnRegisterTimeout = (that: CallComponentInstance, recconectAttempt: number = 0) => {
  if(!that._ismounted) return;
  const ipcRenderer = appConfig.electron?.ipcRenderer;
  console.log("sipjsOnRegisterTimeout", recconectAttempt);
  const { sipAgent, sipRegisterer, isSipConnected, isSipRegistered } = that.state;
  if (sipAgent && sipRegisterer && sipRegisterer.state !== "Terminated" && that._ismounted) {
    that.setState({ isSipRegistered: false });
    if (recconectAttempt === SIP_MAX_RECONNECTION_ATTEMPTS) {
      retryCreateSipFunc(that);
      that.removeToasts();
      return;
    }
    if(!recconectAttempt){
      if(isSipConnected){
        if(!that.state.reconnecting){
          that.showToast(uiString.RECONNECTING_TO_SIP(recconectAttempt), true, true);
          that.setState({reconnecting: true});
          ipcRenderer?.emit(ipcConstants.OPEN_RECONNECTING_NOTIFICATION)
        }
      }
    }
    if (that.retrySipTimer) {
      that.props.clearTimeout!(that.retrySipTimer);
    }
    // Delay the register
    that.retrySipTimer = that.props.setTimeout!(() => {
      const registerRequestDelegate = {
        onAccept: () => {
          ipcRenderer?.emit(ipcConstants.CLOSE_RECONNECTING_NOTIFICATION);
          that.removeToasts();
        },
        onReject: () => {
          sipjsOnRegisterTimeout(that, ++recconectAttempt);
        }
      } as OutgoingRequestDelegate; 
      sipRegisterer.register({requestDelegate: registerRequestDelegate}).catch(() => {
        sipjsOnRegisterTimeout(that, ++recconectAttempt);
      });
    }, SIP_REGISTER_TIMEOUT);
  }
};

/**
 * Stop the sip.js stack and deinitialize (only if it's initialized)
 */
export const deinitSipJS = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { sipAgent, sipRegisterer } = that.state;
  console.log("sip.js deinit");

  if (sipRegisterer) {
    sipRegisterer.dispose();
  }

  if (sipAgent) {
    sipAgent.stop();
    sipAgent.transport.disconnect();
    setSIPStack(that, null, null);
    stateUtils.clearCallSession(that);
    that.setState({ isSipRegistered: false, isSipConnected: false });
  }
};

/**
 * Accept an incoming call on the current call session
 */
export const sipjsAcceptCall = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  console.log("accept new call");

  const { callSession } = that.state;
  console.log(callSession);

  if (callSession) {
    const acceptedSession = (callSession as any).accept({
      sessionDescriptionHandlerOptions: {
        constraints: { audio: true, video: false },
      },
    });
    console.log(acceptedSession);
  }
};

/**
 * A convenience method that sets the SIPml stack on the current session
 */
export const setSIPStack = (
  that: CallComponentInstance,
  sipAgent: UserAgent | null,
  sipRegisterer: Registerer | null
) => {
  if(!that._ismounted) return;
  that.setState({ sipAgent, sipRegisterer });
};

/**
 * Mutes the Mic or not
 */
export const setMicMute = (that: CallComponentInstance, isMuteMic) => {
  if(!that._ismounted) return;
  const { callSession } = that.state;
  const { defaultAudioInput, defaultAudioInputVolume } = that.props;
  const constraints = {
    audio: {
      deviceId: defaultAudioInput ? { exact: defaultAudioInput } : undefined,
    },
  };
  // set custom input volume for microphone
  navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
    (callSession?.sessionDescriptionHandler as any)?.peerConnection
      .getSenders()
      .forEach((sender) => {
        const audioContext = new AudioContext();
        const gainNode = audioContext.createGain();
        const audioSource = audioContext.createMediaStreamSource(stream);
        const audioDestination = audioContext.createMediaStreamDestination();
        audioSource.connect(gainNode);
        gainNode.connect(audioDestination);
        gainNode.gain.value = defaultAudioInputVolume;
        const newTrack = audioDestination.stream.getTracks()[0];
        newTrack.enabled = !isMuteMic;
        sender.replaceTrack(newTrack);
      });
  });
};

/**
 * Holds the call or not
 */
export const setCallHold = (that: CallComponentInstance, isCallHold) => {
  if(!that._ismounted) return;
  console.log("setCallHold", isCallHold);
  const { callSession } = that.state;

  if (callSession) {
    if (isCallHold) {
      callSession.invite({
        sessionDescriptionHandlerModifiers: [holdModifier],
      });
    } else {
      callSession.invite({
        sessionDescriptionHandlerModifiers: [],
      });
    }
  }
};

/**
 * Send a DTMF tone (character) to the current session
 *
 * @param character the character to send
 */
export const sendDTMF = async (that: CallComponentInstance, character) => {
  if(!that._ismounted) return;
  const { callSession } = that.state;
  console.log(character);
  console.log(callSession);

  if (callSession && character) {
    try {
      const options: SessionInfoOptions = {
        requestOptions: {
          body: {
            contentDisposition: "render",
            contentType: "application/dtmf-relay",
            content: `Signal=${character}\r\nDuration=100`,
          },
        },
      };
      await callSession.info(options);
    } catch (e) {
      console.log("e", e);
    }
  }
};

/**
 * Act accordingly to a change in the call state
 */
export const processCallState = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { numberToCall, callState, incomingCallNumber } = that.props;
  console.log("callState", callState);

  switch (callState) {
    case CallStateEnum.IDLE:
      callLifecycle.hangUp(that);
      break;
    case CallStateEnum.CONNECTING:
      break;
    case CallStateEnum.INCOMING:
      return that.showIncomingCallScreen(incomingCallNumber);
    case CallStateEnum.ACCEPT:
      sipjsAcceptCall(that);
      break;
    case CallStateEnum.CONNECTED:
      break;
    case CallStateEnum.STARTING_CALL:
      if (numberToCall) {
        callLifecycle.makeCall(that);
      }
      break;
    default:
      break;
  }
};

/**
 * Check if Sip is registered
 */
export const checkSipRegistered = (that: CallComponentInstance) => {
  if (!that._ismounted || that.checkSipTimer) return;
  that.checkSipTimer = that.props.setInterval!(() => {
    if (
      that.state.sipRegisterer &&
      that.state.sipRegisterer.state !== RegistererState.Registered
    ) {
      that.setState({ isSipRegistered: false });
    } else {
      that.setState({ isSipRegistered: true });
    }
  }, SIP_REGISTER_TIMEOUT);
};

/**
 * Deinit the Sip and retry init
 */
export const retryCreateSipUA = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  that.removeToasts();
  if (!that.retryCreateSipTimer) {
    that.retryCreateSipTimer = that.props.setInterval!(
      () => retryCreateSipFunc(that),
      SIP_RECONNECTION_TIMEOUT * 1000
    );
  }
};

export const retryCreateSipFunc = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const ipcRenderer = appConfig.electron?.ipcRenderer;
  ipcRenderer?.emit(ipcConstants.CLOSE_RECONNECTING_NOTIFICATION);
  deinitSipJS(that);
  initSipJS(that);
};

/**
 * Called to handle the ending call
 */
export const processEndingCall = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  that.setState({ isEndingCall: true });

  that.endCallTimer = that.props.setTimeout!(() => {
    that.setState({ isEndingCall: false });
  }, ENDING_CALL_TIMEOUT);
};

export const holdModifier = (
  description: RTCSessionDescriptionInit
): Promise<RTCSessionDescriptionInit> => {
  if (!description.sdp) {
    return Promise.resolve(description);
  }

  if (!/a=(sendrecv|sendonly|recvonly|inactive)/.test(description.sdp)) {
    description.sdp = description.sdp.replace(
      /(m=[^\r]*\r\n)/g,
      '$1a=sendonly\r\n'
    );
  } else {
    description.sdp = description.sdp.replace(
      /a=sendrecv\r\n/g,
      'a=sendonly\r\n'
    );
    description.sdp = description.sdp.replace(
      /a=recvonly\r\n/g,
      'a=inactive\r\n'
    );
  }
  return Promise.resolve(description);
}


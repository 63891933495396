import { CheckboxProps, withStyles } from "@material-ui/core";
import React from "react";
import { Checkbox } from "@material-ui/core";

export const CustomCheckbox = withStyles({
  root: {
    color: "var(--color-pumpkin-orange)",
    "&$checked": {
      color: "var(--color-pumpkin-orange)",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

import React from 'react'
import { OUTGOING_PHONE_NUMBER_SELECTED, OUTGOING_TEXT_PHONE_NUMBER_SELECTED } from '../../actions/preferencesActions';
import uiString from '../../constants/uiString';
import { useAppSelector } from '../../helpers/hooks/useAppSelector.hook';
import { UnderlinedTitle } from '../PresentationComponents/UnderlinedTitle';
import PhoneNumberDropdown from './PhoneNumberDropdown';

const CallerID: React.FC = () => {
  const outgoingPhoneNumbers = useAppSelector(state => state.preferences.outgoingPhoneNumbers)
  const outgoingTextPhoneNumbers = useAppSelector(state => state.preferences.outgoingTextPhoneNumbers)
  const selectedOutgoingPhoneNumber = useAppSelector(state => state.preferences.selectedOutgoingPhoneNumber)
  const selectedOutgoingTextNumber = useAppSelector(state => state.preferences.selectedOutgoingTextNumber)

  return (
    <>
      <UnderlinedTitle>
        {uiString.preferences.DEFAULT_CALLER_ID}
      </UnderlinedTitle>
      <PhoneNumberDropdown
        phoneNumbers={outgoingPhoneNumbers}
        label={uiString.preferences.OUTGOING_CALLER_ID}
        selectedValue={selectedOutgoingPhoneNumber}
        name={OUTGOING_PHONE_NUMBER_SELECTED} />
      <PhoneNumberDropdown
        phoneNumbers={outgoingTextPhoneNumbers}
        label={uiString.preferences.OUTGOING_TEXT_MESSAGE_PHONE_NUMBER}
        selectedValue={selectedOutgoingTextNumber}
        name={OUTGOING_TEXT_PHONE_NUMBER_SELECTED} />
    </>
  );
};

export default CallerID

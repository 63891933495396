import { CustomCheckbox } from "@components/PresentationComponents/FormComponents/CustomCheckbox";
import InlineDropdown from "@components/PresentationComponents/FormComponents/InlineDropdown";
import {
  FormControl,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { MeetingRepeatEnum } from "@models/Moxtra.models";
import clsx from "clsx";
import React, { useEffect } from "react";

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type IProps = {
  form: {
    endTime: Date;
    interval: number;
    weekDays: Set<string>;
    monthDays: Set<number>;
  }
  setForm: React.Dispatch<React.SetStateAction<{
    endTime: Date;
    interval: number;
    weekDays: Set<string>;
    monthDays: Set<number>;
  }>>
  recurrenceInput: JSX.Element;
  repeat: MeetingRepeatEnum;
  onChange: (form: {
    endTime: Date;
    interval: number;
    weekDays: Set<string>;
    monthDays: Set<number>;
  }) => void;
};
// array of the form [1,...,30]
const optionValues = Array.from({ length: 30 }, (_, i) => i + 1);
const dayOptions = optionValues.map((number) => ({
  value: number,
  text: `Every ${number === 1 ? "" : number} ${number === 1 ? "Day" : "Days"}`,
}));
const weekOptions = optionValues.map((number) => ({
  value: number,
  text: `Every ${number === 1 ? "" : number} ${
    number === 1 ? "Week" : "Weeks"
  }`,
}));

const monthOptions = [...optionValues, 31];
export const RecurrenceForm = ({ form, setForm, recurrenceInput, repeat, onChange }: IProps) => {
  const classes = useStyles();
  useEffect(() => {
    onChange(form);
  }, [form])

  const handleDayChange = (weekDay) => {
    const weekDays = form.weekDays;
    if (weekDays.has(weekDay)) {
      weekDays.delete(weekDay);
    } else {
      weekDays.add(weekDay);
    }
    setForm((prevForm) => ({ ...prevForm, weekDays }));
  };

  const handleMonthChange = (monthDay) => {
    const monthDays = form.monthDays;
    if (monthDays.has(monthDay) && monthDays.size > 1) {
      monthDays.delete(monthDay);
    } else {
      monthDays.add(monthDay);
    }
    setForm((prevForm) => ({ ...prevForm, monthDays }));
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    const { target } = event as any;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setForm((form) => ({
      ...form,
      [target.name]: value,
    }));
  };

  return (
    <>
      {recurrenceInput}
      {(repeat === MeetingRepeatEnum.WEEKLY || repeat === MeetingRepeatEnum.DAILY) && (
        <FormControl className={classes.formControlWrapper}>
          <InlineDropdown
            name="interval"
            options={repeat === MeetingRepeatEnum.WEEKLY ? weekOptions : dayOptions}
            onChange={handleChange}
            value={form.interval}
            innerLabel="interval"
            outlined
            fullWidth
          />
        </FormControl>
      )}

      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker 
          minDate={new Date()}
          disableToolbar
          value={form.endTime}
          id="start-date-picker"
          format="MM/dd/yyyy"
          onChange={(date) => date && setForm(prevForm => ({...prevForm, endTime: date}))}
        />
        
      </MuiPickersUtilsProvider> */}
      {repeat === MeetingRepeatEnum.WEEKLY && (
        <List className={clsx(classes.spacing, classes.selectDay)}>
          {weekdays.map((day) => (
            <ListItem key={day} button onClick={() => handleDayChange(day)}>
              <ListItemText>{day}</ListItemText>
              <ListItemSecondaryAction>
                <CustomCheckbox
                  onClick={() => handleDayChange(day)}
                  checked={form.weekDays.has(day)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}

      {repeat === MeetingRepeatEnum.MONTHLY && (
        <div className={classes.monthGrid}>
          {monthOptions.map((number) => (
            <div
              key={number}
              onClick={() => handleMonthChange(number)}
              className={clsx(
                classes.monthGridButtonContent,
                form.monthDays.has(number) && classes.selected
              )}
            >
              {number}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  formControlWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    whiteSpace: "nowrap",
    padding: "0 12px 0 12px",
    margin: "0 8px",
    marginTop: 17,
  },
  spacing: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 17,
    margin: "0 8px",
  },
  selectDay: {
    marginTop: 17,
    border: "1px solid #dde2eb",
    padding: 0,
    "&>li": {
      borderBottom: "1px solid #dde2eb",
    },
    "&>li:last-child": {
      borderBottom: "none",
    },
  },
  monthGrid: {
    marginLeft: 17,
    marginRight: 7,
    marginTop: 17,
    flexFlow: "wrap",
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gridTemplateRows: "repeat(5, 60px)",
    padding: "0 25px",
  },
  monthGridButtonContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    border: "1px solid #dde2eb",
    marginRight: "20px",
    marginBottom: "20px"
  },
  selected: {
    backgroundColor: "var(--color-pumpkin-orange)",
    border: "none",
    color: "white",
  },
});

import { styled } from "@material-ui/core/styles";

const TitleNormal = styled("h3")({
  fontFamily: "Lato, sans-serif",
  fontWeight: "normal",
  color: "var(--color-charcoal)",
  fontSize: 14,
  letterSpacing: "0.5px",
});

export { TitleNormal };

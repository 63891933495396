import { IUserMetadata } from "@models/LocalStorageItems.models";
import { useAppSelector } from "./useAppSelector.hook";
import useLocalStorage, { localStorageItems } from "./useLocalStorage.hook";

/**
 * React hook for handling the user metadata object that is saved in localStorage
 * @returns 
 */
const useMetadata = () => {
  const [metadata, setMetadata] = useLocalStorage<IUserMetadata[]>(
    localStorageItems.metadata,
    []
  );

  const userId = useAppSelector((state) => state.authentication.userId);

  const markWelcomeVoicemailAsDeleted = (isDeleted: boolean = true) => {
    const existingUserDataIndex = metadata.findIndex(
      (userData) => userData.userId === userId
    );
    if (existingUserDataIndex !== -1) {
      const newMetadata = metadata.map((userData, index) =>
        index === existingUserDataIndex
          ? { ...userData, isWelcomeVoicemailDeleted: isDeleted }
          : userData
      );
      setMetadata(newMetadata);
    } else {
      setMetadata([
        ...metadata,
        {
          userId: userId as number,
          isWelcomeVoicemailDeleted: isDeleted,
          isWelcomeVoicemailRead: false,
          isWelcomeConversationDeleted: false,
          isWelcomeConversationRead: false,
        },
      ]);
    }
  };

  const markWelcomeVoicemailAsRead = (isRead: boolean = true) => {
    const existingUserDataIndex = metadata.findIndex(
      (userData) => userData.userId === userId
    );
    if (existingUserDataIndex !== -1) {
      const newMetadata = metadata.map((userData, index) =>
        index === existingUserDataIndex
          ? { ...userData, isWelcomeVoicemailRead: isRead }
          : userData
      );
      setMetadata(newMetadata);
    } else {
      setMetadata([
        ...metadata,
        {
          userId: userId as number,
          isWelcomeVoicemailDeleted: false,
          isWelcomeVoicemailRead: isRead,
          isWelcomeConversationDeleted: false,
          isWelcomeConversationRead: false,
        },
      ]);
    }
  };

  const markWelcomeConversationAsDeleted = (isDeleted: boolean = true) => {
    const existingUserDataIndex = metadata.findIndex(
      (userData) => userData.userId === userId
    );
    if (existingUserDataIndex !== -1) {
      const newMetadata = metadata.map((userData, index) =>
        index === existingUserDataIndex
          ? { ...userData, isWelcomeConversationDeleted: isDeleted }
          : userData
      );
      setMetadata(newMetadata);
    } else {
      setMetadata([
        ...metadata,
        {
          userId: userId as number,
          isWelcomeVoicemailDeleted: false,
          isWelcomeVoicemailRead: false,
          isWelcomeConversationDeleted: isDeleted,
          isWelcomeConversationRead: false,
        },
      ]);
    }
  };

  const markWelcomeConversationAsRead = (isRead: boolean = true) => {
    const existingUserDataIndex = metadata.findIndex(userData => userData.userId === userId);
    if (existingUserDataIndex !== -1) {
      const newMetadata = metadata.map((userData, index) => 
        index === existingUserDataIndex ? ({...userData, isWelcomeConversationRead: isRead}) : userData
      )
      setMetadata(newMetadata)
    } else {
      setMetadata([...metadata, {
        userId: userId as number,
        isWelcomeVoicemailDeleted: false,
        isWelcomeVoicemailRead: false,
        isWelcomeConversationDeleted: false,
        isWelcomeConversationRead: isRead,
      }])
    }
  };

  return {
    markWelcomeVoicemailAsDeleted,
    markWelcomeVoicemailAsRead,
    markWelcomeConversationAsDeleted,
    markWelcomeConversationAsRead,
    metadata
  };
};

export default useMetadata;
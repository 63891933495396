import { ROWS_PER_PAGE_DEFAULT_VALUE } from "@constants/appConstants";
import { RowsPerPageOptions } from "@models/AppPreference.models";
import { CallHistoryFilterQuery } from "./CallHistoryTypes";

export const CALL_HISTORY_TABLE_PAGE_NUMBER = 1;
export const CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY: CallHistoryFilterQuery = {
  page: CALL_HISTORY_TABLE_PAGE_NUMBER,
  pageSize: ROWS_PER_PAGE_DEFAULT_VALUE
};
export const CALL_HISTORY_TABLE_PAGE_NUMBER_OPTIONS_LIST: RowsPerPageOptions[] = [10, 25, 50, 100];

import apiConstants from "@constants/apiConstants";
import { IApiResponseWithPagination } from "@models/IPagination";
import { IEmailSubscriber, IVirtualNumber } from "@models/TextMessaging.models";
import { AxiosResponse } from "axios";
import HttpClient from "./HttpClient";

export default function TextMessagingService() {
  const url = apiConstants().endpoints.virtualNumbers.FOR_MESSAGING;
  const updateUrl = apiConstants().endpoints.virtualNumbers.UPDATE_NUMBER;
  const subUrl = apiConstants().endpoints.virtualNumbers.EMAIL_SUBSCRIBERS;

  const { Get, Patch, Post, Delete } = HttpClient();

  const GetByPage = async (
    page: number,
    pageSize: number,
    forFiltering: 0 | 1 = 0
  ) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      params.set("forFiltering", String(forFiltering));
      const { data } = await Get<IApiResponseWithPagination<IVirtualNumber[]>>({
        url,
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const UpdateOne = async (id: string) => {
    try {
      const res = await Patch<
        any,
        any,
        AxiosResponse<{ data: IVirtualNumber }>
      >({
        url: updateUrl(id),
        data: { messagingValid: true, messagingActivated: true },
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const GetSubscribers = async (id: string) => {
    try {
      const res = await Get<
        any,
        AxiosResponse<IApiResponseWithPagination<IEmailSubscriber[]>>
      >({ url: subUrl(id) });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const AddSubscriber = async (id: string, email: string) => {
    try {
      const res = await Post<
        any,
        any,
        AxiosResponse<IApiResponseWithPagination<IEmailSubscriber>>
      >({
        url: subUrl(id),
        data: { email },
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const DeleteSubscriber = async (id: string, deleteId: string) => {
    try {
      const res = await Delete({ url: `${subUrl(id)}/${deleteId}` });
      return res;
    } catch (error) {
      throw error;
    }
  };

  return {
    GetByPage,
    UpdateOne,
    GetSubscribers,
    AddSubscriber,
    DeleteSubscriber,
  };
}

import {
  addLeadingCountryCode,
  formatPhoneNumber,
  trimPhoneNumber,
} from "@helpers/functions/phoneNumberFormatter";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IForwardNumberForm } from "./constants";
import { IForwardedPhoneNumber } from "../../models/IPhoneNumbers";
import InputField from "../PresentationComponents/FormComponents/InputField";
import uiString, { ApiErrorMessages } from "../../constants/uiString";
import Dialog from "../PresentationComponents/Dialog/Dialog";
import DialogActions from "../PresentationComponents/Dialog/DialogActions";
import DialogButton from "../PresentationComponents/Dialog/DialogButton";
import DialogContent from "../PresentationComponents/Dialog/DialogContent";
import { CustomCheckbox } from "@components/PresentationComponents/FormComponents/CustomCheckbox";
import { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { useToasts } from "react-toast-notifications";
import { isPossibleNumber, isValidNumber, isValidNumberForRegion } from "libphonenumber-js";

const FPNStrings = uiString.SETTINGS_AND_PREF.FORWARDING_NUMBERS;

interface IProps {
  open: boolean;
  formData: IForwardedPhoneNumber | null;
  close: () => void;
  deleteData: (id: number) => any;
  submitData: (formData: IForwardNumberForm) => any;
}

export const FPNumberCreateDialog = ({
  open,
  close,
  formData,
  submitData,
}: IProps) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState<IForwardNumberForm>({
    description: "",
    announceAndVoicemailEnabled: true,
    number: "",
  });
  const { addToast } = useToasts();
  const [errors, setErrors] = useState<{
    description?: string[],
    announceAndVoicemailEnabled?: string[],
    number?: string[]
  } | null>(null);

  useEffect(() => {
    if (open) {
      setForm({
        description: formData ? formData.description : "",
        announceAndVoicemailEnabled: formData ? formData.announceAndVoicemailEnabled : true,
        number: formData ? String(trimPhoneNumber(formData.number)) : "",
      });
    }
    setErrors(null);
  }, [formData, open]);

  const handleChange = (
    event: React.ChangeEvent<
    HTMLInputElement | {
    name?: string | undefined;
    value: unknown;
    checked?: boolean;
    type: string;
    }>
  ) => {
    const { target } = event;
    const isNumber = target.name === "number";
    const value = target.type !== "checkbox" ? target.value : target.checked;

    setForm((form) => ({
      ...form,
      [target.name as string]: isNumber
        ? trimPhoneNumber(value as string)
        : value,
    }));
  }

  const onSubmitClick = async () => {
    try {
      const validationResults: {description?: string[]; number?: string[]} = {};

      if (form.description.length === 0) validationResults.description = ["The description field is required"]
      else 
      if (form.description.length > 40) validationResults.description = ["The description may not be longer than 40 characters"]
  
      if (form.number == null || form.number.length === 0 ) validationResults.number = ["The number field is required"]
      else
      if (!isValidNumber('+'+form.number)) validationResults.number = ["The phone number is invalid"]

      if (validationResults.description ||  validationResults.number) {
        setErrors(validationResults);
      } else {
        setIsSubmitting(true);
        setErrors(null);
        const addPlusToNumber = form.number
          ? "+" + String(trimPhoneNumber(form.number))
          : "";
        await submitData({ ...form, number: addPlusToNumber });
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);

      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else if ((error as any).response && (error as any).response.status === ErrorStatusCode.BAD_ENTITY) {
        setErrors((error as any).response.data.errors);
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  return (
    <Dialog
      title={
        formData
          ? FPNStrings.EDIT_DIALOG.TITLE
          : FPNStrings.CREATE_DIALOG.TITLE
      } 
      open={open} 
      onClose={close} 
      maxWidth="xs" 
      fullWidth
    >
      <DialogContent>
        <FormControl className={classes.modalInputs}>
          <InputField
            value={form.description}
            label={FPNStrings.CREATE_DIALOG.PHONE_DESCRIPTION}
            errorMessage={errors?.description?.length && errors.description[0] || ""}
            minWidth="190px"
            onChange={handleChange}
            name="description"
            error={Boolean(errors?.description)}
          />
        </FormControl>
        <FormControl className={classes.modalInputs}>
          <InputField
            value={
              form.number
                ? addLeadingCountryCode(formatPhoneNumber(form.number))
                : ""
            }
            className={classes.addNumberInput}
            label={FPNStrings.CREATE_DIALOG.NUMBER}
            errorMessage={errors?.number?.length && errors.number[0] || ""}
            onChange={handleChange}
            name="number"
            inputProps={{ maxLength: 16 }}
            error={Boolean(errors?.number)}
            startAdornment={<InputAdornment position="start">+</InputAdornment>}
          />
        </FormControl>
        <FormGroup className={classes.modalInputs}>
          <FormControlLabel
            control={
              <CustomCheckbox
                defaultChecked={form.announceAndVoicemailEnabled}
                name="announceAndVoicemailEnabled"
                onChange={handleChange}
                color="primary"
              />
            }
            label={FPNStrings.CREATE_DIALOG.ENABLE_CALL_ANNOUNCE_AND_VOICEMAIL}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <DialogButton
          label={uiString.SAVE_CHANGES}
          isLoading={isSubmitting}
          onClick={onSubmitClick}
          disabled={isSubmitting}
          color="secondary"
          fullWidth
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modalInputs: {
    display: "block",
    margin: theme.spacing(1, 0),
  },
  addNumberInput: {
    width: '205px',
  }
}));

import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useToasts } from "react-toast-notifications";
import { ErrorStatusCode } from "./useService.hook";
import { ApiErrorMessages } from "@constants/uiString";

export const useToast = () => {
  const [toastError, setToastError] = useState<AxiosError | null>(null);
  const { addToast } = useToasts();

  useEffect(() => {
    toastError && displayToast(toastError);
  }, [toastError]);

  const displayToast = (error: AxiosError) => {
    if (error.response && error.response.status === ErrorStatusCode.FORBIDDEN) {
      addToast(ApiErrorMessages.FORBIDDEN, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast(ApiErrorMessages.UNEXPECTED, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return {
    setToastError,
  };
};

import { IApiResponseWithPagination } from "@models/IPagination";
import { IBlockedNumber } from "@models/IPhoneNumbers";
import HttpClient from "./HttpClient";
import getApiConstants from "@constants/apiConstants";
import { AxiosResponse } from "axios";
import { appConfig } from "../index";

export default function BlockedNumberService() {
  const url = getApiConstants().endpoints.BLOCK_CALL;
  const urlGet = getApiConstants().endpoints.BLOCK_CALL_V2;

  const { Get } = HttpClient();

  const GetByPage = async (page: number, pageSize: number, id: number) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      const { data } = await Get<
        IApiResponseWithPagination<IBlockedNumber[]>
      >({ url: urlGet(id), params });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const GetBlockedNumbers = async (id: number) => {
    try {
      const { data } = await Get<AxiosResponse<IBlockedNumber[]>>({ url: urlGet(id) });
      return data;
    } catch (error) {
      throw error
    }
  };

  const UnBlockNumber = async (number: string) => {
    try {
      const body = { "number": number };
      const data = await appConfig.axios.delete<string, AxiosResponse>(url, { data: body } );
      return data;
    } catch (error) {
      throw (error);
    };
  };

  const BlockNumber = async (number: string) => {
    try {
      const body = { "number": number };
      const data = await appConfig.axios.post<string, AxiosResponse<AxiosResponse<IBlockedNumber[]>>>(url, body);
      return data;
    } catch (error) {
      throw error;
    };
  };

  return {
    GetByPage,
    GetBlockedNumbers,
    UnBlockNumber,
    BlockNumber,
  }
};
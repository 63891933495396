import { IApiResponseWithPagination } from "./IPagination";

export const enum TransferCodeTypes {
    DID = "did",
    MENU_OPTION = "menu_option",
    FORWARDING_DEVICE = "forwarding_device",
    VOICEMAIL = "voicemail",
    WEBRTC_DEVICE = "webrtc_device",
    EXTENSIONS = "extensions",
  };

  export const MapTransferCodeTypeToLabel = {
    [TransferCodeTypes.DID]: "Virtual Phone Numbers",
    [TransferCodeTypes.MENU_OPTION]: "Menu Options",
    [TransferCodeTypes.FORWARDING_DEVICE]: "Forwarding Phone Numbers",
    [TransferCodeTypes.VOICEMAIL]: "Voicemail Boxes",
    [TransferCodeTypes.WEBRTC_DEVICE]: "Desktop Devices",
    [TransferCodeTypes.EXTENSIONS]: "Extensions",
  }

  export type ITransferCode = {
    id: string;
    digits: string;
    enabled: boolean;
    destination: {
      id: string;
      type: string;
      display: string;
      link: string;
    };
  };
  
import { 
  IFormOptions, 
  IFormError, 
  IFormMetaData 
} from '@models/form.models';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

const transformFormOptions = <FormType extends {}, >(input: IFormOptions) => Object.entries(input).reduce<FormType>((initialValue, entry) => {
  return {
    ...initialValue ?? {},
    [entry[0]]: entry[1].initialValue
  }
}, {} as FormType)
/**
 * Presentation component for useForm hook
 * 
 * @component
 * @param input - accepts object with input field name which has initial value in it
 * and validator functions, for validating the input. --> See IForm interface
 * 
 * @returns 
 * @formErrors - object with each input field name containing hasError boolean,
 *  validatorResults (the name of the error which appeared and error message).
 * @formMetaData - object containing information about each field like dirty property
 * when the field is touched or is checkbox.
 * @form - object containing all the form fields with its values
 * @validateForm - function that validates all the form fields using the field validators
 * @validateField - function that validates the current field using the field validators
 * @changeHandler - function that changes the value of the current field
 * @resetToDefault - resets the form fields to default values
 * @example
 * const MyComponent = () => {
 *  const { form, formMetaData, formErrors, validateForm, validateField, changeHandler } =
 *  useForm({
 *    userEmail: { initialValue: example@anvilo.com, 
 *      validators: (input) => return input !== '' };
 *  });
 * 
 *  const emailChangeHandler = (e, validateOnChange: boolean) => {
 *    validateOnChange && validateField(e.target.name, e.target.value);
 *  };
 * 
 *  const submitHandler = () => {
 *    const isValid = validateForm();
 *    isValid && console.log(form);
 *    resetToDefault();
 *  };
 * 
 *  return (
 *   <>
 *     <Input
 *       name="userEmail"
 *       error={formErrors.userEmail?.hasError}
 *       errorMessage={formErrors.userEmail?.validatorResults.errorMessage}
 *       value={form.userEmail}
 *       onChange={(e) => emailChangeHandler(e, true)}
 *     />
 *     <Button onClick={submitHandler}>Submit</Button>
 *   </>
 *  )
 * }
 */
const useForm = <FormType extends {[name: string]: any},>(input: IFormOptions) => {
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const formMetaData = useRef<IFormMetaData>({});
  const formDefaultData = useRef<IFormOptions>({})
  const [form, setForm] = useState<FormType>(() => transformFormOptions(input));

  /**
   * Check the validity of each form field
   * 
   * @returns true if valid
   */
  const validateForm = () => {
    let errorResults = {};
    for (let [fieldKey, fieldValue] of Object.entries(form)) {
      const validators = formDefaultData.current[fieldKey].validators;

      for (let validator of validators) {
        if (_.isArray(fieldValue)) {
          let fieldIndexArr: number[] = [];
          fieldValue.map((item, index) => {
            const { hasError, validatorResults } = validator(item);
            if (hasError) {
              fieldIndexArr.push(index);
              errorResults[fieldKey] = {
                hasError: true,
                validatorResults,
                fieldIndex: fieldIndexArr
              };
            };
          })
        } else {
          const { hasError, validatorResults } = validator(fieldValue);
          if (hasError) {
            errorResults[fieldKey] = {
              hasError: true,
              validatorResults,
            }
            break;
          };
        };
      };
    };
    setFormErrors(errorResults);
    return _.isEmpty(errorResults);
  };

  /**
   * Validate a single field with the current field validators.
   */
  const validateField = (fieldName: string, fieldValue: string | number) => {
    const validators = input[fieldName].validators;
    let _formErrors = formErrors

    for (let validator of validators) {
      const { hasError, validatorResults } = validator(fieldValue);
      if (!hasError) {
        delete _formErrors[fieldName];
      } else {
        _formErrors[fieldName] = { hasError: true, validatorResults };
      }
    };
    setFormErrors(_formErrors);
  };

  /**
   * Reset all form fields to the default values.
   */
  const resetToDefault = () => {
    setForm(transformFormOptions(formDefaultData.current));
    setFormErrors({});
  };

  /**
   * Update form field value and meta data
   * 
   * @param validateOnChange if true check validity of the value on every change
   */
  const changeHandler = (
    name: string | undefined, fieldValue: any, validateOnChange = false
  ) => {
    const fieldName = name as string;
    if (!validateOnChange) {
      setFormErrors((state) => (
        { ...state, [fieldName]: { hasError: false, validatorResults: { code: null, errorMessage: '' } } }
      ));
    };
    formMetaData.current[fieldName] = { dirty: true, isCheckbox: formMetaData.current[fieldName]?.isCheckbox };
    validateOnChange && validateField(fieldName, fieldValue);
    setForm(state => ({ ...state, [fieldName]: fieldValue }));
  };

  useEffect(() => {
    let initialValues = {};
    for (let element of Object.entries(input)) {
      initialValues[element[0]] = { dirty: false, isCheckbox: typeof element[1].initialValue === 'boolean' };
    };
    formMetaData.current = initialValues;
    formDefaultData.current = input;
  }, [input]);

  return {
    form,
    formErrors,
    formMetaData: formMetaData.current,
    formDefaultData,
    validateForm,
    validateField,
    changeHandler,
    resetToDefault,
    setForm,
    setFormErrors,
  }
};

export default useForm;

import { OptionsType } from "@models/Additional.models"

export const refactorOptions = (options: any[]): OptionsType[] => {
  return options.map(option => {
    if (option.type) {
      return {
        value: option.id,
        text: option.name ? option.name : option.description,
        type: option.type
      }
    } else {
      return {
        value: option.id,
        text: option.name ? option.name : option.description,
      }
    }
  })
}
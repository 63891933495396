import { setPermittedFeatures } from "@actions/userActions";
import PlayAudioBasicDialog from "@components/PresentationComponents/AudioComponents/PlayAudioBasicDialog";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import { ErrorWrapper } from "@components/PresentationComponents/ErrorWrapper";
import RoundedInputField from "@components/PresentationComponents/FormComponents/RoundedInputField";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import Loader from "@components/PresentationComponents/Loader";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import {
  TableDeleteButton,
  TableEditButton,
  TablePlayButton,
} from "@components/PresentationComponents/TableComponents/TableHelpers";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { ComponentDimensions } from "@constants/appConstants";
import uiString, {
  ApiErrorMessages,
  ErrorWrapperMessages,
} from "@constants/uiString";
import useAccessBlocker from "@helpers/hooks/useAccessBlocker.hook";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import usePrevious from "@helpers/hooks/usePrevious.hook";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FeatureIdsEnum } from "@models/Account.models";
import { IAudioFile } from "@models/AudioFiles.models";
import { IOffHoursOptionsBody } from "@models/HoursOfOperation.models";
import { IPagination } from "@models/IPagination";
import { CallDestinationType } from "@models/Menu.models";
import { IVoicemailBox, IVoicemailBoxForm } from "@models/Voicemailbox.models";
import CrossIcon from "@resources/icons/cross-small.svg";
import SearchIcon from "@resources/icons/search-icon.svg";
import AudioFilesService from "@services/AudioFiles.service";
import HoursOfOperationService from "@services/HoursOfOperation.service";
import UserService from "@services/User.service";
import VoicemailboxService from "@services/Voicemailbox.service";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { noneAudioFile } from "../HoursOfOperation/constants";
import InputButton from "../PresentationComponents/FormComponents/InputButton";
import { MANAGE_VOICEMAILS } from "./constants";
import VoicemailBoxDialog from "./VoicemailboxDialog";

const VoicemailStrings = uiString.SETTINGS_AND_PREF.VOICE_MAILBOX;

const VoicemailPreferences: React.FC = () => {
  const classes = useStyles();
  const [audioList, setAudioList] = useState<IAudioFile[]>([]);
  const [vBoxList, setVBoxList] = useState<IVoicemailBox[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState<IVoicemailBox | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [componentIsLoaded, setComponentIsLoaded] = useState(true);
  const audioFilesService = useService(AudioFilesService);
  const voicemailboxService = useService(VoicemailboxService);
  const hoursOfOperationsService = useService(HoursOfOperationService);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const rowsPerPage = useAppSelector(
    (state) => state.preferences.tableSize.voicemailboxes
  );
  const accountId = useAppSelector((state) => state.authentication.accountId);
  const [error, setError] = useState<ErrorWrapperMessages | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [mailboxToDelete, setMailboxToDelete] = useState<IVoicemailBox | null>(
    null
  );
  const prevMailboxToDelete = usePrevious(mailboxToDelete);
  const [searchInput, setSearchInput] = useState<string>("");
  const filterActivated = useRef(false);
  const [selectedMailbox, setSelectedMailbox] = useState<IVoicemailBox | null>(
    null
  );
  const [openPreview, setOpenPreview] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [
    afterHourSettings,
    setAfterHourSettings,
  ] = useState<IOffHoursOptionsBody | null>(null);
  const {
    GuardDialog,
    permissionGuard,
    doublecheckPermissions,
    triggerGuard,
  } = useAccessBlocker(FeatureIdsEnum.VMBOX);

  // const [openPaywallDialog, setOpenPaywallDialog] = useState(false);
  const addVoicemail = permissionGuard(() => {
    selectedBox && setSelectedBox(null);
    setOpenModal(true);
  });

  const editVoicemail = (vm: IVoicemailBox) => {
    setSelectedBox(vm);
    setOpenModal(true);
  }

  const getItems = async (page?: number, useFilter?: boolean) => {
    const forUpdate = 1;
    setLoading(true);
    try {
      const search: { phoneNumber?: string; description?: string } = {};
      // search[searchInput.includes('@') ? "email" : "description"] = searchInput.trim();
      search.description = searchInput.trim();
      const { data, pagination } = await voicemailboxService.GetByPage(
        page ?? 1,
        rowsPerPage,
        forUpdate,
        useFilter ? search : undefined
      );
      !afterHourSettings && (await getAfterHourOptions());
      if (componentIsLoaded) {
        setPageState({ ...pagination, perPage: rowsPerPage });
        setVBoxList(data);
        setLoading(false);
        setFirstLoad(false);
      }
    } catch (error) {
      setLoading(false);

      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
        setError(ErrorWrapperMessages.NOT_AUTHORIZED_TO_VIEW);
        setFirstLoad(false);
      } else {
        setError(ErrorWrapperMessages.FETCH_DATA_ERROR);
        setFirstLoad(false);
        throw error;
      }
    }
  };

  const addFileToAudioList = (file: IAudioFile) => {
    setAudioList((data) => [...data, file]);
  };

  const getAudioOptions = async () => {
    try {
      const { data } = await audioFilesService.GetByPage(1, 1000, null);
      componentIsLoaded && setAudioList([...data, noneAudioFile]);
    } catch (error) {
      console.error(error);
    }
  };

  const getAfterHourOptions = async () => {
    try {
      const response = await hoursOfOperationsService.GetSettings();
      setAfterHourSettings(response.data);
    } catch (error) {
      throw error;
    }
  };

  const onAddVB = async (form) => {
    const body = {
      description: form.description,
      email: form.email,
      displayTranscripts: form.displayTranscripts,
      addTranscriptToEmail:
        form.displayTranscripts && form.addTranscriptToEmail,
      addAudioToEmail: form.addAudioToEmail,
      greetingAudioId:
        form.greetingAudioId !== noneAudioFile.id ? form.greetingAudioId : null,
    };
    try {
      const response = await voicemailboxService.CreateOne(body);
      accountId && (await getPermittedFeatures(accountId));
      if (response.status === 201) {
        await getItems();
        return response;
      }
    } catch (err) {
      if ((err as any).response && (err as any).response.status === 402) {
        doublecheckPermissions(() => {
          setOpenModal(false);
          triggerGuard();
        });
      } else {
        throw err;
      }
    }
  };

  const onEditVb = async (form: IVoicemailBoxForm) => {
    const body = {
      description: form.description,
      email: Boolean(form.email) ? form.email : null,
      displayTranscripts: form.displayTranscripts,
      addTranscriptToEmail:
        form.displayTranscripts && form.addTranscriptToEmail,
      addAudioToEmail: form.addAudioToEmail,
      greetingAudioId:
        form.greetingAudioId !== noneAudioFile.id ? form.greetingAudioId : null,
    };

    try {
      const response = await voicemailboxService.UpdateOne(form.id, body);
      if ((response.status = 200)) {
        setVBoxList((list) =>
          list.map((vbox) => (vbox.id === form.id ? response.data.data : vbox))
        );
      }
      accountId && (await getPermittedFeatures(accountId));
      return response;
    } catch (err) {
      throw err;
    }
  };

  const getPermittedFeatures = async (accountId: number) => {
    const { GetPermittedFeatures } = UserService();
    try {
      const { data } = await GetPermittedFeatures(String(accountId));
      dispatch(setPermittedFeatures(data));
    } catch (error) {
      throw error;
    }
  };

  const onDelete = async (id: string) => {
    try {
      setIsDeleting(true);
      const response = await voicemailboxService.DeleteOne(id);
      if (response.status === 204) {
        setVBoxList((list) => list.filter((item) => item.id !== id));
      }
      await getItems();
      setMailboxToDelete(null);
      setIsDeleting(false);
      accountId && (await getPermittedFeatures(accountId));
    } catch (error) {
      setIsDeleting(false);
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  };

  const toggleSearchButton = () => {
    filterActivated.current = true;
    getItems(1, filterActivated.current);
  };

  const handleSearchOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !loading) {
      filterActivated.current = true;
      getItems(1, filterActivated.current);
    }
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const openPlayModal = (file: IVoicemailBox) => {
    setOpenPreview(true);
    setSelectedMailbox(file);
  };

  const closePlayModal = () => {
    setSelectedMailbox(null);
    setOpenPreview(false);
  };

  const submitHandler = selectedBox ? onEditVb : onAddVB;

  useEffect(() => {
    getItems(1);
    getAudioOptions();
    return () => setComponentIsLoaded(false);
  }, []);

  useEffect(() => {
    if (filterActivated.current && searchInput === "") {
      filterActivated.current = false;
      getItems(1);
    }
  }, [searchInput]);

  useEffect(() => {
    getItems(1, filterActivated.current);
  }, [rowsPerPage]);

  return (
    <>
      <Loader open={loading} />
      <UnderlinedTitle>{MANAGE_VOICEMAILS}</UnderlinedTitle>
      {!firstLoad && (
        <ErrorWrapper
          errorMessage={error}
          onRetry={() => {
            setError(null);
            getItems();
          }}
        >
          <Grid>
            <Grid className={classes.controls}>
              <RoundedInputField
                style={{
                  width: ComponentDimensions.PREFERENCES_SEARCH_WIDTH,
                  height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
                }}
                endAdornment={
                  searchInput ? (
                    <>
                      <SearchIcon
                        className={classes.searchReset}
                        width="19"
                        onClick={toggleSearchButton}
                      />
                      <CloseIcon
                        width="17"
                        className={classes.searchReset}
                        onClick={() => setSearchInput("")}
                      />
                    </>
                  ) : (
                    <SearchIcon className={classes.searchReset} width="19" />
                  )
                }
                value={searchInput}
                onKeyDown={handleSearchOnEnter}
                onChange={handleSearchOnChange}
              />
              <InputButton
                onClick={addVoicemail}
                label="Add Voice Mailbox"
                className={classes.addBtn}
                color="primary"
                minWidth={ComponentDimensions.PREFERENCES_ADD_BUTTON_WIDTH}
                plusIcon
              />
              <VoicemailBoxDialog
                vbox={selectedBox}
                audioList={audioList}
                open={openModal}
                closeHandler={() => setOpenModal(false)}
                submitHandler={submitHandler}
                addFileToAudioList={addFileToAudioList}
                deleteHandler={() =>
                  onDelete((selectedBox as IVoicemailBox).id)
                }
                submitText={uiString.SAVE_CHANGES}
                headerText={
                  selectedBox
                    ? VoicemailStrings.EDIT_DIALOG.TITLE
                    : VoicemailStrings.CREATE_DIALOG.TITLE
                }
              />
            </Grid>
            <Grid>
              {!firstLoad && (
                <CustomTablePagination
                  pagination={pageState}
                  handlePageChange={getItems}
                  storeKey="voicemailboxes"
                >
                  <PlainTable
                    data={vBoxList}
                    selectColumns={[
                      "description",
                      "email",
                      "voicemail greeting",
                    ]}
                    columnSize={["25%", "25%", "auto"]}
                    dataMapper={(vbox) => ({
                      ...vbox,
                      "voicemail greeting": vbox.greetingAudio
                        ? vbox.greetingAudio.name
                        : "None",
                    })}
                    actions={[
                      {
                        colLabel: "Play",
                        width:
                          ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                        ActionComponent: (row) =>
                          row.greetingAudio && row.greetingAudio.link ? (
                            <TablePlayButton
                              row={row}
                              handler={(file) => openPlayModal(file)}
                              downloadUrl={row.greetingAudio.link}
                              id={row.id}
                              disableDelete={false}
                            />
                          ) : (
                            <Tooltip
                              styling="standard"
                              title={"No Recording Available"}
                              aria-label="no-recording"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: 8,
                                }}
                              >
                                <CrossIcon
                                  style={{
                                    width:
                                      ComponentDimensions.TABLE_CROSS_ICON_SIZE,
                                    height:
                                      ComponentDimensions.TABLE_CROSS_ICON_SIZE,
                                    fill: "var(--color-charcoal)",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          ),
                      },
                      {
                        colLabel: uiString.SETTINGS,
                        width:
                          ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                        ActionComponent: (row) => (
                          <TableEditButton row={row} handler={editVoicemail} />
                        ),
                      },
                      {
                        colLabel: uiString.DELETE,
                        width:
                          ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                        ActionComponent: (row) =>
                          afterHourSettings?.closed.destination.type ===
                            CallDestinationType.VOICEMAIL &&
                          String(row.id) ==
                            String(
                              afterHourSettings?.closed?.destination?.id
                            ) ? (
                            <Tooltip
                              styling="standard"
                              title={VoicemailStrings.VOICEMAIL_IS_USED_TOOLTIP}
                            >
                              <span>
                                <TableDeleteButton
                                  row={row}
                                  handler={(row) => setMailboxToDelete(row)}
                                  disabled={true}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            <TableDeleteButton
                              row={row}
                              handler={(row) => setMailboxToDelete(row)}
                            />
                          ),
                      },
                    ]}
                    fullWidth
                  />
                </CustomTablePagination>
              )}
              {/* <PaywallDialog open={openPaywallDialog} onClose={() => setOpenPaywallDialog(false)}/> */}
              <ConfirmDialog
                header={uiString.DELETE_MAILBOX_CONFIRMATION_HEADER}
                content={uiString.DELETE_MAILBOX_CONFIRMATION_BODY}
                open={Boolean(mailboxToDelete)}
                confirmButtonLabel={uiString.DELETE}
                loading={isDeleting}
                onCancel={() => setMailboxToDelete(null)}
                onConfirm={() =>
                  mailboxToDelete && onDelete(mailboxToDelete.id)
                }
                disabledMessage={
                  VoicemailStrings.DELETE_DIALOG.VOICEMAIL_IS_USED
                }
                disabled={
                  afterHourSettings?.closed.destination.type ===
                    CallDestinationType.VOICEMAIL &&
                  String((mailboxToDelete ?? prevMailboxToDelete)?.id) ===
                    String(afterHourSettings?.closed?.destination?.id)
                }
                typeConfirmationText={
                  VoicemailStrings.DELETE_DIALOG.DELETE_CONFIRMATION_TEXT
                }
              />
              <PlayAudioBasicDialog
                open={openPreview}
                close={closePlayModal}
                audioSrc={selectedMailbox?.greetingAudio.link}
                autoplay
              />
            </Grid>
          </Grid>
        </ErrorWrapper>
      )}
      <GuardDialog />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  addVoicemailBox: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    width: 400,
  },
  loader: {
    display: "block",
    margin: "auto",
    marginTop: "50",
  },
  searchReset: {
    cursor: "pointer",
    color: "grey",
  },
  controls: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(1, 0),
  },
  addBtn: {
    fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
    height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
  },
}));

export default VoicemailPreferences;

import getApiConstants from "@constants/apiConstants";
import { IExtension } from "@models/Extension.models";
import { IApiResponseWithPagination } from "@models/IPagination";
import { ITransferCode, TransferCodeTypes } from "@models/TransferCodes.models";
import { appConfig } from "../index";
import HttpClient from "./HttpClient";

export default function TransferCodesService() {
  const url = getApiConstants().endpoints.transfercodesv2.BASE;
  const urlExtensions = getApiConstants().endpoints.extensionsv2.BASE;
  const { Get } = HttpClient();

  const GetByPage = async (
    page: number,
    pageSize: number,
    type?: TransferCodeTypes
  ) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      // type && params.set("type", type);
      const { data } = await Get<IApiResponseWithPagination<ITransferCode[]>>({
        url,
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const GetExtensionsByPage = async (
    page: number,
    pageSize: number,
    type?: TransferCodeTypes
  ) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      type && params.set("type", type);
      const { data } = await Get<IApiResponseWithPagination<IExtension[]>>({
        url: urlExtensions,
        params,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };
  return {
    GetByPage,
    GetExtensionsByPage
  };
}

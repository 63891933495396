import uiString from "@constants/uiString";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { memo, useState, useEffect } from "react";
import InputButton from "./FormComponents/InputButton";
import InputField from "./FormComponents/InputField";

interface IProps {
  confirmButtonLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  content: JSX.Element | string;
  header: JSX.Element | string;
  open: boolean;
  id?: string;
  loading?: boolean;
  typeConfirmationText?: string;
  disabled?: boolean;
  disabledMessage?: string;
  showCancelButton?: boolean;
}

const ConfirmDialog = ({
  open,
  confirmButtonLabel,
  onCancel,
  onConfirm,
  content,
  header,
  typeConfirmationText,
  loading,
  disabled = false,
  showCancelButton = true,
  disabledMessage,
  id,
}: IProps) => {
  const [confirmationText, setConfirmationText] = useState("");
  
  useEffect(() => { !open && setConfirmationText("") }, [open])
  
  return (
    <Dialog
      id={id}
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{header}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {disabled ? disabledMessage : content}
        </DialogContentText>
        {!disabled && typeConfirmationText && (
          <InputField
            style={{ width: 250 }}
            placeholder={typeConfirmationText}
            label={`Type "${typeConfirmationText}" to confirm`}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
        >
          {confirmButtonLabel}
        </Button> */}
        <div
          style={{
            display: "flex",
            justifyContent: showCancelButton ? "space-between" : "flex-end",
            width: "100%",
          }}
        >
          {showCancelButton && <InputButton onClick={onCancel} variant="outlined" color="primary">
            {uiString.CANCEL}
          </InputButton>}
          <InputButton
            onClick={onConfirm}
            variant="contained"
            color="primary"
            isLoading={loading}
            disabled={
              disabled ||
              (typeConfirmationText
                ? confirmationText !== typeConfirmationText
                : false)
            }
          >
            {confirmButtonLabel}
          </InputButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ConfirmDialog);
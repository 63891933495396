import { RootState } from "@helpers/store/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../useAppSelector.hook";

export const makeItemSelector = <T extends { id: string | number }>(
  stateSelector: (state: RootState) => T[],
  stateSetter: (
    payload: T[]
  ) => {
    type: string;
    payload: T[];
  }
) => (arr: T[]) => {
  // const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [lastFocusedItem, setLastFocusedItem] = useState<T | null>(null);
  const selectedItems = useAppSelector(
    stateSelector
  );
  const dispatch = useDispatch();
  const _setSelectedItems = (value: T[]) => dispatch(stateSetter(value));

  useEffect(() => {
    if (selectedItems.length === 1) {
      setLastFocusedItem(selectedItems[0]);
    } else if (selectedItems.length === 0) {
      setLastFocusedItem(null);
    }
  }, [selectedItems.length]);

  const _addItemToSelected = (item: T) => {
    if (selectedItems.find((_item) => _item.id === item.id)) {
      _deselectItem(item);
      setLastFocusedItem(null);
    } else {
      _setSelectedItems(selectedItems.concat(item));
      setLastFocusedItem(item);
    }
  };

  /**
   * `selectedItems` holds a copy of all the selected items. If the original item is modified
   * then those changes do not automatically syncronize in the copy that is within `selectedItems`.
   * Use `syncSelectedElementCopyWithSource` by passing the modified item
   * @param updatedItem 
   */
  const syncSelectedElementCopyWithSourceElement= (updatedItem: T) => {
    let proceedWithUpdate = false;
    const updatedSelectedItems = selectedItems.map(_selectedItem => {
        if (_selectedItem.id === updatedItem.id) {
          proceedWithUpdate = true;
          return updatedItem;   
        } else {
          return _selectedItem
        }
      }
    );
    if (proceedWithUpdate) {
      _setSelectedItems(updatedSelectedItems);
    }
  }

  const selectItem = (item: T) => {
    _setSelectedItems([item]);
    setLastFocusedItem(item);
  };

  const _selectAllBetween = (firstItem: T, subsetConstraint: T[] = []) => {
    const secondItem: T | null = lastFocusedItem;
    if (secondItem && firstItem.id !== secondItem.id) {
      const firstItemIndex = arr.findIndex((item) => item.id === firstItem.id);
      const secondItemIndex = arr.findIndex(
        (item) => item.id === secondItem.id
      );
      const indexToStartFrom = Math.min(firstItemIndex, secondItemIndex);
      const indexToEndAt = Math.max(firstItemIndex, secondItemIndex);
      const itemsToSelect: T[] = [];

      for (let indx = indexToStartFrom; indx <= indexToEndAt; indx++) {
        const itemHasntBeenSelectedYet = Boolean(
          selectedItems.findIndex((item) => item.id === arr[indx].id) === -1
        );
        const checkIfItemPassesConstraint =
          subsetConstraint.length === 0 ||
          !Boolean(
            subsetConstraint.findIndex((item) => item.id === arr[indx].id) ===
              -1
          );
        const weShouldSelectTheItem =
          itemHasntBeenSelectedYet && checkIfItemPassesConstraint;
        weShouldSelectTheItem && itemsToSelect.push(arr[indx]);
      }
      _setSelectedItems(selectedItems.concat(itemsToSelect));
    } else {
      _addItemToSelected(firstItem);
    }
  };

  const _deselectItem = (item: T) => {
    _setSelectedItems(selectedItems.filter((_item) => _item.id !== item.id));
  };

  const deselectAll = () => _setSelectedItems([]);
  const selectAll = (subsetConstraint: T[] = []) =>
    _setSelectedItems(subsetConstraint.length ? subsetConstraint : arr);

  const handleSelection = (
    event: MouseEvent,
    item: T,
    subsetConstraint: T[] = [],
    onSingleSelect?: () => void
  ) => {
    const LEFT_MOUSE_BUTTON_CODE = 0;
    const RIGHT_MOUSE_BUTTON_CODE = 2;
    if (event.button === LEFT_MOUSE_BUTTON_CODE) {
      // left mouse click case
      if (event.shiftKey) {
        // shift + left mouse click
        _selectAllBetween(item, subsetConstraint);
      } else if (event.ctrlKey) {
        // ctrl + left mouse click
        _addItemToSelected(item);
      } else {
        selectItem(item);
        onSingleSelect && onSingleSelect();
      }
    } else if (event.button === RIGHT_MOUSE_BUTTON_CODE) {
      // right mouse click case
      if (!isItemSelected(item)) {
        selectItem(item);
        onSingleSelect && onSingleSelect();
      }
    }
  };

  const handleContextMenu = (event: MouseEvent) => {};

  const isOnlyOneElementSelected = selectedItems.length === 1;

  const isItemSelected = (item: T) => {
    return selectedItems.findIndex((_item) => _item.id === item.id) !== -1;
  };

  return {
    handleSelection,
    handleContextMenu,
    isItemSelected,
    isOnlyOneElementSelected,
    syncSelectedElementCopyWithSourceElement,
    selectAll,
    deselectAll,
    selectItem,
  };
};

import { CallComponentInstance } from "..";

/**
 * Sets the isConnecting parameter in the state
 *
 * @param isConnecting true if it's currently connecting a call. False otehrwise
 */
export const setIsConnecting = (that: CallComponentInstance, isConnecting) => {
  if(!that._ismounted) return;
  that.setState({ isConnecting });
};

/**
 * Clears the current call session from the state
 */
export const clearCallSession = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  that.setState({ callSession: null });
  that.props.setCallSession();
};

/**
 * Sets the current call session in the state
 *
 * @param callSession the session to save
 */
export const setCallSession = (that: CallComponentInstance, callSession) => {
  if(!that._ismounted) return;
  that.setState({ callSession });
  that.props.setCallSession(callSession);
};

/**
 * Set the isShowConnecting state
 *
 * @param isShowing whether shows connecting screen or not
 */
export const setIsShowConnecting = (
  that: CallComponentInstance,
  isShowing: boolean
) => {
  if(!that._ismounted) return;
  that.setState({ isShowConnecting: isShowing });
};

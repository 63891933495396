import { isFirefox, isSafari } from "@constants/appConstants";
import React from "react";
import { connect } from "react-redux";
import ReactTimeout from "react-timeout";
import {
  openMicUnavailableModal,
  closeMicUnavailableModal,
  setIsMicBlocked,
  startMicCheck,
  finishMicCheck,
} from "../actions/micActions";
import DeviceUnavailableDialog from "../components/DeviceUnavailableDialog";

const CHECK_DEVICE_TIMEOUT = 5000; // The timeout to check the audio device in ms

interface IAudioAvailabilityManagerProps
  extends ReactTimeout.ReactTimeoutProps {
  defaultAudioInput: string;
  defaultAudioOutput: string;
  openMicUnavailableModal: () => void;
  closeMicUnavailableModal: () => void;
  startMicCheck: () => void;
  finishMicCheck: () => void;
  setIsMicBlocked: (bool: boolean) => void;
  showMicUnavailableModal: boolean;
}

interface IAudioAvailabilityManagerState {
  isShowDeviceUnavailable: boolean;
  dismissClicked: boolean;
}

class AudioAvailabilityManager extends React.Component<
  IAudioAvailabilityManagerProps,
  IAudioAvailabilityManagerState
> {
  checkDeviceTimer: ReactTimeout.Timer | undefined;

  constructor(props) {
    super(props);
    this.state = {
      isShowDeviceUnavailable: false,
      dismissClicked: false,
    };
    this.checkDeviceTimer = this.props.setTimeout!(
      this.checkAudioDevice,
      CHECK_DEVICE_TIMEOUT
    );
  }

  componentDidMount() {
    this.props.startMicCheck();
  }

  componentDidUpdate(prevProps: IAudioAvailabilityManagerProps) {
    const { defaultAudioInput, defaultAudioOutput } = this.props;
    const {
      defaultAudioInput: prevDefaultAudioInput,
      defaultAudioOutput: prevDefaultAudioOutput,
    } = prevProps;
    if (
      defaultAudioInput !== prevDefaultAudioInput ||
      defaultAudioOutput !== prevDefaultAudioOutput
    ) {
      this.setState({
        isShowDeviceUnavailable: false,
        dismissClicked: false,
      });
    }
  }

  /**
   * Checks if the audio input or output device is unavailable
   */
  checkAudioDevice = () => {
    const audioPlayerElement = document.getElementById("audio-remote");
    const {
      defaultAudioInput,
      defaultAudioOutput,
      setIsMicBlocked,
      startMicCheck,
      finishMicCheck
    } = this.props;

    if (isFirefox || isSafari) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setIsMicBlocked(false);
          this.setState({
            isShowDeviceUnavailable: false,
          });
          finishMicCheck();
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        })
        .catch(this.processDeviceUnavailable);
    } else if ("setSinkId" in HTMLMediaElement.prototype) {
      (audioPlayerElement as any)
        .setSinkId(defaultAudioOutput)
        .then(() => {
          const constraints = {
            audio: {
              deviceId: defaultAudioInput
                ? { exact: defaultAudioInput }
                : undefined,
            },
          };
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              this.setState({
                isShowDeviceUnavailable: false,
              });
              setIsMicBlocked(false);
              stream.getTracks().forEach((track) => {
                track.stop();
              });
              finishMicCheck();
            })
            .catch(() => {
              this.processDeviceUnavailable();
            });
          return null;
        })
        .catch(() => {
          this.processDeviceUnavailable();
        });
    }
  };

  /**
   * Proc function when the device is unavailable
   * Sets the isShowDeviceUnavailable to true,
   * runs the timer to check if the device is available
   */
  processDeviceUnavailable = () => {
    this.props.setIsMicBlocked(true);
    this.props.openMicUnavailableModal();
    this.props.finishMicCheck();
  };

  /**
   * Called when the user dismisses the Audio Device error
   */
  handleDismissDevCheck = () => {
    this.props.closeMicUnavailableModal();
  };

  render = () => {
    return (
      <>
        {this.props.showMicUnavailableModal && (
          <DeviceUnavailableDialog onButtonClick={this.handleDismissDevCheck} />
        )}
        {this.props.children}
      </>
    );
  };
}

// Redux data binding
const mapStateToProps = (state) => ({
  defaultAudioInput: state.preferences.defaultAudioInput,
  defaultAudioOutput: state.preferences.defaultAudioOutput,
  showMicUnavailableModal: state.micStatus.showMicUnavailableModal,
});

const mapDispatchToProps = (dispatch) => ({
  openMicUnavailableModal: () => dispatch(openMicUnavailableModal()),
  closeMicUnavailableModal: () => dispatch(closeMicUnavailableModal()),
  setIsMicBlocked: (bool: boolean) => dispatch(setIsMicBlocked(bool)),
  startMicCheck: () => dispatch(startMicCheck()),
  finishMicCheck: () => dispatch(finishMicCheck()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactTimeout(AudioAvailabilityManager));

import apiConstants from "@constants/apiConstants";
import { ICallHistory, ICallHistoryv1 } from "@models/Callhistory.models";
import { IApiResponseWithPagination } from "@models/IPagination";
import HttpClient from "./HttpClient"

export default function CallHistoryService(){
    const httpClient = HttpClient();
    const url = apiConstants().endpoints.callhistory.BASE;
    const v1GetUrl = apiConstants().endpoints.GET_CALL_HISTORY
    const GetByPage = async (page: number, pageSize: number = 100) => {
        const params = new URLSearchParams();
        params.set("page", String(page));
        params.set("pageSize", String(pageSize));
        try {
            const response = await httpClient.Get<IApiResponseWithPagination<ICallHistory[]>>({
                url,
                params
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    const GetByPageV1 = async (page: number) => {
        try {
            const response = await httpClient.Get<IApiResponseWithPagination<ICallHistoryv1[]>>({
                url: v1GetUrl(page),
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return {
        GetByPage,
        GetByPageV1
    }
}
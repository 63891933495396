import uiString from "@constants/uiString";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import InputButton from "./FormComponents/InputButton";
import Dialog from "../PresentationComponents/Dialog/Dialog";
import { ComponentDimensions } from "@constants/appConstants";
import clsx from "clsx";

const PaywallStrings = uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG;

type IProps = {
  open: boolean;
  onClose: () => void;
  text: JSX.Element;
  title: string;
  dismissable?: boolean;
  fullscreen?: boolean;
};

export const PaywallDialog = ({ onClose, open, text, title, dismissable = true, fullscreen = false, }: IProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="paywall-dialog-title"
      aria-describedby="paywall-dialog-description"
      title={title}
      dismissable={dismissable}
      className={clsx(!fullscreen && classes.root)}
      disableBackdropClick={!dismissable}
      disablePortal
    >
      <DialogContent>
        <DialogContentText data-testid="dialog-paywall-content" id="paywall-dialog-description">
            {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <InputButton
          onClick={() => window.open(uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.PRICING_PAGE, "_blank")}
          color="primary"
          autoFocus
          minWidth="140px"
          label={PaywallStrings.REDIRECT_LABEL}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    "&": {
      position: "absolute",
      left: `${ComponentDimensions.PREFERENCES_NAVIGATION_WIDTH}px !important`,
      top: `${ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT}px !important`
    }, 
    "& .MuiBackdrop-root": {
      left: ComponentDimensions.PREFERENCES_NAVIGATION_WIDTH,
      top: ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT
    },
    "& .MuiDialog-container": {
      left: ComponentDimensions.PREFERENCES_NAVIGATION_WIDTH,
      top: ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT
    }
  }
}))
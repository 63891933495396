import * as Library from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';




type IProps = {
  disabled?: boolean;
  styling?: "standard" | "none";
} & Omit<Library.TooltipProps, 'disabled'>

type IHelperTooltipProps = Omit<IProps, "styling">;

const LargerTooltip = withStyles((theme) =>
  createStyles({
    tooltip: {
      fontSize: "12px",
    },
  })
)(Library.Tooltip);
const StandardTooltip = ({ children, disabled, ...rest }: IHelperTooltipProps) => {
  return !disabled ? 
  <LargerTooltip {...rest}>
    {children}
  </LargerTooltip>
  : children
}

const DefaultTooltip = ({ children, disabled, ...rest } : IHelperTooltipProps)  => {
return !disabled ? 
  <Library.Tooltip {...rest}>
    {children}
  </Library.Tooltip>
  : children;
}

const Tooltip = (
  { styling = "none", ...rest }: IProps
): JSX.Element => {
  switch (styling) {
    case "none":
      return <DefaultTooltip {...rest} />
    case "standard":
      return <StandardTooltip {...rest} />
    default:
      return <DefaultTooltip {...rest} />
  }
};

export default Tooltip;

import getApiConstants from "@constants/apiConstants";
import { appConfig } from "../index";

const URL = getApiConstants().endpoints.voicemessagesv2.BASE;

const VoiceMessagesService = () => {
  const GetOne = async (id: string) => {
    try {
      const response = await appConfig.axios.get(`${URL}/${id}`);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const Update = async (id: string, body: any) => {
    try {
      const response = await appConfig.axios.put(`${URL}/${id}`, body);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const Delete = async (id: string) => {
    try {
      const response = await appConfig.axios.delete(`${URL}/${id}`);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  return {
    GetOne,
    Update,
    Delete,
  };
};

export default VoiceMessagesService;
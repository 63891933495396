import { FormControl, makeStyles, Theme } from "@material-ui/core";
import { AudioTypes, IAudioFileDescription } from "@models/AudioFiles.models";
import React, { memo, useEffect, useState } from "react";
import DropdownComponent from "../DropdownComponent";
import InputField from "../FormComponents/InputField";
import uiString from "@constants/uiString";

const AudioFilesStrings = uiString.SETTINGS_AND_PREF.AUDIO_FILES;

type IProps = {
  onChange: (vals: IAudioFileDescription) => any;
  defaultValues?: IAudioFileDescription;
  error: string | null; // only affects the name field
};

const AudioFileDescriptionForm = ({
  onChange,
  defaultValues,
  error,
}: IProps) => {
  const classes = useStyles();
  const [editForm, setEditForm] = useState<IAudioFileDescription>({
    name: "",
    type: AudioTypes.ANNOUNCEMENT,
  });

  useEffect(() => {
    if (defaultValues) {
      setEditForm(defaultValues);
    }
  }, [defaultValues]);

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    const { name, value } = event.target;
    setEditForm((form) => ({
      ...form,
      [name as string]: value,
    }));
  };

  useEffect(() => {
    onChange(editForm);
  }, [editForm]);

  const dropdownOptions = [
    { value: AudioTypes.ANNOUNCEMENT, text: AudioTypes.ANNOUNCEMENT },
    { value: AudioTypes.MENU, text: AudioTypes.MENU },
    {
      value: AudioTypes.VOICEMAIL_GREETING,
      text: AudioTypes.VOICEMAIL_GREETING,
    },
    { value: AudioTypes.WELCOME_GREETING, text: AudioTypes.WELCOME_GREETING },
    { value: AudioTypes.MUSIC_ON_HOLD, text: AudioTypes.MUSIC_ON_HOLD },
    {
      value: AudioTypes.RECORDED_CALL_NOTIF,
      text: AudioTypes.RECORDED_CALL_NOTIF,
    },
  ];

  return (
    <>
      <FormControl className={classes.modalInputs}>
        <InputField
          value={editForm.name}
          label={AudioFilesStrings.ADD_FILE_DIALOG.DESCRIPTION_FIELD_LABEL}
          onChange={handleChange}
          name="name"
          required
          error={!!error}
          errorMessage={String(error)}
        />
      </FormControl>
      <FormControl className={classes.modalInputs}>
        <DropdownComponent
          value={editForm.type}
          name="type"
          smallIcon
          onChange={handleChange}
          outlined
          options={dropdownOptions}
          label={AudioFilesStrings.ADD_FILE_DIALOG.FILE_TYPE_FIELD_LABEL}
        />
      </FormControl>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modalInputs: {
    display: "block",
    margin: theme.spacing(1, 0),
  },
}));

export default memo(AudioFileDescriptionForm);

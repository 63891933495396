import React from "react";
import { historyItems } from "../../constants/navItems";
import CallHistoryView from "@components/CallHistoryView/index";

type Props = {
  activeItem: string;
};

/**
 * Draw the contents of history & reporting
 */
const ShowContent: React.FC<Props> = ({ activeItem }) => {
  for (const { Component, value } of Object.values(historyItems)) {
    if (value === activeItem && Component !== null) return <Component />;
  }
  return <CallHistoryView />;
};

export default React.memo(ShowContent);

import React, { useEffect, useState } from "react";
import CallHistoryFilters from "./CallHistoryFilters";
import { CallHistoryFilterQuery, CallResultType } from "./CallHistoryTypes";
import uiString, { ApiErrorMessages } from '@constants/uiString';
import { CallHistoryData } from "@services/dataClasses";
import { callHistoryJSONParserV2 } from "@services/dataClasses/CallHistoryData";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY,
  CALL_HISTORY_TABLE_PAGE_NUMBER
} from "./constants";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { IPagination } from "@models/IPagination";
import Loader from "@components/PresentationComponents/Loader";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import { formatSecondsWithQualifier } from "@helpers/functions/converters";
import CrossIcon from "@resources/icons/cross-small.svg"
import { TablePlayButton } from "@components/PresentationComponents/TableComponents/TableHelpers";
import { PlayAudioDialog } from "@components/PresentationComponents/AudioComponents/PlayAudioDialog";
import CallHistoryEvent from "./CallHistoryEvent";
import Tooltip from '@components/PresentationComponents/FormComponents/Tooltip';
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { ComponentDimensions } from "@constants/appConstants";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import { useToasts } from "react-toast-notifications";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import CallHistoryAndReportingService from "@services/CallHistoryAndReporting.service";
import showCallType from "@components/CallHistory/CallTypeIcon";
import { CallType, ICallHistory } from "@models/Callhistory.models";
import { useDispatch } from "react-redux";
import { blockNumberFailure, blockNumberSuccess, unblockNumberFailure } from "@actions/callActions";
import moment from "moment";
import { getCallRecordingForNumber } from "@services/dataClasses/TalkroutePhoneNumber";
import { GET_TEN_THOUSAND_CONTACTS, CONTACTS_INITIAL_PAGE } from "@components/Contacts/constants";
import { useGetAllContactsQuery } from "@services/kingcobraApi";
import { addContactNameToCallHistory, addContactNameToConversation } from "@helpers/functions/contacts";

const CallHistoryView = () => {
  // Set initial filter query
  const rowsPerPage = useAppSelector((state) => state.preferences.tableSize.callHistory);
  const [filterQuery, setFilterQuery] = useState<CallHistoryFilterQuery>(
    CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY
  );
  const [prevFilterQuery, setPrevFilterQuery] = useState<CallHistoryFilterQuery>(
    CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY
  )
  const [callHistory, setCallHistory] = useState<CallHistoryData[]>([]);
  const [isAdvancedMode, setAdvancedMode] = useState<boolean>(false);
  const [callHistoryPagination, setCallHistoryPagination] = useState<any>();
  const [callHistoryLoading, setCallHistoryLoading] = useState(true);
  const [callHistoryResponse, setCallHistoryResponse] = useState<{
    data: ICallHistory[];
    pagination: IPagination;
  } | null>(null);
  const [callToDelete, setCallToDelete] = useState<CallHistoryData | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addToast } = useToasts();
  const classes = useStyles();
  const [openAudioDialog, setOpenAudioDialog] = useState(false);
  const [audioFileSrc, setAudioFileSrc] = useState<string | null>(null);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const {
    GetByPage,
    DeleteCallRecording
  } = useService(CallHistoryAndReportingService);
  const blockNumberError = useAppSelector(state => state.callReducer.blockNumberError);
  const dispatch = useDispatch();
  useEffect(() => {
    if (blockNumberError && blockNumberError.response && blockNumberError.response.status === ErrorStatusCode.FORBIDDEN) {
      addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
    }
    return () => {
      dispatch(blockNumberSuccess())
      dispatch(unblockNumberFailure(null))
    }
  }, [blockNumberError])

  const openRecordingModal = (row: CallHistoryData) => {
    if (row.recording) {
      setAudioFileSrc(row.recording);
      setModalTitle(row.getCallerPhoneNumberFormatted());
      setOpenAudioDialog(true);
    }
  };

  const convertQueryToParams = (query: CallHistoryFilterQuery) => {
    const durationMultiplier =
      query.durationUnit === "hours"
        ? 3600
        : query.durationUnit === "minutes"
        ? 60
        : 1;
    const duration = query.duration ? query.duration * durationMultiplier : "";
    const maxDuration =
      query.durationOperator === "shorter" && duration
        ? duration.toString()
        : "";
    const minDuration =
      query.durationOperator === "longer" && duration
        ? duration.toString()
        : "";

    if (query.endDate) {
      const today = new Date()
       !(query.endDate.getDate() == today.getDate() &&
      query.endDate.getMonth() == today.getMonth() &&
      query.endDate.getFullYear() == today.getFullYear()) && query.endDate.setHours(23, 59, 59)
    }
    query.startDate && query.startDate.setHours(0, 0, 0, 0)
    const params = new URLSearchParams();
    query.page && params.set("page", query.page.toString());
    query.pageSize && params.set("pageSize", query.pageSize.toString());
    query.endDate &&
      params.set("before", moment(query.endDate).format());
    query.startDate &&
      params.set(
        "after",
        moment(query.startDate).format()
      );
    maxDuration && params.set("maxDuration", maxDuration.toString());
    minDuration && params.set("minDuration", minDuration.toString());
    query.direction &&
      query.direction !== "all" &&
      params.set("direction", query.direction.toString());
    query.voiceMailbox &&
      query.voiceMailbox.toString() !== "all" &&
      params.set("voiceMailbox", query.voiceMailbox.toString());
    query.extension &&
      query.extension.toString() !== "all" &&
      params.set("extension", query.extension.toString());
    query.result &&
      query.result !== "all" &&
      params.set("result", query.result.toString());
    query.forwardingNumber &&
      query.forwardingNumber.toString() !== "all" &&
      params.set("forwardingNumber", query.forwardingNumber.toString());
    query.menuOption &&
      query.menuOption.toString() !== "all" &&
      params.set("menu", query.menuOption.toString());
    query.virtualNumbers &&
      query.virtualNumbers.toString() !== "all" &&
      params.set("numbers", query.virtualNumbers);

    return params;
  };

  const updateCallHistory = async (fq?: CallHistoryFilterQuery) => {
    const _filterQuery = { ...filterQuery, pageSize: rowsPerPage };
    const _fq = fq ? {...fq, pageSize: rowsPerPage} : null;
    try {
      !callHistoryLoading && setCallHistoryLoading(true);
      const { data } = await GetByPage(convertQueryToParams(_fq ?? _filterQuery));
      setCallHistoryResponse(data);
      setCallHistoryLoading(false);
      return data;
    } catch (err) {
      console.error(err);
      setCallHistoryLoading(false);
      throw err;
    }
  };

  const { data: contactList } = useGetAllContactsQuery({
    params: {
      pageSize: GET_TEN_THOUSAND_CONTACTS,
      page: CONTACTS_INITIAL_PAGE,
    },
  });

  const updateTableData = () => {
    if (callHistoryResponse) {
      const { data, pagination } = callHistoryResponse;
      const _callHistory = callHistoryJSONParserV2(data);
      const callHistory = contactList ? addContactNameToCallHistory(contactList, _callHistory) : _callHistory;
      setCallHistory(callHistory);
      setCallHistoryPagination(pagination);
    }
  };

  const deleteRecording = (id: number) => {
    if (callHistory) {
      const data = callHistory.map(call => call.id === id ? call.deleteRecording() : call);
      setCallHistory(data);
    }
  }

  const callDeleteHandler = async (id: number) => {
    try {
      setIsDeleting(true);
      await DeleteCallRecording(id);
      deleteRecording(id);
      setIsDeleting(false);
      setCallToDelete(null);
    } catch (error) {
      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
        setCallToDelete(null);
        setIsDeleting(false);
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        setCallToDelete(null);
        setIsDeleting(false);
        throw error;
      }
    }
  };

  const renderCallIcon = (callResult: CallResultType, type: CallType) =>
   showCallType({ callResult, type });

  useEffect(() => {
    updateCallHistory({ ...prevFilterQuery, page: CALL_HISTORY_TABLE_PAGE_NUMBER });
  }, [rowsPerPage]);

  useEffect(() => {
    updateTableData();
  }, [callHistoryResponse]);

  const setInitialFilterOptions = async () => {
    try {
      await updateCallHistory({
        ...CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY,
        pageSize: rowsPerPage,
      });
    } catch (err) {
      console.error(err);
    }
    setPrevFilterQuery({
      ...CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY,
      pageSize: rowsPerPage,
    })

    setFilterQuery({
      ...CALL_HISTORY_TABLE_INITIAL_FILTER_QUERY,
      pageSize: rowsPerPage,
    });
  };

  const handlePaginationChange = async (page: number) => {
    try {
      await updateCallHistory({ ...prevFilterQuery, page, pageSize: rowsPerPage });
    } catch (err) {
      console.error(err);
    }
    setFilterQuery({ ...filterQuery, page, pageSize: rowsPerPage });
  };

  const outgoingPhoneNumbers = useAppSelector(state => state.preferences.outgoingPhoneNumbers);

  return (
    <>
      <UnderlinedTitle>{uiString.SETTINGS_AND_PREF.CALL_HISTORY.TITLE}</UnderlinedTitle>
      <Loader open={callHistoryLoading} />
      <div className={classes.content}>
        <CallHistoryFilters
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          setInitialFilterOptions={setInitialFilterOptions}
          isAdvancedMode={isAdvancedMode}
          setAdvancedMode={setAdvancedMode}
          updateCallHistory={updateCallHistory}
          onApplyFilters={(fq) => fq && setPrevFilterQuery(fq)}
          data={callHistory}
        />

        {callHistory && (
          <CustomTablePagination
            pagination={callHistoryPagination}
            handlePageChange={handlePaginationChange}
            storeKey="callHistory"
          >
            <PlainTable
              data={callHistory}
              selectColumns={["date", "time", "contact", "duration"]}
              dataMapper={(row) => ({
                ...row,
                date: row.getFormattedDatev2(),
                time: row.getFormattedTime(),
                "contact": (
                  <Grid className={classes.phoneNumberWrapper}>
                    {renderCallIcon(row.callResult, row.type)}
                    <div style={{ width: "8px" }} />
                    {row.getContactInfo()}
                  </Grid>
                ),
                duration: formatSecondsWithQualifier(row.duration.toString()),
              })}
              actions={[
                {
                  colLabel: "Recording",
                  width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                  ActionComponent: (row) =>
                    row.isCallRecorded() && row.recording? (
                      <Tooltip
                        title={uiString.SETTINGS_AND_PREF.CALL_HISTORY.PLAY_BUTTON_TOOLTIP_LABEL}
                        aria-label="play-recording"
                      >
                        <TablePlayButton
                          row={row}
                          handler={openRecordingModal}
                          deleteHandler={row => setCallToDelete(row)}
                          downloadUrl={row.recording}
                          id={row.id}
                          disableDelete={!getCallRecordingForNumber(row.numberDialed, outgoingPhoneNumbers)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={uiString.SETTINGS_AND_PREF.CALL_HISTORY.NO_RECORDING_AVAILABLE_TOOLTIP_LABEL}
                        aria-label="no-recording"
                        styling="standard"
                      >
                      <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center", 
                          padding: 8
                        }}>
                          <CrossIcon style={{ 
                            width: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
                            height: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
                            fill: "var(--color-charcoal)" }} />
                      </div>
                      </Tooltip>
                    ),
                }
              ]}
              extraDetails={{
                header: "Details",
                resetTrigger: callHistoryLoading,
                DetailsComponent: (row, open) => (
                  <CallHistoryEvent row={row} open={open} />
                ),
              }}
              fullWidth
            />
          </CustomTablePagination>
        )}
        {!callHistory && !callHistoryLoading && (
          <div className={classes.center}>
            <Typography variant="h5">No records found</Typography>
          </div>
        )}
      </div>
      <PlayAudioDialog
        open={openAudioDialog}
        closeHandler={() => setOpenAudioDialog(false)}
        src={audioFileSrc}
        title={String(modalTitle)}
        autoplay
      />
      <ConfirmDialog
        header={uiString.callHistoryView.DELETE_CALL_CONFIRMATION_HEADER}
        content={
          <>
            {uiString.callHistoryView.DELETE_CALL_CONFIRMATION_BODY}
          </>
        }
        open={Boolean(callToDelete)}
        confirmButtonLabel={uiString.DELETE}
        loading={isDeleting}
        onCancel={() => setCallToDelete(null)}
        onConfirm={() => callToDelete && callDeleteHandler(callToDelete.id)}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    relative: {
      position: "relative",
      minHeight: "100%",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: '100%',
    },
    pagination: {
      paddingTop: 15,
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
    },
    fixedPosition: {
      position: "sticky",
      bottom: "15px",
      width: "100%",
      minWidth: "calc(100% - 300px)", // 250px the min for navigation plus 50px is for parent padding
    },
    regularTable: { overflow: "auto", overflowX: "hidden" },
    advancedTable: { overflow: "auto", overflowX: "hidden" },
    rowsPerPage: { display: "flex" },
    rowsPerPageSelect: {
      marginLeft: 5,
      marginBottom: 5,
      "&:before, &:after": {
        border: "none !important",
      },
      "&>div": {
        paddingLeft: 5,
      },
    },
    phoneNumberWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "fit-content"
    }
  })
);

export default CallHistoryView;

export enum AudioTypes {
  MENU = "Menu",
  ANNOUNCEMENT = "Announcement",
  VOICEMAIL_GREETING = "Voicemail Greeting",
  WELCOME_GREETING = "Welcome Greeting",
  MUSIC_ON_HOLD = "Music on Hold",
  RECORDED_CALL_NOTIF = "Recorded Call Notification",
}
export type IAudioFileDescription = Omit<
  IAudioFile,
  "id" | "link" | "createdAt"
>;

export type IAudioFileBody = {
  name: string;
  type: AudioTypes;
  audio: File | null;
};

type IUsedBy =
  | "accountWhenClosed"
  | "did"
  | "didAsRecordingNotification"
  | "didAsDefaultHoldMusic"
  | "extension"
  | "hourOverride"
  | "menu"
  | "menuOption"
  | "musicOnHold"
  | "queue"
  | "ringGroup"
  | "voicemailBox";
export interface IAudioFile {
  id: string;
  link: string;
  name: string;
  type?: AudioTypes;
  isDefault?: boolean;
  createdAt: string | null;
  usedBy?: IUsedBy;
};

export type AudioOptions = {
  label: string
  devices: MediaDeviceInfo[]
  selected: string
  type: 'audioinput' | 'audiooutput'
  forceSelect?: boolean
};

export type IAudioFileForm = Omit<IAudioFile, "createdAt">;

import getApiConstants from "@constants/apiConstants";
import HttpClient from "./HttpClient";

export default function DeviceService() {
    const urlBuilder = getApiConstants().endpoints.device.DELETE;
    const { Delete } = HttpClient();

    const UnregisterDevice = async (deviceDeregistrationId: number, deviceType) => {
        try {
            const url = urlBuilder(deviceType);
            await Delete({url, id: String(deviceDeregistrationId)});
            
        } catch (error) {
            throw error;
        }
    };

    return {
        UnregisterDevice
    };
  }
  
import React, { useEffect, useRef, useState } from "react";
import { Box, List, ListItem, ListItemText } from "@material-ui/core";
import { isIOS } from "@constants/appConstants";

interface IContextMenuItems {
  callback: (x: any) => any;
  render: boolean;
  label: string;
  disabled: boolean;
}

interface IContextMenuPos {
  xPos: number;
  yPos: number;
}

interface IProps {
  items: IContextMenuItems[];
  pos: IContextMenuPos;
  open: boolean;
  closeHandler: () => any;
}

const ContextMenu = ({ items, pos, open, closeHandler }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const _closeHandler = (e) => {
    const clickTargetIsContextMenu = ref.current?.contains(e.target);
    if (!clickTargetIsContextMenu) {
      closeHandler();
    }
  };
  useEffect(() => {
    const mouseEvent = isIOS ? "mousedown" : "mouseup";
    window.addEventListener(mouseEvent, _closeHandler);
    return () => {
      window.removeEventListener(mouseEvent, _closeHandler);
    };
  }, []);
  return (
    <div
      className="ui vertical left pointing menu"
      style={{
        position: "fixed",
        top: pos.yPos,
        left: pos.xPos,
        zIndex: 1000,
        padding: 0,
        display: open ? "block" : "none",
      }}
      ref={ref}
    >
      <List component="nav">
        {items.map(
          (item, index) =>
            item.render && (
              <ListItem
                key={index + item.label}
                button
                onMouseUp={item.callback}
                disabled={item.disabled}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            )
        )}
      </List>
    </div>
  );
};

export default ContextMenu;

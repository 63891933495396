import React from 'react'
import { useDispatch } from 'react-redux';
import { Button, Radio } from 'semantic-ui-react';
import { useAuthContext } from '../../context/AuthProvider';
import { AUTO_UPDATE_CHECKBOX, settingChanged, updateAppNow } from '../../actions/preferencesActions';
import appConfig from '../../config';
import { domIds } from '../../constants/testConstants';
import uiString from '../../constants/uiString';
import { useAppSelector } from '../../helpers/hooks/useAppSelector.hook';

/**
   * Draw the Updates sub page
   */
 const PreferenceUpdates: React.FC = () => {
  const appVersion = appConfig.version;
  const strVersion = `${uiString.preferences.CURRENT_APP_VERSION}: ${appVersion}`;
  const autoUpdatePermission = useAppSelector(state => state.preferences.autoUpdatePermission);
  const dispatch = useDispatch();
  const {clearData} = useAuthContext();

  const clearDataHandler = clearData

  return (
    <div>
      <div
        style={{
          padding: "20px 0 10px 0",
          width: "90%",
          borderBottom: "1px solid var(--color-dark-grey)",
          fontSize: "18px",
        }}
        className="none-select-text"
      >
        {uiString.preferences.UPDATES}
      </div>
      <div style={{ padding: "15px 0 10px 0" }}>
        <div
          style={{ padding: "5px 0", display: "flex" }}
          className="none-select-text"
        >
          {uiString.preferences.TURN_ON_AUTO_UPDATE}
          <Radio
            toggle
            label=""
            onChange={() =>
              dispatch(settingChanged(AUTO_UPDATE_CHECKBOX, !autoUpdatePermission))
            }
            checked={autoUpdatePermission}
            style={{ marginLeft: "25px" }}
          />
        </div>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <div style={{ padding: "5px 0" }} className="none-select-text">
          {strVersion}
          <Button
            basic
            icon
            compact
            style={{
              marginLeft: "25px",
              height: "25px",
              padding: "0 10px",
              borderRadius: "10px",
            }}
            onClick={() => dispatch(updateAppNow)}
          >
            {uiString.preferences.CHECK_FOR_UPDATE}
          </Button>
        </div>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        <div style={{ padding: "5px 0" }} className="none-select-text">
          {uiString.preferences.CLEAR_DATA}
          <Button
            id={domIds.PREFERENCES_CLEAR_DATA_BUTTON}
            basic
            icon
            compact
            className="dev-button"
            style={{
              marginLeft: "25px",
              height: "25px",
              padding: "0 10px",
              borderRadius: "10px",
            }}
            onClick={() => clearDataHandler()}
          >
            {uiString.preferences.CLEAR_DATA_BUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceUpdates
import { AudioPlayer } from "@components/PresentationComponents/AudioPlayer";
import {
  createStyles,
  DialogContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import Dialog from '../../PresentationComponents/Dialog/Dialog';

interface IProps {
  closeHandler: () => any;
  src: string | null;
  title: string;
  open: boolean;
  autoplay?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      overflow: "hidden",
      padding: 0,
    },
  })
);
  
export const PlayAudioDialog = ({ open, autoplay, closeHandler, src, title }: IProps) => {
  const classes = useStyles();
  return (
    <Dialog 
      maxWidth="xs"
      fullWidth
      open={open} 
      onClose={closeHandler}
      title={title}
    >
      <DialogContent className={classes.content}>
        {src && <AudioPlayer autoplay={autoplay} elevation={0} src={src} />}
      </DialogContent>
    </Dialog>
  );
};

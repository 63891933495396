import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { createStyles, FormHelperText, FormHelperTextProps, makeStyles } from "@material-ui/core";

type IProps = {
  children?: JSX.Element | string | null;
  standalone?: boolean;
} & FormHelperTextProps;

const useStyles = makeStyles({
  row: {
    display: "flex",
    alignItems: "center",
    color: "red"
  },
  iconSpacing: {
    marginRight: 5,
    fill: "red"
  },
});

export const InputErrorText = ({ children, standalone,...rest }: IProps) => {
  const classes = useStyles();
  return standalone ? (
    <span {...rest} className={classes.row}>
      {children && (
        <>
          <WarningIcon className={classes.iconSpacing} />
          <span>{children}</span>
        </>
      )}
    </span>
  ) : (
    <FormHelperText {...rest} className={classes.row}>
      {children && (
        <>
          <WarningIcon className={classes.iconSpacing} />
          <span>{children}</span>
        </>
      )}
    </FormHelperText>
  );
};

/**
 * Used to keep track of the current data state
 */
export enum DataState {
  LOADING = "LOADING",
  ERROR = "ERROR",
  READY = "READY",
}

export default DataState;

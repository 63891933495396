import uiString from "@constants/uiString";

const uiStrings = uiString.SETTINGS_AND_PREF.TEXT_MESSAGING_HISTORY.FILTERS;

// Filter configurations
export const filterTypesEnum = {
  DROPDOWN: "DROPDOWN",
  INPUT: "INPUT",
  DATE: "DATE",
  GROUP: "GROUP"
};

export interface TextMessageHistoryFilterQuery {
  virtualNumbers?: string;
  startDateFirst?: Date;
  endDateFirst?: Date;
  startDateLast?: Date;
  endDateLast?: Date;
  messageCountOperator?: string;
  messageCount?: number;
  pageSize?: number;
  page?: number;
  limit?: number;
}

export interface FilterOption {
  name: string;
  title: string | undefined;
  type: string;
  values?: any[];
  inputType?: string;
  advanced?: boolean;
  renderValue?: string;
  dynamicVariable?: string;
}

export type DynamicOption = {
  id: string | number;
  value: string | number;
};

export interface DynamicSelectOptions {
  virtualNumbers?: DynamicOption[];
}
export const FILTER_OPTIONS = {
  VIRTUAL_NUMBERS: {
    name: "virtualNumbers",
    title: undefined,
    type: filterTypesEnum.DROPDOWN,
    values: [{ id: "all", value: uiStrings.VIRTUAL_NUMBER_ALL }],
    advanced: false,
    dynamicVariable: "virtualNumbers",
  },
  START_DATE_FIRST_MESSAGE: {
    name: "startDateFirst",
    title: uiStrings.START_DATE_FIRST_MESSAGE,
    type: filterTypesEnum.DATE,
    advanced: true,
  },
  END_DATE_FIRST_MESSAGE: {
    name: "endDateFirst",
    title: uiStrings.END_DATE_FIRST_MESSAGE,
    type: filterTypesEnum.DATE,
    advanced: true,
  },
  START_DATE_LAST_MESSAGE: {
    name: "startDateLast",
    title: uiStrings.START_DATE_LAST_MESSAGE,
    type: filterTypesEnum.DATE,
    advanced: true,
  },
  END_DATE_LAST_MESSAGE: {
    name: "endDateLast",
    title: uiStrings.END_DATE_LAST_MESSAGE,
    type: filterTypesEnum.DATE,
    advanced: true,
  },
  NUMBER_OF_MESSAGES: {
    name: "numberOfMessages",
    title: "Number of Messages",
    type: filterTypesEnum.GROUP,
    advanced: true,
    values: [
      {
        name: "messageCountOperator",
        type: filterTypesEnum.DROPDOWN,
        values: [
          { id: "greater", value: uiStrings.GREATER_THAN },
          { id: "less", value: uiStrings.LESS_THAN },
        ],
        advanced: true,
      },
      {
        name: "messageCount",
        type: filterTypesEnum.INPUT,
        title: "Number of Messages",
        inputType: "number",
        advanced: true,
      },
    ]
  },
};

import _ from "lodash";
import { useState, useEffect } from "react";



/**
 * A hook to get and keep track of the current browser window width and height
 * 
 * @param {number} minTimeBeforeUpdateMilisec - minimum time before a resize
 * will trigger a rerender. Lower value means faster updates at the expense of 
 * performance. Too low values can significantly reduce app performance
 * 
 * @return object containing the window height and width properties in pixels
 * @example
 *  const MyComponent = () => {
 *    // MyComponent will rerender after window resize with updated width/height.
 *    // Keep in mind the performance implications resulting from said rerender.
 *    const {width, height} = useWindowSize();
 *    return (
 *      <div>
 *        Current window width: {width}px
 *        </br>
 *        Current window height: {height}px
 *      </div>
 *    )
 *  }
 * 
 */
export const useWindowSize = (minTimeBeforeUpdateMilisec: number = 1000) => {

    const [windowSize, setWindowSize] = useState<{
        width: number;
        height: number;
    }>({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      const handleResize = _.throttle(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, minTimeBeforeUpdateMilisec)
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowSize;
  }


import React from 'react';
import * as Library from '@material-ui/core'
import { DialogContentProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const DialogContent: React.FC<DialogContentProps> = ({ className,...props }) => {
  const classes = useStyles();

  return (
    <Library.DialogContent 
      {...props}
      className={`${classes.root}, ${className}`}
    >
      {props.children}
    </Library.DialogContent>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
  },
});

export default DialogContent;
import React, { useEffect, useRef } from "react";

type Props = {
  label?: string;
  style?: any;
  className?: string;
  noMargin?: boolean;
  disablePointerActions?: boolean;
  boldLabel?: boolean;
  onCalculateWidth?: (event: number | undefined) => void;
};

const CustomLabel: React.FC<Props> = ({
  label,
  className,
  noMargin,
  onCalculateWidth,
  disablePointerActions,
  boldLabel,
}) => {
  const pRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    onCalculateWidth &&
      pRef.current &&
      onCalculateWidth(pRef.current.clientWidth);
  }, []);
  return (
    <p
      ref={pRef}
      className={className}
      style={{
        marginBottom: noMargin ? 0 : "4px",
        fontSize: "14px",
        pointerEvents: disablePointerActions ? "none" : "all",
      }}
    >
      {boldLabel 
        ? <b>{label}</b>
        : label
      }
    </p>
  );
};

export default CustomLabel;

import React, { useEffect, memo, useState, useMemo } from 'react';
import Dialog from '../PresentationComponents/Dialog/Dialog';
import DialogContent from '../PresentationComponents/Dialog/DialogContent';
import DialogDivider from '../PresentationComponents/Dialog/DialogDivider';
import { ContactDialogEnum, IContact, IContactDropdownForm, IContactsPartition } from '@models/Contacts.models';
import { openDialog } from '@actions/appActions';
import { useDispatch } from 'react-redux';
import useForm from '@helpers/hooks/useForm.hook';
import { Grid, makeStyles } from '@material-ui/core';
import SearchbarField from '@components/PresentationComponents/FormComponents/SearchbarField';
import { useAppSelector } from '@helpers/hooks/useAppSelector.hook';
import { filterContactsByName, partitionContactsByName, sortContacts } from '@helpers/functions/contacts';
import createKeyIndex from '@helpers/createKeyIndex';
import DropdownLineItem from './DropdownLineItem';
import usePrevious from '@helpers/hooks/usePrevious.hook';
import uiString from '@constants/uiString';
import { useGetAllContactsQuery } from '@services/kingcobraApi';
import { GET_TEN_THOUSAND_CONTACTS, CONTACTS_INITIAL_PAGE } from './constants';
import _ from 'lodash';

const SEARCH_DEBOUCE_INTERVAL = 100

type IProps = {
  onClose: () => void;
  open: boolean;
  onClick: (phoneNumber: string) => void;
};

const ContactDialogWithDropdown: React.FC<IProps> = (
  { onClose, open, onClick }
) => {
  const { form, changeHandler } = useForm<IContactDropdownForm>({
    searchContactNames: { initialValue: "", validators: [] },
  });
  const [contactsList, setContactsList] = useState<IContact[] | null>(null);
  const [filteredContactsList, setFilteredContactsList] = useState<IContact[] | null>(null);
  const [partitionedContacts, setPartitionedContacts] = useState<IContactsPartition>({});
  const dispatch = useDispatch();
  // const contacts = useAppSelector(state => state.contactsReducer.data);
  const {data: _contacts, refetch, isFetching} = useGetAllContactsQuery({ params: { 
    pageSize: GET_TEN_THOUSAND_CONTACTS,
    page: CONTACTS_INITIAL_PAGE
  } });
  const contacts = useMemo(() => _contacts || [], [_contacts]);

  const prevContacts = usePrevious(contacts);
  const classes = useStyles();

  const handleSubmit = () =>
    contactsList && setFilteredContactsList(filterContactsByName(contactsList, form.searchContactNames));

  const handleChange = (value: string) =>
    changeHandler(ContactDialogEnum.SEARCH_CONTACT_NAMES, value);

  /**
   * Clear the search bar & display all contacts from contact list
   */
  const clearSearchBar = () => {
    setFilteredContactsList(null);
    changeHandler(ContactDialogEnum.SEARCH_CONTACT_NAMES, "");
  }

  /**
  * Display names of contacts to UI into sections with first letter of first name.
  * @param contacts list with all initial users
  * @returns array of elements with name and button with dropdown menu to display contact phone numbers.
  */
   const renderContacts = () => {
    let result: JSX.Element[] = [];
    for (const key in partitionedContacts) {
      let headerRendered = false;
      partitionedContacts[key].map((item, index) => {
        result.push(
          <DropdownLineItem 
            key={createKeyIndex(index, item.id)} 
            contactData={item}
            onClick={onClick}
            headerLetter={headerRendered ? undefined : key}
          />
        );
        headerRendered = true;
      });
    };
    return result;
  };

  const debouncedSearch = _.debounce(handleChange, SEARCH_DEBOUCE_INTERVAL, { leading: false, trailing: true })

  /**
   * Display all the contacts data to user.
   */
  useEffect(() => {
    const sortedContacts = sortContacts(contacts.length !== 0 ? contacts : prevContacts ?? []);
    setContactsList(sortedContacts);
    setPartitionedContacts(partitionContactsByName(sortedContacts));
  }, [contacts]);

  /**
   * Display just the contacts that respond to searchbar field value.
   */
  useEffect(() => {
    if (filteredContactsList) {
      setPartitionedContacts(partitionContactsByName(filteredContactsList));
    } else {
      contactsList && setPartitionedContacts(partitionContactsByName(contactsList));
    };
  }, [filteredContactsList]);

  /**
   * Save dialog state to redux
   */
  useEffect(() => {
    dispatch(openDialog(open));
    open && changeHandler(ContactDialogEnum.SEARCH_CONTACT_NAMES, "");
  }, [open])

  /**
   * Submit search field on change.
   */
  useEffect(() => {
    handleSubmit();
  }, [form.searchContactNames]);

  return (
    <Dialog
      title={uiString.CONTACTS.PAGE_HEADER}
      open={open}
      onClose={onClose}
      fullWidth
      className={classes.dialogWrapper}
    >
      <Grid className={classes.inputWrapper}>
        <SearchbarField
          style={{ maxWidth: "90%" }}
          fullWidth
          autoFocus
          submitHandler={handleSubmit}
          clearSearchbarHandler={clearSearchBar}
          autoComplete="off"
          value={form.searchContactNames}
          name={ContactDialogEnum.SEARCH_CONTACT_NAMES}
          onChange={(e) => debouncedSearch(e.target.value)}
          type="text"
        />
      </Grid>
      <DialogDivider />
      <DialogContent className={classes.listWrapper}>
        {renderContacts()}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogWrapper: {
    "& .MuiPaper-root": {
      height: "60%",
    }
  },
  inputWrapper: {
    alignItems: 'center',
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
  },
  listWrapper: {
    overflowY: "auto",
    padding: "32px 24px",
  }
});

export default memo(ContactDialogWithDropdown);
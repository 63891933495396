import { SvgIcon, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React from "react";

export const BinIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = (
  props
) => (
  <SvgIcon viewBox="0 0 21 29" {...props}>
    <g fillRule="nonzero">
      <path d="M18.9 2.825H14v-.707A2.112 2.112 0 0 0 11.9 0H9.1C7.942 0 7 .95 7 2.118v.707H2.1c-1.158 0-2.1.95-2.1 2.118v1.412c0 .921.585 1.706 1.4 1.997v17.776c0 1.168.942 2.118 2.1 2.118h14c1.158 0 2.1-.95 2.1-2.118V8.352A2.12 2.12 0 0 0 21 6.355V4.943a2.112 2.112 0 0 0-2.1-2.118zM8.4 2.118c0-.389.314-.706.7-.706h2.8c.386 0 .7.317.7.706v.707H8.4v-.707zm9.1 24.716h-14a.704.704 0 0 1-.7-.706V8.474h15.4v17.654c0 .39-.314.706-.7.706zm2.1-20.479c0 .39-.314.707-.7.707H2.1a.704.704 0 0 1-.7-.707V4.943c0-.39.314-.706.7-.706h16.8c.386 0 .7.317.7.706v1.412z" />
      <path d="M14.7 9.886c-.387 0-.7.316-.7.706v14.124c0 .39.313.706.7.706.387 0 .7-.316.7-.706V10.592a.703.703 0 0 0-.7-.706zM10.5 9.886c-.387 0-.7.316-.7.706v14.124c0 .39.313.706.7.706.387 0 .7-.316.7-.706V10.592a.703.703 0 0 0-.7-.706zM6.3 9.886c-.387 0-.7.316-.7.706v14.124c0 .39.313.706.7.706.387 0 .7-.316.7-.706V10.592a.703.703 0 0 0-.7-.706z" />
    </g>
  </SvgIcon>
);

import { Grid, IconButton, MenuItem, Select } from "@material-ui/core";
import React, { memo } from "react";
import PreferencesIcon from "@resources/icons/main-nav-preferences.svg";
import DeleteIcon from "@resources/icons/top-nav-delete.svg";
import ViewContactIcon from "@resources/icons/view-contact.svg";
import { Button, makeStyles } from "@material-ui/core";
import { FormIconButton } from "../FormComponents/FormIconButton";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { ComponentDimensions } from "@constants/appConstants";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import uiString from "@constants/uiString";

const _TableEditButton = <T = any, >({
  row,
  handler,
}: { row: T; handler: (row: T) => void }) => (
  <IconButton onClick={() => handler(row)}>
    <PreferencesIcon
      style={{
        width: ComponentDimensions.TABLE_EDIT_ICON_SIZE,
        height: ComponentDimensions.TABLE_EDIT_ICON_SIZE,
      }}
    />
  </IconButton>
);

export const TableEditButton = memo(
  _TableEditButton, 
  (prevProps, nextProps) => prevProps.row === nextProps.row
) as typeof _TableEditButton

const _TableDeleteButton = <T, >({
  row,
  handler,
  disabled,
}: {
  row: T;
  handler: (row: T) => void;
  disabled?: boolean;
}) => (
  <FormIconButton disabled={disabled} onClick={() => handler(row)}>
    <DeleteIcon
      style={{
        width: ComponentDimensions.TABLE_DELETE_ICON_SIZE,
        height: ComponentDimensions.TABLE_DELETE_ICON_SIZE,
      }}
    />
  </FormIconButton>
);

export const TableDeleteButton = memo(
  _TableDeleteButton, 
  (prevProps, nextProps) => prevProps.row === nextProps.row
) as typeof _TableDeleteButton

const _TableViewContactButton = <T, >({
  row,
  handler,
  disabled,
}: {
  row: T;
  handler: (row: T) => void;
  disabled?: boolean;
}) => (
  <FormIconButton disabled={disabled} onClick={() => handler(row)}>
    <ViewContactIcon
      style={{
        width: ComponentDimensions.TABLE_DELETE_ICON_SIZE,
        height: ComponentDimensions.TABLE_DELETE_ICON_SIZE,
      }}
    />
  </FormIconButton>
);

export const TableViewContactButton = memo(
  _TableViewContactButton, 
  (prevProps, nextProps) => prevProps.row === nextProps.row
) as typeof _TableViewContactButton

const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    width: ComponentDimensions.TABLE_PLAY_BUTTON_WIDTH,
    height: ComponentDimensions.TABLE_PLAY_BUTTON_HEIGHT,
    border: "1px solid var(--color-charcoal)",
    textTransform: "capitalize",
    display: "flex",
    margin: "auto",
    backgroundColor: "#fff",
  },
  iconRing: {
    borderRadius: "50%",
    border: "1px solid var(--color-charcoal)",
    width: ComponentDimensions.TABLE_PLAY_BUTTON_ICON_SIZE,
    height: ComponentDimensions.TABLE_PLAY_BUTTON_ICON_SIZE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: ComponentDimensions.TABLE_PLAY_BUTTON_FONT_SIZE,
    paddingLeft: ComponentDimensions.TABLE_PLAY_BUTTON_TEXT_PADDING_LEFT,
    paddingTop: 2,
    color: "var(--color-charcoal)",
  },
  svg: {
    width: ComponentDimensions.TABLE_PLAY_BUTTON_ICON_SIZE,
    height: ComponentDimensions.TABLE_PLAY_BUTTON_ICON_SIZE,
    fill: "var(--color-charcoal)",
  },
  select: {
    height: ComponentDimensions.TABLE_PLAY_BUTTON_ARROW_BOX_SIZE,
    textTransform: "capitalize",
    marginLeft: 1,
    "& .MuiSelect-root": {
      // padding: '3px 6px 3px 0',
      width: "100%",
      height: "100%",
      padding: 0,
    },
  },
  dropdownButton: {
    textTransform: "capitalize",
  },
  playButton: {
    padding: 0,
    paddingLeft: ComponentDimensions.TABLE_PLAY_BUTTON_PADDING_LEFT,
    maxWidth:
      ComponentDimensions.TABLE_PLAY_BUTTON_WIDTH -
      ComponentDimensions.TABLE_PLAY_BUTTON_ARROW_BOX_SIZE,
    minWidth: 0,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRight: "1px solid rgba(0, 0, 0, 0.3)",
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    textTransform: "capitalize",
    "& .MuiTouchRipple-root": {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
});

const downloadHandler = (url: string) => {
  window.open(url);
};

export const TablePlayButton = <T,>({
  row,
  handler,
  downloadUrl,
  deleteHandler,
  id,
  disableDelete,
}: {
  row: T;
  handler: (row: T) => any;
  deleteHandler?: (row: T) => any;
  downloadUrl: string;
  id: number | string;
  disableDelete: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Button className={classes.playButton} onClick={() => handler(row)}>
        <div className={classes.iconRing}>
          <PlayArrowOutlinedIcon className={classes.svg} />
        </div>
        <div className={classes.text}>Play</div>
      </Button>
      {downloadUrl && (
        <Select
          className={classes.select}
          IconComponent={(props) => (
            <KeyboardArrowDownIcon
              style={{ width: 18, marginTop: 2 }}
              {...props}
            />
          )}
          disableUnderline
          value={""}
        >
          <MenuItem onClick={() => downloadUrl && downloadHandler(downloadUrl)}>
            {uiString.tooltips.DOWNLOAD_BUTTON}
          </MenuItem>
          {deleteHandler && !disableDelete && (
            <MenuItem onClick={() => deleteHandler(row)}>
              {uiString.DELETE}
            </MenuItem>
          )}
        </Select>
      )}
    </Grid>
  );
};

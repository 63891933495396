import { FETCHING_MOXTRA_SCHEDULED_MEETINGS, SET_MOXTRA_SCHEDULED_MEETINGS } from "@actions/moxtraActions"
import { IScheduledMeeting } from "@models/Moxtra.models"

export interface IMoxtraReducer {
    scheduledMeetings: IScheduledMeeting[];
    isFetchingScheduledMeetings: boolean;
}

const initialState = {
    scheduledMeetings: [],
    isFetchingScheduledMeetings: false,
}

export default function moxtraReducer(state: IMoxtraReducer = initialState, action): IMoxtraReducer {
    switch(action.type) {
        case SET_MOXTRA_SCHEDULED_MEETINGS:
            return {...state, scheduledMeetings: action.payload}
        case FETCHING_MOXTRA_SCHEDULED_MEETINGS:
            return {...state, isFetchingScheduledMeetings: action.payload}
        default:
            return state
    }
}

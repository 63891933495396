import getApiConstants from "@constants/apiConstants";
import { IAccount, IAccountDetails, IFeature, IUserDetails, IUserPreferences } from "@models/Account.models";
import { IApiResponse, IApiResponseWithPagination } from "@models/IPagination";
import HttpClient from "./HttpClient";

export default function UserService() {
  const { BASE_URL, PERMITTED_FEATURES } = getApiConstants().endpoints.account;
  const BASE_URL_USER = getApiConstants().endpoints.user.BASE_URL;
  const SINGLE_URL_USER = getApiConstants().endpoints.user.SINGLE_URL;

  const { Get, Put, Patch } = HttpClient();

  const GetAccountDetails = async (userId: string) => {
    try {
      const { data } = await Get<IApiResponse<IAccount>>({
        url: BASE_URL,
        id: userId,
      });
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const GetUserDetails = async (userId: string) => {
    try {
      const { data } = await Get<IApiResponse<IUserDetails>>({
        url: BASE_URL_USER,
        id: userId,
      });
      return data.data;
    } catch (error) {}
  };

  const PatchAccountDetails = async (userId: string, body: IAccountDetails) => {
    try {
      const { data } = await Patch<IApiResponse<any>, any, any>({
        url: BASE_URL + `/${userId}`,
        data: body,
      });
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const GetPermittedFeatures = async (
    userId: string,
    page: number = 1,
    pageSize: number = 100
  ) => {
    try {
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      const { data } = await Get<IApiResponseWithPagination<IFeature[]>>({
        url: PERMITTED_FEATURES(userId),
        params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  // for impersonation
  const GetUser = async (userId: number) => {
    try {
        // not typing here because it was getting screwy and I'm not sure why
        const { data } = await Get<any>({
            url: SINGLE_URL_USER,
            headers: { Impersonate: userId }
        })
        return data;
    } catch (error) {
        throw error;
    }
  }

  const GetUserPreferences = async (userId: string) => {
    try {
        const { data } = await Get<IApiResponse<IUserPreferences>>({
            url: BASE_URL_USER + `/${ userId }/preferences`,
        })
        return data.data;
    } catch (error) {
        throw error;
    }
  }

  const UpdateUserPreferences = async (userId: string, body: IUserPreferences) => {
    try {
        const data = await Patch<IApiResponse<any>, any, any>({ 
            url: BASE_URL_USER + `/${ userId }/preferences`,
            data: body,
        })
        return data;
    } catch (error) {
        throw error;
    }
  }

  return {
    GetAccountDetails,
    GetPermittedFeatures,
    PatchAccountDetails,
    GetUserDetails,
    GetUser,
    GetUserPreferences,
    UpdateUserPreferences
  };
}

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React from "react";
import { DayEnum, MapDayCodeToLabel } from "@models/HoursOfOperation.models";
import DropdownComponent from "./DropdownComponent";

type IProps = {
  style?: React.CSSProperties;
  onChange: (day: DayEnum) => void;
  className?: string;
  value: DayEnum;
  label: string;
};

const DayOfTheWeekSelect = ({
  onChange,
  label,
  value,
  style,
  className,
}: IProps) => {
  return (
    <FormControl className={className} style={style}>
      <DropdownComponent
        onChange={(e) => onChange(e.target.value as DayEnum)}
        options={Object.entries(MapDayCodeToLabel).map(([key, value]) => ({
          value: key,
          text: value
        }))}
        value={value}
        outlined
        smallIcon
        fullWidth
        label={label}
        lightColor={false}
      />
    </FormControl>
  );
};

export default DayOfTheWeekSelect;

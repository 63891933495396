import React, { FC, useEffect, useState } from "react";
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import { formatPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import { domIds } from "@constants/testConstants";
import uiString from "@constants/uiString";

const TITLE = "Request Text Messaging Activation";
const HEADER = (number: string) => `Activate Text Messaging for ${number}`;
const HEADING = "Text Messaging will be ready for use in 30 minutes. A confirmation email will be sent when Text Messaging is activated.";
const CONTENT =
  "Please note that it may take up to 3 days for text messaging to be fully activate across all phone networks.";
const TOLLFREE = "Toll Free Numbers: We recommend completing a toll-free text verification form as some providers may block incoming messages from unverified toll-free numbers. For more info, please visit our ";

type IProps = {
  open: boolean;
  phoneNumber?: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const ActivationDialog: FC<IProps> = ({
  onClose,
  onSubmit,
  open,
  phoneNumber,
}) => {
  const [isAgreedTerms, setIsAgreedTerms] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    open && setIsAgreedTerms(false)
  }, [open])



  return (
    <Dialog open={open} title={TITLE} onClose={onClose} className={classes.root}>
      <DialogContent>
        <Typography className={classes.header} variant="h5">
          {HEADER(
            phoneNumber ? formatPhoneNumber(phoneNumber.replace("+", "")) : ""
          )}
        </Typography>
        <Typography className={classes.content}>{HEADING}</Typography>
        {/* <Typography className={classes.content}>
          {TOLLFREE}
          <Link
              href="https://talkroute.com/terms-and-conditions/"
              target="_blank"
          >
              {"Toll-Free Text Verification page."}
          </Link>
        </Typography> */}
        <Typography className={classes.content}>{CONTENT}</Typography>
        <FormControlLabel
          className={classes.termsLabel}
          label={
            <>
              <span style={{ paddingLeft: "1px" }}>
                {uiString.initialModal.termsModal.I_AGREE}
              </span>
              <Link
                href="https://talkroute.com/terms-and-conditions/"
                target="_blank"
              >
                {uiString.initialModal.termsModal.TITLE}
              </Link>
            </>
          }
          control={
            <Checkbox
              id={domIds.MODAL_TERMS_CHECKBOX_MESSAGING}
              checked={isAgreedTerms}
              onClick={() => setIsAgreedTerms(!isAgreedTerms)}
              color="primary"
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} onClick={onClose}>
          {uiString.updateStrings.CANCEL}
        </Button>
        <DialogButton
          className={classes.agreeButton}
          color="secondary"
          onClick={onSubmit}
          label="Activate Text Messaging"
          disabled={!isAgreedTerms}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(2, 0),
  },
  content: {
    padding: theme.spacing(1, 0),
  },
  cancelButton: {
    border: "1px solid var(--color-dark-grey)",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
   root : {
    "& .MuiDialogTitle-root h2" : {
      fontSize: "1.5rem",
      fontWeight: 400
    },
    "& .MuiTypography-body1": {
      color: "#6e6e6e",
    }
  },
    termsLabel: {
      fontSize: "1rem"
    },
  agreeButton: {
    padding: "6px 8px"
  }
}));

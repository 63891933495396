export const domIds = {
  ADD_CONTACT_PHONE_BUTTON: "add-contact-phone-button",
  ADD_CONTACT_PHONE_BUTTON_CONVERSATIONS: "add-contact-phone-button-for-conversations",
  NUMBER_TO_CALL_INPUT: "number-to-call-input",
  COMPANY_NAME_LINE: "company-name-line",
  USER_NAME_LINE: "user-name-line",
  REMOVE_NUMBER_TO_CALL_BUTTON: "remove-number-to-call-button",
  CALL_STATUS_HANGUP_BUTTON: "call-status-hangup-button",
  CALL_STATUS_ANSWER_BUTTON: "call-status-answer-button",
  CALL_STATUS_DURATION_STRING: "call-status-duration-string",
  CALL_STATUS_HANGUP_CONFIRMATION_DIALOG:
    "call-status-hangup-confirmation-dialog",
  CALL_STATUS_HANGUP_CONFIRMATION_DIALOG_CANCEL:
    "call-status-hangup-confirmation-dialog-cancel",
  CALL_STATUS_HANGUP_CONFIRMATION_DIALOG_OK:
    "call-status-hangup-confirmation-dialog-ok",
  CALL_STATUS_MUTE_BUTTON: "call-status-mute-button",
  IDLE_KEYPAD_CALL_BUTTON: "idle-keypad-call-button",
  IDLE_KEYPAD_REDIAL_BUTTON: "idle-keypad-redial-button",
  STONLY_USER_TARGET: "stonly-user-navitem-selector",
  STONLY_CALLER_ID_TARGET: "stonly-callerid-navitem-selector",
  STONLY_CONTROLS_TARGET: "stonly-controls-navitem-selector",
  STONLY_NOTIFICATIONS_SOUNDS_TARGET: "stonly-notifications-sounds-navitem-selector",
  STONLY_MANAGER_TARGET: "stonly-manager-navitem-selector",
  STONLY_TEXT_MESSAGING_TARGET: "stonly-text-messaging-navitem-selector",
  STONLY_FORWARDING_TARGET: "stonly-forwarding-navitem-selector",
  STONLY_TRANSFER_CODES_TARGET: "stonly-transfer-codes-navitem-selector",
  STONLY_HOURS_TARGET: "stonly-hours-navitem-selector",
  STONLY_GREETINGS_TARGET: "stonly-greetings-navitem-selector",
  STONLY_SUBMENUS_TARGET: "stonly-submenus-navitem-selector",
  STONLY_VOICEMAIL_TARGET: "stonly-voicemail-navitem-selector",
  STONLY_BLOCKED_NUMBERS_TARGET: "stonly-blocked-numbers-navitem-selector",
  STONLY_TEXT_HISTORY_TARGET: "stonly-text-history-navitem-selector",
  STONLY_CALL_HISTORY_TARGET: "stonly-call-history-navitem-selector",
  STONLY_ACCOUNT_TARGET: "stonly-account-navitem-selector",
  STONLY_CONTACTS_TARGET: "stonly-contacts-navitem-selector",
  STONLY_BILLING_TARGET: "stonly-billing-navitem-selector",
  STONLY_USERS_PERMISSIONS_TARGET: "stonly-users-permissions-navitem-selector",
  STONLY_VIRTUAL_PHONE_NUMBERS_TARGET: "stonly-virtual-phone-numbers-navitem-selector",
  STONLY_REFERRAL_TARGET: "stonly-referral-navitem-selector",
  PREFERENCES_CLEAR_DATA_BUTTON: "preferences-clear-data-button",
  PREFERENCES_UPDATES_NAV_ITEM: "preferences-updates-nav-item",
  PREFERENCES_NOTIFICATIONS_AND_SOUND_NAV_ITEM:
    "preferences-notifications-and-sound-nav-item",
  PREFERENCES_VOICEMAILS: "preferences-voicemails",
  PREFERENCES_CALL_HISTORY_NAV_ITEM: "preferences-call-history-nav-item",
  KEYPAD_HEADER_FROM_PHONE_DROPDOWN: "keypad-header-from-phone-dropdown",
  DTMF_STRING_INPUT: "dtmf-string-input",
  CONNECTED_KEYPAD_1_NUM_BUTTON: "connected-keypad-1-num-button",
  CONNECTED_KEYPAD_TOGGLE_KEYPAD_BUTTON:
    "connected-keypad-toggle-keypad-button",
  CONNECTED_KEYPAD_END_CALL_BUTTON: "connected-keypad-end-call-button",
  CONNECTED_KEYPAD_MUTE_BUTTION: "connected-keypad-mute-button",
  CONNECTED_KEYPAD_HOLD_BUTTION: "connected-keypad-hold-button",
  CONNECTING_KEYPAD_MUTE_BUTTION: "connecting-keypad-mute-button",
  CONNECTING_KEYPAD_FROM_PHONE_NUMBER: "connecting-keypad-from-phone-number",
  CONNECTING_KEYPAD_TO_PHONE_NUMBER: "connecting-keypad-to-phone-number",
  USER_INFO_BAR_LOGOUT_BUTTON: "user-info-bar-logout-button",
  LOGIN_PAGE_EMAIL_INPUT: "login-page-email-input",
  LOGIN_PAGE_PASSWORD_INPUT: "login-page-password-input",
  LOGIN_PAGE_LOGIN_BUTTON: "login-page-login-button",
  MODAL_TERMS_CHECKBOX: "modal-terms-checkbox",
  MODAL_TERMS_CHECKBOX_MESSAGING: "modal-terms-checkbox-messaging",
  SHOW_PREFERENCES_BUTTON: "show-preferences-button",
  SHOW_HISTORY_REPORTING_BUTTON: "show-history-reporting-button",
  CLOSE_PREFERENCES_BUTTON: "close-preferences-button",
  CLOSE_HISTORY_REPORTING_BUTTON: "close-history-reporting-button",
  RESIZE_PREFERENCES_BUTTON: "resize-preferences-button",
  RESIZE_HISTORY_REPORTING_BUTTON: "resize-history-reporting-button",
  COMPANY_PAGE_BUTTON: "company-page-button",
  SUPPORT_PAGE_BUTTON: "support-page-button",
  AUDIO_PREFERENCES_INPUT_DEVICES_DROPDOWN:
    "audio-preferences-input-devices-dropdown",
  AUDIO_PREFERENCES_OUTPUT_DEVICES_DROPDOWN:
    "audio-preferences-output-devices-dropdown",
  AUDIO_PREFERENCES_TEXT_NOTIFICATIONS_SOUND_DROPDOWN:
    "audio-preferences-text-notifications-sound-dropdown",
  AUDIO_PREFERENCES_CALL_NOTIFICATIONS_SOUND_DROPDOWN:
    "audio-preferences-call-notifications-sound-dropdown",
  CALL_HISTORY_LOADER: "call-history-loader",
  CALL_HISTORY_FILTER_DROPDOWN: "call-history-filter-dropdown",
  CALL_HISTORY_CALLBACK_BUTTON: "call-history-callback-button",
  CALL_HISTORY_TEXTBACK_BUTTON: "call-history-textback-button",
  CONVERSATIONS_TO_PHONE_NUMBER_INPUT: "conversations-to-phone-number-input",
  CONVERSATIONS_TO_PHONE_NUMBER_LABEL: "conversations-to-phone-number-label",
  CONVERSATIONS_TO_PHONE_NUMBER_DRAFT_LABEL:
    "conversations-to-phone-number-draft-label",
  CALL_HISTORY_UNBLOCK_ICON: "call-history-unblock-icon",
  CALL_HISTORY_BLOCK_ICON: "call-history-block-icon",
  CALL_HISTORY_BLOCK_UNBLOCK_BUTTON: "call-history-block-unblock-button",
  NAVIGATION_KEYPAD_ITEM: "navigation-keypad-item",
  NAVIGATION_HISTORY_ITEM: "navigation-history-item",
  NAVIGATION_MESSAGES_ITEM: "navigation-messages-item",
  NAVIGATION_VOICEMAIL_ITEM: "navigation-voicemail-item",
  NAVIGATION_MEETINGS_ITEM: "navigation-meetings-item",
  VOICEMAIL_LOADER: "voicemail-loader",
  VOICEMAIL_FILTER_DROPDOWN: "voicemail-filter-dropdown",
  VOICEMAIL_UNREAD_ICON: "voicemail-unread-icon",
  VOICEMAIL_READ_ICON: "voicemail-read-icon",
  VOICEMAIL_READSTATUS_BUTTON: "voicemail-readstatus-button",
  VOICEMAIL_PLAYER_SLIDER: "voicemail-player-slider",
  VOICEMAIL_PLAYER_ELAPSED_TIME: "voicemail-player-elapsed-time",
  VOICEMAIL_DETAILS_DELETE_BUTTON: "voicemail-details-delete-button",
  VOICEMAIL_DELETE_CONFIRM_DIALOG: "voicemail-delete-confirm-dialog"
};

export const domClasses = {
  MODAL_ACCEPT_BUTTON: "modal-accept-button",
  CALL_HISTORY_ITEM_INBOUND_HANGUP_ICON:
    "call-history-item-inbound-hangup-icon",
  CALL_HISTORY_ITEM_INBOUND_MISSED_ICON:
    "call-history-item-inbound-missed-icon",
  CALL_HISTORY_ITEM_OUTBOUND_ICON: "call-history-item-outbound-icon",
  CALL_HISTORY_ITEM_CALLER_PHONE_NUMBER:
    "call-history-item-caller-phone-number",
  VOICEMAIL_LINE_ITEM_DESCRIPTION: "voicemail-line-item-description",
  VOICEMAIL_PLAYER_PLAY_BUTTON: "voicemail-player-play-button"
};

export default {
  domIds,
  domClasses,
};

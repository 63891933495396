import { deviceTypes } from "@components/NotificationsAndSounds/constants";

/**
 * Splits the audio devices into input and output devices
 *
 * Sets inputDevices and outputDevices state variables
 */
export const filterDevices = (devices: MediaDeviceInfo[]) => {
  let filteredInputDevices: MediaDeviceInfo[] = [];
  let filteredOutputDevices: MediaDeviceInfo[] = []
  if (Array.isArray(devices) && devices.length > 0) {
    filteredInputDevices = devices.filter(
      (device) => device.kind === deviceTypes.INPUT
    );

    filteredOutputDevices = devices.filter(
      (device) => device.kind === deviceTypes.OUTPUT
    );
  };
  return { filteredInputDevices, filteredOutputDevices }
};
import React, { memo } from 'react';
import { 
  ContactLineWrapper, 
  ContactLetterLineItem, 
  ContactFullNameWrapper, 
  ContactWrapper 
} from './Contacts.styles';
import AddIcon from '@material-ui/icons/Add';

type IProps = {
  onClick: () => void;
  contactFullName: string;
  headerLetter?: string;
};

/**
 * Display a row with contact full name and an add icon button
 */
const ContactLineItemBase: React.FC<IProps> = ({ contactFullName, onClick }) => (
  <ContactWrapper 
    onClick={onClick}
  >
    <ContactLineWrapper>
      <ContactFullNameWrapper>
        <span>{contactFullName}</span>
      </ContactFullNameWrapper>
      <AddIcon style={{ color: 'rgba(0, 0, 0, 0.7)' }}/>
    </ContactLineWrapper>
  </ContactWrapper>
);

/**
 * Display a row with first name letter and below it a row with contact full name and an add icon button.
 * If the first letter is a symbol, display item under "Other" header row.
 */
const ContactLineItem: React.FC<IProps> = ({ contactFullName, onClick, headerLetter }) => (
  <>
    {headerLetter != undefined && 
      <ContactLetterLineItem>
        {headerLetter}
      </ContactLetterLineItem>
    }
    <ContactLineItemBase 
      contactFullName={contactFullName}
      onClick={onClick}
    />
  </>
);

export default memo(ContactLineItem);
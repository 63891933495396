import { createStyles, FormControlLabel, makeStyles, Switch, SwitchProps, Theme } from '@material-ui/core'
import React from 'react'

type IProps = {
  className?: string
  label?: string
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  checked?: boolean
} & SwitchProps

const ToggledRadioButton: React.FC<IProps> = ({ label = "", onChange, className, ...props }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={`${className} ${classes.wrapper}`}
      label={label}
      control={
        <Switch
          {...props}
          onChange={onChange}
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      } />
  )
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      whiteSpace: 'nowrap'
    },
    root: {
      width: 49,
      height: 22,
      padding: 0,
      marginRight: theme.spacing(1),
      '&:hover': {
        '& .MuiSwitch-track': {
          backgroundColor: '#C4C4C4'
        }
      }
    },
    switchBase: {
      padding: 1,
      transition: 'all 400ms',
      '&$checked': {
        transform: 'translateX(28px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: "var(--color-pumpkin-orange)",
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#D9D9D9',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 19,
      height: 19,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: '#8a8a8e',
      opacity: 1,
      transition: theme.transitions.create([ 'background-color','border']),
    },
    checked: {},
    focusVisible: {},
  }),
);

export default ToggledRadioButton;

import React, { memo, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FILTER_OPTIONS, FilterOption, TextMessageHistoryFilterQuery, filterTypesEnum } from "./TextMessageHistoryTypes";
import { ComponentDimensions } from "@constants/appConstants";
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import { Grid, MenuItem, Select } from "@material-ui/core";
import { Divider, FormGroup } from "semantic-ui-react";
import useAccessBlocker from "@helpers/hooks/useAccessBlocker.hook";
import { FeatureIdsEnum } from "@models/Account.models";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import CustomLabel from "@components/PresentationComponents/FormComponents/CustomLabel";
import clsx from "clsx";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputField from "@components/PresentationComponents/FormComponents/InputField";
import ArrowDropdownIcon from "@material-ui/icons/ArrowDropDown";
import { TEXT_MESSAGE_HISTORY_TABLE_INITIAL_FILTER_QUERY, TEXT_MESSAGE_HISTORY_TABLE_PAGE_NUMBER } from "./constants";
import { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { useToasts } from "react-toast-notifications";
import { ApiErrorMessages } from "@constants/uiString";
import { TalkroutePhoneNumber } from "@services/dataClasses/TalkroutePhoneNumber";
import { formatPhoneNumber } from "@services/dataClasses";
import uiString from "@constants/uiString";

interface TextMessageHistoryFiltersProps {
    filterQuery: TextMessageHistoryFilterQuery;
    isAdvancedMode: boolean;
    setAdvancedMode: (mode: boolean) => void;
    setFilterQuery: (query: TextMessageHistoryFilterQuery) => void;
    updateTextMessageHistory: (query: TextMessageHistoryFilterQuery) => any;
    onApplyFilters: (query: TextMessageHistoryFilterQuery) => any;
    outgoingTextNumbers: TalkroutePhoneNumber[];
}

function TextMessageHistoryFilters({
    filterQuery,
    isAdvancedMode,
    setAdvancedMode,
    setFilterQuery,
    updateTextMessageHistory,
    onApplyFilters,
    outgoingTextNumbers,
  }: TextMessageHistoryFiltersProps) {
    const classes = useStyles();

    const { addToast } = useToasts();

    const toggleAdvancedFilters = () => {
      setAdvancedMode(!isAdvancedMode)
    };

    const [filtersHaveChanged, setFiltersHaveChanged] = useState(false);
    const [displayResetButton, setDisplayResetButton] = useState(false);
    const [error, setError] = useState(false);

    const setNewFilters = async () => {
        let _filterQuery = { ...filterQuery };
        if (filterQuery.messageCount && !filterQuery.messageCountOperator) {
          _filterQuery.messageCountOperator = "greater";
        }
        
        setFilterQuery({
          ..._filterQuery,
          page: TEXT_MESSAGE_HISTORY_TABLE_PAGE_NUMBER,
        });
        try {
          onApplyFilters(_filterQuery);
          await updateTextMessageHistory({
            ..._filterQuery,
            page: TEXT_MESSAGE_HISTORY_TABLE_PAGE_NUMBER,
          });
          setFiltersHaveChanged(false);
          setDisplayResetButton(true);
        } catch (error) {
          if (
            (error as any).response &&
            (error as any).response.status === ErrorStatusCode.FORBIDDEN
          ) {
            addToast(ApiErrorMessages.FORBIDDEN, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            addToast(ApiErrorMessages.UNEXPECTED, {
              appearance: "error",
              autoDismiss: true,
            });
            throw error;
          }
        }
    };

    const resetHandler = async () => {
        // Reset filter query to initial state
        setFilterQuery(TEXT_MESSAGE_HISTORY_TABLE_INITIAL_FILTER_QUERY);

        // Trigger fetch with initial filters
        updateTextMessageHistory(TEXT_MESSAGE_HISTORY_TABLE_INITIAL_FILTER_QUERY);

        // Reset any other states if necessary
        setFiltersHaveChanged(false);
        setDisplayResetButton(false);
    };

    const handleSelectEvent = (event: any) => {
        const value = event.target.value.id
            ? event.target.value.id
            : event.target.value;
        handleChange(event.target.name, value);
    };

    // handle change on input fields
    const handleInputEvent = (event: any) => {
        handleChange(event.target.name, event.target.value);
    };

    const renderDropdownField = (option: FilterOption) => {
        // Check if the dropdown to be rendered is for outgoingTextNumbers
        if (option.name === "virtualNumbers") {
            return (
                <>
                    {option.title && (
                        <CustomLabel className={classes.inputLabel} label={option.title} />
                    )}
                    <Select
                        name={option.name}
                        value={filterQuery[option.name] || "all"} // Set default value to "all"
                        onChange={handleSelectEvent}
                        IconComponent={undefined}
                        variant="outlined"
                        className={clsx(
                            classes.select,
                            option.name === "virtualNumbers"
                                ? classes.makeWidthBigger
                                : undefined
                        )}
                    >
                        {/* Add "All" option */}
                        <MenuItem key="all" value="all">
                            {uiString.SETTINGS_AND_PREF.TEXT_MESSAGING_HISTORY.FILTERS.VIRTUAL_NUMBER_ALL}
                        </MenuItem>
                        {/* Map outgoingTextNumbers to MenuItem components */}
                        {outgoingTextNumbers.map((number, index) => (
                            <MenuItem key={index} value={number.phoneNumber}>
                                {formatPhoneNumber(number.phoneNumber)}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            );
        }
    };

    const renderNumberOfMessagesGroup = (option: FilterOption) => {
        const [numberOperator, number] = option.values as FilterOption[];
        const operatorValueString = filterQuery[numberOperator.name] ? filterQuery[numberOperator.name] : numberOperator.values && numberOperator.values[0].id;
        const numberValue = filterQuery[number.name] || "";
    
        return (
          <>
            <CustomLabel label={option.title} className={classes.inputLabel} />
            <div className={classes.buttonGroupWrapper}>
              <Select
                name={numberOperator.name}
                value={operatorValueString}
                onChange={handleSelectEvent}
                IconComponent={ArrowDropdownIcon}
                variant="outlined"
                className={`${classes.selectGroupItem} ${classes.groupLeftItem}`}
              >
                {numberOperator.values?.map((props: any, index: number) => (
                  <MenuItem
                    key={createKeyIndex(index, "number-operator-item")}
                    value={props.id}
                  >
                    {props.value}
                  </MenuItem>
                ))}
              </Select>
              <InputField
                name={number.name}
                value={numberValue}
                onChange={handleInputEvent}
                type={number.inputType}
                className={clsx(classes.inputGroupItem)}
                wrapperClass={classes.inputGroupWrapper}
              />
            </div>
          </>
        );
    };

    // handle change on any form field
    const handleChange = (property: string, value: any) => {
        setFiltersHaveChanged(true);
        setFilterQuery({
        ...filterQuery,
        [property]: value,
        });
    };

    // render date component
    const renderDateField = (option: FilterOption) => (
        <Grid>
        {option.title && (
            <CustomLabel label={option.title} className={classes.inputLabel} />
        )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                value={filterQuery[option.name] ? filterQuery[option.name] : null}
                InputProps={{ disableUnderline: true }}
                className={classes.datePicker}
                onChange={(date) => handleChange(option.name, date)}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                onError={(err) => {
                    if (err) {
                    setError(true);
                    } else {
                    setError(false);
                    }
                }}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
  
    return (
      <>
        <Grid className={classes.wrapper}>
          <div className={classes.buttonsWrapper}>
            {Object.values(FILTER_OPTIONS)
              .filter((option: FilterOption) => option.advanced === false)
              .map((option: FilterOption, index: number) => (
                <FormGroup
                  key={createKeyIndex(index, "setting-filter-options")}
                  className={classes.formControl}
                >
                  {option.type === filterTypesEnum.DROPDOWN &&
                    renderDropdownField(option)}
                </FormGroup>
              ))}
            <Divider
              style={{
                height: ComponentDimensions.TABLE_FILTER_DIVIDER_HEIGHT,
                width: "1px",
                backgroundColor: "#d8d8d8",
                marginRight: "11px",
              }}
            />
            <InputButton
              className={classes.filterControls}
              minWidth={ComponentDimensions.TABLE_FILTER_BUTTON_MIN_WIDTH}
              color="primary"
              onClick={toggleAdvancedFilters}
              label={"Advanced Filters"}
            />
            {filtersHaveChanged && (
              <InputButton
                className={classes.filterControls}
                minWidth={ComponentDimensions.TABLE_FILTER_BUTTON_MIN_WIDTH}
                onClick={setNewFilters}
                color="secondary"
                disabled={error}
                label={"Apply Filters"}
              />
            )}
            {displayResetButton && (
              <InputButton
                className={classes.filterControls}
                minWidth={ComponentDimensions.TABLE_FILTER_BUTTON_MIN_WIDTH}
                onClick={resetHandler}
                color="inherit"
                label={"Reset Filters"}
              />
            )}
          </div>
        </Grid>
        {isAdvancedMode && (
        <Grid className={classes.advancedOptionsWrapper}>
          {Object.values(FILTER_OPTIONS)
            .filter((option: FilterOption) => option.advanced)
            .map((option: FilterOption, index: number) => (
              <FormGroup
                key={createKeyIndex(index, "call-history-advanced-filters")}
                className={classes.advancedFiltersFormControl}
              >
                {option.type === filterTypesEnum.DROPDOWN &&
                  renderDropdownField(option)}
                {option.type === filterTypesEnum.DATE &&
                  renderDateField(option)}
                {option.type === filterTypesEnum.GROUP &&
                  renderNumberOfMessagesGroup(option)}
              </FormGroup>
            ))}
        </Grid>
      )}
      </>
    );
  }

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
    },

    formControl: {
      minWidth: 166,
      height: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
    },
    advancedFiltersFormControl: {
      minWidth: 125,
      minHeight: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
    },
    makeWidthBigger: {
      width: 175,
    },
    select: {
      lineHeight: "normal",
      marginRight: "11px",
      marginBottom: "11px",
      height: "100%",
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "var(--color-pumpkin-orange)",
          zIndex: 1,
        },
      },
      "& > .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "6px",
      },
      "& > div": {
        padding: "6px",
      },
      "& > svg": {
        display: "none",
      },
    },
    buttonGroupWrapper: {
      display: "flex",
      marginRight: "11px",
      marginBottom: "11px",
    },
    selectGroupItem: {
      lineHeight: "normal",
      height: "100%",
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "var(--color-pumpkin-orange)",
          zIndex: 1,
        },
      },
      "& > .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "25px",
      },
      "& > div": {
        padding: "6px",
      },
      "& > svg": {
        top: "5px",
      },
    },
    groupLeftItem: {
      "& fieldset": {
        borderRadius: "4px 0 0 4px",
      },
    },
    groupRightItem: {
      "& fieldset": {
        borderRadius: "0 4px 4px 0",
      },
    },
    inputGroupWrapper: {
      width: "fit-content",
      marginLeft: -1,
      marginRight: -1,
    },
    hoveredInput: {
      "& fieldset": {
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
      },
    },
    inputGroupItem: {
      height: "29px",
      width: "80px",

      // "& .MuiOutlinedInput-root": {
      //   "& .Mui-focused > fieldset": {
      //     borderColor: "var(--color-pumpkin-orange)",
      //     zIndex: 1,
      //   },
      // },
      "& .MuiOutlinedInput-input": {
        padding: "0 6px 1px",
      },
      "& fieldset": {
        borderRadius: 0,
      },
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    printButton: {
      minWidth: "fit-content",
      padding: theme.spacing(0, 1),
    },
    inputLabel: {
      margin: 0,
      fontSize: ComponentDimensions.TABLE_FILTER_LABEL,
      lineHeight: "normal",
    },
    inputField: {
      height: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
      marginRight: "11px",
      marginBottom: "11px",
    },
    advancedOptionsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "11px 0",
      alignItems: "flex-end",
    },
    filterControls: {
      height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
      fontSize: ComponentDimensions.TABLE_FILTER_LABEL,
      marginRight: "11px",
    },
    datePicker: {
      "& .MuiInput-root": {
        height: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
        border: "1px solid rgba(0, 0, 0, 0.5)",
        borderRadius: "4px",
        marginRight: "11px",
        marginBottom: "11px",

        "& input": {
          paddingLeft: "8px",
        },
      },
    },
  })
);

  export default memo(TextMessageHistoryFilters);
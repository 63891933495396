import fs from "fs";

import { IFileStorage } from "../../../core/src";

export class FileSystemManager implements IFileStorage {
  get(path: string) {
    return fs.readFileSync(path);
  }

  getList(path: string) {
    return fs.readdirSync(path);
  }

  save(path: string, content: any) {
    return fs.writeFileSync(path, content);
  }

  copy(source: string, dist: string) {
    const sourceData = this.get(source);
    return fs.writeFileSync(dist, sourceData);
  }

  copyAsync(source: string, dist: string, callback: (error?: any) => any) {
    return fs.copyFile(source, dist, callback);
  }

  createPath(path: string) {
    return fs.mkdirSync(path);
  }

  exists(path: string) {
    return fs.existsSync(path);
  }

  remove(path: string) {
    const files = this.getList(path);
    files.forEach((file) => {
      fs.unlinkSync(file);
    });
    return fs.rmdirSync(path);
  }
}

import { IScheduledMeeting } from "@models/Moxtra.models";

export default function mergeMeetLists(
  meetList1: IScheduledMeeting[],
  meetList2: IScheduledMeeting[]
) {
  const mergedMeetings = [...meetList1, ...meetList2];
  const uniqueMeetings = mergedMeetings.filter(
    (meeting, index, self) =>
      index ===
      self.findIndex(
        (m) =>
          m.topic === meeting.topic &&
          m.session_key === meeting.session_key &&
          m.scheduled_starts === meeting.scheduled_starts
      )
  );

  return uniqueMeetings.filter(
    (meet) => new Date(meet.scheduled_ends).getTime() > Date.now()
  );
}

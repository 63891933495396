import { makeStyles, OutlinedInput, OutlinedInputProps } from '@material-ui/core'
import React from 'react'
import CustomLabel from './CustomLabel'
import { InputErrorText } from './InputErrorText'

type Props = {
  id?: string;
  className?: string;
  label?: string;
  errorMessage?: string;
  minWidth?: string;
  endAdornment?: React.ReactNode;
  helperText?: string;
  boldLabel?: boolean;
  wrapperClass?: string;
} & Omit<OutlinedInputProps, "label">

const InputField: React.FC<Props> = (
  { id, className, helperText, endAdornment, errorMessage, boldLabel,
   label, minWidth = '100px', startAdornment, wrapperClass, style, ...props }) => {
  const classes = useStyles()

  return (
    <>
    <div className={`${wrapperClass} ${classes.root}`}>
      {label && <CustomLabel boldLabel={boldLabel} label={label} />}
      <OutlinedInput 
        {...props}
        style={{ minWidth: minWidth, ...style }}
        id={id} 
        className={`${classes.inputField} ${className}`} 
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        />
      {helperText && <CustomLabel label={helperText}/>}
      {props.error &&
        <InputErrorText standalone style={{ fontSize: '0.75rem', marginTop: 5 }}>
          {errorMessage}
        </InputErrorText>
      }
    </div>
    </>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputField: {
    height: '40px',
    minWidth: '80px',

    '& .MuiOutlinedInput-input': {
      padding: '9px',
    },
    '& fieldset': {
      borderRadius: '4px',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--color-pumpkin-orange)",
      zIndex: 1 // this is useful whenever the input is part of a button/input/dropdown group
    }
  },
  helperText: {
    fontSize: '10px',
    color: 'red',
  }
})

export default InputField
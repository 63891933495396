import React from 'react'
import uiString from '../../constants/uiString';
import { getTextMessageEnabledForNumber, getTextMessageAllowedForNumber, TalkroutePhoneNumber } from '../../services/dataClasses/TalkroutePhoneNumber';
import validateArray from '../../helpers/functions/arrayValidator';
import { useDispatch } from 'react-redux';
import { settingChanged } from '../../actions/preferencesActions';
import DropdownComponent from '../PresentationComponents/DropdownComponent';

type Props = {
  phoneNumbers: TalkroutePhoneNumber[]
  label: string
  selectedValue: string | null
  name: string
}

const PhoneNumberDropdown: React.FC<Props> = ({
  phoneNumbers, label, selectedValue, name
}) => {
  let placeholderValue = uiString.SELECT_PHONE_NUMBER;
  let dropdownOptions: any = [];
  const newValue = selectedValue ? selectedValue : ""
  const dispatch = useDispatch()
  const editableTextPhoneNumbers = phoneNumbers.filter(
    (numberEntry) => getTextMessageAllowedForNumber(numberEntry.phoneNumber, phoneNumbers)
  );

  if (validateArray(phoneNumbers)) {
    if (label == uiString.preferences.OUTGOING_CALLER_ID) {
      dropdownOptions = phoneNumbers.map((entry) => ({
        value: entry.getPhoneNumber(),
        text: entry.getPhoneNumberFormatted(),
      }));
    }

    if (label == uiString.preferences.OUTGOING_TEXT_MESSAGE_PHONE_NUMBER) {
      dropdownOptions = editableTextPhoneNumbers.map((entry) => {
        return {
            value: entry.getPhoneNumber(),
            text: entry.getPhoneNumberFormatted(),
        };
      });
    }
  } else {
    // Add "None" as an option only if there are no other phone numbers
    dropdownOptions.push({
      value: null,
      text: uiString.preferences.OUTGOING_NONE,
    });

    placeholderValue = uiString.preferences.OUTGOING_NONE;
  }

  const changeHandler = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    const {name, value} = event.target;
    dispatch(settingChanged(name, value))
  }

  return (
    <div style={{ padding: "15px 0 10px 0" }}>
      <DropdownComponent
        className="text-light"
        value={newValue}
        label={label}
        name={name}
        placeholder={placeholderValue}
        options={dropdownOptions}
        onChange={changeHandler}
        smallIcon
      />
    </div>
  );
};

export default PhoneNumberDropdown

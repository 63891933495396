import React, { FC, memo } from "react";
import RoundedInputField from "./RoundedInputField";
import SearchIcon from "@resources/icons/search-icon.svg";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, OutlinedInputProps } from "@material-ui/core";
import keyConstants from "@constants/keyConstants";

type IProps = {
  submitHandler?: () => void;
  clearSearchbarHandler?: () => void;
} & Omit<OutlinedInputProps, "">

const SearchbarField: FC<IProps> = (
  { submitHandler, clearSearchbarHandler, value, ...props }
) => {
  const classes = useStyles();
  /**
   * Searchbox's key down event handler
   */
   const onKeyDown = (e) => {
    if (e.keyCode === keyConstants.CODE_ENTER) {
      e.preventDefault();
      submitHandler && submitHandler();
    };
    if (e.keyCode === keyConstants.CODE_ESCAPE) {
      e.preventDefault();
      clearSearchbarHandler && clearSearchbarHandler();
    };
  };

  return (
    <RoundedInputField
      {...props}
      placeholder='Search...'
      value={value}
      className={classes.root}
      endAdornment={
        value ? (
          <>
            <SearchIcon 
              style={{ cursor: 'pointer', color: 'grey' }}
              width="19" 
              onClick={submitHandler}
            />
            <CloseIcon
              width="17"
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={clearSearchbarHandler}
            />
          </>
        ) : (
          <SearchIcon style={{ cursor: 'pointer', color: 'grey' }} width="19" />
        )
      }
      onKeyDown={(e) => onKeyDown(e)}
    />
  );
};

const useStyles = makeStyles({
  root: {
    '& input': {
      padding: '4px 0 4px 14px',
    },
  }
});

export default memo(SearchbarField);
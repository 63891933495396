import { ComponentDimensions } from "@constants/appConstants";
import { createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  createStyles({
    underlinedTitle: {
      // padding: `16px 0 ${ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN}px 0`,
      width: "100%",
      borderBottom: "1px solid var(--color-dark-grey)",
      fontSize: ComponentDimensions.PREFERENCES_PAGE_HEADER_FONT_SIZE,
      marginBottom: ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN,
      paddingBottom: ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN,
    },
  })
);

type IProps = {
    children: JSX.Element | string;
    className?: string;
    style?: React.CSSProperties;
};

export const UnderlinedTitle = ({ children, className, style }: IProps) => {
  const classes = useStyles();
  return <div style={style} className={clsx(classes.underlinedTitle, className)}>{children}</div>;
};

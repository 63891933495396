/**
 * textMessageHistoryActions.js
 * Talkroute
 * */

import { RootState } from "@helpers/store/store";
import apiServiceInstance from "@services/TalkrouteAPIService";
import validateArray from "@helpers/arrayValidator";

import {
  ConversationData,
} from "@services/dataClasses/ConversationData";

export const FETCH_CONVERSATION_HISTORY_BEGIN = "FETCH_CONVERSATION_HISTORY_BEGIN";
export const FETCH_CONVERSATION_HISTORY_SUCCESS = "FETCH_CONVERSATIONS_HISTORY_SUCCESS";
export const FETCH_CONVERSATION_HISTORY_FAILURE = "FETCH_CONVERSATIONS_HISTORY_FAILURE";
export const FETCH_ALL_HISTORY_COMPLETE = "FETCH_ALL_HISTORY_COMPLETE"

/**
 * Fetches threads from the API service
 *
 * @param since an optioanal since parameter. Fetch only conversation since this time
 * @param offset an optioanl offset parameter. Fetch the next conversation limit after the offset
 * @param showLoading show the loading state while fetching. Default is true
 * @param clearDraft clear any draft conversations on a successful fetch. Default is false
 */
export const fetchConversationHistory = (
    since,
    offset,
    showLoading = true,
    clearDraft = false
  ) => {
    return async (dispatch, getState: () => RootState) => {
      if (showLoading === true) {
        dispatch(fetchConversationsBegin());
      }
  
        try {
            const data = await apiServiceInstance.getConversations({ since, offset });
            const { conversations, pagination } = data;
            let sortedData = sortConversations(conversations);

            dispatch(fetchConversationsSuccess({
                data: sortedData,
                pagination,
                clearDraft,
            }));
            dispatch(fetchAllHistoryComplete())
        } catch (error) {
            dispatch(fetchConversationsFailure(error));
        }
    };
};

/**
 * Fetches threads from the API service with all the filters
 *
 * @param filterParams Object containing filter parameters and options
 */
export const fetchFilteredConversationHistory = (filterParams) => {
  return async (dispatch, getState: () => RootState) => {
    const {
      offset = null,
      showLoading = true,
      clearDraft = false,
      startDateFirst = null,
      endDateFirst = null,
      startDateLast = null,
      endDateLast = null,
      virtualNumbers = null,
      messageCount = null,
      messageCountOperator = null,
      pageSize,
    } = filterParams;

    if (showLoading) {
      dispatch(fetchConversationsBegin());
    }

    try {
      // Convert dates to Unix timestamps

      const queryParams = {
        offset: offset,
        firstMessageAfter: startDateFirst ? startDateFirst.getTime() / 1000 : undefined,
        firstMessageBefore: endDateFirst ? endDateFirst.getTime() / 1000 : undefined,
        since: startDateLast ? startDateLast.getTime() / 1000 : undefined,
        before: endDateLast ? endDateLast.getTime() / 1000 : undefined,
        minMessagesCount: messageCountOperator && messageCountOperator === 'greater' ? messageCount : undefined,
        maxMessagesCount: messageCountOperator && messageCountOperator == 'less' ? messageCount : undefined,
        talkrouteNumber: virtualNumbers && virtualNumbers == 'all' ? null : virtualNumbers,
        pageSize: pageSize,
      };

      const data = await apiServiceInstance.getConversations(queryParams);
      const { conversations, pagination } = data;
      let sortedData = sortConversations(conversations);

      dispatch(fetchConversationsSuccess({
        data: sortedData,
        pagination,
        clearDraft,
      }));
      dispatch(fetchAllHistoryComplete())
    } catch (error) {
      dispatch(fetchConversationsFailure(error));
    }
  };
};

/**
 * Fetch the next batch of conversation using the last known pagination information as reference
 */
export const fetchNextBatchConversationHistory = (filterParams, page, pageSize) => {
    return (dispatch, getState) => {
      const {
        offset = null,
        showLoading = true,
        clearDraft = false,
        startDateFirst = null,
        endDateFirst = null,
        startDateLast = null,
        endDateLast = null,
        virtualNumbers = null,
        messageCount = null,
        messageCountOperator = null,
        pageSize,
      } = filterParams;

      const { conversationPagination } = getState().textMessageHistoryReducer;
      if (conversationPagination) {
        const { count } = conversationPagination;
        const { perPage } = conversationPagination;
        let nextOffset = (page - 1) * pageSize;

        if (pageSize !== perPage) {
          nextOffset = 0;
        }

        const queryParams = {
          offset: nextOffset,
          pageSize: pageSize,
          startDateFirst: startDateFirst ? startDateFirst : undefined,
          endDateFirst: endDateFirst ? endDateFirst : undefined,
          startDateLast: startDateLast ? startDateLast : undefined,
          endDateLast: endDateLast ? endDateLast : undefined,
          messageCount: messageCount ? messageCount : undefined,
          messageCountOperator: messageCountOperator ? messageCountOperator : undefined,
          virtualNumbers: virtualNumbers,
          showLoading: showLoading,
          clearDraft: clearDraft,
        };
  
        if (nextOffset < count) {
          dispatch(fetchFilteredConversationHistory(queryParams));
        }
      }
    };
};

/**
 * Helper function
 * Sorts the passed in array of conversations by the
 * createdAtMicroseconds value of their last messages
 * @param {*} data the conversations to sort
 */
export const sortConversations = (data: ConversationData[]) => {
    const sortedData = [...data];
    if (validateArray(sortedData)) {
      // Sort by date before returning
      sortedData.sort(
        (a, b) =>
          // A simple comparator function that uses the numeric createdAtMicroseconds values for comparison
          b.lastMessage.createdAtMicroseconds -
          a.lastMessage.createdAtMicroseconds
      );
    }
  
    return sortedData;
};

/**
 * Start fething the conversations
 */
export const fetchConversationsBegin = () => ({
    type: FETCH_CONVERSATION_HISTORY_BEGIN,
});

/**
 * Successfully fetched the conversations
 * @param {*} conversations the that were fetched
 */
export const fetchConversationsSuccess = (props) => ({
    type: FETCH_CONVERSATION_HISTORY_SUCCESS,
    payload: { conversations: props },
});

/**
 * An error occured while fetching the conversations
 * @param {*} error
 */
export const fetchConversationsFailure = (error) => ({
    type: FETCH_CONVERSATION_HISTORY_FAILURE,
    payload: { error },
});

export const fetchAllHistoryComplete = () => ({
  type: FETCH_ALL_HISTORY_COMPLETE,
});
import React, { useEffect, memo, useState, useMemo, useCallback } from "react";
import Dialog from "../PresentationComponents/Dialog/Dialog";
import DialogContent from "../PresentationComponents/Dialog/DialogContent";
import DialogDivider from "../PresentationComponents/Dialog/DialogDivider";
import {
  ContactDialogEnum,
  IContactForm,
  IContactPhoneNumberBody,
  IContactsPartition,
} from "@models/Contacts.models";
import { openDialog } from "@actions/appActions";
import { useDispatch } from "react-redux";
import useForm from "@helpers/hooks/useForm.hook";
import { DialogActions, Grid, makeStyles } from "@material-ui/core";
import SearchbarField from "@components/PresentationComponents/FormComponents/SearchbarField";
import {
  partitionContactsByName,
  sortContacts,
} from "@helpers/functions/contacts";
import ContactLineItem from "./ContactLineItem";
import createKeyIndex from "@helpers/createKeyIndex";
import uiString from "@constants/uiString";
import usePrevious from "@helpers/hooks/usePrevious.hook";
import DropdownComponent from "@components/PresentationComponents/DropdownComponent";
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import InputField from "@components/PresentationComponents/FormComponents/InputField";
import { formatBothNationalInternational } from "@helpers/functions/phoneNumberFormatter";
import { contactDropdownOptions, CONTACTS_INITIAL_PAGE, GET_TEN_THOUSAND_CONTACTS } from "./constants";
import clsx from "clsx";
import { useAddContactPhoneNumberMutation, useGetAllContactsQuery } from "@services/kingcobraApi";
import _ from "lodash";

const SEARCH_DEBOUCE_INTERVAL = 100

type IProps = {
  onClose: () => void;
  open: boolean;
  phoneNumber: string;
};

const ContactDialog: React.FC<IProps> = ({ onClose, open, phoneNumber }) => {
  const { form, changeHandler } = useForm<IContactForm>({
    searchContactNames: { initialValue: "", validators: [] },
    currentContactName: { initialValue: "", validators: [] },
    currentContactId: { initialValue: "", validators: [] },
  });
  const [showContactsList, setShowContactsList] = useState(true);
  const [phoneType, setPhoneType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const { AddPhoneToContact } = useService(ContactsService);
  const [AddPhoneNumber, {isLoading: isPhoneNumberAttachmentPending}] = useAddContactPhoneNumberMutation();

  const dispatch = useDispatch();
  // const contacts = useAppSelector((state) => state.contactsReducer.data);
  const {data: _contacts, } = useGetAllContactsQuery({ params: { 
    pageSize: GET_TEN_THOUSAND_CONTACTS,
    page: CONTACTS_INITIAL_PAGE
  } });
  const contacts = useMemo(() => _contacts || [], [_contacts]);
  const pervContacts = usePrevious(contacts);
  const classes = useStyles();

  useEffect(() => {
    dispatch(openDialog(open));
    open && setShowContactsList(true);
    open && changeHandler(ContactDialogEnum.SEARCH_CONTACT_NAMES, "");
  }, [open]);

  const sortedContacts = useMemo(
    () => sortContacts(contacts.length !== 0 ? contacts : pervContacts ?? []),
    [contacts, pervContacts]
  );
  const partitionedContacts = useMemo(
    () => !Boolean(form.searchContactNames)
        ? partitionContactsByName(sortedContacts) 
        : partitionContactsByName(sortedContacts.filter(item => 
            item.name.toLowerCase().includes(form.searchContactNames.toLowerCase())
          ))
    ,
    [sortedContacts, form.searchContactNames]
  );

  /**
   * Clear the search bar & display all contacts from contact list
   */
  const clearSearchBar = useCallback(() => {
    changeHandler(ContactDialogEnum.SEARCH_CONTACT_NAMES, "");
  }, []);

  /**
   * Opens aditional dialog where can be attached the current phone number to an existing contact
   * @param name of the contact that the number can be attached
   */
  const openAddPhoneNumberDialog = (name: string, id: string) => {
    setShowContactsList(false);
    changeHandler(ContactDialogEnum.CURRENT_CONTACT_NAME, name);
    changeHandler(ContactDialogEnum.CURRENT_CONTACT_ID, id);
  };

  /**
   * Attach phone number to current contact.
   */
  const submitHandler = async () => {
    setIsLoading(true);
    const body: IContactPhoneNumberBody = {
      phoneNumber: "+" + phoneNumber,
      type: contactDropdownOptions[phoneType].text,
    };

    try {
      const data = await AddPhoneNumber({contactId: form.currentContactId, numberToAdd: body}).unwrap();
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const handleChange = (value: string) =>
    changeHandler(ContactDialogEnum.SEARCH_CONTACT_NAMES, value);

  const debouncedSearch = _.debounce(handleChange, SEARCH_DEBOUCE_INTERVAL, { leading: false, trailing: true })

  /**
   * Display names of contacts to UI into sections with first letter of first name
   * @param contacts list with all initial users
   * @returns array of elements with name and button to add current number to
   */
  const renderContacts = (contacts: IContactsPartition) => {
    let result: JSX.Element[] = [];
    for (const key in contacts) {
      let headerRendered = false;
      contacts[key].map((item, index) => {
        result.push(
          <ContactLineItem
            key={createKeyIndex(index, item.id)}
            contactFullName={item.name}
            onClick={() => openAddPhoneNumberDialog(item.name, item.id)}
            headerLetter={headerRendered ? undefined : key}
          />
        );
        headerRendered = true;
      });
    }
    return result;
  };

  return (
    <Dialog
      title={uiString.CONTACTS.PAGE_HEADER}
      open={open}
      onClose={onClose}
      fullWidth
      className={clsx(showContactsList && classes.dialogWrapper)}
    >
      {showContactsList ? (
        <>
          <Grid className={classes.inputWrapper}>
            <SearchbarField
              style={{ maxWidth: "90%" }}
              fullWidth
              autoFocus
              clearSearchbarHandler={clearSearchBar}
              autoComplete="off"
              name={ContactDialogEnum.SEARCH_CONTACT_NAMES}
              onChange={(e) => debouncedSearch(e.target.value)}
              type="text"
            />
          </Grid>
          <DialogDivider />
          <DialogContent className={classes.listWrapper}>
            {renderContacts(partitionedContacts)}
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent>
            <Grid style={{ marginBottom: "6px" }}>
              <b>{form.currentContactName}</b>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <InputField
                disabled
                value={formatBothNationalInternational(phoneNumber)}
                style={{ height: "32px" }}
                className={classes.inputField}
              />
              <DropdownComponent
                options={contactDropdownOptions}
                value={phoneType}
                onChange={(e) => setPhoneType(e.target.value as number)}
                smallIcon
                outlined
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <InputButton
              color="inherit"
              label={uiString.BACK}
              onClick={() => setShowContactsList(true)}
            />
            <InputButton
              label={uiString.CONTACTS.ADD_PHONE_INPUT_BUTTON_LABEL}
              onClick={() => submitHandler()}
              isLoading={isLoading}
            />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogWrapper: {
    "& .MuiPaper-root": {
      height: "60%",
    },
  },
  inputWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
  },
  listWrapper: {
    overflowY: "auto",
    padding: "32px 24px",
  },
  inputField: {
    maxWidth: "430px",
  },
});

export default memo(ContactDialog);

import getApiConstants from "@constants/apiConstants";
import {
  IHoursOfOperation,
  IHoursOfOperationBody,
  IOffHoursOptions,
  IOffHoursOptionsBody,
} from "@models/HoursOfOperation.models";
import { AxiosError } from "axios";
import {
  IApiResponseWithPagination,
  IApiResponse,
} from "../models/IPagination";
import HttpClient from "./HttpClient";

export default function HoursOfOperationService() {
  const url = getApiConstants().endpoints.hoursofoperationv2.BASE;
  const settingsUrl = getApiConstants().endpoints.hoursofoperationv2.SETTINGS;

  const { Get, Put, Post, Delete, Patch } = HttpClient();

  const GetByPage = async (page: number, pageSize: number) => {
    try {
      if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
      const params = new URLSearchParams();
      params.set("page", String(page));
      params.set("pageSize", String(pageSize));
      const { data } = await Get<
        IApiResponseWithPagination<IHoursOfOperation[]>
      >({ url, params });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const CreateOne = async (body: IHoursOfOperationBody) => {
    try {
      const { data } = await Post<
        IApiResponse<IHoursOfOperation>,
        IHoursOfOperationBody
      >({ url, data: body });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const DeleteOne = async (id: string) => {
    try {
      await Delete<void>({ url, id });
    } catch (err) {
      throw err;
    }
  };

  const UpdateOne = async (id: string, body: IHoursOfOperationBody) => {
    try {
      const { data } = await Put<
        IApiResponse<IHoursOfOperation>,
        IHoursOfOperationBody
      >({ url, id, data: body });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const UpdateSettings = async (body: IOffHoursOptions) => {
    try {
      const {id, type} = body.destination;
      const reformattedBody = {
          closed: {
            audioFileId: body.audioFileId !== '0'? body.audioFileId : null,
            destination: {
              id: id ? id : undefined,
              type,
            },
          },
          timezone: body.timezone
      };
      const { data } = await Patch<IApiResponse<IOffHoursOptionsBody>, typeof reformattedBody>({
        url: settingsUrl,
        data: reformattedBody,
      });
      return data;
    } catch (err) {
      throw err;
    }
  };

  const GetSettings = async () => {
    try {
      const { data } = await Get<IApiResponse<IOffHoursOptionsBody>>({ url: settingsUrl });
      return data;
    } catch (err) {
      throw err;
    }
  }

  return {
    GetByPage,
    CreateOne,
    DeleteOne,
    UpdateOne,
    UpdateSettings,
    GetSettings
  };
}

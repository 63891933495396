import React, { FC, useEffect, useState } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

type IProps = {
  initial: number;
  max: number;
};

enum RowEnum {
  USED = "used",
  EMPTY = "empty",
}

export const DisplayRowIndicator: FC<IProps> = ({ initial = 0, max = 0 }) => {
  const classes = useStyles();
  const [rows, setRows] = useState<RowEnum[]>([]);

  useEffect(() => {
    displayIndicator(initial, max);
  }, [initial, max]);

  const displayIndicator = (initial: number, max: number) => {
    for (let i = 1; i <= max; i++) {
      if (i <= initial) {
        setRows((state) => [...state, RowEnum.USED]);
      } else {
        setRows((state) => [...state, RowEnum.EMPTY]);
      }
    }
  };

  return (
    <Grid className={classes.root}>
      {rows.map((item, index) => {
        return item === RowEnum.USED ? (
          <div
            key={item + index}
            className={clsx(
              classes.usedBox,
              initial <= max ? classes.orangeBox : classes.redBox
            )}
          />
        ) : (
          <div key={item + index} className={classes.emptyBox} />
        );
      })}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: "1px solid var(--color-dark-grey)",
    borderRadius: "4px",
    minHeight: "1rem",
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
  emptyBox: {
    width: "100%",
    height: "1rem",
    display: "inline-flex",
  },
  usedBox: {
    width: "100%",
    height: "1rem",
    display: "inline-flex",
  },
  orangeBox: {
    backgroundColor: theme.palette.common.pumpkinOrange,
  },
  redBox: {
    backgroundColor: theme.palette.common.paleRed,
  },
}));

import { IPagination } from "@models/IPagination";

export const initialPagination: IPagination = {
  count: 1,
  currentPage: 1,
  firstPageUrl: "",
  from: 1,
  lastPageUrl: "",
  nextPageUrl: null,
  path: "",
  perPage: 25,
  prevPageUrl: null,
  to: 1,
  total: 1,
  totalPages: 1
}
import { useState } from "react";
import useInterval from "./useInterval.hook";
/**
 *
 * @param timer - milliseconds
 * @returns time in the form mm:ss:sss
 */
const formatTime = (timer: number) => {
  let time = new Date(timer);
  let minutes = time.getUTCMinutes();
  let seconds = time.getUTCSeconds();
  let milliseconds = Number(String(time.getUTCMilliseconds())[0]);
  return minutes + ":" + seconds + ":" + milliseconds;
};


/**
 * A basic stopwatch that can be used to track time in the UI
 * 
 * @param {number} startTime - where to begin counting from, default is 0
 * @param incrementBy - how quickly the time should update in milliseconds, default 100
 * @returns timer.startTime - function which starts the timer from the current time
 * @returns timer.stopTimer - function which pauses the timer at the current time
 * Note that we do not set the time back to 0, so we can start from where we paused.
 * @returns timer.resetTimer - function which sets the time back to 0.
 * Note that it does not stop the timer, only resets.
 * @returns timer.getRawTime - getter function for the time in milliseconds
 * @returns timer.getFormattedTime - getter function for the time in "mm:ss:sss" format
 * @example
 * const MyComponent = () => {
 *    const {stopTimer, startTimer, getRawTime} =  useTimer();
 *    return (
 *      <div>
 *        <button onClick={startTimer}> Start counting! </button>
 *        <button onClick={stopTimer}> Stop counting! </button>
 *        <div>
 *          Time in milliseconds: {getRawTime()}ms
 *        </div> 
 *      </div>   
 *    )
 * }
 */
const useTimer = (startTime: number = 0, incrementBy: number = 100) => {
  const [timer, setTimer] = useState(startTime);
  const [stop, setStop] = useState(true);
  useInterval(() => {
    setTimer((timer) => timer + incrementBy);
  }, stop ? null : incrementBy)

  const getRawTime = () => timer;
  const getFormattedTime = () => formatTime(timer);
  const stopTimer = () => setStop(true);
  const startTimer = () => setStop(false);
  const resetTimer = () => setTimer(0);
  return {
    getRawTime,
    getFormattedTime,
    stopTimer,
    startTimer,
    resetTimer,
  };
};

export default useTimer;

import { DIALOG_OPEN } from "@actions/appActions";
import { REHYDRATE } from "redux-persist";

export interface IAppReducer {
  isDialogOpened: boolean;
};

const initialState: IAppReducer = {
  isDialogOpened: false,
};

/**
 * A redux function to process call actions
 * @param {*} state the redux state
 * @param {*} action passed in action through the callActions creator
 */
export default function appReducer(state: IAppReducer = initialState, action): IAppReducer {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload !== undefined && action.payload.appReducer) {
        return {
          ...state,
        };
      }

      return {
        ...state
      };
    
    case DIALOG_OPEN:
      return {
        ...state,
        isDialogOpened: action.payload
      };

    default: 
      return state;
  };
};
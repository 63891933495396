import { useEffect, useRef } from "react";

/**
 * Saves the previous value of a variable.
 * Mostly combined with `useState`.
 * @param value
 * @returns prevValue, updated after value changes
 */
const usePrevious = <T,>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default usePrevious;

import React from "react";
import { phoneSystemItems, companyItems, yourProfileItems } from "../../constants/navItems";
import CallerID from "./CallerID";

type Props = {
  activeItem: string;
};

/**
 * Draw the contents of preferences
 */
const ShowContent: React.FC<Props> = ({ activeItem }) => {
  for (const { Component, value } of Object.values(phoneSystemItems)) {
    if (value === activeItem && Component !== null) return <Component />;
  }
  for (const { Component, value } of Object.values(companyItems)) {
    if (value === activeItem && Component !== null) return <Component />;
  }
  for (const { Component, value } of Object.values(yourProfileItems)) {
    if (value === activeItem && Component !== null) return <Component />;
  }
  return <CallerID />;
};

export default React.memo(ShowContent);

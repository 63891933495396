import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import DayOfTheWeekSelect from "@components/PresentationComponents/DayOfTheWeekSelect";
import { FormControl } from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import {
  DayEnum,
  IHoursOfOperation,
  MapDayCodeToLabel,
} from "@models/HoursOfOperation.models";
import {
  IClockTime,
  IHoursOfOperationForm,
  TimeClockFormat,
} from "./constants";
import uiString from "../../constants/uiString";
import CustomLabel from "../PresentationComponents/FormComponents/CustomLabel";
import DropdownComponent from "../PresentationComponents/DropdownComponent";
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import DateFnsUtils from "@date-io/date-fns";
import usePrevious from "@helpers/hooks/usePrevious.hook";

type IProps = {
  onSubmit: (body: IHoursOfOperationForm) => Promise<void>;
  formData: IHoursOfOperation | null;
  close: () => void;
  open: boolean;
  clockFormat: TimeClockFormat;
};

const clockTimeToDate = (time: IClockTime): Date =>
  new Date(0, 0, 0, time.hour, time.minute, 0);

const dateToClocktime = (date: Date): IClockTime => ({
  hour: date.getHours(),
  minute: date.getMinutes(),
});

const defaultDateStart = clockTimeToDate({ hour: 9, minute: 0 });

const defaultDateEnd = clockTimeToDate({ hour: 17, minute: 30 });

const presetFormat = [
  {
    start: DayEnum.MONDAY,
    end: DayEnum.SUNDAY,
  },
  {
    start: DayEnum.MONDAY,
    end: DayEnum.FRIDAY,
  },
  {
    start: DayEnum.MONDAY,
    end: DayEnum.THURSDAY,
  },
  {
    start: DayEnum.SATURDAY,
    end: DayEnum.SUNDAY,
  },
  {
    start: DayEnum.MONDAY,
    end: DayEnum.MONDAY,
  },
  {
    start: DayEnum.TUESDAY,
    end: DayEnum.TUESDAY,
  },
  {
    start: DayEnum.WEDNESDAY,
    end: DayEnum.WEDNESDAY,
  },
  {
    start: DayEnum.THURSDAY,
    end: DayEnum.THURSDAY,
  },
  {
    start: DayEnum.FRIDAY,
    end: DayEnum.FRIDAY,
  },
  {
    start: DayEnum.SATURDAY,
    end: DayEnum.SATURDAY,
  },
  {
    start: DayEnum.SUNDAY,
    end: DayEnum.SUNDAY,
  },
];

const AddTimeSlotDialog = ({
  open,
  onSubmit,
  formData,
  clockFormat,
  close,
}: IProps) => {
  const classes = useStyles();
  const [dayPresets, setDayPresets] = useState<number>(2);
  const [startDay, setStartDay] = useState<DayEnum>(DayEnum.MONDAY);
  const [endDay, setEndDay] = useState<DayEnum>(DayEnum.FRIDAY);
  const [startTime, setStartTime] = useState<Date>(defaultDateStart);
  const [endTime, setEndTime] = useState<Date>(defaultDateEnd);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const _onSubmit = async () => {
    try {
      if (startDay && endDay && startTime && endTime) {
        setIsSubmitting(true);
        await onSubmit({
          startDay,
          endDay,
          startTime: dateToClocktime(startTime),
          endTime: dateToClocktime(endTime),
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handlePresetsChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    const { value } = event.target;
    const num = Number(value);

    setStartDay(presetFormat[num - 1].start);
    setEndDay(presetFormat[num - 1].end);
    setDayPresets(num);
  };

  const dayPresetsDropdown = [
    { value: 1, text: uiString.hoursOfOperations.EVERY_DAY },
    // { value: uiString.SUBHEADER, text: uiString.hoursOfOperations.COMMON_RANGES },
    { value: 2, text: uiString.hoursOfOperations.MONDAY_TO_FRIDAY },
    { value: 3, text: uiString.hoursOfOperations.MONDAY_TO_THURSDAY },
    { value: 4, text: uiString.hoursOfOperations.SATURDAY_TO_SUNDAY },
    // { value: uiString.SUBHEADER, text: uiString.hoursOfOperations.INDIVIDUAL_DAYS },
    { value: 5, text: MapDayCodeToLabel[DayEnum.MONDAY] },
    { value: 6, text: MapDayCodeToLabel[DayEnum.TUESDAY] },
    { value: 7, text: MapDayCodeToLabel[DayEnum.WEDNESDAY] },
    { value: 8, text: MapDayCodeToLabel[DayEnum.THURSDAY] },
    { value: 9, text: MapDayCodeToLabel[DayEnum.FRIDAY] },
    { value: 10, text: MapDayCodeToLabel[DayEnum.SATURDAY] },
    { value: 11, text: MapDayCodeToLabel[DayEnum.SUNDAY] },
  ];

  useEffect(() => {
    if (formData && open) {
      setDayPresets(presetFormat.findIndex(
            (item) =>
              item.start === formData.dayStart && item.end === formData.dayEnd
          ) + 1
        )
      setStartDay(formData.dayStart);
      setEndDay(formData.dayEnd);
      setStartTime(clockTimeToDate({
        hour: formData.hourStart,
        minute: Number(formData.minuteStart),
      }));
      setEndTime(clockTimeToDate({
        hour: formData.hourEnd,
          minute: Number(formData.minuteEnd),
        }));
      } else if (open) {
        setDayPresets(2);
        setStartDay(DayEnum.MONDAY);
        setEndDay(DayEnum.FRIDAY);
        setStartTime(defaultDateStart);
        setEndTime(defaultDateEnd);
      }
  }, [open, formData])
  
  return (
    <Dialog
      title={
        formData
          ? uiString.hoursOfOperations.TIME_SLOT_SETTINGS
          : uiString.hoursOfOperations.ADD_TIME_SLOT
      }
      open={open}
      onClose={close}
      onSubmit={_onSubmit}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent className={classes.dialogContent}>
        <FormControl className={classes.presetsPicker}>
          <DropdownComponent
            value={dayPresets}
            options={dayPresetsDropdown}
            onChange={handlePresetsChange}
            className={classes.selectInput}
            outlined
            smallIcon
            label={uiString.hoursOfOperations.DAY_PRESETS}
            lightColor={false}
          />
        </FormControl>
        <div className={classes.formRow}>
          <DayOfTheWeekSelect
            className={classes.selectorWidth}
            label={uiString.hoursOfOperations.START_DAY}
            value={startDay}
            onChange={setStartDay}
          />
          <DayOfTheWeekSelect
            className={classes.selectorWidth}
            style={{ marginLeft: 20 }}
            label={uiString.hoursOfOperations.END_DAY}
            value={endDay}
            onChange={setEndDay}
          />
        </div>
        <div className={classes.formSecondRow}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ width: "45%" }}>
              <CustomLabel label={uiString.hoursOfOperations.START_TIME} />
              <TimePicker
                value={startTime}
                className={classes.timePicker}
                minutesStep={5}
                InputProps={{ disableUnderline: true }}
                onChange={(e) => e && setStartTime(e)}
                ampm={clockFormat === TimeClockFormat.BAD}
              />
            </div>
            <div style={{ width: "45%" }}>
              <CustomLabel label={uiString.hoursOfOperations.END_TIME} />
              <TimePicker
                className={classes.timePicker}
                value={endTime}
                InputProps={{ disableUnderline: true }}
                minutesStep={5}
                onChange={(e) => e && setEndTime(e)}
                ampm={clockFormat === TimeClockFormat.BAD}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <DialogButton
          isLoading={isSubmitting}
          color="secondary"
          fullWidth
          onClick={_onSubmit}
          label={uiString.SAVE_CHANGES}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    formSecondRow: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(3),
    },
    dialogContent: {
      paddingLeft: "25px",
      paddingRight: "25px",
    },
    selectorWidth: {
      width: "45%",
    },
    selectInput: {
      minWidth: 200,
      height: "32px",
    },
    presetsPicker: {
      width: "65%",
      marginBottom: theme.spacing(3),
    },
    timePicker: {
      width: "100%",
      "& .Mui-focused": {
        border: "1px solid var(--color-pumpkin-orange) !important",
        borderRadius: "5px",
      },

      "& .MuiInput-root": {
        border: "1px solid var(--color-light-grey)",
        height: "40px",
        borderRadius: "5px",
      },
      "& .MuiInput-input": {
        padding: "5px 8px",
      },
    },
    actions: {
      padding: theme.spacing(2, 3, 0, 3),
    },
  })
);

export default React.memo(AddTimeSlotDialog);

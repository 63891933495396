import { logout, setAccessTokenValidity, setUserTokenNotReady } from "@actions/userActions";
import { IAuthenticationReducer } from "@reducers/authenticationReducer";
import AuthService from "@services/Auth.service";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useAppSelector } from "./useAppSelector.hook";
import { isAxiosError } from "@utils";

interface IService {
  [methodName: string]: (...params) => Promise<any>;
}

export enum ErrorStatusCode {
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  SERVER_ERROR = 500,
  NOT_FOUND = 404,
  BAD_ENTITY = 422,
}

type IUseServiceErrorHandler = {
  status: ErrorStatusCode;
  handler: (err: Error | AxiosError, dispatch: Dispatch<any>, auth?: IAuthenticationReducer | null) => any;
};

const defaultErrorMiddleware: IUseServiceErrorHandler[] = [
  {
    status: ErrorStatusCode.UNAUTHORIZED,
    handler: async (err, dispatch, auth) => {
      try {
        if (auth && auth.refreshToken) {
          if (auth.isRefreshingToken) {
            // not sure yet what to do here
          } else {
            // dispatch(setAccessTokenValidity(false));
          }
        } else {
          // dispatch(logout())
        }
      } catch (error) {
        // if the refresh token request fails then bail out
        // so user can relogin manually
        // dispatch(logout())
      }
    },
  },
];

/**
 * This is an experimental hook for handling http requests
 * @param service
 * @returns
 */
const useService = <T extends IService>(
  service: () => T,
  errorMiddleware: IUseServiceErrorHandler[] = defaultErrorMiddleware
) => {
  const [httpService, _setHttpService] = useState<T>(service());
  const dispatch = useDispatch();
  const authentication = useAppSelector(state => state.authentication);

  const computeService = () => {
    const computedObj = {};
    for (const [serviceMethodName, serviceMethod] of Object.entries(
      httpService
    )) {
      computedObj[serviceMethodName] = async (...params) => {
        try {
          return await serviceMethod(...params);
        } catch (error) {
          // verify that the error comes from the axios request
          if (isAxiosError(error)) {
            let isHandled = false;
            for (let middleware of errorMiddleware) {
              if (error.response?.status === middleware.status) {
                middleware.handler(error, dispatch, authentication);
                isHandled = true;
                break;
              }
            }
            if (!isHandled) throw error as AxiosError;
          } else {
            // if the error does not come from axios then let someone else handle it
            throw error as Error;
          }
        }
      };
    }
    return computedObj as T;
  };

  return computeService();
};

export default useService;

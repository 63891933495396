import React from "react";
import * as Lib from "material-ui-audio-player";
import { GridSpacing, IconButton, makeStyles } from "@material-ui/core";

import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import PauseRoundedIcon from "@material-ui/icons/PauseRounded";
import { Replay } from "@material-ui/icons";

const AudioPlayerLibrary = Lib.default;

type IProps = {
  src: string | string[];
  elevation?: number;
  width?: string;
  variation?: "primary" | "secondary" | "default";
  spacing?: GridSpacing;
  download?: boolean;
  autoplay?: boolean;
  volume?: boolean;
  order?: "standart" | "reverse";
};

const icons = {
  PlayIcon: (props) => (
    <IconButton
      style={{
        marginRight: 5,
        marginBottom: 2,
        padding: 4,
      }}
      {...props}
    >
      <PlayArrowRoundedIcon
        style={{ width: 27, height: 27, transform: "none" }}
      />
    </IconButton>
  ),
  PauseIcon: (props) => (
    <IconButton
      style={{
        marginRight: 5,
        marginBottom: 2,
        padding: 4,
      }}
      {...props}
    >
      <PauseRoundedIcon style={{ width: 27, height: 27, transform: "none" }} />
    </IconButton>
  ),
  ReplayIcon: (props) => (
    <IconButton
      style={{
        marginRight: 5,
        marginBottom: 2,
        padding: 4,
      }}
      {...props}
    >
      <Replay style={{ width: 27, height: 27, transform: "none" }} />
    </IconButton>
  ),
};

/**
 * Wrapper around `material-ui-audio-player`
 * https://www.npmjs.com/package/material-ui-audio-player
 */
export const AudioPlayer = ({ src, ...rest }: IProps) => {
  return (
    <AudioPlayerLibrary
      elevation={1}
      width="100%"
      variation="primary"
      spacing={3}
      download={false}
      volume={false}
      autoplay={false}
      order="standart"
      icons={icons}
      src={src}
      useStyles={makeStyles((theme) => ({
        root: {
          margin: 0,
          paddingLeft: 0,
          "& svg": {
            transform: "translateY(4px)",
          },
          "& > div:last-child": {
            transform: "translateY(7px)",
          },
        },
        playIcon: {
          color: theme.palette.common.darkGrey,
          "&:hover": {
            color: theme.palette.common.darkGrey,
          },
        },
        pauseIcon: {
          color: theme.palette.common.darkGrey,
          "&:hover": {
            color: theme.palette.common.darkGrey,
          },
        },
        replayIcon: {
          color: theme.palette.common.darkGrey,
          "&:hover": {
            color: theme.palette.common.darkGrey,
          },
        },
        mainSlider: {
          color: theme.palette.common.pumpkinOrange,
          // "& .MuiSlider-rail": {
          //   color: "green",
          // },
          // "& .MuiSlider-track": {
          //   color: "pink",
          // },
          // "& .MuiSlider-thumb": {
          //   color: "cyan",
          // },
        },
      }))}
      {...rest}
    />
  );
};

import getApiConstants from "@constants/apiConstants";
import { appConfig } from "../index";
import HttpClient from "./HttpClient";

type ILoginResultData = {
  account_id: number;
  company_name: string;
  first_name: string;
  last_name: string;
  user_id: number;
  webrtc_password: string;
  webrtc_username: string;
};

type ILoginResultAuth = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

type ILoginResponse = {
  access_token: string;
  auth: ILoginResultAuth;
  data: ILoginResultData;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

export type IUserDetails = {
  accessToken: string;
  tokenExpiresAt: number;
  refreshToken: string;
  webrtcUserName: string;
  webrtcPassword: string;
  firstName: string;
  lastName: string;
  companyName: string;
  userName: string;
};

// returned from the axios request
type IRefreshTokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};
// what the auth service returns
export type IRefreshTokenPayload = {
  accessToken: string;
  refreshToken: string;
  tokenExpiresAt: number;
} 

export default function AuthService() {
  const loginUrl = getApiConstants().endpoints.REQUEST_TOKEN;
  const refreshTokenUrl = getApiConstants().endpoints.REFRESH_TOKEN;

  const { Get, Put, Post, Delete } = HttpClient();

  /**
   * Requests a user login
   *
   * @param user the user name
   * @param password the password
   * @returns a promise that can either succseed or fail
   * @returns a successfull promise returns the auhtnetication token
   * @returns a failed promise returns an error message
   */
  const Authenticate = async (user: string, password: string) => {
    try {
      const { machineId: deviceId, version, clientType } = appConfig;
      const dataBody = {
        username: user,
        password,
        device_id: deviceId,
        device_version: version,
        device_type: clientType,
      };

      const response = await Post<ILoginResponse, typeof dataBody>({
        url: loginUrl,
        data: dataBody,
        isPublic: true,
      });

      const { auth, data } = response.data;

      const {
        access_token: accessToken,
        expires_in: tokenExpiresIn,
        refresh_token: refreshToken,
      } = auth;

      const {
        webrtc_username: webrtcUserName,
        webrtc_password: webrtcPassword,
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        user_id: userId,
        account_id: accountId
      } = data;

      return {
        accessToken,
        tokenExpiresAt:
          new Date().getTime() + tokenExpiresIn * 1000 - 30 * 1000,
        refreshToken,
        webrtcUserName,
        webrtcPassword,
        firstName,
        lastName,
        companyName,
        userName: user,
        userId,
        accountId,
      };
    } catch (err) {
      throw err;
    }
  };

  /**
   * Requests a refresh token action
   * requires a refresh token to be valid
   * @param refToken: refresh token
   * @param updateAxios: whether or not to update the axios instance to use
   * the new access token
   * @returns a promise that can either succseed or fail
   * @returns a successfull promise returns the refresh token response
   * @returns a failed promise returns an error message
   */
  const RefreshAccessToken = async (
    refToken: string,
    updateAxios: boolean = false
  ) => {
    try {
      const response = await Post<
        IRefreshTokenResponse,
        {
          refresh_token: string;
        }
      >({
        url: refreshTokenUrl,
        data: {
          refresh_token: refToken,
        },
        isPublic: true,
      });

      const {
        expires_in: expiresIn,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = response.data;

      const payload: IRefreshTokenPayload = {
        tokenExpiresAt: new Date().getTime() + expiresIn * 1000 - 30 * 1000,
        accessToken,
        refreshToken,
      };

      if (updateAxios) {
        appConfig.axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
      }

      return payload;
    } catch (err) {
      throw err;
    }
  };

  return {
    Authenticate,
    RefreshAccessToken,
  };
}

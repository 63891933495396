import React from 'react';
import { Divider, DividerProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

type Props = {
  spacing?: boolean
} & Omit<DividerProps, ''>

const DialogDivider: React.FC<Props> = ({ spacing, className, ...props }) => {
  const classes = useStyles();

  return (
    <Divider {...props} className={clsx(className, spacing && classes.root)} />
  );
};

const useStyles = makeStyles({
  root: {
    margin: '25px 0',
  },
});

export default DialogDivider;
import routes, {
  MeetingsRouteActions,
  routeUrlBuilders,
} from "@constants/routes";
import { useMoxtra } from "@helpers/hooks/useMoxtra.hook";
import { useMoxtraSdk } from "@helpers/hooks/useMoxtraSdk.hook";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { push } from "connected-react-router";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputButton from "./PresentationComponents/FormComponents/InputButton";
import InputField from "./PresentationComponents/FormComponents/InputField";
import CrossIcon from "@resources/icons/cross-small.svg";
import { ADD_PARTICIPANTS } from "./Meetings/constants";
import keyConstants from "@constants/keyConstants";
import { emailRegexValidation } from "@helpers/emailRegexValidation";
import { ComponentDimensions } from "@constants/appConstants";
import appConfig from "../config/index";
import { useAuthContext } from "../context/AuthProvider";
import { useToasts } from "react-toast-notifications";
import {
  useDeleteMeetingMutation,
  useGetMeetingQuery,
  useSendEmailInviteMutation,
} from "@services/Moxtra.slice";
import {
  IScheduledMeeting,
  IScheduledMeetingDetailed,
} from "@models/Moxtra.models";
import { IMepMeetingSetupResponse } from "../global";
import MeetingInfoDialog from "./Meetings/MeetingInfoDialog";
import { isAxiosError } from "@utils";

const htmlContainerId = "#moxtra-portal";

const GENERIC_ERROR_MESSAGE = "There is an issue preventing you from joining the meeting.";

const InviteDialog = ({ open, onClose, onSubmit }) => {
  const [participantsIsValid, setParticipantsIsValid] = useState(true);
  const [participantsErrorMessage, setParticipantsErrorMessage] = useState<
    undefined | string
  >(undefined);
  const [participants, setParticipants] = useState<string[]>([]);
  const [email, setEmail] = useState<string>("");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const addEmailToList = (email) => {
    const checkEmail = email.match(emailRegexValidation);
    const emailAlreadyIncluded = participants.includes(email);
    if (checkEmail && !emailAlreadyIncluded) {
      setParticipants((prevArr) => [...prevArr, email]);
      setEmail("");
      setParticipantsErrorMessage(undefined);
      setParticipantsIsValid(true);
    } else {
      const errormsg = emailAlreadyIncluded
        ? "Email already included"
        : "Invalid Format!";
      setParticipantsErrorMessage(errormsg);
      setParticipantsIsValid(false);
    }
  };

  const onEmailEnter = (event) => {
    if (event.keyCode === keyConstants.CODE_ENTER) {
      addEmailToList(email);
    }
  };

  const submit = async () => {
    try {
      const emailIsValid =
        email.length === 0 || email.match(emailRegexValidation);
      if (!emailIsValid) {
        setParticipantsIsValid(false);
        setParticipantsErrorMessage("Invalid Format!");
      } else {
        const _participants =
          participants.length > 0
            ? participants
            : emailIsValid && email !== ""
            ? [email]
            : [];
        if (_participants.length === 0) {
          setParticipantsIsValid(false);
          setParticipantsErrorMessage("Field required!");
        } else {
          setIsLoading(true);
          await onSubmit(_participants);
          setIsLoading(false);
          onClose();
        }
      }
    } catch (error) {
      if (typeof (error as any).message === "string") {
        addToast((error as any).message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle className={classes.header}>
        Invite Participants
        <Button className={classes.closeButton} onClick={onClose}>
          <CrossIcon />
        </Button>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent style={{ minHeight: 300 }}>
        <FormControl className={classes.formControlWrapper} fullWidth>
          <InputField
            name="participants"
            value={email}
            className={classes.smallFields}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={onEmailEnter}
            placeholder={ADD_PARTICIPANTS}
            fullWidth
            error={!participantsIsValid}
            errorMessage={participantsErrorMessage}
          />
          <InputButton
            color="primary"
            onClick={() => addEmailToList(email)}
            style={{ height: 32, marginLeft: 5, alignSelf: "flex-end" }}
          >
            Add
          </InputButton>
        </FormControl>
        <List>
          {participants.map((_email, index) => (
            <ListItem key={email + index}>
              <ListItemText>{_email}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() =>
                    setParticipants((prevArr) =>
                      prevArr.filter((item) => item !== _email)
                    )
                  }
                >
                  <CrossIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions>
        <InputButton variant="outlined" onClick={onClose}>
          Cancel
        </InputButton>
        <InputButton isLoading={isLoading} onClick={submit}>
          Send Invite
        </InputButton>
      </DialogActions>
    </Dialog>
  );
};

export const Meeting = () => {
  const {
    joinMeeting,
    startMeet,
    isSDKAvailable,
    moxtraAccessToken,
  } = useMoxtraSdk(() => showErrorMessage(GENERIC_ERROR_MESSAGE));
  const dispatch = useDispatch();
  const history = useHistory();
  const { isGuest } = useMoxtra();
  const [
    sendInvites,
    { isLoading: sendingInvitesInProgress , error: invitationError},
  ] = useSendEmailInviteMutation();
  const [errorMessage, showErrorMessage] = useState<string | null>(null);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const { sessionKey, action } = useParams<{
    sessionKey?: string;
    action: MeetingsRouteActions;
  }>();
  const [skipMeetFetch, setSkipMeetFetch] = useState(true);
  const {
    data: currentMeeting,
    isLoading,
    refetch: getMeeting,
    error: getMeetingError,
  } = useGetMeetingQuery({
    meetIdentifier: sessionKey as string, moxtraAccessToken: isGuest ? moxtraAccessToken : null, skipAuth: isGuest}, {
    skip: skipMeetFetch,
    refetchOnMountOrArgChange: true,
  });

  const [deleteMeeting] = useDeleteMeetingMutation();
  const { addToast } = useToasts();
  const [meetingWindow, setMeetingWindow] = useState<IMepMeetingSetupResponse | null>(null);
  const [openJoinDialog, setOpenJoinDialog] = useState<boolean>(false);

  const joinMeetingFn = sessionKey => joinMeeting(sessionKey, htmlContainerId, {
    autoJoinAudio: false,
    autoJoinVideo: false,
  });

  const startMeetingFn = async () => {
    try {
      return await startMeet(
        {
          topic: "New Meeting",
          autoJoinVideo:
            action === MeetingsRouteActions.NEW_SCREENSHARING_MEETING,
          hideRecordingCtrl: true,
        },
        htmlContainerId
      );
    } catch (error) {
        throw error;
    }
  }

  const enableInMeetInvites = (meetingWindow: IMepMeetingSetupResponse) => {
    meetingWindow.onTapInviteInLiveMeet((event) => {
      setShowInviteDialog(true);
    });
  }
  const goHome = () => dispatch(push(routes.HOME));


  const handleMeetEnd = (
    currentMeeting: IScheduledMeetingDetailed | undefined,
  ) => (_meetEndEvent) => {
      goHome();
  };

  const initMeetingWindow = async () => {
    try {
      if (action === MeetingsRouteActions.JOIN_MEETING && sessionKey) {
        return joinMeetingFn(sessionKey);
      } else {
        return await startMeetingFn();
      }
    } catch (error) {
      showErrorMessage(GENERIC_ERROR_MESSAGE);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (isAxiosError(getMeetingError)) {
      addToast(getMeetingError.message, {
        appearance: "error",
        autoDismiss: true,
      });
      goHome();
    } else if (getMeetingError) {
      goHome();
    }
  }, [getMeetingError])

  useEffect(() => {
    if (isAxiosError(invitationError)) {
      addToast(invitationError.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [invitationError])

  useEffect(() => {
    if (isSDKAvailable && moxtraAccessToken && !meetingWindow) {
      if (action === MeetingsRouteActions.NEW_MEETING) {
        initMeetingWindow()
          .then(setMeetingWindow)
      } else if (currentMeeting && action === MeetingsRouteActions.JOIN_MEETING){
        setOpenJoinDialog(true);
      }
    }
  }, [isSDKAvailable, moxtraAccessToken, currentMeeting]);

  useEffect(() => {
    if (meetingWindow) {
      meetingWindow.onError(res => {
        goHome();
      })
      meetingWindow.onReady(event => {
        if (action === MeetingsRouteActions.NEW_MEETING || action === MeetingsRouteActions.NEW_SCREENSHARING_MEETING) {
          history.replace(
            routeUrlBuilders.MEETINGS_IFRAME(
              MeetingsRouteActions.JOIN_MEETING,
              event.meetId
            )
          );
        }
      }) 

      !isGuest && enableInMeetInvites(meetingWindow);
    }
      return () => {
        if (meetingWindow) {
          meetingWindow.destroy();
        }
      }
  }, [meetingWindow]);

  useEffect(() => {
    if (meetingWindow && currentMeeting) {
      meetingWindow.onMeetEnd(handleMeetEnd(currentMeeting));
    }
  }, [meetingWindow, currentMeeting])

  useEffect(() => {
    if (moxtraAccessToken && sessionKey) {
      setSkipMeetFetch(false);
    }
  }, [moxtraAccessToken, sessionKey])
  
  const onInvite = async (emails: string[]) => {
    try {
      if (sessionKey) {
        await sendInvites({ invitees: emails, sessionKey });
      } else {
        // we should never reach this
        console.error("session key is undefined");
      }
    } catch (error) {
      throw error;
    }
  };
  const isDesktop = appConfig.clientType === "desktop";
  const viewHeight = isDesktop
    ? `calc(100vh - ${ComponentDimensions.DESKTOP_TOOLBAR_HEIGHT}px)`
    : "100vh";
  return (
    <div>
      <div
        style={{ height: viewHeight }}
        className="moxtra-portal"
        id="moxtra-portal"
      ></div>
      <MeetingInfoDialog 
        open={openJoinDialog}
        onClose={() => setOpenJoinDialog(false)}
        enterMeeting={() => 
          {
            showErrorMessage(null);
            initMeetingWindow().then((e) => 
            {
              setMeetingWindow(e);
              setOpenJoinDialog(false);
            }
          )}
        }
        data={currentMeeting || null}
        isGuest={isGuest}
        exitMeeting={goHome}
        displayErrorMessage={errorMessage}
      />
      <InviteDialog
        open={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        onSubmit={onInvite}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: "12px 10px 9px 26px",

    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
  closeButton: {
    padding: "10px",
    minWidth: "0",
    borderRadius: "6px",
  },
  smallFields: {
    height: "32px",
  },
  formControlWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  divider: {
    margin: "25px 0",
  },
}));

import React from "react";
import { withStyles } from "@material-ui/core";
import * as Library from "@material-ui/pickers";

export const TimePicker = withStyles({
  root: {
    border: "1px solid var(--color-light-grey)",
    borderRadius: "6px",
    paddingLeft: "8px",
    height: "34px",
    margin: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "var(--color-pumpkin-orange)"
    // },
    "& button": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "13px",
    },
  },
})((props: Library.TimePickerProps) => (
  <Library.TimePicker
    InputProps={{ disableUnderline: true, ...props.InputProps }}
    {...props}
  />
));

import {
  deleteConversation,
  deleteNewConversation,
  MULTIPLE_SELECTION_TYPES,
  selectConversation,
  selectMultipleConversation,
  setTemporaryConversation,
  switchConversationSelectionMode,
  updateReadStatus,
} from "@actions/conversationActions";
import uiString from "@constants/uiString";
import { formatPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import { Box, List, ListItem, ListItemText } from "@material-ui/core";
import { ConversationData } from "@services/dataClasses";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmDialog from "./PresentationComponents/ConfirmDialog";
import { isIOS } from "@constants/appConstants";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { NEW_CONVERSATION_ID } from "@services/dataClasses/ConversationData";
import { getTextMessageAllowedForNumber } from "@services/dataClasses/TalkroutePhoneNumber";

interface MessagesContextMenuProps {
  position: any;
  showMenu: boolean;
  selectAllEntries: () => void;
  hideConversationMenu: () => void;
  conversationScrollRef: any; // ref
}

const MessagesContextMenu = ({
  position,
  showMenu,
  selectAllEntries,
  hideConversationMenu,
  conversationScrollRef,
}: MessagesContextMenuProps) => {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false);
  const multipleSelectedConversations = useAppSelector(
    (state) => state.conversations.multipleSelectedConversations
  );
  const temporaryConversation = useAppSelector(
    (state) => state.conversations.temporaryConversation
  );
  const selectedConversationId = useAppSelector(
    (state) => state.conversations.selectedConversationId
  );
  const multipleSelectionMode = useAppSelector(
    (state) => state.conversations.multipleSelectionMode
  );
  const conversations = useAppSelector((state) => state.conversations.data);

  const outgoingPhoneNumbers = useAppSelector(state => state.preferences.outgoingPhoneNumbers);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mouseEvent = isIOS ? "mousedown" : "mouseup";
    window.addEventListener(mouseEvent, clickHandler);
    return () => {
      window.removeEventListener(mouseEvent, clickHandler);
    };
  }, []);

  const { xPosMenu, yPosMenu } = position;
  /**
   * Handle selection
   */
  const handleSelection = (e, action) => {
    e.stopPropagation();
    if (!e.button) action();
  };

  const deselectAllEntries = (conversationId = "") => {
    dispatch(switchConversationSelectionMode(false));
    dispatch(
      selectMultipleConversation(conversationId, MULTIPLE_SELECTION_TYPES.NONE)
    );
  };

  const handleConversationDelete = () => {
    if (
      temporaryConversation?.conversationId === selectedConversationId
      || (temporaryConversation && multipleSelectedConversations.includes(temporaryConversation?.conversationId))
    ) {
        temporaryConversation && dispatch(deleteConversation(temporaryConversation?.conversationId));
        dispatch(setTemporaryConversation(null, false))
    }
    if (multipleSelectionMode) {
      for (const convo of multipleSelectedConversations) {
        if (convo !== null && convo !== "Talkroute") {
          if (convo === NEW_CONVERSATION_ID) {
            dispatch(deleteNewConversation());
          } else {
            dispatch(deleteConversation(convo));
          }
        }
      }
      dispatch(selectConversation("Talkroute"));
      dispatch(switchConversationSelectionMode(false));
      setOpenDeleteAllDialog(false);
    } else if (selectedConversationId !== "Talkroute") {
      if (selectedConversationId === NEW_CONVERSATION_ID) {
        dispatch(deleteNewConversation());
      } else {
        dispatch(deleteConversation(selectedConversationId));
      }
      dispatch(selectConversation("Talkroute"));
      setOpenDeleteDialog(false);
    }
  };

  const getIsEditable = () => {
    if (multipleSelectionMode) {
      for (const conversationId of multipleSelectedConversations) {
        let currentPhoneNumber = "";
    
        const currentConversation = conversations.find(
          (conversation) => conversation.conversationId === conversationId
        );
    
        if (currentConversation) {
          currentPhoneNumber = currentConversation.talkrouteNumber;
        }
    
        if (!getTextMessageAllowedForNumber(currentPhoneNumber, outgoingPhoneNumbers)) {
          return false; // If a conversation is not editable, return false immediately
        }
      }
    } else {
      let currentPhoneNumber = "";
      
      const currentConversation = conversations.find(
        (conversation) => conversation.conversationId === selectedConversationId
      );
  
      if (currentConversation) {
        currentPhoneNumber = currentConversation.talkrouteNumber;
      }
  
      if (!getTextMessageAllowedForNumber(currentPhoneNumber, outgoingPhoneNumbers)) {
        return false; // If the single selected conversation is not editable, return false
      }
    }
    
    return true; // If no conversations are uneditable, return true
  };

  useEffect(() => {
    getIsEditable();
  }, [multipleSelectedConversations, conversations, outgoingPhoneNumbers]);

  /**
   * Called when all messages shouled be marked as read
   *
   * @param conversationId is empty
   */
  const onMarkAllEntiresAsRead = () => {
    for (const convo of conversations) {
      if (
        multipleSelectedConversations.includes(convo.conversationId) &&
        convo.lastMessage.isRead === false &&
        convo.conversationId !== NEW_CONVERSATION_ID
      ) {
        dispatch(
          updateReadStatus({
            conversationId: convo.conversationId,
            readStatus: true,
          })
        );
      }
    }

    hideConversationMenu();
  };

  /**
   * Called when all messages should be marked as unread
   *
   * @param conversationId is empty
   */
  const onMarkAllEntiresAsUnread = () => {
    for (const convo of conversations) {
      if (
        multipleSelectedConversations.includes(convo.conversationId) &&
        convo.lastMessage.isRead === true &&
        convo.conversationId !== NEW_CONVERSATION_ID
      ) {
        dispatch(
          updateReadStatus({
            conversationId: convo.conversationId,
            readStatus: false,
          })
        );
      }
    }

    hideConversationMenu();
  };

  /**
   * Called when a single messages should be marked as unread
   *
   * @param selectedConversationId
   */
  const onMarkEntryAsUnread = () => {
    if (selectedConversationId !== "Talkroute") {
      dispatch(
        updateReadStatus({
          conversationId: selectedConversationId,
          readStatus: false,
        })
      );
    }
    hideConversationMenu();
  };

  /**
   * Alert if clicked on outside of conversation scroll so we could hide the context menu
   */
  const clickHandler = (event) => {
    const clickTargetIsContextMenu = ref.current?.contains(event.target);
    const deleteDialogOpened = openDeleteAllDialog || openDeleteDialog;
    if (!clickTargetIsContextMenu && !deleteDialogOpened) {
      hideConversationMenu();
    }
  };

  const _selectedConversationContactNumber = () => {
    if (!(selectedConversationId && conversations.length)) return null;

    const selectedConversationNumber = conversations.find(
      (conv) => conv.conversationId === selectedConversationId
    );

    if (!selectedConversationNumber) return null;

    return formatPhoneNumber(selectedConversationNumber?.contactNumber);
  };

  const selectedConversationContactNumber = useMemo(
    _selectedConversationContactNumber,
    [openDeleteDialog, openDeleteAllDialog]
  );

  const selectedConversationCount = multipleSelectedConversations?.filter(
    (i) => !!i
  ).length;

  return (
    <>
      <div
        className="ui vertical left pointing menu"
        ref={ref}
        style={{
          position: "fixed",
          top: yPosMenu,
          left: xPosMenu,
          zIndex: 1000,
          padding: 0,
          display: showMenu ? "block" : "none",
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <List component="nav">
          <ListItem
            button
            onMouseUp={(e) => handleSelection(e, selectAllEntries)}
          >
            <ListItemText primary={uiString.contextMenu.SELECT_ALL_ENTRIES} />
          </ListItem>
          {multipleSelectedConversations?.length > 1 && (
            <ListItem
              button
              onMouseUp={(e) => {
                handleSelection(e, deselectAllEntries);
                hideConversationMenu();
              }}
            >
              <ListItemText
                primary={uiString.contextMenu.DESELECT_ALL_ENTRIES}
              />
            </ListItem>
          )}
          {multipleSelectedConversations?.length > 1 && (
            <ListItem
              button
              disabled={!getIsEditable()}
              onMouseUp={(e) => handleSelection(e, onMarkAllEntiresAsRead)}
            >
              <ListItemText primary={uiString.contextMenu.MARK_ALL_AS_READ} />
            </ListItem>
          )}
          {multipleSelectedConversations?.length > 1 && (
            <ListItem
              button
              disabled={!getIsEditable()}
              onMouseUp={(e) => handleSelection(e, onMarkAllEntiresAsUnread)}
            >
              <ListItemText primary={uiString.contextMenu.MARK_ALL_AS_UNREAD} />
            </ListItem>
          )}
          {(
            <ListItem
              button
              disabled={!getIsEditable() && selectedConversationId !== "Talkroute"}
              onMouseUp={(e) =>
                handleSelection(e, () => {
                  setOpenDeleteDialog(true);
                })
              }
            >
              <ListItemText primary={uiString.contextMenu.DELETE} />
            </ListItem>
          )}
        </List>
      </div>
      <ConfirmDialog
        header={`${uiString.CONVERSATION_DELETE_CONFIRMATION_HEADER}${
          !multipleSelectionMode && selectedConversationContactNumber
            ? " - " + selectedConversationContactNumber
            : ""
        }`}
        content={
          selectedConversationCount > 1
            ? uiString.MULTIPLE_CONVERSATIONS_DELETE_CONFIRMATION_BODY
            : uiString.CONVERSATION_DELETE_CONFIRMATION_BODY
        }
        open={openDeleteDialog}
        confirmButtonLabel={uiString.DELETE}
        onCancel={() => {
          openDeleteDialog && setOpenDeleteDialog(false);
          openDeleteAllDialog && setOpenDeleteAllDialog(false);
        }}
        onConfirm={handleConversationDelete}
      />
    </>
  );
};

export default React.memo(MessagesContextMenu);

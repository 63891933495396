import { AudioFileEditDialog } from "@components/PresentationComponents/AudioComponents/AudioFileEditDialog";
import PlayAudioBasicDialog from "@components/PresentationComponents/AudioComponents/PlayAudioBasicDialog";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import {
  TableDeleteButton,
  TableEditButton,
  TablePlayButton,
} from "@components/PresentationComponents/TableComponents/TableHelpers";
import { ComponentDimensions } from "@constants/appConstants";
import uiString, { ApiErrorMessages } from "@constants/uiString";
import { getSimpleFormattedDate } from "@helpers/functions/dateFormatter";
import usePrevious from "@helpers/hooks/usePrevious.hook";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { IAudioFile } from "@models/AudioFiles.models";
import AudioFilesService from "@services/AudioFiles.service";
import moment from "moment";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

const musicStrings = uiString.SETTINGS_AND_PREF.AUDIO_FILES;
interface IProps {
  audioFileList: IAudioFile[];
  onEditSuccess: (file: IAudioFile) => void;
  onDeleteSuccess: (id: string) => void;
}

const AudioFilesTable = ({
  audioFileList,
  onEditSuccess,
  onDeleteSuccess,
}: IProps) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IAudioFile | null>(null);
  const { DeleteOne } = useService(AudioFilesService);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<IAudioFile | null>(null);
  const prevFileToDelete = usePrevious(fileToDelete);
  const { addToast } = useToasts();
  const closeEditModal = () => setSelectedFile(null);

  const openEditModal = (file: IAudioFile) => {
    setSelectedFile(file);
  };

  const openPlayModal = (file: IAudioFile) => {
    setOpenPreview(true);
    setSelectedFile(file);
  };

  const closePlayModal = () => {
    setSelectedFile(null);
    setOpenPreview(false);
  };

  const handleDelete = async (id: string) => {
    try {
      setIsDeleting(true);
      await DeleteOne(id);
      setIsDeleting(false);
      setFileToDelete(null);
      onDeleteSuccess(id);
    } catch (error) {
      setIsDeleting(false);
      setFileToDelete(null);
      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  return (
    <>
      <PlainTable
        data={audioFileList}
        selectColumns={["description", "created"]}
        columnSize={["30%", "auto"]}
        dataMapper={(audioFile) => ({
          ...audioFile,
          description: audioFile.name,
          created: getSimpleFormattedDate(moment(audioFile.createdAt)) || 'N/A'
        })}
        colSettings={[{
          index: 2,
          centerHeader: true,
        }]}
        groupBy={"type"}
        actions={[
          {
            colLabel: "Play",
            width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
            ActionComponent: (row) => (
              <TablePlayButton
                row={row}
                handler={(file) => openPlayModal(file)}
                downloadUrl={row.link}
                id={row.id}
                disableDelete={false}
              />
            )
          },
          {
            colLabel: "Settings",
            width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
            ActionComponent: (row) => {
              return !row.isDefault ? (
                <TableEditButton
                  row={row}
                  handler={(file) => openEditModal(file)}
                />
              ) : null
            },
          },
          {
            colLabel: "Delete",
            width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
            ActionComponent: (row) => {
              return !row.isDefault ? (
                <TableDeleteButton
                  row={row}
                  handler={(file) => setFileToDelete(file)}
                />
              ) : null
            },
          },
        ]}
        fullWidth
      />
      <AudioFileEditDialog
        open={!!selectedFile && !openPreview}
        close={closeEditModal}
        audioFile={selectedFile as IAudioFile} // only open if selectedFile is valid
        onEdit={onEditSuccess}
      />
      <PlayAudioBasicDialog
        open={openPreview}
        close={closePlayModal}
        audioSrc={selectedFile?.link}
        autoplay
      />
      <ConfirmDialog
        header={uiString.DELETE_AUDIO_FILE_CONFIRMATION_HEADER}
        content={uiString.DELETE_AUDIO_FILE_CONFIRMATION_BODY}
        open={Boolean(fileToDelete)}
        confirmButtonLabel={uiString.DELETE}
        loading={isDeleting}
        onCancel={() => setFileToDelete(null)}
        disabled={Boolean(fileToDelete?.usedBy || prevFileToDelete)}
        disabledMessage={musicStrings.DELETE_DIALOG.FILE_USED_MSG}
        onConfirm={() => fileToDelete && handleDelete(fileToDelete.id)}
      />
    </>
  );
};

export default React.memo(AudioFilesTable);

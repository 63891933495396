import React, { useState } from "react";
import { Select, MenuItem, makeStyles, FormHelperText, SelectProps, Grid } from "@material-ui/core";
import clsx from "clsx";
import FilterChevron from "@resources/icons/filter-chevron.svg";
import CustomLabel from "./FormComponents/CustomLabel";
import { InputErrorText } from "./FormComponents/InputErrorText";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import { OptionsType } from "@models/Additional.models";

type Props = {
  id?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  value?: any;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
}>) => void;
  options: OptionsType[];
  dropdownStyle?: React.CSSProperties;
  lightColor?: boolean;
  boldColor?: boolean;
  smallIcon?: boolean;
  label? : any;
  outlined?: boolean;
  helperText?: string;
  errorMessage?: string;
  wrapperClass?: string;
  fullWidth?: boolean;
  wrapperStyles?: React.CSSProperties;
} & Omit<SelectProps, "smallIcon" | "onChange">;

const CustomExpandMore = ({ smallIcon, openDropdown, ...rest }) => {
  return (
    <FilterChevron
      style={{
        width: smallIcon ? 14 : 22,
        height: smallIcon ? 8 : 10,
        transform: clsx(
          smallIcon ? "translateY(-1px)" : "translateY(0)",
          openDropdown ? 'rotate(180deg)' : '',
        ),
        right: smallIcon ? 8 : 0,
        top: '40%',
        color: 'var(--color-dark-grey)'
      }}
      {...rest}
    />
  );
};

const DropdownComponent: React.FC<Props> = ({
  className, disabled = false, lightColor = true, value, placeholder, onChange, options,
  boldColor, name, dropdownStyle, smallIcon = false, helperText, errorMessage, wrapperClass,
  wrapperStyles, label, outlined, fullWidth, error, ...rest
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const classes = useStyles();
  let displayedTypes: string[] = [];
  const handleClose = () => {
    setOpenDropdown(false);
  };

  return (
    <Grid style={wrapperStyles} className={wrapperClass}>
    {label && <CustomLabel label={label} />}
    <Select
      name={name}
      className={clsx(
        className,
        classes.wrapper,
        outlined && classes.outlinedWrapper,
        lightColor && classes.lightColor,
        boldColor && classes.boldColor
        )}
      fullWidth={fullWidth}
      onOpen={() => setOpenDropdown(true)}
      onClose={() => handleClose()}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={(event) => onChange(event)}
      inputProps={{ "data-testid": "dropdown-test-id" }}
      IconComponent={(props) => (
        <CustomExpandMore {...props} smallIcon={smallIcon} openDropdown={openDropdown} />
        )}
      style={dropdownStyle}
      disableUnderline
      {...rest}
    >
      {options.map(({ value, text, type }, index) => {
        if (type && !displayedTypes.includes(type)) {
          displayedTypes.push(type)
          return [
            <MenuItem className={classes.dropdownTitle} disabled key={createKeyIndex(index, type)}>
              {type}
            </MenuItem>,
            <MenuItem key={createKeyIndex(index, text)} value={value}>
              {text}
            </MenuItem>
          ]
        } 
        return (
          <MenuItem key={createKeyIndex(index, text)} value={value}>
            {text}
          </MenuItem>
        )
      })}
    </Select>
     {error && 
        <InputErrorText standalone style={{ fontSize: '0.75rem' }}>
          {errorMessage}
        </InputErrorText>
      }
      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {

    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
    "& .MuiSelect-select": {
      paddingRight: 34,
    },
    "& .MuiSelect-icon": {
      top: "30%",
    },
    "& .MuiSelect-root": {
      fontWeight: 400,
    },
  },
  outlinedWrapper: {
    "& .MuiSelect-select:focus": {
      borderColor: theme.palette.common.pumpkinOrange,
      borderRadius: "5px",
    },
    "& .MuiSelect-select": {
      paddingLeft: "9px",
      border: "1px solid",
      borderColor: theme.palette.common.darkGrey,
      borderRadius: 5
    },
  },
  smallIcon: {
    "& .MuiSelect-icon": {
      width: "14px",
      height: "8px",
    },
  },
  lightColor: {
    "& .MuiSelect-root": {
      fontWeight: 300,
      fontFamily: "Lato, sans-serif",
    },
  },
  boldColor: {
    "& .MuiSelect-root": {
      fontWeight: 400,
      fontFamily: "Lato, sans-serif",
    },
  },
  dropdownTitle: {
    backgroundColor: theme.palette.common.lightGrey,
    opacity: "1 !important",
    fontWeight: "bold"
  }
}));

export default DropdownComponent;

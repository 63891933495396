import {
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import uiString from "@constants/uiString";
import routes from "../../constants/routes";
import { DeselectIcon } from "@components/Icons/Deselect.icon";
import { UnreadIcon } from "@components/Icons/Unread.icon";
import { ReadIcon } from "@components/Icons/Read.icon";
import { BinIcon } from "@components/Icons/Bin.icon";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import VoiceMessagesService from "@services/VoiceMessages.service";
import { fetchVoicemails, findVoicemailIndexById, showVoicemailDetails, updateReadStatus } from "@actions/voicemailActions";
import { getEditableForId } from "../../services/dataClasses/VoicemailMailbox";
import useService from "@helpers/hooks/useService.hook";
import useVoicemailSelector from "@helpers/hooks/useVoicemailSelector.hook";
import useMetadata from "@helpers/hooks/useMetadata.hook";
import { WELCOME_VOICEMAIL } from "@services/dataClasses/VoicemailData";
import Tooltip from '@components/PresentationComponents/FormComponents/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "400px",
      height: "200px",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    actionRow: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    counter: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
  })
);

// TODO: Rethink this component.
// ! It hardcodes way too many functions that will cause problems down the line if they are not abstracted properly
export const SelectedVoicemails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const voicemails = useAppSelector((state) => state.voicemails.data);
  const callHistory = useAppSelector((state) => state.callHistory.data);
  const selectedItems = useAppSelector(
    state => state.itemSelection.selectedVoicemails
  );
  const { Delete } = useService(VoiceMessagesService);
  const {selectItem, syncSelectedElementCopyWithSourceElement} = useVoicemailSelector(voicemails);
  const { markWelcomeVoicemailAsDeleted, markWelcomeVoicemailAsRead } = useMetadata();
  const mailboxes = useAppSelector((state) => state.voicemails.mailboxes);

  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const newIsEditable = selectedItems.every((entry) => {
      const editable = getEditableForId(entry.mailboxId, mailboxes);
      return editable;
    });
    setIsEditable(newIsEditable);
  }, [selectedItems, mailboxes]);

  useEffect(() => {
    if (selectedItems?.length === 0) deselectAllEntries();
  }, [selectedItems?.length]);

  const deselectAllEntries = () => {
    if (voicemails.length) {
      const welcomeVoicemailId = findVoicemailIndexById({
        voicemails,
        voicemailId: 1
      });
      syncSelectedElementCopyWithSourceElement(voicemails[Math.max(0, welcomeVoicemailId)]);
      selectItem(voicemails[Math.max(0, welcomeVoicemailId)]);
      dispatch(showVoicemailDetails(callHistory, voicemails[Math.max(0, welcomeVoicemailId)], routes.VOICEMAIL));
    }
  };

  const deleteSelectedMessage = async () => {
  // TODO: refactor once batch delete endpoint is available
    for (const item of selectedItems) {
      if (item.id !== 1) {
        await Delete(String(item.id));
      }
    }
    dispatch(fetchVoicemails(false, true));
    deselectAllEntries();
    setOpenDeleteDialog(false);
  };

  const markAsReadSelectedMessage = () => {
  // TODO: refactor once batch updateStatus endpoint is available
    for (const item of selectedItems) {
      if (!item.messageRead) {
        dispatch(updateReadStatus({voicemailId: item.id, readStatus: true}));
      }
      if (item.id === WELCOME_VOICEMAIL) markWelcomeVoicemailAsRead();
    }
    deselectAllEntries();
  };

  const markAsUnreadSelectedMessage = () => {
  // TODO: refactor once batch updateStatus endpoint is available
    for (const item of selectedItems) {
      if (item.messageRead) {
        dispatch(updateReadStatus({voicemailId: item.id, readStatus: false}));
      }
      if (item.id === WELCOME_VOICEMAIL) markWelcomeVoicemailAsRead(false);
    }
    deselectAllEntries();
  };

  const selectedVoicemailCount = selectedItems?.filter(
    (i) => !!i
  ).length;
  return (
    <Paper className={classes.root}>
      <div className={classes.counter}>
        <Typography variant="h3" align="center">
          {selectedVoicemailCount}
        </Typography>
        <Typography variant="h6" align="center">
          Voicemails Selected
        </Typography>
      </div>
      <div className={classes.actionRow}>
        <Tooltip title="Delete">
          <IconButton
            style={{
              color: "var(--color-bright-light-blue)",
              opacity: !isEditable ? .45 : 1
            }}
            aria-label="delete message"
            component="span"
            onClick={() => setOpenDeleteDialog(true)}
            disabled={!isEditable}
          >
            <BinIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mark as Read">
          <IconButton
            style={{
              color: "var(--color-bright-light-blue)",
              opacity: !isEditable ? .45 : 1
            }}
            aria-label="mark as read"
            component="span"
            onClick={markAsReadSelectedMessage}
            disabled={!isEditable}
          >
            <ReadIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mark as Unread">
          <IconButton
            style={{
              color: "var(--color-bright-light-blue)",
              opacity: !isEditable ? .45 : 1
            }}
            aria-label="mark as unread"
            component="span"
            onClick={markAsUnreadSelectedMessage}
            disabled={!isEditable}
          >
            <UnreadIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Deselect">
          <IconButton
            style={{color: "var(--color-bright-light-blue)"}}
            aria-label="deselect"
            component="span"
            onClick={deselectAllEntries}
          >
            <DeselectIcon fontSize="default" />
          </IconButton>
        </Tooltip>
      </div>
      <ConfirmDialog
        header={uiString.VOICEMESSAGE_DELETE_CONFIRMATION_HEADER}
        content={
          selectedVoicemailCount > 1
            ? uiString.MULTIPLE_VOICEMESSAGE_DELETE_CONFIRMATION_BODY
            : uiString.VOICEMESSAGE_DELETE_CONFIRMATION_BODY
        }
        open={openDeleteDialog}
        confirmButtonLabel={uiString.DELETE}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={deleteSelectedMessage}
      />
    </Paper>
  );
};

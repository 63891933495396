import { ComponentDimensions } from "@constants/appConstants";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import {
  Divider,
  Link,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import appConfig from "../../config";
import {
  NavigationItems,
  historyItems,
} from "../../constants/navItems";
import uiString from "../../constants/uiString";
import HistoryAndReportingNavItem from "./HistoryAndReportingNavItem";
import ExitRightIcon from "@resources/icons/exit-right.svg";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";

type Props = {
  handleItemClick: (name: string) => void;
  activeItem: string;
};

/**
 * Draw the navigation items
 */
const HistoryAndReportingNavigation: React.FC<Props> = ({
  handleItemClick,
  activeItem,
}) => {
  const classes = useStyles();
  const dividerStyle = {
    marginBottom: ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  };
  const userRole = useAppSelector((state) => state.authentication.userRole);
  /**
   * Draws each list item
   * @param items object with list of navigation pages
   */
  const showNavItems = (items: NavigationItems) => {
    const userIsAdmin = userRole === "Account Admin";
    const itemsToShow = Object.values(items);
    return itemsToShow.map(({ value, adminOnly, link, domId, Component, desktopOnly }, index) => {
      if (appConfig.clientType === "web" && desktopOnly) {
        return null
      }
      const key = createKeyIndex(index, `${value}-${link}`);
      const itemIsAvailable = userIsAdmin || !adminOnly;

      if (!itemIsAvailable) return '';
      let Content = (
        <HistoryAndReportingNavItem
          disabled={!itemIsAvailable}
          key={key}
          id={domId}
          onClick={(name) => itemIsAvailable && handleItemClick(name)}
          activeItem={activeItem}
          value={value}
        />
      );

      if (Component === null)
        Content = (
          <Link
            key={key}
            id={domId}
            className={clsx(classes.link, !itemIsAvailable && classes.disabled)}
            // href={itemIsAvailable ? link : undefined}
            style={{cursor: "pointer"}}
            onClick={() => itemIsAvailable && window.open(link, '_blank')}
          >
            {value}
            <ExitRightIcon style={{ marginLeft: "12px", pointerEvents: "none"}} />
          </Link>
        );

      return itemIsAvailable ? (
        Content
      ) : (
        <Tooltip styling="standard" placement="bottom" title="Available Only To Admins">
          <div>{Content}</div>
        </Tooltip>
      );
    }).filter(item => item !== null);
  };

  return (
    <List className={classes.navList} component="nav">
      <Typography
        variant="h6"
        className={classes.navSectionHeader}
      >
        {uiString.preferences.HISTORY_AND_REPORTING}
      </Typography>
      <Divider style={dividerStyle} />
      {showNavItems(historyItems)}
    </List>
  );
};

const useStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    alignItems: "center",
    fontSize: ComponentDimensions.PREFERENCES_NAVIGATION_LINK_FONT_SIZE,
    lineHeight: "normal",
    padding: "8px 16px",
    width: "100%",
    color: theme.palette.common.charcoal,
    "&:hover": {
      color: theme.palette.common.charcoal,
      backgroundColor: "white",
      textDecoration: "none",
    },
  },
  disabled: {
    opacity: 0.5,
    "&:hover": {
      background: "none"
    }
  },
  navList: {
    paddingTop: 0,
  },
  navSectionHeader: {
    fontSize: ComponentDimensions.PREFERENCES_PAGE_HEADER_FONT_SIZE,
    paddingBottom: ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN,
    color: theme.palette.common.textBlack,
    lineHeight: "21px",
  }
}));

export default HistoryAndReportingNavigation;
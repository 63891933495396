import { createStyles, makeStyles, ListItem } from '@material-ui/core';
import React from 'react'
import Icon from '../components/Icon';
import clsx from 'clsx'

type Props = {
  id: string
  item: string
  IconSvg: JSX.Element
  missedCount: number
  activeItem: string
  shouldDisableMenu: boolean
  handleClick: (value: string) => void
  className?: string
}

/**
   * Build the content of each menu item
   *
   * @param item a string with the menu item name
   * @param iconName a string with the icon name for the menu item
   * @param missedCount the count of missed items the menu contains
   * if 0, the label is not displayed
   * @param activeItem the currently active menu item of type menuItems
   * @param shouldDisableMenu disable the menu if true passed in, enable it otherwise
   * @param handleClick function that changes the route on click
   */
const MenuItemBuilder: React.FC<Props> = ({
  id, item, IconSvg, missedCount, activeItem, shouldDisableMenu, handleClick, className
}) => {
  const isActive = activeItem === item
  const classes = useStyles()
  
  return (
    <ListItem
      button
      id={id}
      disabled={shouldDisableMenu}
      onClick={() => handleClick(item)}
      className={clsx(className, isActive ? "text-normal pref-nav-item-active" : "text-normal")}>
      <div
        className={classes.sideButton}>
        <div className={classes.sideButtonIcon}>
          {IconSvg}
        </div>
        <div className={classes.sideButtonText}>
          <div>{item}</div>
          {missedCount !== 0 && <div className={classes.sideButtonCounter}>
            <span className={classes.sideButtonCounterText}>
              {missedCount}
            </span>
          </div>}
        </div>
      </div>
    </ListItem>
  );
};

const useStyles = makeStyles(
  createStyles({
    sideButton: {
      display: 'flex',
      width: 'fit-content',
      cursor: 'pointer',
      alignItems: 'center',
      padding: '0 1em',
      fontSize: '19px'
    },
    sideButtonIcon: {
      marginRight: '1em',
      minWidth: '30px',
      textAlign: 'center'
    },
    sideButtonText: {
      display: 'flex',
      alignItems: 'center'
    },
    sideButtonCounter: {
      width: '100%',
      minWidth: 22,
      height: '22px',
      // minWidth: '1.5em',
      minHeight: '1.5em',
      marginLeft: '0.5em',
      marginTop: '-1.4em',
      padding: '0.4em',
      lineHeight: '0.8em',
      textAlign: 'center',
      borderRadius: '500rem',
      backgroundColor: 'var(--color-pumpkin-orange)',
      color: 'white',
      fontFamily: 'Lato, sans-serif',
      fontSize: 'calc(0.8em - 1px)',
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    sideButtonCounterText: {
      display: "inline-block"
    }
  })
)

export default React.memo(MenuItemBuilder)

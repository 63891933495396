import getApiConstants from "@constants/apiConstants";
import { IApiResponse } from "@models/IPagination";
import { IScheduledMeeting, IScheduledMeetingDetailed } from "@models/Moxtra.models";
import HttpClient from "./HttpClient";

export type IScheduledMeetingRecurrence = {
  frequency?: string;
  endTime?: string;
  daily?: {
    interval: number;
  };
  weekly?: {
    interval: number;
    weekDays:  "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"[];
  };
  monthly?: {
    monthDays: number;
  };
};

export type IScheduleMeetingOptions = {
  name: string;
  starts?: string;
  ends?: string;
  startTime?: number;
  endTime?: number;
  agenda?: string;
  originalBinderId?: string;
  autoRecording?: boolean;
  hideRecordingCtrl?: boolean;
  joinBeforeMinutes?: number;
  recordMultipleVideoChannel?: boolean;
  tags?: [
    {
      name: string;
      value: string;
    }
  ];
  notificationOff?: boolean;
  hostVideoOn?: boolean;
  participantVideoOn?: boolean;
  recurrence?: IScheduledMeetingRecurrence;
};

export default function MoxtraService() {
  const getTokenUrl = getApiConstants().endpoints.moxtrav2.GET_ACCESS_TOKEN;
  const sendEmailInviteUrl = getApiConstants().endpoints.moxtrav2
    .SEND_EMAIL_INVITATION;
  const baseUrl = getApiConstants().endpoints.moxtrav2.BASE;
  const getGuestTokenUrl = getApiConstants().endpoints.moxtrav2
    .GET_GUEST_ACCESS_TOKEN;

  const { Get, Post, Delete } = HttpClient();

  const GetAccessToken = async (resourceId: string, accessToken?: string) => {
    try {
      const params = accessToken ? { url: getTokenUrl(resourceId), headers: {
        Authorization: `Bearer ${accessToken}`
      }
     } : { url: getTokenUrl(resourceId) }
      const { data } = await Get<{
        data: { access_token: string; expires_in: string };

      }>(params);
      return data.data;
    } catch (err) {
      throw err;
    }
  };

  const GetAccessTokenForGuest = async () => {
    try {
      const { data } = await Get<{
        data: { access_token: string; expires_in: string };
      }>({ url: getGuestTokenUrl });
      return data.data;
    } catch (err) {
      throw err;
    }
  };

  const SendEmailInvite = async (invitees: string[], sessionKey: string) => {
    try {
      const body = {
        invitees,
      };
      const response = await Post<void, typeof body>({
        url: sendEmailInviteUrl(sessionKey),
        data: body,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  const ScheduleMeetings = async (options: IScheduleMeetingOptions) => {
    try {
        const { data } = await Post<{sessionKey: string}, IScheduleMeetingOptions>({url: baseUrl, data: options})
        return data;
    } catch (error) {
        throw error;
    }
  };

  const GetMeetings = async () => {
    try {
      const { data } = await Get<IApiResponse<IScheduledMeeting[]>>({
        url: baseUrl,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const GetMeetingBySessionKey = async (sessionKey: string) => {
    try {
      const { data } = await Get<IApiResponse<IScheduledMeetingDetailed>>({
        url: baseUrl + '/' + sessionKey,
      });
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const DeleteMeeting = async (sessionKey: string) => {
    try {
      return await Delete({
        url: baseUrl,
        id: sessionKey
      })
    } catch (error) {
      throw error;
    }
  }
  return {
    GetAccessToken,
    GetAccessTokenForGuest,
    SendEmailInvite,
    ScheduleMeetings,
    GetMeetings,
    DeleteMeeting,
    GetMeetingBySessionKey
  };
}

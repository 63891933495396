import { SvgIcon, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import React from "react";

export const UnreadIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = (
  props
) => (
  <SvgIcon viewBox="0 0 640 640" {...props}>
    <g id="icomoon-ignore"></g>
    <path d="M573.038 177.661l-233.39-142.627c-9.74-5.953-22.401-9.231-35.648-9.231s-25.908 3.278-35.648 9.231l-233.39 142.627c-19.932 12.18-34.962 38.981-34.962 62.339v288c0 26.468 21.533 48 48 48h512c26.468 0 48-21.532 48-48v-288c0-23.358-15.030-50.158-34.962-62.339zM51.648 204.966l233.39-142.627c4.717-2.882 11.628-4.536 18.962-4.536s14.245 1.653 18.962 4.536l233.39 142.626c4.967 3.036 9.717 8.503 13.304 14.701l-247.905 165.272c-9.289 6.192-26.211 6.192-35.501 0l-247.905-165.271c3.587-6.198 8.336-11.666 13.303-14.701zM560 544h-512c-8.822 0-16-7.178-16-16v-274.104l236.5 157.666c9.953 6.636 22.727 9.954 35.5 9.954s25.548-3.318 35.501-9.954l236.499-157.666v274.104c0 8.822-7.178 16-16 16z"></path>
  </SvgIcon>
);

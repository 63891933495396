import React, { useEffect, useState } from 'react';
import Dialog from '@components/PresentationComponents/Dialog/Dialog';
import DialogButton from '@components/PresentationComponents/Dialog/DialogButton';
import { DialogActions, DialogContent, DialogContentText, makeStyles, Theme } from '@material-ui/core';
import keyConstants from '@constants/keyConstants';
import { UNBLOCK_PHONE_NUMBER } from './constants';
import uiString, { ApiErrorMessages } from '@constants/uiString';
import { formatPhoneNumber } from '@helpers/functions/phoneNumberFormatter';
import CallBlockedNumbersService from '@services/BlockedNumberService.service';
import useService, { ErrorStatusCode } from '@helpers/hooks/useService.hook';
import { useDispatch } from 'react-redux';
import { fetchBlockedNumbers } from '@actions/callActions';
import { useToasts } from 'react-toast-notifications';


type Props = {
    open: boolean;
    phonenumber: string,
    onClose: () => void;
    afterUnblock: () => void;
};

const UnblockNumberDialog: React.FC<Props> = ({ open, phonenumber, onClose, afterUnblock }) => {

    const { UnBlockNumber } = useService(CallBlockedNumbersService);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const keyDownHandler = (e) => {
        e.keyCode === keyConstants.CODE_ENTER && handleSubmit();
        e.keyCode === keyConstants.CODE_ESCAPE && onClose();
    };

    /**
     * Unblock input number and updates blocked numbers array
     */
    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            await UnBlockNumber(phonenumber);
            dispatch(fetchBlockedNumbers());
            afterUnblock();
            setIsSubmitting(false);
            onClose();
          } catch (error) {
            setIsSubmitting(false);
            if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
              addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
            } else {
              addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
              throw error;
            }
        }
    };

    return (
        <Dialog
            title={ UNBLOCK_PHONE_NUMBER }
            open={ open }
            onClose={ onClose }
            onKeyDown={ (e) => keyDownHandler(e) }
            maxWidth="sm"
        >
            <DialogContent className={ classes.contentArea }>
                <DialogContentText>{ uiString.unBlockNumberModal.DESC }</DialogContentText>
                <DialogContentText className={ classes.emphasize }>{ formatPhoneNumber(phonenumber) }</DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogButton
                    label={ uiString.unBlockNumberModal.CONFIRM }
                    isLoading={ isSubmitting }
                    disabled={ isSubmitting }
                    color="secondary"
                    onClick={ handleSubmit }
                    fullWidth
                />
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    contentArea: {
        padding: "2em 2em 1em"
    },
    emphasize: {
        fontWeight: "bold",
        textAlign: "center"
    }
}));

export default UnblockNumberDialog;
import { REHYDRATE } from "redux-persist";
import { IContact } from "@models/Contacts.models";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ContactsService from "@services/Contacts.service";
export interface IContactsReducer {
  // data: IContact[];
  error: null | string;
  shouldMergeToCallhistoy: boolean;
  shouldMergeToVoicemailList: boolean;
  shouldMergeToConversations: boolean;
}

const initialState: IContactsReducer = {
  // data: [],
  error: null,
  shouldMergeToVoicemailList: false,
  shouldMergeToConversations: false,
  shouldMergeToCallhistoy: false,
};

export const fetchAllContacts = createAsyncThunk("contacts/fetchAll", async () => {
  const response = await ContactsService().GetContacts();
  return response.data;
});


const contactsSlice = createSlice({
  name: "contacts",
  initialState: initialState,
  reducers: {
    contactsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    contactsLoading: (state, _action: PayloadAction<void>) => {
      state.error = null;
    },
    saveContacts: (state, action: PayloadAction<IContact[]>) => {
      // state.data = action.payload;
    },
    mergeContactsToCallHistory: (state, action: PayloadAction<boolean>) => {
      state.shouldMergeToCallhistoy = action.payload;
    },
    mergeContactsToVoicemailList: (state, action: PayloadAction<boolean>) => {
      state.shouldMergeToVoicemailList = action.payload;
    },
    mergeContactsToConversations: (state, action: PayloadAction<boolean>) => {
      state.shouldMergeToCallhistoy = action.payload;
    },
    updateContact: (state, action: PayloadAction<IContact>) => {
      const contactToUpdate = action.payload;
      state.shouldMergeToCallhistoy = true;
      state.shouldMergeToVoicemailList = true;
      state.shouldMergeToCallhistoy = true;
      // state.data = state.data.map(contact => contact.id === contactToUpdate.id ? contactToUpdate : contact);
     },
    addContact: (state, action: PayloadAction<IContact>) => {
      state.shouldMergeToCallhistoy = true;
      state.shouldMergeToVoicemailList = true;
      state.shouldMergeToCallhistoy = true;
      const contactToAdd = action.payload;
      // state.data.push(contactToAdd);
     },
    removeContact: (state, action: PayloadAction<IContact>) => {
      state.shouldMergeToCallhistoy = true;
      state.shouldMergeToVoicemailList = true;
      state.shouldMergeToCallhistoy = true;
      const contactToAdd = action.payload;
      // state.data = state.data.filter(contact => contactToAdd.id !== contact.id)
     },
     refreshPhonenumbers: (state) => {
      state.shouldMergeToCallhistoy = true;
      state.shouldMergeToVoicemailList = true;
      state.shouldMergeToCallhistoy = true;
     }
  },
  extraReducers: (builder) => {
    builder
      .addCase(REHYDRATE, (state, action: any) => {
        // A redux-persist action. Called immediately after launch. ContactsData objects are restored as generic Object by default.
        // Using a rehydrator function to restore to ContactsData objects. Rehydrator checks for empty objects.
        if (action.payload && action.payload.contactsReducer) {
          // state.data = action.payload.contactsReducer.data;
          state.shouldMergeToCallhistoy = true;
          state.shouldMergeToVoicemailList = true;
          state.shouldMergeToCallhistoy = true;        }
      })
      .addCase(fetchAllContacts.fulfilled, (state, action) => {
      //  state.data = action.payload;
      })
      .addCase(fetchAllContacts.rejected, (state, action) => {
        state.error = action.error.message ?? "There was an error";
      })
  },
});

const { actions, reducer: contactReducer } = contactsSlice;

export const {
   contactsError,
  contactsLoading,
  // addContact,
  // saveContacts,
  // removeContact,
  // updateContact,
  refreshPhonenumbers,
  mergeContactsToCallHistory,
  mergeContactsToVoicemailList,
  mergeContactsToConversations,
} = actions;

export default contactReducer;

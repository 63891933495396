import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import usePrevious from "@helpers/hooks/usePrevious.hook";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";
import { MOXTRA_DELETE_MEETING_GENERIC_ERROR } from "./constants";

type IProps = {
  open: boolean;
  onClose: () => void;
  sessionKey: string;
  handleDelete: () => void;
};
export const DeleteMeetingDialog = ({
  open,
  onClose,
  sessionKey,
  handleDelete,
}: IProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const prevSessionKey = usePrevious(sessionKey);
  const [error, setError] = useState<string | null>(null)
  const _handleDelete = async () => {
    try {
      setError(null);
      // setIsDeleting(true);
      handleDelete();
      // setIsDeleting(false);
      onClose()
    } catch (error) {
      setIsDeleting(false);
      if ((error as any).message) {
        setError((error as any).message)
      } else {
        setError(MOXTRA_DELETE_MEETING_GENERIC_ERROR)
      }
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <div>The following meeting will be deleted - <b>{sessionKey || prevSessionKey}</b></div>
        {error && (
            <Alert style={{ marginTop: 15 }} severity="error">
              <AlertTitle>Error</AlertTitle> {error}{" "}
            </Alert>
          )}

      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <InputButton onClick={onClose} variant="outlined" color="primary">
            Cancel
          </InputButton>
          <InputButton
            onClick={() => _handleDelete().then()}
            variant="contained"
            color="primary"
            isLoading={isDeleting}
            disabled={isDeleting}
          >
            Delete
          </InputButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

import { DialogContentText, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import uiString from "@constants/uiString";
import { IMoxtraUserInfo } from '@models/Moxtra.models';

type Props = {
  user: IMoxtraUserInfo;
  isAvailable: boolean;
};

const UserInfoBox: React.FC<Props> = (
  { user, isAvailable }
) => {
  const  { email, host, name } = user;
  const classes = useStyles();

  const splitName: string[] | null = useMemo(() => {
    return name ? name.split(' ') : null;
  }, [user.name])

  const firstName = splitName ? splitName[0] : null;
  const lastName = splitName ? splitName[1] : null;
  const nameInitials = 
    lastName && 
    firstName && 
    lastName.length &&
    firstName.length ? firstName[0] + lastName[0] : firstName ? firstName.slice(0,2) : "Us";

  return (
    <div className={classes.inviteeWrapper}>
      <span className={classes.avatar}>{nameInitials}</span>
      {isAvailable 
        ? <CheckIcon className={classes.checkIcon}/> 
        : null}
      <DialogContentText 
        className={classes.name}
      >
        <b>{name}</b>
        {host && <span  className={classes.host}>({uiString.HOST})</span>}
      </DialogContentText>
      <DialogContentText
        className={classes.email}
      >
        <span>{email}</span>
      </DialogContentText>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  inviteeWrapper: {
    position: 'relative',
    display: 'grid',
    gridTemplateAreas: `
      'img name host'
      'img email host'
    `,
    inlineSize: 'fit-content',
    paddingBottom: '8px',
  },
  avatar: {
    gridArea: 'img',
    width: '42px',
    height: '42px',
    marginRight: '14px',
    border: '1px solid var(--color-dark-grey)',
    background: "linear-gradient(315deg, #485461 0%, #414042 84%)",
    borderRadius: 8,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '17px',
    textTransform: 'uppercase',
  },
  name: {
    gridArea: 'name',
    margin: 0,
  },
  email: {
    gridArea: 'email',
    margin: 0,
  },
  host: {
    paddingLeft: '5px',
  },
  checkIcon: {
    color: 'green',
    background: 'lightgreen',
    borderRadius: '50%',
    position: 'absolute',
    top: '26px',
    left: '28px',
  },
}))

export default React.memo(UserInfoBox);

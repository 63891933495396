import { IconButtonProps, CircularProgress, IconButton } from "@material-ui/core";
import React from "react";

interface IProps extends IconButtonProps {
  loading?: boolean;
  children: JSX.Element;
}
/**
 * Similar to materials `IconButton` component, with the difference that
 * it contains a `loading` prop as well as all the standard `IconButton` props (color, onClick, variant...)
 * A `CircularProgress` indicator appears inside the button when `loading` is set to true. Perfect for buttons
 * handling asynchronous actions that may need a UI indicator for the pending state
 * @param props.loading - boolean
 * @returns JSX.Element
 */
export const FormIconButton = ({ loading, children, color, ...rest }: IProps) => {
  // we can't directly pass the button color prop to CircularProgress
  // due to their type compatibiltiy
  const circularProgressColor: "inherit" | "primary" | "secondary" | undefined =
    color as string | undefined === "defaut" ? undefined : color as any;
  return (
    <IconButton disabled={loading} {...rest} color={color}>
      {loading ? <CircularProgress color={circularProgressColor} size={14} /> : children}
    </IconButton>
  );
};

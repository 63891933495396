import React from "react";
import { Store } from "redux";

import {
  RootContainer,
  AudioAvailabilityManager,
  PoolingManager,
  routes,
} from "../../../core/src";
import { connect } from "../../../core/src";

import { CallStateEnum } from "../../../core/src/constants/callConstants";

import { hangUp, accept } from "../../../core/src/actions/callActions";

import { SystemNotificationManager } from "./NotificationManager";
import { hot } from 'react-hot-loader/root';

import { saveImpersonateUserId, impersonateLogout } from "../../../core/src/actions/impersonateUserActions";

interface IAppProps {
  isUserAuthenticated: boolean;
  store: {
    store: Store<any, any> & {
      dispatch: {};
    };
    history: any;
    persistor: any;
  };
  callSession: any;
  callState: CallStateEnum;
  dialedPhoneNumber: string;

  accept: () => any;
  hangUp: () => any;

  saveImpersonateUserId: (userId: number | null) => any;
  impersonateLogout: () => any;
  impersonateUserId: number | null;
}

interface IAppState {}

class App extends React.Component<IAppProps, IAppState> {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    let impersonateUserId: number | null = Number(queryParams.get('impersonateUserId'));

    if (isNaN(impersonateUserId) || impersonateUserId <= 0) {
      // grab impersonateUserId from the store if it's not in the URL
      if (this.props.impersonateUserId !== null && !isNaN(this.props.impersonateUserId) && this.props.impersonateUserId > 0) {
        impersonateUserId = this.props.impersonateUserId;
        return;
      } else {
        impersonateUserId = null;
      }
    }

    if (impersonateUserId !== null) {
      // save impersonateUserId to redux store
      this.props.saveImpersonateUserId(impersonateUserId);

      // log out and clear all data except impersonate user id
      this.props.impersonateLogout();
    } else {
      this.props.saveImpersonateUserId(null);
    }
  }

  onAppMount() {
    // if (!this.props.isUserAuthenticated)
      // this.props.store.history.push(routes.LOGIN);
  }

  render() {
    return (
      <>
      <RootContainer
        onMount={() => {
          this.onAppMount();
        }}
        // notificationManager={
          //   <SystemNotificationManager
          //     accept={this.props.accept}
          //     hangUp={this.props.hangUp}
          //     isUserAuthenticated={this.props.isUserAuthenticated}
          //     callSession={this.props.callSession}
          //     callState={this.props.callState}
          //     dialedPhoneNumber={this.props.dialedPhoneNumber}
          //   />
          // }
          history={this.props.store.history}
          >
        {this.props.isUserAuthenticated && <AudioAvailabilityManager />}
      </RootContainer>
        <PoolingManager />
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserAuthenticated: state.authentication.isUserAuthenticated,
  callSession: state.callReducer.callSession,
  callState: state.callReducer.callState,
  dialedPhoneNumber: state.callReducer.dialedNumber,
  impersonateUserId: state.impersonateUserReducer.impersonateUserId,
});

export default hot(connect(mapStateToProps, {
  hangUp,
  accept,
  saveImpersonateUserId,
  impersonateLogout,
})(App));

import { TableBody, TableCell, TableRow, Theme } from "@material-ui/core";
import { styled, withStyles, createStyles } from "@material-ui/core/styles";

const ContactLineWrapper = styled("div")({
  borderBottom: '1px solid grey',
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  padding: '3px 0',

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
});

const ContactFullNameWrapper = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  width: '100%',
});

const ContactWrapper = styled("div")({
  width: '100%',
});

const ContactLetterLineItem = styled("div")({
  borderBottom: '1px solid grey',
  backgroundColor: 'lightgray',
  fontWeight: 'bold',
});

const ContactStyledRow = withStyles((theme: Theme) => 
createStyles({
  root: {
    padding: theme.spacing(0),
  }
})
)(TableRow);

const ContactPhoneStyledRow = withStyles((theme: Theme) => 
  createStyles({
    root: {
      padding: theme.spacing(0),
      cursor: "pointer",
      
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
      }
    }
  })
)(TableRow);

const ContactTableBody = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid grey'
    }
  })
)(TableBody);

const ContactTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 1),
      border: "none",
    }
  })
)(TableCell);

const ContactIconTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 1),
      paddingTop: "4px",
      textAlign: "right",
      border: "none",
      width: "26px",
      cursor: "pointer",
    }
  })
)(TableCell);

export {
  ContactLineWrapper,
  ContactLetterLineItem,
  ContactFullNameWrapper,
  ContactWrapper,
  ContactStyledRow,
  ContactTableBody,
  ContactTableCell,
  ContactIconTableCell,
  ContactPhoneStyledRow
};
export type IScheduledMeeting = {
  binder_id: string;
  topic: string;
  session_key: string;
  scheduled_starts: string;
  scheduled_ends: string;
  host: {
    id: string;
    email: string;
  }
};

export enum MeetingRepeatEnum {
  NEVER = "never",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}






export type IScheduledMeetingRecurrence = {
frequency?: MeetingRepeatEnum;
endTime?: string;
daily?: {
  interval: number;
};
weekly?: {
  interval: number;
  weekDays:  "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"[];
};
monthly?: {
  monthDays: number[];
};
};

export type IScheduleMeetingOptions = {
name: string;
starts?: string;
ends?: string;
startTime?: number;
endTime?: number;
agenda?: string;
originalBinderId?: string;
autoRecording?: boolean;
hideRecordingCtrl?: boolean;
joinBeforeMinutes?: number;
recordMultipleVideoChannel?: boolean;
tags?: [
  {
    name: string;
    value: string;
  }
];
notificationOff?: boolean;
hostVideoOn?: boolean;
participantVideoOn?: boolean;
recurrence?: IScheduledMeetingRecurrence;
};

type IMeetTag = {
name: "participant_video_on",
value: "0" | "1"
}

type IMeetStatus = "SESSION_SCHEDULED" | "SESSION_STARTED" | "SESSION_ENDED"

export type IScheduledMeetingDetailed = {
  topic: string;
  sessionKey: string;
  binderId: string;
  status: IMeetStatus;
  scheduledStarts: string;
  scheduledEnds: string;
  agenda: string;
  joinBeforeMinutes: number;
  recordMultipleVideoChannel: boolean;
  invitees?: IMoxtraUser[],
  participants?: IMeetingParticipant[],
  totalParticipants: number;
  totalInvitees: number;
  hasRecording: boolean;
  startedRecording: boolean;
  createdTime: number;
  updatedTime: number;
  originalBinderId: string;
  autoRecording: boolean;
  dialinNumbers: IMeetingDialNumber[];
  recurrence?: IScheduledMeetingRecurrence;
  tags?: IMeetTag[]
  audioUsed: boolean;
  draftMeet: boolean;
  hideRecordingCtrl: boolean;
  reminderOff: boolean;
  telephoneUsed: boolean;
  videoUsed: boolean;
};

export type IMoxtraUserInfo = {
  email: string;
  host: boolean;
  name?: string;
}

export type IMoxtraUser = {
  uniqueId: string;
  id: string;
  status: string;
  pictureUri: string;
} & IMoxtraUserInfo;

export type IMeetingParticipant = {
  audioUsed: boolean,
  invisible: boolean,
  orgId: string,
  participantNumber: number,
  phoneNumber: string,
  pictureUri: string,
  presenter: boolean,
  rosterId: string,
  starts: string,
  telephoneUsed: boolean,
  videoUsed: boolean,
} & IMoxtraUser;



export type IMeetingDialNumber = {
  location: string;
  phoneNumber: string;
};
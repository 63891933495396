/**
 * impersonateUserActions.js
 * Talkroute
 */
import { push } from "connected-react-router";
import routes from "@constants/routes";
import { RootState } from "@helpers/store/store";
import { setUserTokenNotReady } from "./userActions";
import apiServiceInstance from "@services/TalkrouteAPIService";
import { appConfig } from "../index";
import FilePathHelper from "@helpers/filePathHelper";
import userConstants from "@constants/userConstants";

/**
 * Save impersonation user id to store
 */
export const saveImpersonateUserId = (userId: number | null) => ({
    type: userConstants.SAVE_IMPERSONATE_USER_ID,
    payload: userId,
});

/**
 * Logout action for impersonation
 */
export const impersonateLogout = () => (dispatch, getState: () => RootState) => {
    dispatch({ type: userConstants.RESET_STORE_EXCEPT_IMPERSONATE_USER_ID });
    dispatch(setUserTokenNotReady());
    dispatch(push(routes.LOGIN));
    performPostLogoutCleanup();
    appConfig.axios.interceptors.response.eject(apiServiceInstance.getInterceptor())
    return apiServiceInstance
      .logout()
      .then((token) => {
        return token
      })
      .catch((error) => error);
};

/**
 * Called to clean up user data after logout
 */
const performPostLogoutCleanup = () => {
    const filePathHelper = new FilePathHelper();
    // Remove voicemail and text message attachment data
    const voicemailFilePath = filePathHelper.getVoicemailFilePath();
    const messageAttachmentFilePath = filePathHelper.getMessageAttachmentBaseFilePath();
  
    appConfig.fileStorage?.exists(voicemailFilePath) && appConfig.fileStorage?.remove(voicemailFilePath);
    appConfig.fileStorage?.exists(messageAttachmentFilePath) &&  appConfig.fileStorage?.remove(messageAttachmentFilePath);
    filePathHelper.clearTempFoler();
};
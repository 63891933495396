import { Backdrop, BackdropProps, CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const LimitedBackdrop = withStyles({
    root: {
        position: "fixed",
        zIndex: 5,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "#fff",
    },
})(Backdrop);

const Loader = ({ open, ...rest }: { open: boolean } & BackdropProps) => (
    <LimitedBackdrop {...rest} open={open}>
        <CircularProgress color="inherit" />
    </LimitedBackdrop>
);

export default Loader;
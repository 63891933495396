import { setCallHistory } from "@actions/callHistoryActions";
import { fetchConversationsSuccess } from "@actions/conversationActions";
import { fetchVoicemailsSuccess } from "@actions/voicemailActions";
import { GET_TEN_THOUSAND_CONTACTS, CONTACTS_INITIAL_PAGE } from "@components/Contacts/constants";
import { addContactNameToCallHistory, addContactNameToConversation, addContactNameToVoicemail } from "@helpers/functions/contacts";
import { mergeContactsToCallHistory, mergeContactsToConversations, mergeContactsToVoicemailList } from "@reducers/contactsReducer";
import { useGetAllContactsQuery } from "@services/kingcobraApi";
import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector.hook";

const useContactsManager = () => {
    // const callHistory = useAppSelector(state => state.callHistory.data);
    // const voicemailList = useAppSelector(state => state.voicemails.data);
    // const conversations = useAppSelector(state => state.conversations.data);
    // const conversationsPagination = useAppSelector(state => state.conversations.conversationPagination);
    // // const contactsList = useAppSelector(state => state.contactsReducer.data);
    // const shouldMergeContactsToCallhistory = useAppSelector(state => state.contactsReducer.shouldMergeToCallhistoy);
    // const shouldMergeContactsToVoicemailList = useAppSelector(state => state.contactsReducer.shouldMergeToVoicemailList);
    // const shouldMergeContactsToConversations = useAppSelector(state => state.contactsReducer.shouldMergeToConversations);
    // const dispatch = useDispatch();

    // const {data: _contacts, } = useGetAllContactsQuery({ params: { 
    //     pageSize: GET_TEN_THOUSAND_CONTACTS,
    //     page: CONTACTS_INITIAL_PAGE
    //   } });

    // const contactsList = useMemo(() => _contacts || [], [_contacts]);
      
    // useEffect(() => {
    //     if (shouldMergeContactsToCallhistory && contactsList.length && callHistory.length) {
    //         const updatedCallHistory = addContactNameToCallHistory(contactsList, callHistory);
    //         dispatch(setCallHistory({data: updatedCallHistory}));
    //         dispatch(mergeContactsToCallHistory(false));
    //     }
    // }, [contactsList, shouldMergeContactsToCallhistory, callHistory]);

    // useEffect(() => {
    //     if (shouldMergeContactsToVoicemailList && contactsList.length && voicemailList.length) {
    //         const updatedVoicemailList = addContactNameToVoicemail(contactsList, voicemailList);
    //         dispatch(fetchVoicemailsSuccess({data: updatedVoicemailList}));
    //         dispatch(mergeContactsToVoicemailList(false));
    //     }
    // }, [contactsList, shouldMergeContactsToVoicemailList, voicemailList]);

    // useEffect(() => {
    //     if (shouldMergeContactsToConversations && contactsList.length && conversations.length) {
    //         const updatedConversations = addContactNameToConversation(contactsList, conversations);
    //         dispatch(fetchConversationsSuccess({data: updatedConversations, pagination: conversationsPagination, clearDraft: false}));
    //         dispatch(mergeContactsToConversations(false));
    //     }
    // }, [contactsList, shouldMergeContactsToConversations, conversations]);
}

export default useContactsManager;
import { REHYDRATE } from "redux-persist";

import {
  FETCH_CONVERSATION_HISTORY_BEGIN,
  FETCH_CONVERSATION_HISTORY_SUCCESS,
  FETCH_CONVERSATION_HISTORY_FAILURE,
  FETCH_ALL_HISTORY_COMPLETE,
} from "../actions/textMessageHistoryActions";
import dataStateEnum from "../constants/dataStateConstants";
import uiString from "../constants/uiString";
import {
  ConversationData,
  conversationsRehydrator,
} from "../services/dataClasses/ConversationData";
import validateArray from "../helpers/arrayValidator";
import { IPagination } from "../models/IPagination";

export interface ITextMessageHistoryReducer {
    data: ConversationData[];
    conversationPagination: IPagination;
    dataState: dataStateEnum;
    filterBy: string;
    filteredData: ConversationData[];
    error: null | string;
}

const initialState: any = {
    data: [],
    conversationPagination: null,
    dataState: dataStateEnum.LOADING,
    filterBy: uiString.ALL_MESSAGES,
    filteredData: [],
    error: null,
};

/**
 * A redux function to process text message history actions
 * @param {*} state the redux state
 * @param {*} action passed in action through the conversationActions creator
 */
export default function textMessageHistory(state: ITextMessageHistoryReducer = initialState, action): ITextMessageHistoryReducer {
    switch (action.type) {
        case REHYDRATE:
          // A redux-persist action. Called immediately after launch
          if (action.payload !== undefined) {
            return {
              ...state,
              data: conversationsRehydrator(action.payload.conversations.data),
              filteredData: conversationsRehydrator(
                action.payload.conversations.data
              ),
              conversationPagination:
                action.payload.conversations.conversationPagination,
            };
          }
    
          return {
            ...state,
          };
        case FETCH_CONVERSATION_HISTORY_BEGIN:
          // Fetching the conversations has started
          return {
            ...state,
            dataState: dataStateEnum.LOADING,
            error: null,
        };
        case FETCH_CONVERSATION_HISTORY_SUCCESS: {
            // Successfully fetched the conversations
            let { data: newConversations } = action.payload.conversations;
            const { pagination } = action.payload.conversations;
            const iPagination = convertToIPagination(pagination, 'https://testing-webapp-api.talkroute.com/');
    
            const sortedConversations = sortConversations(newConversations);
    
            const filteredData = conversationFilterHelper(
                sortedConversations,
                state.filterBy
            );
    
            return {
                ...state,
                data: sortedConversations,
                conversationPagination: iPagination,
                filteredData,
            };
        }

        case FETCH_CONVERSATION_HISTORY_FAILURE:
            // Error while fetching conversations
            return {
                ...state,
                dataState: dataStateEnum.ERROR,
                error: action.payload.error,
            };
        case FETCH_ALL_HISTORY_COMPLETE:
            return {
                ...state,
                dataState: dataStateEnum.READY,
            };
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
};

/**
 * Helper function
 * Sorts the passed in array of conversations by the
 * createdAtMicroseconds value of their last messages
 * @param {*} data the conversations to sort
 */
export const sortConversations = (data: ConversationData[]) => {
    const sortedData = [...data];
    if (validateArray(sortedData)) {
      // Sort by date before returning
      sortedData.sort(
        (a, b) =>
          // A simple comparator function that uses the numeric createdAtMicroseconds values for comparison
          b.lastMessage.createdAtMicroseconds -
          a.lastMessage.createdAtMicroseconds
      );
    }
  
    return sortedData;
};

/**
 * Helper function
 * Filters the passed in conversations by a passed in filter.
 *
 * @param {*} conversationData An array containing conversations
 * @param {*} filterBy A phone number to filter by
 */
export const conversationFilterHelper = (conversationData, filterBy) => {
    if (filterBy === uiString.ALL_MESSAGES) {
      return conversationData;
    }
  
    if (validateArray(conversationData)) {
      // Null or empty check for conversations
      const filteredConversations = conversationData.filter(
        (conversationRecord) =>
          conversationRecord.talkrouteNumber === filterBy ||
          conversationRecord.isNewConversation()
      );
      return filteredConversations;
    }
  
    return conversationData;
};

/**
 *
 * @param messages the new messages
 * @param conversationId the ID of the conversation to update
 * @param conversationsLocal a local pointer to the conversations object
 * @param pagination an object that contains data on the total messages available on the server
 */
const updateMessagesForConversation = (props) => {
    const { messages, conversationId, conversationsLocal, pagination } = props;
    if (!validateArray(messages)) {
      return conversationsLocal;
    }
  
    if (conversationsLocal && conversationId) {
      const foundIndex = findConversationById({
        conversations: conversationsLocal,
        conversationId,
      });
  
      if (foundIndex !== undefined && foundIndex !== -1) {
        const conversation = conversationsLocal[foundIndex];
        conversation.addMessages({ messages, pagination });
  
        const updatedConversations = [...conversationsLocal];
        updatedConversations[foundIndex] = conversation;
  
        return updatedConversations;
      }
    }
    return conversationsLocal;
};

  /**
 *
 * @param conversations the array of conversations to search in
 * @param conversationId the ID of the conversation to search for
 *
 * @returns either a found conversation or undefined
 */
export const findConversationById = (props) => {
    const { conversations, conversationId } = props;
  
    if (validateArray(conversations)) {
      const foundIndex = conversations.findIndex(
        (conversationEntry) => conversationEntry.conversationId === conversationId
      );
  
      return foundIndex;
    }
  
    return undefined;
};

/**
 * Merge a new list of conversations with the existing one
 *
 * @param {*} conversations the existing list of conversations
 * @param {*} newConversations the new conversations
 */
const addAllUniqueConversations = (props) => {
    const { conversations: conversationsLocal, newConversations } = props;
  
    if (validateArray(newConversations)) {
      const uniqueConversations: any[] = [];
      newConversations.forEach((newConversation) => {
        const conversationIndex = findConversationIndex({
          conversations: conversationsLocal,
          newConversation,
        });
        if (conversationIndex === -1) {
          // This is a unique conversation
          uniqueConversations.push(newConversation);
        } else {
          // This is an existing conversation with index conversationIndex
          const existingConversation = conversationsLocal[conversationIndex];
          if (existingConversation) {
            existingConversation.mergeWithUpdatedConversation(newConversation);
          }
        }
      });
  
      const updatedConversations = [
        ...conversationsLocal,
        ...uniqueConversations,
      ];
      return updatedConversations;
    }
  
    return conversationsLocal;
};

/**
 * Finds the index of a conversation and performs error checking. Returns -1 if not found
 *
 * @param {*} conversations the list of conversations to look in
 * @param {*} newConversation the convesation to look for
 */
const findConversationIndex = (props) => {
    const { conversations: conversationsLocal, newConversation } = props;
  
    const { conversationId } = newConversation;
  
    if (conversationsLocal && conversationId) {
      const foundIndex = findConversationById({
        conversations: conversationsLocal,
        conversationId,
      });
  
      return foundIndex;
    }
  
    return -1;
};

function convertToIPagination(conversationPagination, baseUrl) {
    const total = conversationPagination.count;
    const perPage = conversationPagination.limit;
    const offset = conversationPagination.offset;
    const currentPage = offset / perPage + 1;
    const totalPages = Math.ceil(total / perPage);
    const from = offset + 1;
    const to = Math.min(offset + perPage, total);

    return {
        count: total,
        currentPage: currentPage,
        firstPageUrl: `${baseUrl}?page=1`,
        from: from,
        lastPageUrl: `${baseUrl}?page=${totalPages}`,
        nextPageUrl: null,
        path: baseUrl,
        perPage: perPage,
        prevPageUrl: null,
        to: to,
        total: total,
        totalPages: totalPages,
    };
}
import React, { useState } from "react";
import {
  Button,
  Theme,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import {
  DIALOG_SHEDULE_MEETING,
  DIALOG_VIDEO_HOST,
  DIALOG_VIDEO_PARTICIPANTS,
  DIALOG_RECORD_MEETING,
  ADD_AGENDA,
  ADD_MEETING_NAME,
  ADD_PARTICIPANTS,
  SCHEDULE,
  DIALOG_START_DATE,
  DIALOG_END_DATE,
  DIALOG_START_HOUR,
  DIALOG_END_HOUR,
  repeatOptions,
} from "./constants";
import TimeIcon from "@resources/icons/meeting-time.svg";
import VideoHostIcon from "@resources/icons/video-host.svg";
import AgendaIcon from "@resources/icons/add-agenda.svg";
import ParticipantsIcon from "@resources/icons/add-participants.svg";
import RecordIcon from "@resources/icons/record-meeting.svg";
import MeetingIcon from "@resources/icons/meeting-title.svg";
import CrossIcon from "@resources/icons/cross-small.svg";
import InputField from "../PresentationComponents/FormComponents/InputField";
import InputButton from "../PresentationComponents/FormComponents/InputButton";
import { TimeClockFormat } from "../HoursOfOperation/constants";
import InlineDropdown from "../PresentationComponents/FormComponents/InlineDropdown";
import { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useAppSelector } from "../../helpers/hooks/useAppSelector.hook";
import { emailRegexValidation } from "../../helpers/emailRegexValidation";
import { useDispatch } from "react-redux";
import keyConstants from "@constants/keyConstants";
import { RecurrenceForm } from "./RecurrenceForm";
import { MeetingRepeatEnum } from "@models/Moxtra.models";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DatePicker } from "@components/PresentationComponents/FormComponents/DatePicker";
import { TimePicker } from "@components/PresentationComponents/FormComponents/TimePicker";
import axios from "axios";
import _ from "lodash";
import ToggledRadioButton from "@components/PresentationComponents/FormComponents/ToggledRadioButton";
import mergeMeetLists from "@helpers/mergeMeetLists";
import { InputErrorText } from "@components/PresentationComponents/FormComponents/InputErrorText";
import { useGetAllMeetingsQuery, useScheduleMeetingMutation, useSendEmailInviteMutation } from "@services/Moxtra.slice";
import { isAxiosError } from "@utils";
import { JOIN_MEET_BEFORE_MINUTES } from "@constants/index";

type Props = {
  open: boolean;
  close: () => void;
};

type DateProps = {
  startDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
};

const getInitialFormState = () => {
  const rightNow = new Date();
  const fewMinutesLater = new Date();
  fewMinutesLater.setMinutes(rightNow.getMinutes() + 10);
  const oneHourLater = new Date();
  oneHourLater.setHours(fewMinutesLater.getHours() + 1);
  return {
    startDate: fewMinutesLater,
    endDate: oneHourLater,
    videoHostLabel: true,
    videoParticipants: true,
    recordMeeting: false,
    meetingName: "",
    agenda: "",
    repeat: MeetingRepeatEnum.NEVER,
  };
}

const ScheduleMeetingDialog: React.FC<Props> = ({ open, close }) => {
  const firstName = useAppSelector((state) => state.authentication.firstName);
  const userId = useAppSelector((state) => state.authentication.userId);
  const clockFormat = TimeClockFormat.BAD;
  const classes = useStyles();
  const [meetingNameIsValid, setMeetingNameIsValid] = useState(true);
  const [participantsIsValid, setParticipantsIsValid] = useState(true);
  const [startTimeIsValid, setStartTimeIsValid] = useState(true);
  const [participantsErrorMessage, setParticipantsErrorMessage] = useState<
    undefined | string
  >(undefined);
  const [scheduleMeeting, {isLoading: scheduleMeetInProgress}] = useScheduleMeetingMutation();
  const [sendInvites, {isLoading: sendingInvitesInProgress}] = useSendEmailInviteMutation();
  const [form, setForm] = useState<{
    startDate: Date;
    endDate: Date;
    videoHostLabel: boolean;
    videoParticipants: boolean;
    recordMeeting: boolean;
    meetingName: string;
    agenda: string;
    repeat: MeetingRepeatEnum;
  }>(getInitialFormState);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [participants, setParticipants] = useState<string[]>([]);
  const [isRecurrenceOpen, setIsRecurrenceOpen] = useState(false);
  const [recurrenceForm, setRecurrenceForm] = useState<{
    endTime: Date;
    interval: number;
    weekDays: Set<string>;
    monthDays: Set<number>;
  }>({
    endTime: new Date(),
    interval: 1,
    weekDays: new Set<string>(),
    monthDays: new Set<number>([1]),
  });
  const [timeError, setTimeError] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      const initialState = getInitialFormState();
      const timezoneToString = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setForm(prevForm => ({
        ...initialState,
        timezone: timezoneToString,
        meetingName: `${firstName}'s meeting`,
      }))
      setEmail("");
      setParticipants([]);
    }
  }, [open])

  useEffect(() => {
    const startDate = form.startDate?.getDate() as number;
    const endDate = form.endDate?.getDate() as number;
    if (startDate > endDate) {
      setForm((prevForm) => ({ ...prevForm, endDate: form.startDate }));
    }
  }, [form.startDate, form.endDate]);

  useEffect(() => {
    setIsRecurrenceOpen(form.repeat !== "never");
  }, [form.repeat]);

  useEffect(() => {
    const meetingInput = form.meetingName;
    const participantsInput = participants;
    if (meetingInput !== "") {
      setMeetingNameIsValid(true);
    } else {
      setMeetingNameIsValid(false);
    }
  }, [form.meetingName, participants]);

  // const timezone = form.timezone
  //   ? TIMEZONES_ARRAY.find((timezone) => form.timezone === timezone.value)
  //   : null;

  const isDurationValid = (startDate: Date, endDate: Date) => {
    const differenceInHours = Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
    return differenceInHours < 24;
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    const { target } = event as any;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setForm((form) => ({
      ...form,
      [target.name]: value,
    }));
  };

  const timeChangeHandler = (
    property: string,
    value: MaterialUiPickersDate
  ) => {
    if (!value) return;
    
    let startDate = form.startDate;
    let endDate = form.endDate;
    switch (property) {
      case DIALOG_START_DATE:
        startDate.setFullYear(value.getFullYear());
        startDate.setMonth(value.getMonth());
        startDate.setDate(value.getDate());
        if (Number(startDate) >= Number(endDate)) {
          endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + 1);
        }
        break;
      case DIALOG_END_DATE:
        endDate.setFullYear(value.getFullYear());
        endDate.setMonth(value.getMonth());
        endDate.setDate(value.getDate());
        if (Number(startDate) >= Number(endDate)) {
          endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + 1);
        }
        break;
      case DIALOG_START_HOUR:
        setStartTimeIsValid(true);
        startDate.setHours(value.getHours());
        startDate.setMinutes(value.getMinutes());
        const oneMinuteFromNow = Date.now() + 60 * 1000;
        const fiveMinutesFromNow = Date.now() + 60 * 1000 * 5;
        const startDateInMs = Number(startDate);
        if (Number(startDate) >= Number(endDate)) {
          endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + 1);
        } else {
          startDate = new Date(Math.max(startDateInMs, fiveMinutesFromNow));
        }
        break;
      case DIALOG_END_HOUR:
        endDate.setHours(value.getHours());
        endDate.setMinutes(value.getMinutes());
        if (Number(startDate) >= Number(endDate)) {
          endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + 1);
        }
        break;
      default:
        break;
    }
    
    const validDuration = isDurationValid(startDate, endDate);
    if (!validDuration) {
      setTimeError("Meeting duration cannot be 24 hours or longer.");
    } else {
      setTimeError(null);
    }
    setForm(prevForm => ({ ...prevForm, startDate, endDate }));
  };

  const _sendInvites = async (participants: string[], sessionKey: string) => {
    try {
      await sendInvites({
        invitees: participants, sessionKey
      });
    } catch (error) {
      throw "There was an issue with sending out invites";
    }
  };
  const submitHandler = async () => {
    try {
      setSubmitError(null);
      let recurrence;
      if (form.repeat === MeetingRepeatEnum.DAILY) {
        recurrence = {
          frequency: form.repeat,
          daily: {
            interval: recurrenceForm.interval,
          },
        };
      } else if (form.repeat === MeetingRepeatEnum.WEEKLY) {
        recurrence = {
          frequency: form.repeat,
          weekly: {
            interval: recurrenceForm.interval,
            weekDays: [...recurrenceForm.weekDays],
          },
        };
      } else if (form.repeat === MeetingRepeatEnum.MONTHLY) {
        recurrence = {
          frequency: form.repeat,
          monthly: {
            monthDays: [...recurrenceForm.monthDays].map((day) => Number(day)),
          },
        };
      }

      const nameIsNonEmpty = form.meetingName.trim() !== "";
      const emailIsValid =
        email.length === 0 || email.match(emailRegexValidation);
      
      const startTimeIsValid = form.startDate.getTime() >= (Date.now() + 1000*60);

      if (!nameIsNonEmpty || !emailIsValid || !startTimeIsValid) {
        if (!emailIsValid) {
          setParticipantsIsValid(false);
          setParticipantsErrorMessage("Invalid Format!");
        }

        if (!startTimeIsValid) {
          setStartTimeIsValid(false);
        }
      } else {
        const _participants =
          participants.length > 0
            ? participants
            : emailIsValid && email !== ""
            ? [email]
            : [];

        const starts = form.startDate;
        starts.setSeconds(0,0);
        const ends = form.endDate;
        ends.setSeconds(0,0);

        const {sessionKey} = await scheduleMeeting({
          starts: form.startDate.toISOString().split('.')[0]+'Z',
          ends: form.endDate.toISOString().split('.')[0]+'Z',
          agenda: form.agenda ? form.agenda : undefined,
          name: form.meetingName,
          hostVideoOn: form.videoHostLabel,
          participantVideoOn: form.videoParticipants,
          autoRecording: form.recordMeeting,
          joinBeforeMinutes: JOIN_MEET_BEFORE_MINUTES,
          recurrence: recurrence ? recurrence : undefined,
          hideRecordingCtrl: true,
          host: String(userId)
        }).unwrap();
        _participants.length > 0 &&
          (await _sendInvites(_participants, sessionKey));
        // await getAllMeetings();
        close();
      }
    } catch (error) {
      if (isAxiosError(error) && error.response && error.response.data) {
        const message = JSON.parse(error.response.data.message);
        if (message.message && typeof message.message === "string") {
          setSubmitError(
            _.capitalize(
              (message.message as string).replace(new RegExp("_", "gi"), " ")
            )
          );
        } else {
          setSubmitError("There was an issue with meeting creation");
        }
      } else {
        setSubmitError("There was an issue with meeting creation");
      }
    }
  };

  const getLabel = (bool: boolean) => (bool ? "On" : "Off");

  const addEmailToList = (email) => {
    const checkEmail = email.match(emailRegexValidation);
    const emailAlreadyIncluded = participants.includes(email);
    if (checkEmail && !emailAlreadyIncluded) {
      setParticipants((prevArr) => [...prevArr, email]);
      setEmail("");
      setParticipantsErrorMessage(undefined);
      setParticipantsIsValid(true);
    } else {
      const errormsg = emailAlreadyIncluded
        ? "Email already included"
        : "Invalid Format!";
      setParticipantsErrorMessage(errormsg);
      setParticipantsIsValid(false);
    }
  };

  const onEmailEnter = (event) => {
    if (event.keyCode === keyConstants.CODE_ENTER) {
      addEmailToList(email);
    }
  };

  const RecurrenceInput = ({
    fullWidth,
    fullHeight,
  }: {
    fullWidth?: boolean;
    fullHeight?: boolean;
  }) => (
    <FormControl
      className={classes.formControlWrapper}
      style={{
        paddingLeft: fullWidth ? 12 : 53,
        paddingRight: fullWidth ? 12 : 17,
      }}
      fullWidth
    >
      <InlineDropdown
        name="repeat"
        options={repeatOptions}
        onChange={handleChange}
        value={form.repeat}
        innerLabel="repeat"
        dropdownStyle={{ height: fullHeight ? 40 : 28 }}
        outlined
        fullWidth
      />
    </FormControl>
  );

  return (
    <Dialog open={open} onClose={close} className={classes.dialog} fullWidth>
      <DialogTitle className={classes.header}>
        {DIALOG_SHEDULE_MEETING}
        <Button className={classes.closeButton} onClick={close}>
          <CrossIcon />
        </Button>
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.contentWrapper}>
        {!isRecurrenceOpen && (
          <>
            <FormControl
              className={classes.formControlWrapper}
              fullWidth
            >
              <MeetingIcon style={{ marginRight: "8px", marginBottom: meetingNameIsValid ? 0 : 26 }} />
              <InputField
                name="meetingName"
                value={form.meetingName}
                onChange={handleChange}
                placeholder={ADD_MEETING_NAME}
                fullWidth
                error={!meetingNameIsValid}
                errorMessage={"Enter meeting name!"}
              />
            </FormControl>
            <FormControl className={classes.dateWrapper}>
              <TimeIcon className={classes.timeIcon} />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  className={classes.datePicker}
                  style={{ maxWidth: "100px" }}
                  minDate={new Date()}
                  value={form.startDate}
                  id="start-date-picker"
                  format="MM/dd/yyyy"
                  InputProps={{ disableUnderline: true }}
                  onChange={(date) =>
                    timeChangeHandler(DIALOG_START_DATE, date)
                  }
                  margin="normal"
                />
                <TimePicker
                  className={classes.datePicker}
                  style={{ maxWidth: "87px" }}
                  value={form.startDate}
                  minutesStep={5}
                  InputProps={{ disableUnderline: true }}
                  onChange={(date) =>
                    timeChangeHandler(DIALOG_START_HOUR, date)
                  }
                  ampm={clockFormat === TimeClockFormat.BAD}
                />
                <p className={classes.dash}>-</p>
                <TimePicker
                  className={classes.datePicker}
                  style={{ maxWidth: "87px" }}
                  value={form.endDate}
                  minutesStep={5}
                  InputProps={{ disableUnderline: true }}
                  onChange={(date) => timeChangeHandler(DIALOG_END_HOUR, date)}
                  ampm={clockFormat === TimeClockFormat.BAD}
                />
                <DatePicker
                  disableToolbar
                  style={{ maxWidth: "100px" }}
                  className={classes.datePicker}
                  value={form.endDate}
                  id="end-date-picker"
                  format="MM/dd/yyyy"
                  InputProps={{ disableUnderline: true }}
                  onChange={(date) => timeChangeHandler(DIALOG_END_DATE, date)}
                  margin="normal"
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            {/* <FormControl
          className={`${classes.formControlWrapper} ${classes.smallGap}`}
          fullWidth
        >
          <VirtualizedSelector
            id="timezone-selector-schedule-meeting-dialog"
            options={TIMEZONES_ARRAY}
            label="time zone"
            variant="outlined"
            chevronIcon
            className={classes.virtualizedSelector}
            value={timezone}
            groupBy={(option) => returnGmtPrefixFromTimezone(option.label)}
            transformLabel={removeGmtPrefixFromTimezone}
            onChange={(_e, value) => {
              setForm((prevForm) => ({
                ...prevForm,
                timezone: value ? String(value.value) : "",
              }));
            }}
          />
        </FormControl> */}
            <RecurrenceInput />
            <Divider className={classes.divider} />

            <FormControl
              className={classes.formControlWrapper}
              fullWidth
            >
              <ParticipantsIcon style={{ marginRight: "8px", marginBottom: participantsIsValid ? 0 : 26}} />
              <InputField
                name="participants"
                value={email}
                className={classes.smallFields}
                wrapperClass={classes.rightSpacing}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={onEmailEnter}
                placeholder={ADD_PARTICIPANTS}
                fullWidth
                error={!participantsIsValid}
                errorMessage={participantsErrorMessage}
              />
              <InputButton
                color="primary"
                onClick={() => addEmailToList(email)}
                style={{ height: 32, alignSelf: "flex-end", marginBottom: participantsIsValid ? 0 : 26 }}
              >
                Add
              </InputButton>
            </FormControl>
            <List className={classes.emailList}>
              {participants.map((_email, index) => (
                <ListItem key={email + index}>
                  <ListItemText>{_email}</ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() =>
                        setParticipants((prevArr) =>
                          prevArr.filter((item) => item !== _email)
                        )
                      }
                    >
                      <CrossIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <Divider className={classes.divider} />

            {/* <FormControl
              className={`${classes.formControlWrapper}
          ${classes.smallGap} ${classes.agendaField}`}
              fullWidth
            >
              <AgendaIcon />
              <InputField
                name="agenda"
                value={form.agenda}
                className={classes.smallFields}
                onChange={handleChange}
                placeholder={ADD_AGENDA}
                fullWidth
              />
            </FormControl> */}

            <FormControl
              className={`${classes.formControlWrapper} ${classes.toggleTopWrapper}`}
            >
              <VideoHostIcon className={classes.toggleIcon} />
              <span className={classes.videoHostLabel}>
                {DIALOG_VIDEO_HOST}
              </span>
              <ToggledRadioButton
                label={getLabel(form.videoHostLabel)}
                onChange={handleChange}
                name="videoHostLabel"
                className={`${classes.toggleButton} ${classes.videoHost}`}
                checked={form.videoHostLabel}
              />
              <span className={classes.videoParticipants}>
                {DIALOG_VIDEO_PARTICIPANTS}
              </span>
              <ToggledRadioButton
                label={getLabel(form.videoParticipants)}
                onChange={handleChange}
                name="videoParticipants"
                className={classes.toggleButton}
                checked={form.videoParticipants}
              />
            </FormControl>

            {/* <FormControl
              className={`${classes.formControlWrapper} ${classes.midGap}`}
            >
              <RecordIcon />
              <span className={classes.recordLabel}>
                {DIALOG_RECORD_MEETING}
              </span>
              <ToggledRadioButton
                label={getLabel(form.recordMeeting)}
                onChange={handleChange}
                name="recordMeeting"
                className={classes.toggleButton}
                checked={form.recordMeeting}
              />
            </FormControl> */}
          </>
        )}

        {isRecurrenceOpen && (
          <RecurrenceForm
            form={recurrenceForm}
            setForm={setRecurrenceForm}
            onChange={setRecurrenceForm}
            repeat={form.repeat}
            recurrenceInput={<RecurrenceInput fullWidth fullHeight />}
          />
        )}
        {timeError && (
          <Alert style={{ marginTop: 15 }} severity="error">
            <AlertTitle>Error</AlertTitle> {timeError}
          </Alert>
        )}
        { !startTimeIsValid &&
        <Alert style={{ marginTop: 15 }} severity="error">
          <AlertTitle>Error</AlertTitle> Meeting start must be at least a minute in the future.
        </Alert>
        }
        {submitError && (
          <Alert style={{ marginTop: 15 }} severity="error">
            <AlertTitle>Error</AlertTitle> {submitError}{" "}
          </Alert>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {isRecurrenceOpen && (
          <div className={classes.recurrenceActions}>
            <InputButton
              onClick={() =>
                setForm((prevForm) => ({
                  ...prevForm,
                  repeat: MeetingRepeatEnum.NEVER,
                }))
              }
              variant="outlined"
              color="primary"
            >
              Cancel
            </InputButton>
            <InputButton
              onClick={() => setIsRecurrenceOpen(false)}
              variant="contained"
              color="primary"
            >
              Save
            </InputButton>
          </div>
        )}
        {!isRecurrenceOpen && (
          <InputButton
            label={SCHEDULE}
            className={classes.submitButton}
            fullWidth
            type="button"
            color="primary"
            variant="contained"
            onClick={submitHandler}
            isLoading={scheduleMeetInProgress}
            disabled={scheduleMeetInProgress || sendingInvitesInProgress || !open || Boolean(timeError)}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      maxWidth: "493px",
      maxHeight: "100%",
    },
  },
  header: {
    padding: "12px 10px 9px 26px",

    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
  emailList: {
    marginLeft: 40,
  },
  closeButton: {
    padding: "10px",
    minWidth: "0",
    borderRadius: "6px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 0 16px 0",
  },
  formControlWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    padding: "0 17px 0 28px",
  },
  dateWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 17px 0 28px",
    margin: "17px 0 17px 0",
  },
  divider: {
    margin: "25px 0",
  },
  virtualizedSelector: {
    width: "100%",
    margin: "0 0 7px 31px",

    "& .MuiInputBase-root": {
      height: "24px",
      paddingTop: "2px",
    },
    "& .MuiInputBase-input": {
      padding: "2px 4px !important",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(7px, 7px) scale(1)",
      fontWeight: "bold",
      color: "#979797",
      textTransform: "uppercase",
      fontSize: "10px",
    },
    "& input": {
      marginLeft: "60px",
    },
    "& fieldset > legend > span": {
      display: "none",
    },
  },
  datePicker: {
    border: "1px solid var(--color-light-grey)",
    borderRadius: "6px",
    paddingLeft: "8px",
    height: "34px",
    margin: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "var(--color-pumpkin-orange)"
    // },
    "& button": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "13px",
    },
  },
  dash: {
    fontSize: "26px",
    lineHeight: "normal",
    margin: "0",
  },
  timeIcon: {
    marginRight: "3px",
  },
  smallFields: {
    height: "32px",
  },
  rightSpacing: {
    marginRight: theme.spacing(1)
  },
  agendaField: {
    marginBottom: "20px",
  },
  toggleIcon: {
    marginRight: "17px",
  },
  videoHostLabel: {
    marginRight: "20px",
    fontSize: "13px",
  },
  toggleButton: {
    "& .MuiSwitch-root": {
      width: "34px",
      height: "20px",

      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(16px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: "14px",
      height: "14px",
    },
    "& .MuiButtonBase-root": {
      padding: "3px 0 0 2px",
    },
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },
  videoHost: {
    marginRight: "50px",
  },
  toggleTopWrapper: {
    marginBottom: "12px",
  },
  videoParticipants: {
    fontSize: "13px",
    marginRight: "24px",
  },
  recordLabel: {
    fontSize: "13px",
    marginRight: "12px",
  },
  submitButton: {
    height: "34px",
    fontSize: "13px",
  },
  dialogActions: {
    padding: "2px 25px",
    marginBottom: "17px",
  },
  recurrenceActions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

export default ScheduleMeetingDialog;

import { makeStyles, ButtonProps } from '@material-ui/core';
import React, { memo } from 'react';
import InputButton from '../FormComponents/InputButton';

type Props = {
  isLoading?: boolean;
  label: string;
} & ButtonProps

const DialogButton: React.FC<Props> = ({ isLoading, label, ...props }) => {
  const classes = useStyles();

  return (
    <InputButton
      {...props}
      label={label}
      className={`${classes.root} ${props.className}`}
      type="button"
      variant="contained"
      isLoading={isLoading}
      disabled={isLoading || props.disabled}
    />
  );
};

const useStyles = makeStyles({
  root: {
    height: "34px",
    fontSize: "13px",
  }
})

export default memo(DialogButton);
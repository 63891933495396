import getApiConstants from "@constants/apiConstants";
import { IApiResponse, IApiResponseWithPagination } from "@models/IPagination";
import { IPlan } from "@models/Plans.models";
import HttpClient from "./HttpClient";

export default function PlanService() {
  const BASE_URL = getApiConstants().endpoints.plans.BASE_URL
  const GET_SINGLE_PLAN = getApiConstants().endpoints.plans.GET_SINGLE_PLAN

  const { Get } = HttpClient();

  const GetPlan = async (planId: string) => {
    try {
        const {data} = await Get<IApiResponse<IPlan>>({
            url: GET_SINGLE_PLAN(planId),
        })
        return data.data;
    } catch (error) {
        throw error;
    }
  };

  const GetAllPlans = async () => {
    try {
        const { data } = await Get<IApiResponseWithPagination<IPlan[]>>({
            url: BASE_URL,
        })
        return data.data;
    } catch (error) {
        throw error;
    }
  }

  return {
    GetPlan,
    GetAllPlans,
  };
}

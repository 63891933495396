export const objectMap = (object, mapFn) =>
Object.keys(object).reduce((result, key) => {
  const output = result;
  output[key] = mapFn(object[key]);
  return output;
}, {});

export const objectMapToArray = (object, mapFn) =>
  Object.keys(object).reduce((result, key) => {
    const output: any = result;
    output.push(mapFn(object[key]));
    return output;
  }, []);

/**
 * Returns object with keys from expected object that match
 * keys from input object and take it's values.
 * 
 * @param inputObjectWithValues that should be attached to @param expectedObject
 * @param expectedObjectWithKeys that will attach it's keys to the return value
 * @returns object with Keys from @param expectedObjectWithKeys and Values from @param inputObjectWithValues
 */
export const objectTransformator = <ObjectType>(inputObjectWithValues, expectedObjectWithKeys: ObjectType) => {
  let result = {};
  Object.keys(expectedObjectWithKeys).map(key => result[key] = inputObjectWithValues[key]);
  return result as ObjectType;
};

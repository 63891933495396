import axios from "axios";
import AxiosMockAdapter from "axios-mock-adapter";

const axiosInstance = axios.create();

export const mock = new AxiosMockAdapter(axiosInstance, {
  delayResponse: 1000,
});

export default axiosInstance;

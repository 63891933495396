import { AudioPlayer } from "@components/PresentationComponents/AudioPlayer";
import { Dialog, DialogContent, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  paperOverride: {
    background: "transparent",
    // height: 130, // reducing this causes volume bar to clip
    boxShadow: "none",
  },
  dialogContent: {
    overflow: "hidden"
  },
}));

interface IProps {
  close: () => void;
  audioSrc?: string | null;
  open: boolean;
  autoplay?: boolean;
}
/**
 * 
 * @param props.open - boolean which determines if the dialog is open or closed
 * @param props.close - callback used to close the dialog
 * @param props.audioFile - url to the audio file to play
 */
const PlayAudioBasicDialog = ({ open, autoplay, close, audioSrc }: IProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        classes: {
          root: classes.paperOverride,
        },
      }}
    >
      <DialogContent className={classes.dialogContent}>
        {audioSrc && <AudioPlayer autoplay={autoplay ?? false} src={audioSrc} />}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(PlayAudioBasicDialog);
import getApiConstants from "@constants/apiConstants";
import { ICallHistory } from "@models/Callhistory.models";
import { IApiResponse, IPagination } from "../models/IPagination";
import { CallHistoryData } from "./dataClasses";
import HttpClient from "./HttpClient";

export default function CallHistoryAndReportingService() {

    const url = getApiConstants().endpoints.BLOCK_CALL;
    const deleteUrl = getApiConstants().endpoints.DELETE_CALL_RECORD_V2;
    const { Get, Put, Post, Delete } = HttpClient();

    const GetByPage = async (params: URLSearchParams) => {
        const url = getApiConstants().endpoints.callhistory.BASE;
        try {
            const data = await Get<{ data: ICallHistory[], pagination: IPagination }>({ url, params })
            return data;
        } catch (err) {
            throw err
        }
    };

    const GetOne = async (id: string) => {
        try {
            const { data } = await Get<IApiResponse<CallHistoryData>>({ url, id });
            return data;
        } catch (err) {
            throw err
        }
    };

    // const CreateOne = async (body: ) => {
    //     try {
    //         const { data } = await Post<IApiResponse<CallHistoryData>, >({ url, data: body })
    //         return data;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    const DeleteCallRecording = async (id: string | number) => {
        try {
            await Delete<void>({ url: deleteUrl(id) })
        } catch (err) {
            throw err;
        }
    };

    // const UpdateOne = async (id: string, body: ) => {
    //     try {
    //         const { data } = await Put<IApiResponse<CallHistoryData>, >({ url, id, data: body })
    //         return data;
    //     } catch (err) {
    //         throw err;
    //     }
    // };
    return {
        GetByPage,
        GetOne,
        DeleteCallRecording,
    }
}

import { useState } from "react";
import { useEffect } from "react";
import appConfig from "../../../src/config";
import { useAppSelector } from "./useAppSelector.hook";
import { useMoxtra } from "./useMoxtra.hook";
import { useAuthContext } from "../../context/AuthProvider";

type IScheduleMeetOptions = {
  chat_id?: string;
  startTime: number;
  invitees?: { email: string }[] | { unique_id: string }[];
  endTime: number;
  topic: string;
};

type IStartMenuOptions = {
  invitees?: {email: string}[] | {unique_id: string}[];
  topic: string;
  autoJoinAudio?: boolean;
  autoJoinVideo?: boolean;
  autoRecording?: boolean;
  hideRecordingCtrl?: boolean;
}

type IMoxtraSDKConfig = {
  disableDownload?: boolean;
  disableChangeChatName?: boolean;
  disableEmoji?: boolean;
  hideInactiveRelationChat?: boolean;
  fontFileLink?: string;
  disableUserPresence?: boolean;
  hideChatNotificationsSetting?: boolean;
  disableWhiteboard?: boolean;
  disableUserProfile?: boolean;
  disableForwardMessage?: boolean;
  disableLeaveChat?: boolean;
  disableChangePassword?: boolean;
  disableLogout?: boolean;
  disableEditChatMessage?: boolean;
  allowedFileTypes?: ("pdf" | "png" | "txt")[] | null;
  maxFileSize?: number;
  disableRingerUI?: boolean;
  showOOOOnly?: boolean;
  saveMeetFilesEnabled: boolean;
  disableMakeOwner?: boolean;
};

export const useMoxtraSdk = (onFetchtokenError?) => {
  const [MepSDK, setMepSDK] = useState<typeof window.mepsdk | null>(null);
  const {getMoxtraToken, clearMoxtraToken} = useAuthContext();
  const [moxtraAccessToken, setMoxtraAccessToken] = useState<string | null>(null)
  const [componentLoaded, setComponentLoaded] = useState(true);
  const isSDKAvailable = Boolean(MepSDK);

  useEffect(() => {
    let script
    getMoxtraToken().then(({token}) => {
      setMoxtraAccessToken(token)
      script = document.createElement("script");
      if (window.mepsdk !== undefined) {
        setMepSDK(window.mepsdk);
        _setup(token, window.mepsdk);
        _configureSDK(window.mepsdk);
      } else {
        script.src = appConfig.moxtraSDKURL;
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => {
          setMepSDK(window.mepsdk);
          _setup(token, window.mepsdk);
          _configureSDK(window.mepsdk); 
        };
        document.body.appendChild(script);
      }

    })
      return () => {
        document.body.contains(script) && document.body.removeChild(script);
      };
  }, []);
  
  const _setup = (token: string, sdk: typeof window.mepsdk) => {
    try {
      const response = sdk.setup({
        baseDomain: appConfig.moxtraURL,
        deployDirectory: "web",
        accessToken: token,
      });
    } catch (error) {
      throw error;
    }
  };

  const _configureSDK = (sdk: typeof window.mepsdk, options?: IMoxtraSDKConfig, ) => {
    try {
      const _options: IMoxtraSDKConfig = options? options : {
        saveMeetFilesEnabled: true,
      }
      const response = sdk.setConfig(_options);
    } catch (error) {
      throw error;
    }
  };

  const clearToken = clearMoxtraToken;
  const scheduleMeet = async (options: IScheduleMeetOptions) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return await MepSDK.scheduleMeet(options);
    } catch (error) {
      throw error;
    }
  };

  const startMeet = async (
    options: IStartMenuOptions,
    htmlContainerId: string
  ) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return await MepSDK.startMeet(options, htmlContainerId);
    } catch (error) {
      throw error;
    }
  };

  const showMEPWindow = (htmlContainerId: string) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return MepSDK.showMEPWindow(htmlContainerId);
    } catch (error) {
      throw error;
    }
  }

  const showMEPWindowLite = (htmlContainerId: string) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return MepSDK.showMEPWindowLite(htmlContainerId);
    } catch (error) {
      throw error;
    }
  }
  
  const showMEPClientDashboard = (htmlContainerId: string) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return MepSDK.showMEPClientDashboard(htmlContainerId);
    } catch (error) {
      throw error;
    }
  }

  const linkUserWithAccessToken = async (token: string) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      const response = await MepSDK.linkUserWithAccessToken(token);
      return response;
    } catch (error) {}
  };

  const joinMeeting = (
    session_key: string,
    htmlContainerId: string,
    auth?: {
      meetId?: string;
      password?: string;
      autoJoinAudio?: boolean;
      autoJoinVideo?: boolean;
    }
  ) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return MepSDK.joinMeetWithMeetID(session_key, htmlContainerId, auth);
    } catch (error) {
      throw error;
    }
  };

  const joinMeetingAnonymously = (
    session_key: string,
    htmlContainerId: string,
    auth: {
      meetId?: string;
      password?: string;
      userName?: string;
      email?: string;
    }
  ) => {
    try {
      if (!MepSDK) throw new Error("SDK not available");
      return MepSDK.joinMeetAnonymousWithMeetID(session_key, htmlContainerId, auth);
    } catch (error) {
      throw error;
    }
  };

  
  return {
    isSDKAvailable,
    scheduleMeet,
    startMeet,
    linkUserWithAccessToken,
    joinMeeting,
    showMEPWindow,
    showMEPClientDashboard,
    joinMeetingAnonymously,
    showMEPWindowLite,
    moxtraAccessToken,
  };
};

import { ComponentDimensions } from "@constants/appConstants";
import { styled } from "@material-ui/core/styles";
import appConfig from "../../config/index"

const paddingX = "13px";
const paddingY = "10px";

const MeetingViewWrap = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "1",
});

const MeetingView = styled("div")(({theme}) => ({
  borderBottom: "1px solid var(--color-dark-grey)",
  padding: "26px 43px",
  flex: "1",
  borderLeft: "1px solid var(--color-dark-grey)",
  backgroundColor: "var(--color-background-grey)",
  [theme.breakpoints.down(1200)]: {
    overflowY: "scroll",
    maxHeight: `calc(100vh - ${ComponentDimensions.MEETINGS_MAIN_SECTION_HEADER}px - ${appConfig.clientType === "desktop" ? ComponentDimensions.DESKTOP_TOOLBAR_HEIGHT : 0}px)`,
  },
}));

const MeetingCardsScroll = styled("div")({
  height: "100%",
  overflow: "auto",
});

const MeetingCard = styled("div")({
  position: "relative",
});

const MeetingCardHead = styled("div")({
  position: "sticky",
  top: 0,
  left: 0,
  backgroundColor: "var(--color-light-grey)",
  padding: `${paddingY} ${paddingX}`,
  color: "var(--color-charcoal)"
});

const MeetingCardTitle = styled("h4")({
  fontWeight: "normal",
  letterSpacing: "0.22px",
  fontSize: "15px",
  color: "inherit",
  textAlign: "left"
});

const MeetingCardItem = styled("div")({
  padding: `${paddingY} ${paddingX}`,
  color: "var(--color-charcoal)",
  fontWeight: 400,
  borderTop: "1px solid var(--color-dark-grey)",
  "&:last-child": {
    borderBottom: "1px solid var(--color-dark-grey)",
  },
});

const MeetingCardItemTitle = styled("h5")({
  fontSize: "14px",
  letterSpacing: "0.19px",
  color: "inherit",
  marginBottom: "10px",
  fontWeight: "normal"
});

const MeetingNoItemsText = styled("div")({
  fontSize: "14px",
  letterSpacing: "0.19px",
  color: "var(--color-charcoal)",
  fontWeight: "inherit",
  marginBottom: "20px",
  textAlign: "center",
  margin: "20px 20px 10px 20px"
});

const MeetingCardItemText = styled("p")({
  fontSize: "12px",
  letterSpacing: "0.15px",
  fontWeight: "inherit",
  marginBottom: "2px",
  color: "var(--color-brown-grey-darker)"
});

const MeetingCardItemActions = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  margin: "10px 0",
});

const MeetingCardButton = styled("button")({
  padding: "2px 12px 2px",
  appearance: "none",
  border: "1px solid transparent",
  borderRadius: "15px",
  fontSize: "12px",
  textAlign: "center",
  backgroundColor: "var(--color-white)",
  color: "var(--color-charcoal)",
  lineHeight: "normal",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: "var(--color-white)",
  },

  "&:not(:last-of-type)": {
    marginRight: "7px",
  },
});

const MeetingCardButtonPrimary = styled(MeetingCardButton)({
  border: "1px solid var(--color-pumpkin-orange)",
  backgroundColor: "var(--color-pumpkin-orange)",
  color: "var(--color-white)",

  "&:hover": {
    backgroundColor: "var(--color-white)",
    color: "var(--color-charcoal)",
  },
});

const MeetingCardButtonSecondary = styled(MeetingCardButton)({
  border: "1px solid var(--color-dark-grey)",

  "&:hover": {
    backgroundColor: "var(--color-dark-grey)",
    color: "var(--color-white)",
  },
});

export {
  MeetingCardsScroll,
  MeetingCard,
  MeetingCardHead,
  MeetingCardTitle,
  MeetingCardItem,
  MeetingCardItemTitle,
  MeetingCardItemActions,
  MeetingCardItemText,
  MeetingCardButton,
  MeetingCardButtonPrimary,
  MeetingCardButtonSecondary,
  MeetingViewWrap,
  MeetingView,
  MeetingNoItemsText
};

import { SELECT_VOICEMAIL_ITEMS } from "@actions/itemSelectionActions";
import { VoicemailData } from "@services/dataClasses/VoicemailData";

export interface IItemSelectionReducer {
  selectedVoicemails: VoicemailData[];
}

const initialState = {
  selectedVoicemails: [],
};

const itemSelection = (
  state: IItemSelectionReducer = initialState,
  action
): IItemSelectionReducer => {
  switch (action.type) {
    case SELECT_VOICEMAIL_ITEMS:
      return { ...state, selectedVoicemails: action.payload };
    default:
      return state;
  }
};

export default itemSelection;

import React from "react";
import { render } from "react-dom";

import { PersistGate } from "redux-persist/lib/integration/react";

import encoderPath from 'opus-recorder/dist/encoderWorker.min.js';

import { appConfig, helpers, IAppConfig, mock, Provider } from "../../core/src";

import path from "path";

import App from "./containers/App";

import { FileSystemManager } from "./helpers/FileSystemManager";
import getDeviceID from "./helpers/DeviceIdManager";

import startTalkrouteCrashReporter from "./services/talkrouteCrashReporter";

import packageJson from "../package.json";

import "../../core/src/app.global.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { history, persistor, store } from "../../core/src/helpers/store/store";

// Mocks Talkroute API
//mock.mockApi();

// Set opus encoder path
window.__opusEncoderPath = encoderPath;

const isDev = process.env.NODE_ENV === "development";

if (isDev) {
  startTalkrouteCrashReporter(false);
} else {
  const { sentryDSN } = __APP_CONFIG__;
  startTalkrouteCrashReporter(true, sentryDSN);
}

const LoadingView = () => <div>Loading....</div>;

document.addEventListener("click", (event) => {
  const url = (event?.target as any)?.href;
  if (url && url.startsWith("http")) {
    event.preventDefault();
    window.open(url, "_blank");
  }
});

BrowserFS.install(window);
// Configures BrowserFS to use the LocalStorage file system.
BrowserFS.configure(
  {
    fs: "InMemory",
  },
  function (e) {
    if (e) throw e;
    appConfig.setAppConfig(
      Object.assign(__APP_CONFIG__, {
        resourcesPath: "resources",
        fileStorage: new FileSystemManager(),
        machineId: getDeviceID(),
        clientType: "web",
        path,
        version: packageJson.version,
        environment: isDev ? "development" : "production",
        // axios: mock.axios,
      } as IAppConfig)
    );

    const rootEl = document.getElementById("root");

    const redux = {
      store,
      persistor,
      history
    }

    document.addEventListener("DOMContentLoaded", () =>
      render(
        <Provider store={redux.store}>
          <PersistGate loading={<LoadingView />} persistor={redux.persistor}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App store={redux} />
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>,
        rootEl
      )
    );
  }
);

import { IAudioFile } from "./AudioFiles.models";

export type IMenu = {
  id: number;
  name: string;
  enabled: boolean;
  audio: IAudioFile;
  audioId: string;
  menuOptions: IMenuOptions;
  parentId: null | string;
  announceOnly: boolean;
};

export enum CallDestinationType {
  VOICEMAIL = "voicemail",
  MENU = "menu",
  DISCONNECT = "hangup",
  RINGGROUP = "ring_group",
}

export type IMenuOptions = {
  id: number;
  name: string;
  enabled: boolean;
  selection: string;
  extensionsEnabled: string;
  audio: IAudioFile;
  destination: CallDestinationType;
};

export type IMenuDestination = {
  description: string;
  id: string;
  type;
};

import { makeStyles, OutlinedInput, OutlinedInputProps } from '@material-ui/core'
import React from 'react'

type Props = {
  id?: string
  className?: string
  endAdornment?: React.ReactNode
} & Omit<OutlinedInputProps, "id">

const RoundedInputField: React.FC<Props> = (
  { id, className, endAdornment, value, error, onChange, name, ...rest }) => {
  const classes = useStyles()

  return (
    <OutlinedInput 
      value={value}
      name={name}
      onChange={onChange}
      error={error}
      id={id} 
      className={`${classes.inputField} ${className}`} 
      endAdornment={endAdornment}
      {...rest}
    />
  )
}

const useStyles = makeStyles({
  inputField: {
    maxWidth: '380px',
    width: '100%',
    height: '30px',

    '&.MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {  
          borderColor: 'var(--color-pumpkin-orange)',
      },
    },
    '& fieldset': {
      height: '30px',
      borderRadius: '20px',
      top: -3
    },
    '& input::-webkit-outer-spin-button': {
      WebkitAppearance: "none"
    },
    '& input::-webkit-inner-spin-button': {
      WebkitAppearance: "none"
    }
  },
})

export default RoundedInputField
export type CH_RowsPerPage = 10 | 25 | 50 | 100;

/**
 * An enum that defines different call types.
 * Two different call types are possible, as defined below.
 */
export enum CallType {
    INBOUND = "inbound",
    OUTBOUND = "outbound",
    MISSED = "missed",
}

export enum CallStatus {
    ANSWERED = "answered",
    MISSED = "missed",
    HANGUP = "hangup",
    CLOSED = "closed",
    BLOCKED = "blocked",
}

export enum CallEventType {
    CALL_END = "call_end",
    CALL_MENU = "call_menu",
    CALL_MENU_OPTION = "call_menu_option",
    CALL_FORWARD = "call_forward",
    CALL_TRANSFER = "call_transfer",
    FORWARDING_DEVICE = "forwarding_device",
    HOURS_OF_OPERATION = "hours_of_operation",
    INBOUND_CALLER_ID_ROUTING = "inbound_caller_id_routing",
    TALKROUTE_NUMBER = "talkroute_number",
    VOICEMAIL = "voicemail",
}

type ICallHistoryEvent = {
    id: string;
    type: CallEventType;
    description: string;
    createdAt: string;
}

export type ICallHistory = {
    id: number;
    direction: CallType;
    callDate: string;
    externalNumber: string;
    phoneNumber: string;
    duration: number;
    recorded: boolean;
    recording?: string;
    result: CallStatus;
    events: ICallHistoryEvent[];
}

export type ICallHistoryv1 = {
    id: number;
    date: string;
    type: string;
    duration: number;
    call_result: string;
    number_dialed: string;
    is_call_recorded: boolean;
    call_recording: {
        id: string;
        deleted_at: string;
    };
    called_number: string;
    caller_number: string;
    caller_cname: string;
  }
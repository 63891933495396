import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGroupParams,
} from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { Typography } from "@material-ui/core";
import FilterChevron from '@resources/icons/filter-chevron.svg'

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

type ILabelValue = { label: string; value: number | string };
type IProps = {
  style?: React.CSSProperties | undefined;
  options: ILabelValue[];
  id: string;
  label?: string;
  chevronIcon?: boolean
  value?: ILabelValue | null;
  onChange:
    | ((
        event: React.ChangeEvent<{}>,
        value: ILabelValue | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<ILabelValue> | undefined
      ) => void)
    | undefined;
  groupBy?: (option: ILabelValue) => string;
  className?: string;
  transformLabel?: (valueLabel: string) => string;
  error?: boolean;
  placeholder?: string
  variant?: 'standard' | 'outlined'
  helperText?: React.ReactNode;
};

const VirtualizedSelector = ({
  options,
  id,
  style,
  label,
  chevronIcon,
  onChange,
  className,
  groupBy,
  transformLabel,
  value,
  helperText,
  error,
  placeholder,
  variant = 'standard',
}: IProps) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id={id}
      style={style}
      disableListWrap
      className={`${className} ${classes.root}`}
      fullWidth
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      renderGroup={renderGroup}
      popupIcon={chevronIcon && <FilterChevron style={{ width: '8px' }} />}
      options={options}
      value={value}
      getOptionLabel={(option) => option.label}
      groupBy={groupBy}
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          {...params}
          label={label}
          variant={variant}
          className={classes.textField}
        />
      )}
      renderOption={(option) => (
        <Typography noWrap>
          {transformLabel ? transformLabel(option.label) : option.label}
        </Typography>
      )}
      onChange={onChange}
    />
  );
};

const useStyles = makeStyles({
  root: {
    
  },
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  textField: {
    '& .MuiInput-root': {
      border: '1px solid var(--color-light-grey)',
      borderRadius: '5px',
      paddingRight: '0 !important',
      '& :focus': {
        border: '1px solid var(--color-pumpkin-orange)',
        borderRadius: '5px',
      },
    },
    '& .Mui-focused': {
      border: 'none',
    },
    '& input': {
      fontWeight: '300',
      color: 'var(--color-grey)',
      fontFamily: 'Lato, sans-serif',
      padding: '7px 36px 7px 9px !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '9px',
      top: 'calc(50% - 11px)',
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--color-pumpkin-orange)"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
  },
});

export default VirtualizedSelector;

import uiString from "@constants/uiString";
import LocalStorageManager, {
  LOCALSTORAGE_ITEM_KEYS,
} from "@helpers/LocalStorageManager";
import React, { useState } from "react";
// import CloseIcon from "@resources/icons/cross-small.svg"
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";
const FooterBanner = () => {
  const localStorageManager = new LocalStorageManager({
    key: LOCALSTORAGE_ITEM_KEYS.FOOTER_BANNER_CLOSED,
  });

  const [showFooter, setShowFooter] = useState<boolean>(
    localStorageManager.get()
  );

  const closeFooterBanner = () => {
    localStorageManager.set("true");
    setShowFooter(false);
  };

  return showFooter ? (
    <div className="footer-banner">
      <div>
        <span className="footer-banner-title">
          {uiString.footerBanner.MAIN_TEXT}
          <a
            href={uiString.DESKTOP_DOWNLOAD_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {uiString.footerBanner.LINK_TEXT}
          </a>
        </span>
        <IconButton
          style={{
            position: "absolute",
            right: "1em",
            padding: 0
          }}
          disableRipple
          onClick={closeFooterBanner}
        >
          <CloseIcon
            style={{
              fill: "white",
              opacity: 0.4,
            }}
          />
        </IconButton>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FooterBanner;

import { CONTACTS_INITIAL_PAGE, GET_TEN_THOUSAND_CONTACTS } from "@components/Contacts/constants";
import apiConstants from "@constants/apiConstants";
import { RootState } from "@helpers/store/store";
import {
  sortContacts,
} from "@helpers/functions/contacts";
import {
  IContact,
  IContactBody,
  IContactPhoneNumber,
  IContactPhoneNumberBody,
} from "@models/Contacts.models";
import { IApiResponse, IApiResponseWithPagination } from "@models/IPagination";
import { refreshPhonenumbers } from "@reducers/contactsReducer";
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosRequestConfig } from "axios";
import appConfig from "../../src/config";

const axiosBaseQuery = (
  { baseUrl }: { baseUrl?: string } = { baseUrl: "" }
): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    skipRequest?: boolean;
    usePublicApi?: boolean;
  },
  unknown,
  unknown
> => async ({ url, method, data, params, skipRequest, usePublicApi }) => {
  try {
    /**
     * skipRequest should only be used on mutations and not on queries
     */
    if (skipRequest && method !== "GET") {
      return {
        data: null,
      }
    }
    const result = await appConfig[usePublicApi? "axiosPublic" : "axios"]({ url: url, method, data, params });
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError as AxiosError;
    return {
      error: axiosError
    };

  }
};

const kingcobraApi = createApi({
  reducerPath: "kingcobraApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Contacts", "ContactModification", "Meetings"],
  endpoints: (builder) => ({
    getAllContacts: builder.query<IContact[], AxiosRequestConfig>({
      query: (arg) => ({
        url: apiConstants().endpoints.contacts.BASE_URL,
        method: "GET",
        params: arg.params,
      }),
      transformResponse: (
        response: IApiResponseWithPagination<IContact[]>,
        meta,
        arg
      ) => response.data,
      providesTags: ["Contacts"],
    }),
    deleteContact: builder.mutation<IApiResponse<IContact>, string>({
      query: (id) => ({
        url: `${apiConstants().endpoints.contacts.BASE_URL}/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(contactId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          kingcobraApi.util.updateQueryData('getAllContacts', { params: { 
            pageSize: GET_TEN_THOUSAND_CONTACTS,
            page: CONTACTS_INITIAL_PAGE
          } }, draft => {
            const contactToDelIndex = draft.findIndex(c => c.id === contactId);
            draft.splice(contactToDelIndex, 1)
            console.log(draft);
          })
        );
        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
          throw error
        }
      }
    }),
    createContact: builder.mutation<IApiResponse<IContact>, IContactBody>({
      query: (body) => ({
        url: apiConstants().endpoints.contacts.BASE_URL,
        method: "POST",
        data: body,
      }),
      async onQueryStarted(_contactBody, { dispatch, queryFulfilled }) {
        try {
          const {data} = await queryFulfilled
          const patchResult = dispatch(
            kingcobraApi.util.updateQueryData('getAllContacts', { params: { 
              pageSize: GET_TEN_THOUSAND_CONTACTS,
              page: CONTACTS_INITIAL_PAGE
            } }, draft => {
              draft.push(data.data);
              sortContacts(draft, true);
              return draft;
            })
          );
        } catch (error) {
          // patchResult.undo()
          throw error
        }
      }
    }),
    updateContact: builder.mutation<
      IApiResponse<IContact>,
      Partial<Omit<IContact, "id" | "emails">> & { id: string, emails: string[] | null }
    >({
      query: (contactToUpdate) => ({
        url: `${apiConstants().endpoints.contacts.BASE_URL}/${
          contactToUpdate.id
        }`,
        data: contactToUpdate,
        method: "PATCH",
      }),
      async onQueryStarted(contactToUpdate, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          kingcobraApi.util.updateQueryData('getAllContacts', { params: { 
            pageSize: GET_TEN_THOUSAND_CONTACTS,
            page: CONTACTS_INITIAL_PAGE
          } }, draft => {
            const contact = draft.find(ct => ct.id === contactToUpdate.id)
            Object.assign(contact, {...contactToUpdate, emails: contactToUpdate.emails? contactToUpdate.emails : [], phoneNumbers: contactToUpdate.phoneNumbers?.filter(item => item.id)})
          })
        );
        try {
          await queryFulfilled
        } catch (err) {
          patchResult.undo();
          throw err;
        }
      }
    }),
    updateContactPhoneNumber: builder.mutation<
      IApiResponse<IContactPhoneNumber>,
      {
        contactId: string;
        updatedNumber: IContactPhoneNumber;
      }
    >({
      query: ({ contactId, updatedNumber }) => ({
        url: apiConstants().endpoints.contacts.MODIFY_PHONE_NUMBER(
          contactId,
          updatedNumber.id
        ),
        method: "PATCH",
        data: updatedNumber,
      }),
      async onQueryStarted({ contactId, updatedNumber }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          kingcobraApi.util.updateQueryData('getAllContacts', { params: { 
            pageSize: GET_TEN_THOUSAND_CONTACTS,
            page: CONTACTS_INITIAL_PAGE
          } }, draft => {
            const contact = draft.find(contact => contact.id === contactId)
            if (contact) {
              const contactNumberToUpdate = contact.phoneNumbers.find(number => number.id === updatedNumber.id);
              if (contactNumberToUpdate) {
                Object.assign(contactNumberToUpdate, updatedNumber);
              } else {
                throw Error("Contact does not exist")
              }
            }
          })
        )
        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
          throw error
        }
      }
    }),
    deleteContactPhoneNumber: builder.mutation<
      IApiResponse<IContactPhoneNumber>,
      { contactId: string; numberId: string }
    >({
      query: ({ contactId, numberId }) => ({
        url: apiConstants().endpoints.contacts.MODIFY_PHONE_NUMBER(
          contactId,
          numberId
        ),
        method: "DELETE",
      }),
      async onQueryStarted({ contactId, numberId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          kingcobraApi.util.updateQueryData('getAllContacts', { params: { 
            pageSize: GET_TEN_THOUSAND_CONTACTS,
            page: CONTACTS_INITIAL_PAGE
          } }, draft => {
            const contact = draft.find(contact => contact.id === contactId)
            if (contact) {
              const numberToDeleteIndex = contact.phoneNumbers.findIndex(n => n.id === numberId);
              if (numberToDeleteIndex !== -1) {
                contact.phoneNumbers.splice(numberToDeleteIndex, 1);
              } else {
                throw Error("Number to delete does not exist");
              }
            } else {
              throw Error("Contact does not exist")
            }
          })
        )
        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
          throw error
        }
      }
    }),
    addContactPhoneNumber: builder.mutation<
      IApiResponse<IContactPhoneNumber>,
      { numberToAdd: IContactPhoneNumberBody; contactId: string }
    >({
      query: ({ numberToAdd, contactId }) => ({
        url: apiConstants().endpoints.contacts.ADD_PHONE_NUMBER(contactId),
        data: numberToAdd,
        method: "POST",
      }),
      async onQueryStarted({ contactId, numberToAdd }, { dispatch, queryFulfilled }) {
        // `updateQueryData` requires the endpoint name and cache key arguments,
        // so it knows which piece of cache state to update
        try {
          const {data} = await queryFulfilled;
          dispatch(
            kingcobraApi.util.updateQueryData('getAllContacts', { params: { 
              pageSize: GET_TEN_THOUSAND_CONTACTS,
              page: CONTACTS_INITIAL_PAGE
            } }, draft => {
              // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
              const contactIndex = draft.findIndex(contact => contact.id === contactId)
              if (contactIndex !== -1) {
                draft[contactIndex].phoneNumbers.push(data.data);
                return draft;
              } 
              return draft;
            })
          )
        } catch (error) {
          // patchResult.undo()
          throw error
        }
      }
    }),
  }),
});

export const {
  useGetAllContactsQuery,
  useDeleteContactMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
  useUpdateContactPhoneNumberMutation,
  useAddContactPhoneNumberMutation,
  useDeleteContactPhoneNumberMutation,
} = kingcobraApi;
export default kingcobraApi;

/**
 * impersonateUserIdReducer.js
 * Talkroute
 */

import userConstants from "@constants/userConstants";

export interface IImpersonateUserReducer {
    impersonateUserId: number | null;
}

const initialState: IImpersonateUserReducer = {
    impersonateUserId: null,
}

/**
 * Called to process impersonation actions
 *
 * @param {*} state
 * @param {*} action
 */

export default function impersonateUserReducer(state: IImpersonateUserReducer = initialState, action): IImpersonateUserReducer {
    switch(action.type) {
        case userConstants.SAVE_IMPERSONATE_USER_ID:
            return {
                ...state,
                impersonateUserId: action.payload,
            }
        default:
            return state
    }
}
export type IContactsPartition = {
  [ name: string ]: IContact[]
};

export enum ContactPhoneTypeEnum {
  MOBILE = "Mobile",
  OFFICE = "Office",
  HOME = "Home",
  OTHER = "Other"
};

export enum ContactDialogEnum {
  SEARCH_CONTACT_NAMES = "searchContactNames",
  CURRENT_CONTACT_NAME = "currentContactName",
  CURRENT_CONTACT_ID = "currentContactId"
};

export type IContactPhoneNumber = {
  phoneNumber: string;
  type: ContactPhoneTypeEnum;    
  id: string;
};

export type IContact = {
  name: string;
  company: string;
  emails: string[];
  role: string;
  url: string;
  notes: string;
  id: string;
  phoneNumbers: IContactPhoneNumber[];
  isFavorite?: boolean;
};

export type IContactDefaultPhone = {
  "default phone number": string | undefined;
  rawPhoneNumber: string | undefined;
} & IContact

export type IContactBody = Partial<Omit<IContact, "phoneNumbers" | "id">> & {id: string}
export type IContactPhoneNumberBody = Omit<IContactPhoneNumber, "id">;

export type IContactFormData = {
  name: string,
  phoneNumbers: IContactPhoneNumber[],
  phoneNumberFieldsCount: number,
  emails: string[],
  emailAddressFieldsCount: number,
  id: string,
  company: string,
  notes: string,
  role: string,
  url: string,
};

export type IContactForm = {
  searchContactNames: string;
  currentContactName: string;
  currentContactId: string;
};

export type IContactDropdownForm = Omit<IContactForm, "currentContactName" | "currentContactId">
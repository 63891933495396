import alertActions from '@actions/alertActions'
import { clearErrors } from '@actions/errorActions'
import { logout, setUserTokenReady } from '@actions/userActions'
import routes, { isPublicRoute } from '@constants/routes'
import uiString from '@constants/uiString'
import userConstants from '@constants/userConstants'
import { useAppSelector } from '@helpers/hooks/useAppSelector.hook'
import useLocalStorage, { localStorageItems } from '@helpers/hooks/useLocalStorage.hook'
import AuthService from '@services/Auth.service'
import { AxiosRequestConfig } from 'axios'
import { push } from 'connected-react-router'
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import appConfig from '../config'
import DeviceService from '@services/Devices.service'
import MoxtraService from '@services/Moxtra.service'
import { impersonateSuccess } from "../actions/userActions";
import UserService from "@services/User.service";

const moxtraService = MoxtraService();
class PreRequestLock {
  private promise?: Promise<unknown>;
  private fullfil: (_v: unknown) => void
  constructor() {
    this.fullfil = (v: unknown) => { }

    // lock by default on initialization
    this.lock();
  }

  async wait() {
    if (this.promise !== undefined) await this.promise;
  }

  unlock() {
    this.fullfil(0)
  }

  lock() {
    if (this.promise !== undefined) return;
    this.promise = new Promise<unknown>((r, j) => this.fullfil = r)
      .then(() => this.promise = undefined)
  }

  locked(): boolean {
    return this.promise !== undefined;
  }
}

type Session = {
  accessToken: string
  refreshToken: string
  // userId: number
  tokenExpiresAt: number
}

const DUMMY_SESSION: Session = {
  accessToken: "",
  refreshToken: "",
  tokenExpiresAt: 0
}

type MoxtraSdkSession = {
  moxtraAccessToken: string
  expires_in: number
}

const TEMP_REFRESH_TOKEN_KEY = "TEMP_REFRESH_TOKEN_KEY";
const TEMP_ACCESS_TOKEN_KEY = "TEMP_ACCESS_TOKEN_KEY";
class MemorySessionManager {
  public mutex: PreRequestLock;

  private waitingRequests: Map<string, number>;

  private postRequestInterceptor?: number;

  constructor(session: Session) {
    this.mutex = new PreRequestLock();
    this.refreshToken = session.refreshToken;
    this.waitingRequests = new Map<string, number>();
    this.renewSession(session);
    this.initPreRequestInterceptor();
  }

   private get refreshToken(): string {
      return localStorage.getItem(TEMP_REFRESH_TOKEN_KEY) ?? "";
   }

   private set refreshToken(token: string) {
    localStorage.setItem(TEMP_REFRESH_TOKEN_KEY, token);
   }

   private get tempAccessToken(): string {
      return localStorage.getItem(TEMP_ACCESS_TOKEN_KEY) ?? "";
   }

   private set tempAccessToken(token: string) {
    localStorage.setItem(TEMP_ACCESS_TOKEN_KEY, token);
   }

  renewSession(session: Session) {
    this.tempAccessToken = session.accessToken;
    this.refreshToken = session.refreshToken;
    appConfig.axios.defaults.headers.common["Authorization"] = `Bearer ${session.accessToken}`;
  }

  getAccessToken(): string {
    return this.tempAccessToken ?? "";
  }

  setRefreshToken(token: string) {
    this.refreshToken = token;
  }

  getRefreshToken(): string {
    return this.refreshToken;
  }

  updateRequestConfig(config: AxiosRequestConfig) {
    config.headers["Authorization"] = `Bearer ${this.getAccessToken()}`;
  }

  async refreshAccessToken(): Promise<Session> {
    try {
      const refreshTokenPayload = await authService.RefreshAccessToken(this.refreshToken);
      const newSession: Session = {
        accessToken: refreshTokenPayload.accessToken,
        refreshToken: refreshTokenPayload.refreshToken,
        tokenExpiresAt: refreshTokenPayload.tokenExpiresAt,
      }

      this.renewSession(newSession);
      this.mutex.unlock();
      return newSession;
    }
    catch (e) {
      throw e;
    } finally {
      this.mutex.unlock();
    }
  }

  initPreRequestInterceptor() {
    // Initialize global pre-request interception logic
    appConfig.axios.interceptors.request.use(async r => {
      await this.mutex.wait();
      this.updateRequestConfig(r);
      return r;
    })
  }

  async initPostRequestInterceptor(onRefreshSuccess: ((session: Session) => void), onRefreshFailed: ((e: any) => void)) {
    this.postRequestInterceptor = appConfig.axios.interceptors.response.use(r => r, async (err) => {
      const originalRequest = err.config;
      if (
        err?.response?.status === 401 &&
        !originalRequest._retry
      ) {
        if (!this.mutex.locked()) {
          this.mutex.lock();
          await this.refreshAccessToken().then(onRefreshSuccess).catch(onRefreshFailed);
        } else {
          await this.mutex.wait();
        }
        originalRequest._retry = true;
        this.updateRequestConfig(originalRequest);

        if (this.isRequestQueued(originalRequest.url)) {
          return Promise.reject(new Error("Request already already queued for retry"));
        }
        this.queueRequest(originalRequest);
        return appConfig.axios(originalRequest);
      } else {
        return Promise.reject(err);
      }
    });
  }

  // request queueing
  queueRequest(config: AxiosRequestConfig) {
    if (config.url === undefined) return;
    this.waitingRequests.set(config.url, Date.now());
  }

  isRequestQueued(url: string = ""): boolean {
    const has = this.waitingRequests.has(url);
    if (!has) return false;
    const time = this.waitingRequests.get(url) ?? 0;
    return (Date.now() - time) < 1000 * 5; // check if request was queued less than 5 seconds ago
  }
}

class MoxtraTokenManager {
  private _token: string;
  private _expiration: number;

  constructor() {
    this._token = localStorage.getItem(localStorageItems.moxtraToken) || '';
    this._expiration = Number(localStorage.getItem(localStorageItems.moxtraTokenExp)) || 0;
  }
  
  public get token(): string {
    return this._token;
  }

  private set token(t: string) { 
    this._token = t;
    localStorage.setItem(localStorageItems.moxtraToken, t)
  }

  public get expiration(): number {
    return this._expiration;
  }

  private set expiration(e: number) {
    this._expiration = e;
    localStorage.setItem(localStorageItems.moxtraTokenExp, String(e))
  }

  public getToken = async (userId: number | null, session: Session | null) => {
    try {
      const userIsAuthenticated = session && userId
      const {access_token, expires_in} =
      await (userIsAuthenticated ? moxtraService.GetAccessToken(String(userId), session.accessToken) : moxtraService.GetAccessTokenForGuest());
      this.token = access_token;
      this.expiration =  new Date().getTime() + Number(expires_in) * 1000 - 30 * 1000
      return {
        token: this.token,
        expiration: this.expiration
      }
    } catch (error) {
      throw error;
    }
  }

  public renewSession = async (userId: number | null, session: Session | null) => {
    try {
      if (this.expiration < Date.now()) {
        return await this.getToken(userId, session)
      } else {
        return {
          token: this.token,
          expiration: this.expiration
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public clear() {
    this.token = "";
    this.expiration = 0;
  }
}

// used to dynamically reinitialize requests after the new access token has been fetched
let sessionManager: MemorySessionManager | undefined = undefined;

type AuthContextPayload = {
  session: Session | null
  setSession: React.Dispatch<Session | null>
  accessTokenStatus: "pending" | "ready" | "notavailable"
  getMoxtraToken: () => Promise<{
    token: string;
    expiration: number;
  }>
  clearMoxtraToken: () => void
  logout: () => void
  clearData: () => void
  login: (userName: string, password: string) => void;
}


export const AuthContext = React.createContext<AuthContextPayload | undefined>(undefined)

export function useAuthContext(): AuthContextPayload {
  const context = useContext(AuthContext)
  // Check if caller component is within a AuthContext.Provider
  if (context === undefined) {
    throw new Error('useAuthContext must be within a AuthContext')
  }
  return context
}

interface Props {
  children?: ReactNode
}

const authService = AuthService();

export const AuthProvider: React.FunctionComponent<Props> = ({ children }: Props) => {
  const [cachedSession, setCachedSession] = useLocalStorage<Session | null>('session', null);
  // const [cachedMoxtraSession, setCachedMoxtraSession] = useLocalStorage<MoxtraSdkSession | null>(localStorageItems.moxtraSession, null);

  const userTokenReady = useAppSelector(state => state.authentication.userTokenReady);
  const [accessTokenStatus, setAccessTokenStatus] = useState<"pending" | "ready" | "notavailable">("pending");
  const dispatch = useDispatch()
  const oldAccessToken = useAppSelector(state => state.authentication.accessToken);
  const oldRefreshAccessToken = useAppSelector(state => state.authentication.refreshToken);
  const userId = useAppSelector((state) => state.authentication.userId);
  const {current: moxtraTokenManager} = useRef(new MoxtraTokenManager())
  // const moxtraAccessToken = useAppSelector(state => state.authentication.moxtraAccessToken);
  // const moxtraAccessTokenExpDate = useAppSelector(state => state.authentication.moxtraAccessTokenExpDate);
  const impersonateUserId = useAppSelector(state => state.impersonateUserReducer.impersonateUserId);

  useEffect(() => {
    const oldSessionExists = oldAccessToken && oldRefreshAccessToken;

    // if migrating from Redux (session present) to local storage (session not present)
    if (!cachedSession && oldSessionExists) {
      sessionManager = new MemorySessionManager({...DUMMY_SESSION, refreshToken: oldRefreshAccessToken});
      sessionManager.initPostRequestInterceptor(onRefreshSuccess, onRefreshFailed);
      sessionManager.refreshAccessToken().then(onRefreshSuccess).catch(onRefreshFailed);
    }
    // If local storage session exists, initialize session manager
    else if (cachedSession) {
      sessionManager = new MemorySessionManager(cachedSession);
      sessionManager.initPostRequestInterceptor(onRefreshSuccess, onRefreshFailed);
      if (cachedSession.tokenExpiresAt > new Date().getTime()) {
        dispatch(alertActions.clear());
        dispatch(clearErrors());
        dispatch(setUserTokenReady());
        sessionManager.mutex.unlock();
      } else {
        sessionManager.refreshAccessToken().then(onRefreshSuccess).catch(onRefreshFailed);
      }
    } else if (!isPublicRoute(window.location.pathname)){
      setAccessTokenStatus("notavailable");
      _logout();
    } else {
      setAccessTokenStatus("notavailable");
    }

    // If local storage session does not exist && no old session exists,
    // initialize session manager
    if (sessionManager === undefined) {
      sessionManager = new MemorySessionManager({ ...DUMMY_SESSION });
      sessionManager.initPostRequestInterceptor(onRefreshSuccess, onRefreshFailed);
    }
  }, []);


  useEffect(() => {
    if (userTokenReady) {
      setAccessTokenStatus("ready");
    }
  }, [userTokenReady])

  const onRefreshSuccess = (session: Session) => {
    setCachedSession(session);
    dispatch(setUserTokenReady());
  }

  const onRefreshFailed = (err: any) => {
    const errConnLost = err.code === "ECONNABORTED";
    const errNetworkError = err.message && err.message.toLowerCase() === "network error"
    if (errConnLost || errNetworkError) {
      sessionManager?.mutex.lock();
      setTimeout(() => {
        sessionManager?.refreshAccessToken().then(onRefreshSuccess).catch(onRefreshFailed);
      }, 1000 * 5);
    } else {
      _logout();
    }
  }

  const _logout = (afterDeviceDeregistrationFn?: () => void) => {
    if (appConfig.clientType === "desktop" && appConfig.deviceDeregistrationId ) {
      const {UnregisterDevice } = DeviceService()
      UnregisterDevice(appConfig.deviceDeregistrationId, appConfig.clientType).then(
        () => {
          setCachedSession(null);
          dispatch(logout());
          afterDeviceDeregistrationFn && afterDeviceDeregistrationFn()
        }
        ).catch(err => {          
          setCachedSession(null) ;
          dispatch(logout());
          afterDeviceDeregistrationFn && afterDeviceDeregistrationFn()
      });
    } else {
      setCachedSession(null);
      dispatch(logout());
    }
    cleanupLocalStorage();    
    moxtraTokenManager.clear();
  }

  const cleanupLocalStorage = () => {
    localStorage.removeItem(localStorageItems.meetCache)
  }

  const clearData = () => _logout(() => localStorage.clear())

  const login = async (userName: string, password: string) => {
    try {
      const response = await authService.Authenticate(userName, password);
      const newSession = {
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        tokenExpiresAt: response.tokenExpiresAt,
        // userId: response.userId
      };

      sessionManager?.renewSession(newSession);
      sessionManager?.initPostRequestInterceptor(onRefreshSuccess, onRefreshFailed);

      setCachedSession(newSession)
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: {
          user: userName,
          tokenExpiresAt: response.tokenExpiresAt,
          webrtcUserName: response.webrtcUserName,
          webrtcPassword: response.webrtcPassword,
          firstName: response.firstName,
          lastName: response.lastName,
          companyName: response.companyName,
          userId: response.userId,
          accountId: response.accountId
        },
      });

      // Check if impersonateUserId exists
      if (impersonateUserId) {
        // if it does, get user & account data for the impersonated user
        const { GetUser } = UserService();
        const { GetAccountDetails } = UserService();
        try {
          const userData = await GetUser(impersonateUserId);
          if (userData) {
            try {
              const accountDetails = await GetAccountDetails(userData.account_id)
              if (accountDetails) {
                // dispatch the data that needs to change
                dispatch(impersonateSuccess(impersonateUserId, userData.email, userData.first_name, userData.last_name, userData.account_id, accountDetails.name));
              }
            } catch (error) {
              console.warn(error);
            }
          }
        } catch (error) {
          console.warn(error);
        }
      }

      dispatch(alertActions.clear());
      dispatch(clearErrors());
      dispatch(push(routes.HOME));
      dispatch(setUserTokenReady());
      clearMoxtraToken();
    } catch (error) {
      const { response } = error as any;
      let errMessage = uiString.AUTH_ERROR;
      if (response) {
        const { data } = response;

        if (data) {
          const { message } = data;

          if (message) {
            errMessage = message;
          }
        }
      }

      dispatch({
        type: userConstants.LOGIN_FAILURE,
        payload: {
          error: errMessage,
        },
      })
    }
    finally {
      sessionManager?.mutex.unlock();
    }
  }

  const getMoxtraToken = async () => {
    try {
      const response = await moxtraTokenManager.renewSession(userId, cachedSession);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const clearMoxtraToken = moxtraTokenManager.clear;

  return (
    <AuthContext.Provider
      value={{
        session: cachedSession,
        setSession: setCachedSession,
        logout: _logout,
        login,
        clearData,
        accessTokenStatus,
        getMoxtraToken,
        clearMoxtraToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

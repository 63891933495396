import React, { useEffect, useState } from "react";
import { TextMessageHistoryFilterQuery } from "./TextMessageHistoryTypes";
import uiString from '@constants/uiString';
import { formatPhoneNumber } from "@services/dataClasses/CallData";
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
    TEXT_MESSAGE_HISTORY_TABLE_INITIAL_FILTER_QUERY,
} from "./constants";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import Loader from "@components/PresentationComponents/Loader";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import Tooltip from '@components/PresentationComponents/FormComponents/Tooltip';
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { ComponentDimensions } from "@constants/appConstants";
import { useDispatch } from "react-redux";
import { fetchFilteredConversationHistory, fetchNextBatchConversationHistory } from "@actions/textMessageHistoryActions";
import dataStateEnum from "@constants/dataStateConstants";
import TextMessageHistoryFilters from "./TextMessageHistoryFilters";
import TextMessageHistoryEvent from "./TextMessageHistoryEvent";
import DownloadButton from "@resources/icons/arrow-download-button.svg";
import { getConversationDownload } from "@actions/conversationActions";
import { GET_TEN_THOUSAND_CONTACTS, CONTACTS_INITIAL_PAGE } from "@components/Contacts/constants";
import { useGetAllContactsQuery } from "@services/kingcobraApi";
import { addContactNameToConversationHistory } from "@helpers/functions/contacts";

const TextMessageHistoryView = () => {
  // Set initial filter query
  const rowsPerPage = useAppSelector((state) => state.preferences.tableSize.textMessageHistory);
  const [filterQuery, setFilterQuery] = useState<TextMessageHistoryFilterQuery>(
    TEXT_MESSAGE_HISTORY_TABLE_INITIAL_FILTER_QUERY,
  );
  const [prevFilterQuery, setPrevFilterQuery] = useState<TextMessageHistoryFilterQuery>(
    TEXT_MESSAGE_HISTORY_TABLE_INITIAL_FILTER_QUERY,
  )
  const textMessageHistoryData = useAppSelector(state => state.textMessageHistoryReducer.filteredData);
  const paginationData = useAppSelector(state => state.textMessageHistoryReducer.conversationPagination);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAdvancedMode, setAdvancedMode] = useState<boolean>(false);

  const { data: contactList } = useGetAllContactsQuery({
    params: {
      pageSize: GET_TEN_THOUSAND_CONTACTS,
      page: CONTACTS_INITIAL_PAGE,
    },
  });

  const updateTextMessageHistory = (query: TextMessageHistoryFilterQuery) => {
    dispatch(fetchFilteredConversationHistory({ ...query, pageSize: rowsPerPage }));
  };

  useEffect(() => {
    const updatedFilterQuery = { ...filterQuery, pageSize: rowsPerPage };
    setFilterQuery(updatedFilterQuery);
    updateTextMessageHistory(updatedFilterQuery);
  }, [rowsPerPage]);

  const textMessageHistoryDataWithContacts = contactList ? addContactNameToConversationHistory(contactList, textMessageHistoryData) : textMessageHistoryData;

  const textMessageHistory = textMessageHistoryDataWithContacts.map(conversation => ({
    ...conversation,
    id: conversation.conversationId,
  }));

  const handlePaginationChange = (page: number) => {
    const updatedFilterQuery = {
        ...prevFilterQuery,
        page,
        pageSize: rowsPerPage,
    };
    setFilterQuery(updatedFilterQuery);
    dispatch(fetchNextBatchConversationHistory(updatedFilterQuery, page, rowsPerPage));
  };

  const outgoingTextMessagePhoneNumbers = useAppSelector(state => state.preferences.outgoingTextPhoneNumbers);
  const dataState = useAppSelector(state => state.textMessageHistoryReducer.dataState);
  const textMessageHistoryLoading = dataState === dataStateEnum.LOADING;

  const csvDownload = (conversationId) => () => {
    getConversationDownload(conversationId)
      .catch(error => {
        console.error('Error downloading CSV:', error);
      });
  };

  return (
    <>
      <UnderlinedTitle>{uiString.SETTINGS_AND_PREF.TEXT_MESSAGING_HISTORY.TITLE}</UnderlinedTitle>
      {textMessageHistoryLoading && <Loader open={textMessageHistoryLoading} />}
      <div className={classes.content}>
        <TextMessageHistoryFilters
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          isAdvancedMode={isAdvancedMode}
          setAdvancedMode={setAdvancedMode}
          updateTextMessageHistory={updateTextMessageHistory}
          onApplyFilters={(fq) => fq && setPrevFilterQuery(fq)}
          outgoingTextNumbers={outgoingTextMessagePhoneNumbers}
        />

        {textMessageHistory && (
          <CustomTablePagination
            pagination={paginationData}
            handlePageChange={handlePaginationChange}
            storeKey="textMessageHistory"
          >
            <PlainTable
              data={textMessageHistory}
              selectColumns={["date", "time", "virtual phone number", "contact", "messages"]}
              dataMapper={(row) => ({
                ...row,
                date: row.getFormattedDate(),
                time: row.getSimpleFormattedTime(),
                "virtual phone number": formatPhoneNumber(row.talkrouteNumber),
                "contact": row.getContactInfo(),
                messages: row.messageCount,
              })}
              actions={[
                {
                  colLabel: "Transcript",
                  width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                  ActionComponent: (row) =>
                    (
                      <Tooltip
                        title={"Download Conversation"}
                        aria-label="download"
                        styling="standard"
                      >
                      <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center", 
                          padding: 8
                        }}>
                          <IconButton
                            onClick={csvDownload(row.conversationId)}
                          >
                            <DownloadButton style={{ 
                              width: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
                              height: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
                              fill: "var(--color-charcoal)" }} />
                          </IconButton>
                          
                      </div>
                      </Tooltip>
                    ),
                }
              ]}
              extraDetails={{
                header: "Details",
                resetTrigger: textMessageHistoryLoading,
                DetailsComponent: (row, open) => (
                  <TextMessageHistoryEvent row={row} open={open} />
                ),
              }}
              fullWidth
            />
          </CustomTablePagination>
        )}
        {!textMessageHistory && !textMessageHistoryLoading && (
          <div className={classes.center}>
            <Typography variant="h5">No records found</Typography>
          </div>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    relative: {
      position: "relative",
      minHeight: "100%",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: '100%',
    },
    pagination: {
      paddingTop: 15,
      overflow: "hidden",
      display: "flex",
      justifyContent: "space-between",
    },
    fixedPosition: {
      position: "sticky",
      bottom: "15px",
      width: "100%",
      minWidth: "calc(100% - 300px)", // 250px the min for navigation plus 50px is for parent padding
    },
    regularTable: { overflow: "auto", overflowX: "hidden" },
    advancedTable: { overflow: "auto", overflowX: "hidden" },
    rowsPerPage: { display: "flex" },
    rowsPerPageSelect: {
      marginLeft: 5,
      marginBottom: 5,
      "&:before, &:after": {
        border: "none !important",
      },
      "&>div": {
        paddingLeft: 5,
      },
    },
    phoneNumberWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "fit-content"
    }
  })
);

export default TextMessageHistoryView;

import axios, { AxiosInstance, AxiosStatic } from "axios";
import path from "path";

import { IFileStorage } from "../models/IFileStorage";


type EnvironmentString = "production" | "development";

export interface IAppConfig {
  version?: string;
  environment?: EnvironmentString;
  machineId: string;
  baseURL: string;
  webAppUrl: string;
  moxtraURL: string;
  moxtraSDKURL: string;
  webRTC: string;
  sentryDSN?: string;
  axios?: AxiosStatic;
  fileStorage: IFileStorage;
  electron?: any;
  path: typeof path;
  clientType?: "web" | "desktop";
  resourcesPath?: string;
}

let appConfig = {} as AppConfig;
class AppConfig {
  public version: string = "1.0.0";
  public machineId: string = "";
  public baseURL: string = "";
  public webAppUrl: string = "";
  public moxtraURL: string = "";
  public moxtraSDKURL: string = "";
  public webRTC: string = "";
  public sentryDSN: string = "";
  public axios: AxiosStatic = axios;
  public axiosPublic: AxiosInstance = axios;
  public fileStorage?: IFileStorage;
  public deviceDeregistrationId?: number;
  public electron?: any;
  public path?: typeof path;
  public clientType: "web" | "desktop" = "desktop";
  public resourcesPath: string = "resources";

  private environment: EnvironmentString = "production";

  constructor() {}

  setAppConfig(inputConfig: IAppConfig) {
    if (inputConfig.axios) this.axios = inputConfig.axios;
    if (inputConfig.environment) this.environment = inputConfig.environment;
    if (inputConfig.version) this.version = this.embelishVersionString(inputConfig.version, inputConfig.environment ?? "production");
    if (inputConfig.clientType) this.clientType = inputConfig.clientType;
    if (inputConfig.sentryDSN) this.sentryDSN = inputConfig.sentryDSN;

    this.baseURL = inputConfig.baseURL;
    this.webRTC = inputConfig.webRTC;
    this.webAppUrl = inputConfig.webAppUrl;
    this.moxtraURL = inputConfig.moxtraURL;
    this.moxtraSDKURL = inputConfig.moxtraSDKURL;
    this.fileStorage = inputConfig.fileStorage;
    this.electron = inputConfig.electron;
    this.path = inputConfig.path;
    this.machineId = inputConfig.machineId;
    this.axios = axios;
    this.axiosPublic = axios.create({
      baseURL: inputConfig.baseURL,
      headers: {
        "access-control-allow-origin": "*"
      }
    })
    this.axios.defaults.baseURL = this.baseURL;
    this.axios.defaults.headers.common["access-control-allow-origin"] = "*";
    // this.axios.defaults.timeout = 30 * 1000;
    if (inputConfig.resourcesPath)
      this.resourcesPath = inputConfig.resourcesPath;
  }

  setDeviceDeregistrationId(id: number) {
    this.deviceDeregistrationId = id;
  }

  private embelishVersionString(version: string, environment: EnvironmentString) {
    if (environment === "development") {
      return `${version}-dev-${process.env.LAST_COMMIT_HASH}`;
    }
    return version;
  }

}
appConfig = new AppConfig();
export default appConfig;

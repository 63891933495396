import { MAX_NUMBER_RECURRENT_MEETINGS_TO_SHOW } from "@components/Meetings/constants";
import { IScheduledMeeting } from "@models/Moxtra.models";
import moment from "moment";

export const startTime = (time: string, isToday?: boolean) => {
  const date = moment(time).format(
    isToday ? "hh:mm A" : "YYYY-MM-DD, hh:mm A"
  );
  return isToday ? "Today: " + date : date;
};

export const endTime = (time: string) => {
  const date = moment(time).format("hh:mm A");
  return date;
};

export const filterRecurrentMeetings = (meetings: IScheduledMeeting[]) => {
  const countMeetings = {};
  const filteredMeetings: IScheduledMeeting[] = [];
  for (let meeting of meetings) {
    if (countMeetings[meeting.session_key]) {
      countMeetings[meeting.session_key] =
        countMeetings[meeting.session_key] + 1;
    } else {
      countMeetings[meeting.session_key] = 1;
    }

    if (
      countMeetings[meeting.session_key] <=
      MAX_NUMBER_RECURRENT_MEETINGS_TO_SHOW
    ) {
      filteredMeetings.push(meeting);
    }
  }
  return filteredMeetings;
};
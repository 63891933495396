import getApiConstants from "@constants/apiConstants";
import { IApiResponse, IApiResponseWithPagination } from "@models/IPagination";
import { IVoicemailBox, IVoicemailBoxBody } from "@models/Voicemailbox.models";
import HttpClient from "./HttpClient";

export default function VoicemailboxService() {
    const url = getApiConstants().endpoints.voicemailv2.VOICEMAILBOX_BASE;
  
    const { Get, Put, Post, Delete } = HttpClient();
  
    const GetByPage = async (
      page: number,
      pageSize: number,
      forUpdating?: number,
      search?: {
       email?: string;
       description?: string; 
      },
    ) => {
      try {
        if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
        const params = new URLSearchParams();
        params.set("page", String(page));
        params.set("pageSize", String(pageSize));
        search?.email && params.set("email", String(search?.email));
        search?.description && params.set("description", String(search?.description));
        forUpdating && params.set("forUpdating", String(forUpdating));
        const { data } = await Get<IApiResponseWithPagination<IVoicemailBox[]>>({
          url,
          params,
        });
        return data;
      } catch (err) {
        throw err;
      }
    };
  
    const CreateOne = async (body: IVoicemailBoxBody) => {
        try {
          const response = await Post<IApiResponse<IVoicemailBox>, IVoicemailBoxBody>({
            url,
            data: body
          })
          return response;
        } catch (err) {
          throw err;
        }
    };
  
    const DeleteOne = async (id: string) => {
        try {
          const response = await Delete({
            url,
            id
          })
          return response;
        } catch (err) {
          throw err;
        }
    };
  
    const UpdateOne = async (id: string, body: any) => {
      try {
        const response = await Put<IApiResponse<IVoicemailBox>, IVoicemailBoxBody>({
          url,
          id,
          data: body
        })
        return response;
      } catch (err) {
        throw err;
      }
    };
  
    return {
      GetByPage,
      CreateOne,
      DeleteOne,
      UpdateOne,
    };
  }
  
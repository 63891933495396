import { AudioTypes } from "./AudioFiles.models";

export type IFormValidator<DataType> = (data: DataType) => IFormErrorField;

export type IFormField<DataType = any> = {
  initialValue: DataType;
  validators: IFormValidator<DataType>[];
};

export type IFormMetaData = {
  [name: string]: { dirty: boolean, isCheckbox: boolean };
}

export type IFormErrorField = {
  hasError: boolean;
  validatorResults: { code: FormValidatorCodes | null, errorMessage: string};
  fieldIndex?: number[];
};

export interface IFormOptions {
  [name: string]: IFormField;
};

export interface IFormError {
  [name: string]: IFormErrorField;
};

export interface IDropdownOption {
  text: string;
  value: string | number;
}

export interface IAudioFileDropdown extends IDropdownOption {
  link: string;
  type?: AudioTypes;
}

export enum FormValidatorCodes {
  MAX_LENGTH,
  MIN_LENGTH,
  VALIDATE_EMAIL,
  DUPLICATED_INPUT,
  REQURED_FIELD, 
  IS_PHONE_NUMBER,
  COMPANY_LENGTH,
  ROLE_LENGTH
};
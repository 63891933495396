import React, { FC, memo, useState } from 'react';
import { 
  ContactLineWrapper, 
  ContactLetterLineItem, 
  ContactFullNameWrapper, 
  ContactWrapper, 
  ContactTableCell,
  ContactIconTableCell,
  ContactTableBody,
  ContactPhoneStyledRow
} from './Contacts.styles';
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { IContact } from '@models/Contacts.models';
import { CONTACTS_FIRST_INDEX } from '@helpers/functions/contacts';
import { Collapse, IconButton, makeStyles, Table, Tooltip } from '@material-ui/core';
import { formatBothNationalInternational, isPhoneNumberInternational, trimPhoneNumberPlusSign } from '@helpers/functions/phoneNumberFormatter';
import CallBackIcon from "@resources/icons/top-nav-call-back.svg";
import createKeyIndex from '@helpers/createKeyIndex';
import { FAST_DIAL, NO_PHONE_NUMBER_AVAILABLE } from './constants';
import clsx from 'clsx';
import { parsePhoneNumber } from 'libphonenumber-js';

type IProps = {
  contactData: IContact;
  onClick: (phoneNumber: string) => void;
  headerLetter?: string;
};

/**
 * Display a row with contact full name and a dropdown.
 */
const DropdownLineItemBase: FC<IProps> = ({ contactData, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const expandMoreHandler = () => setIsOpen(prevState => !prevState);
  const classes = useStyles();

  return (
    <ContactWrapper>
      {contactData.phoneNumbers.length === 0
        ? <Tooltip title={NO_PHONE_NUMBER_AVAILABLE}>
            <ContactLineWrapper>
              <ContactFullNameWrapper>
                <span>{contactData.name}</span>
              </ContactFullNameWrapper>
            </ContactLineWrapper>
          </Tooltip>
        : <ContactLineWrapper 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} 
            onClick={expandMoreHandler}>
            <ContactFullNameWrapper>
              <span>{contactData.name}</span>
            </ContactFullNameWrapper>
            {isOpen 
              ? <>
                  <ExpandLess />
                  <Tooltip title={FAST_DIAL}>
                    <IconButton 
                      className={classes.iconButton}
                      disableFocusRipple
                      onClick={() => onClick(contactData.phoneNumbers[CONTACTS_FIRST_INDEX].phoneNumber)}
                    >
                      <CallBackIcon style={{ width: 17, height: 17 }} />
                    </IconButton>
                  </Tooltip>
                </>
              : <>
                  {isHovered && <ExpandMore />}
                  <Tooltip title={FAST_DIAL}>
                    <IconButton 
                      className={classes.iconButton}
                      disableFocusRipple
                      onClick={() => onClick(contactData.phoneNumbers[CONTACTS_FIRST_INDEX].phoneNumber)}
                    >
                      <CallBackIcon style={{ width: 17, height: 17 }} />
                    </IconButton>
                  </Tooltip>
                </>
            }
          </ContactLineWrapper>
      }
      <Collapse in={isOpen}>
        <Table>
          <ContactTableBody>
            {contactData.phoneNumbers.length !== 0 &&
              contactData.phoneNumbers.map(({ phoneNumber, id, type }, index) => {
                const isInternational = isPhoneNumberInternational(phoneNumber)
                return (
                  <ContactPhoneStyledRow 
                    onClick={() => !isInternational && onClick(phoneNumber)} 
                    key={createKeyIndex(index, id)}
                  >
                    <ContactTableCell className={clsx(isInternational && classes.disabledRow)}>
                      <p style={{ minWidth: "70px", display: 'inline-block' }}>
                        <b>{`${type}: `}</b>
                      </p>
                      { isInternational
                        ? parsePhoneNumber(phoneNumber).formatInternational()
                        : formatBothNationalInternational(trimPhoneNumberPlusSign(phoneNumber))
                      }
                    </ContactTableCell>
                    {!isInternational && <ContactIconTableCell>
                      <CallBackIcon style={{ width: 17, height: 17 }} />
                    </ContactIconTableCell>}
                  </ContactPhoneStyledRow>
              )})
            } 
          </ContactTableBody>
        </Table>
      </Collapse>
    </ContactWrapper>
  );
};

/**
 * Display a row with first name letter and below it a row with contact full name and a dropdown.
 * If the first letter is a symbol, display item under "Other" header row.
 */
const DropdownLineItem: FC<IProps> = ({ contactData, onClick, headerLetter }) => (
  <>
    {headerLetter &&
      <ContactLetterLineItem>
        {headerLetter}
      </ContactLetterLineItem>
    }
    <DropdownLineItemBase 
      contactData={contactData}
      onClick={onClick}
    />
  </>
);

const useStyles = makeStyles({
  iconButton: {
    padding: '0 8px'
  },
  disabledRow: {
    color: "grey",
    height: "26px"
  }
});

export default memo(DropdownLineItem);
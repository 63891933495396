import { AudioTypes } from "@models/AudioFiles.models";

export const MAX_ALLOWED_FILE_SIZE_IN_MB = 20971520 // 20 MB in binary;
export const AUDIO_FILE_SIZE_ERROR = "Audio file should not exceed 20MB in file size";

export const AudioMapTypeToCode = {
  [AudioTypes.MENU]: "menu",
  [AudioTypes.ANNOUNCEMENT]: "ann",
  [AudioTypes.VOICEMAIL_GREETING]: "vmgreet",
  [AudioTypes.WELCOME_GREETING]: "greet",
  [AudioTypes.MUSIC_ON_HOLD]: "moh",
  [AudioTypes.RECORDED_CALL_NOTIF]: "recording",
};

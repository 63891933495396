import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import { persistStore,
    persistReducer,
    createTransform,
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE
  } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { stringify, parse} from 'flatted';
import type { PreloadedState } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import createRootReducer, { createAppReducer } from "../../reducers";
import { configureStore } from "@reduxjs/toolkit";
import kingcobraApi from "@services/kingcobraApi";
import appConfig from "../../../src/config";
import apiServiceInstance from "@services/TalkrouteAPIService";

const rootStore = "rootStore";

const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState),
)

/** Configure the persistant store
 * A note on autoMergeLevel2. autoMergeLevel2 is how you merge two-levels deep.
 * For the auth state, this means that the merge process will first make a
 * copy of the initial auth state, and then only override the keys within this auth object that were persisted.
 *
 * https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
 */
const persistConfig = {
  key: rootStore,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["router", kingcobraApi.reducerPath],
  transforms: [transformCircular]
};


// export const history = appConfig.clientType === "desktop" ? createMemoryHistory() : createBrowserHistory();
 export const history = createBrowserHistory();

const router = routerMiddleware(history);

const logger = createLogger({
  level: "info",
  collapsed: true,
});

const middleware = process.env.NODE_ENV === "production" ?
 [router] : [router, logger]

// reducers
const persistedReducer = persistReducer<any, any>(
  persistConfig,
  createRootReducer(history) 
) as ReturnType<typeof createAppReducer>;

export const setupStore = (preloadedState?: PreloadedState<RootReducer>) => configureStore({
  reducer: persistedReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //   ignorePaths: ['']
      // },
      // immutableCheck: { warnAfter: 128 },
      immutableCheck: false,
      serializableCheck: false,
      
    })
    .concat(middleware)
    .concat(kingcobraApi.middleware)
})

export const store = setupStore();

// Set up the interceptor
apiServiceInstance.setupRequestInterceptor(store.getState);

// enables
setupListeners(store.dispatch)

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('../../reducers', () => store.replaceReducer(persistedReducer))
}

export const persistor = persistStore(store);

export type RootReducer = ReturnType<typeof persistReducer>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof setupStore>

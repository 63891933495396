export const MEETINGS_HEADING = 'Meetings'
export const MEETINGS_TEXT = 'Get Started'
export const MEETINGS_NEW_MEETING = 'New Meeting'
export const MEETINGS_JOIN = 'Join'
export const MEETINGS_SCHEDULE_MEETING = 'Schedule'
export const MEETINGS_SHARE_SCREEN = 'Share Screen'
export const TODAY = 'Today';
export const NO_MEETINGS_TODAY = 'No meetings today';
export const NO_UPCOMING_MEETINGS = 'No upcoming meetings';
export const MEETING_ID = 'Meeting ID:';
export const LOADING = 'Loading...';
export const MEETING_LINK_COPIED = 'Meeting link copied';

// Dialog
export const DIALOG_SHEDULE_MEETING = 'Schedule Meeting'
export const DIALOG_VIDEO_HOST = 'Video Host'
export const DIALOG_VIDEO_PARTICIPANTS = 'Video Participants'
export const DIALOG_RECORD_MEETING = 'Record Meeting Automatically'
export const DIALOG_MEETING_NAME = 'Meeting Name'
export const DIALOG_ADD_PARTICIPANTS = 'Add Participants'
export const DIALOG_ADD_AGENDA = 'Add Agenda'
export const ADD_MEETING_NAME = 'Add meeting name'
export const ADD_PARTICIPANTS = 'Enter emails of all participants'
export const ADD_AGENDA = 'Add agenda'
export const SCHEDULE = 'Schedule'
export const DIALOG_START_DATE = 'Start date'
export const DIALOG_END_DATE = 'End date'
export const DIALOG_START_HOUR = 'Start hour'
export const DIALOG_END_HOUR = 'End hour'
export const MOXTRA_DELETE_MEETING_GENERIC_ERROR = "There was an issue deleting this meeting. Please try again later";
export const repeatOptions = [
  { value: 'never', text: 'Never' },
  { value: 'daily', text: 'Daily' },
  { value: 'weekly', text: 'Weekly' },
  { value: 'monthly', text: 'Monthly' },
]

export const MAX_NUMBER_RECURRENT_MEETINGS_TO_SHOW = 3;
import { createMuiTheme } from '@material-ui/core/styles';

/**
 * This declaration is used for Module Augmentation.
 * The interfaces below are merged with the existing
 * found at the specified Url.
 * 
 * Any property below will be merged with priority.
 * 
 * To read more on Module Augmentation, check this - 
 * https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
 */
declare module '@material-ui/core/styles/createPalette' {
  interface CommonColors {
    paleRed: '#e74c3c',
    backgroundGrey: '#fafafa',
    charcoal: '#414042',
    darkGrey: '#979797',
    lightGrey: '#e1e1e1',
    veryLightGrey: '#efefef',
    pumpkinOrange: '#ff7100',
    pumpkinOrangeHover: '#ee6a00d9',
    brownGrey: '#7f7f7f',
    veryLightBlue: "#d9edf7",
    lightBlue: "#bce8f1",
    darkBlue: "#31708f",
    blue: '#0184ff',
    buttonBlue: '#3f51b5',
    buttonBlueHover: '#334191',
    green: '#38cf46',
    buttonGreen: '#5cb85c',
    buttonGreenHover: '#4A944A',
    brightLightBlue: '#35c5f0',
    buttonRed: '#ee4949',
    buttonRedHover: '#C93E3E',
    backgroundBlack: '#252525',
    whiteTan: "#f6f5f1",
    textBlack: "#2a2a2a",
  }
  interface PaletteOptions {
    common?: Partial<CommonColors>;
  }
  interface Palette {
    common: CommonColors;
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
        '& h2': {
          fontSize: '13px',
          fontWeight: 'bold',
        }
      }
    }
  },
  palette: {
    common: {
      paleRed: '#e74c3c',
      backgroundGrey: '#fafafa',
      charcoal: '#414042',
      darkGrey: '#979797',
      lightGrey: '#e1e1e1',
      veryLightGrey: '#efefef',
      pumpkinOrange: '#ff7100',
      pumpkinOrangeHover: '#ee6a00d9',
      brownGrey: '#7f7f7f',
      veryLightBlue: "#d9edf7",
      lightBlue: "#bce8f1",
      darkBlue: "#31708f",
      blue: '#0184ff',
      buttonBlue: '#3f51b5',
      buttonBlueHover: '#334191',
      green: '#38cf46',
      buttonGreen: '#5cb85c',
      buttonGreenHover: '#4A944A',
      brightLightBlue: '#35c5f0',
      buttonRed: '#ee4949',
      buttonRedHover: '#C93E3E',
      backgroundBlack: '#252525',
      whiteTan: "#f6f5f1",
      textBlack: "#2a2a2a"
    }
  }
});

theme.palette.success = theme.palette.augmentColor({
  main: "#689f38"
});

theme.palette.warning = theme.palette.augmentColor({
  main: "#ee4949"
});

theme.palette.info = theme.palette.augmentColor({
  main: "#7f7f7f"
});

export default theme;

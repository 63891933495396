import getApiConstants from "@constants/apiConstants";
import { IApiResponseWithPagination } from "@models/IPagination";
import { IMenu } from "@models/Menu.models";
import { IVoicemailBox } from "@models/Voicemailbox.models";
import HttpClient from "./HttpClient";

export default function MenuService() {
    const url = getApiConstants().endpoints.menuv2.BASE;
    const { Get, Put, Post, Delete } = HttpClient();
  
    const GetByPage = async (
      page: number,
      pageSize: number
    ) => {
      try {
        if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
        const params = new URLSearchParams();
        params.set("page", String(page));
        params.set("pageSize", String(pageSize));
        const { data } = await Get<IApiResponseWithPagination<IMenu[]>>({
          url,
          params,
        });
        return data;
      } catch (err) {
        throw err;
      }
    };
  
    const GetOne = async (id: string) => {
        return new Error("Not Implemented")
    };
  
    const CreateOne = async (body: any) => {
        return new Error("Not Implemented")
    };
  
    const DeleteOne = async (id: string) => {
        return new Error("Not Implemented")
    };
  
    const UpdateOne = async (id: string, body: any) => {
        return new Error("Not Implemented")
    };
  
    return {
      GetByPage,
      // GetOne,
      // CreateOne,
      // DeleteOne,
      // UpdateOne,
    };
  }
  
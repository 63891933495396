import { CallDestinationType } from "./Menu.models";

export enum DayEnum {
  MONDAY = "mon",
  TUESDAY = "tue",
  WEDNESDAY = "wed",
  THURSDAY = "thu",
  FRIDAY = "fri",
  SATURDAY = "sat",
  SUNDAY = "sun",
}

export type IHoursOfOperation = {
  id: number;
  dayEnd: DayEnum;
  dayStart: DayEnum;
  hourStart: number;
  hourEnd: number;
  minuteStart: string;
  minuteEnd: string;
};

export type IHoursOfOperationBody = Omit<IHoursOfOperation, "id">;

export const MapDayCodeToLabel = {
  [DayEnum.MONDAY]: "Monday",
  [DayEnum.TUESDAY]: "Tuesday",
  [DayEnum.WEDNESDAY]: "Wednesday",
  [DayEnum.THURSDAY]: "Thursday",
  [DayEnum.FRIDAY]: "Friday",
  [DayEnum.SATURDAY]: "Saturday",
  [DayEnum.SUNDAY]: "Sunday",
};

export type IOffHoursOptions = {
  audioFileId: string | number;
  destination: {
    id: number | string;
    type: CallDestinationType;
  };
  timezone: string;
};

export type IOffHoursOptionsBody = {
  closed: IOffHoursOptions;
  timezone: string;
}
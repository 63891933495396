import React, { useEffect, useState } from "react";
import { CallHistoryData } from "@services/dataClasses";
import {
  CircularProgress,
  Collapse,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  withStyles,
  Link,
} from "@material-ui/core";
import { Cancel, Info } from "@material-ui/icons";
import uiString, { ApiErrorMessages, siteLinks } from "@constants/uiString";
import { useDispatch, useSelector } from "react-redux";
import { blockNumber, unblockNumber } from "@actions/callActions";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { getCallRecordingForNumber } from "@services/dataClasses/TalkroutePhoneNumber";

const StyledTableRowHovered = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(4n)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:nth-of-type(4n-1)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const uiStrings = uiString.SETTINGS_AND_PREF.CALL_HISTORY;
interface CallHistoryEventProps {
  row: CallHistoryData;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      listStyleType: "disc",
    },
    listItem: {
      padding: 0,
      display: "list-item",
      marginLeft: theme.spacing(2),
    },
    listItemText: {
      margin: 0,
    },
    blockButton: {
      width: 24,
      height: 24,
    },
    firstCol: {
      width: 200,
      fontWeight: "bold"
    },
    secondCol: {

    }
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 0,
    },
  })
)(TableRow);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "4px",
    },
    head: {
      backgroundColor: theme.palette.grey.A700,
      color: theme.palette.common.white,
      fontFamily: "'Lato', sans-serif",
      fontSize: 14,
    },
    body: {
      fontSize: 14,
      border: 0,
      fontFamily: "'Lato', sans-serif",
    },
  })
)(TableCell);

const WhiteToolTip = withStyles((theme: Theme) =>
  createStyles({
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[3],
      border: "1px solid #E6E8ED",
      color: "#4A4A4A",
      fontSize: "14px",
    },
  })
)(Tooltip);

const CallHistoryEvent = ({ row, open }: CallHistoryEventProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const blockedNumbers = useSelector<any, string[]>(
    (state: any) => state.callReducer.blockedNumbers
  );
  const [callerNumberIsBlocked, setCallerNumberIsBlocked] = useState(
    blockedNumbers.includes(row.getCallerPhoneNumberWithoutPlus())
  );
  const blockNumberError = useAppSelector(state => state.callReducer.blockNumberError);
  useEffect(() => {
    if (blockNumberError) {
      setIsBlocking(false);
    }
  }, [blockNumberError])
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    setIsBlocking(false);
    setCallerNumberIsBlocked(
      blockedNumbers.includes(row.getCallerPhoneNumberWithoutPlus())
    );
  }, [blockedNumbers.length]);

  const blockNumbersHandler = () => {
    setIsBlocking(true);
    const virtualNumber = row.getCallerPhoneNumberWithoutPlus();
    const reduxAction = callerNumberIsBlocked
      ? unblockNumber(virtualNumber)
      : blockNumber(virtualNumber);
    dispatch(reduxAction);
  };

  const getBlockNumberBtnTooltipText = () => {
    const label = callerNumberIsBlocked ? uiStrings.CALL_DETAILS.UNBLOCK : uiStrings.CALL_DETAILS.BLOCK;
    return label;
  };

  return (
    <StyledTableRowHovered>
      <StyledTableCell colSpan={12} style={{ paddingBottom: 0, paddingTop: 0}}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table style={{width: "100%"}}>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell
                  className={classes.firstCol}
                  align="right"
                >
                  {uiStrings.CALL_DETAILS.VIRTUAL_NUMBER}
                </StyledTableCell>
                <StyledTableCell className={classes.secondCol}>
                  {row.getVirtualPhoneNumberFormatted()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  className={classes.firstCol}
                  align="right"
                >
                  {uiStrings.CALL_DETAILS.CALLED_NUMBER}
                </StyledTableCell>
                <StyledTableCell className={classes.secondCol}>
                  {row.getCallerPhoneNumberFormatted()}{" "}
                  <WhiteToolTip
                    title={getBlockNumberBtnTooltipText()}
                    placement="right"
                    arrow
                    disabled={isBlocking}
                  >
                    <IconButton
                      onClick={blockNumbersHandler}
                      color={callerNumberIsBlocked ? "secondary" : "primary"}
                      aria-label="block"
                      style={{ padding: "2px" }}
                      disabled={isBlocking}
                    >
                      <Cancel
                        className={classes.blockButton}
                        fontSize="large"
                      />
                      {isBlocking && (
                        <CircularProgress size="14px" color="primary" />
                      )}
                    </IconButton>
                  </WhiteToolTip>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  className={classes.firstCol}
                  align="right"
                >
                  {uiStrings.CALL_DETAILS.DIRECTION}
                </StyledTableCell>
                <StyledTableCell className={classes.secondCol}>
                  {row.getCallTypeFormatted()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  className={classes.firstCol}
                  align="right"
                >
                  {uiStrings.CALL_DETAILS.RESULT}
                </StyledTableCell>
                <StyledTableCell className={classes.secondCol}>
                  {row.getCallResultFormatted() ? (
                    row.getCallResultFormatted()
                  ) : (
                    <>
                      {uiStrings.CALL_DETAILS.NOT_AVAILABLE}
                      <WhiteToolTip
                          interactive
                          title={
                            <>
                              <Typography>
                                {uiStrings.TOOLTIPS.NO_CALL_DETAILS_FAQ}
                                <Link href={siteLinks.NO_CALL_DETAILS_FAQ}>
                                  FAQ.
                                </Link>
                              </Typography>
                            </>
                          }
                          aria-label="no-call-details"
                          placement="right"
                          arrow
                        >
                        <Info
                          fontSize="small"
                          color="action"
                          style={{
                            verticalAlign: "middle",
                            paddingLeft: "2px",
                          }}
                        />
                      </WhiteToolTip>
                    </>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              {/* <StyledTableRow>
                <StyledTableCell
                  className={classes.firstCol}
                  align="right"
                >
                  {uiStrings.CALL_DETAILS.CALL_DETAILS}
                </StyledTableCell>
                <StyledTableCell className={classes.secondCol}>
                  <List className={classes.list}>
                    {row.events.length !== 0 ? (
                      row.events.map((e, i) => (
                        <ListItem key={i} className={classes.listItem}>
                          <ListItemText
                            className={classes.listItemText}
                            primary={e.getDescription()}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <>
                        {uiStrings.TOOLTIPS.NO_CALL_DETAILS}
                        <WhiteToolTip
                          interactive
                          title={
                            <>
                              <Typography>
                                {uiStrings.TOOLTIPS.NO_CALL_DETAILS_FAQ}
                                <Link href={siteLinks.NO_CALL_DETAILS_FAQ}>
                                  FAQ.
                                </Link>
                              </Typography>
                            </>
                          }
                          aria-label="no-call-details"
                          placement="right"
                          arrow
                        >
                          <Info
                            fontSize="small"
                            color="action"
                            style={{
                              verticalAlign: "middle",
                              paddingLeft: "2px",
                            }}
                          />
                        </WhiteToolTip>
                      </>
                    )}
                  </List>
                </StyledTableCell>
              </StyledTableRow> */}
            </TableBody>
          </Table>
        </Collapse>
      </StyledTableCell>
    </StyledTableRowHovered>
  );
};


export default CallHistoryEvent;

import InputField from '@components/PresentationComponents/FormComponents/InputField';
import useForm from '@helpers/hooks/useForm.hook';
import { makeStyles, Theme, FormControl, DialogContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import BlockedNumberService from '@services/BlockedNumberService.service';
import { minLength } from '@helpers/formHelpers/formValidatorHelpers';
import { IBlockedNumber } from '@models/IPhoneNumbers';
import { formatPhoneNumber, trimPhoneNumber } from '@helpers/functions/phoneNumberFormatter';
import { INVALID_PHONE_MESSAGE, ADD_BLOCKED_PHONE_NUMBER, BLOCKED_NUMBER } from './constants';
import Dialog from '@components/PresentationComponents/Dialog/Dialog';
import DialogActions from '@components/PresentationComponents/Dialog/DialogActions';
import DialogButton from '@components/PresentationComponents/Dialog/DialogButton';
import uiString, { ApiErrorMessages } from '@constants/uiString';
import { useState } from 'react';
import useService, { ErrorStatusCode } from '@helpers/hooks/useService.hook';
import { useToasts } from 'react-toast-notifications';
import { addLeadingCountryCode } from '@helpers/functions/phoneNumberFormatter';
import keyConstants from '@constants/keyConstants';
import { fetchBlockedNumbers } from '@actions/callActions';
import { useDispatch } from 'react-redux';

type Props = {
  open: boolean;
  onClose: () => void;
  blockNumberHandler: (input: IBlockedNumber) => void;
};

/**
 * Display a box with field to enter a number to block.
 */
const BlockNumberDialog: React.FC<Props> = ({open, onClose, blockNumberHandler }) => {
  const { form, changeHandler, resetToDefault, validateForm, formErrors } = useForm({
    blockedNumber: { initialValue: '', validators: [minLength(15, INVALID_PHONE_MESSAGE)] }
  });
  const { BlockNumber } = useService(BlockedNumberService);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<{
    number: string[];
  }|null>(null);
  const classes = useStyles();

  const inputChangeHandler = (name: string | undefined, value: any) => {
    const formatedPhoneNumber = addLeadingCountryCode(value);
    const updatedPhoneNumber = formatPhoneNumber(formatedPhoneNumber);
    changeHandler(name, updatedPhoneNumber);
  };

  const keyDownHandler = (e) => {
    e.keyCode === keyConstants.CODE_ENTER && handleSubmit();
    e.keyCode === keyConstants.CODE_ESCAPE && onClose();
  };

  useEffect(() => {
    if (open) {
      resetToDefault();
      setErrors(null);
    }
  },[open])

  /**
   * Simplify the input phone number and send request to block it.
   * 
   * @param formatedPhoneNumber number in format 1 (232) 323-4422
   */
  const handleSubmit = async () => {
    const phoneNumber = trimPhoneNumber(form.blockedNumber);
    const isValid = validateForm();
    try {
      if (isValid && phoneNumber) {
        setIsSubmitting(true);
        const { data } = await BlockNumber(phoneNumber);
        dispatch(fetchBlockedNumbers());
        blockNumberHandler(data.data[0])
        setIsSubmitting(false);
        resetToDefault();
        onClose();
      } else {
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      if ((error as any).response?.data?.errors) {
        setErrors((error as any).response?.data.errors);
      } else if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  return (
    <Dialog 
      title={ADD_BLOCKED_PHONE_NUMBER}
      open={open}
      onClose={onClose}
      maxWidth="xs" 
      fullWidth
      onKeyDown={(e) => keyDownHandler(e)}
    >
      <DialogContent>
        <FormControl className={classes.addNumber}>
          <InputField 
            name={BLOCKED_NUMBER}
            error={formErrors.blockedNumber?.hasError || Boolean(errors)}
            errorMessage={formErrors.blockedNumber?.validatorResults.errorMessage || errors?.number[0]}
            value={form.blockedNumber}
            onChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
            autoFocus
            type="string"
            inputProps={{ maxLength: 16 }}
          />
        </FormControl>

        </DialogContent>
        <DialogActions>
        <DialogButton
          label={uiString.SAVE_CHANGES}
          isLoading={isSubmitting}
          onClick={() => handleSubmit()}
          disabled={isSubmitting}
          color="secondary"
          fullWidth
        />          
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(2),
    marginBottom: 0,
  },
  addNumber: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  phoneTitle: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  blockButton: {
    margin: theme.spacing(0, 2),
  }
}))

export default BlockNumberDialog;

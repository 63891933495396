import uiString from "@constants/uiString";

const uiStrings = uiString.SETTINGS_AND_PREF.CALL_HISTORY.FILTERS;

// Filter configurations
export const filterTypesEnum = {
  DROPDOWN: "DROPDOWN",
  INPUT: "INPUT",
  DATE: "DATE",
  GROUP: "GROUP"
};

export type CallResultType = "answered" | "hangup" | "missed" | "all";
export type CallDirectionType = "inbound" | "outbound" | "all";
export type CallDurationType = "seconds" | "minutes" | "hours";

export interface CallHistoryFilterQuery {
  virtualNumbers?: string;
  result?: CallResultType;
  startDate?: Date;
  endDate?: Date;
  durationOperator?: string;
  duration?: number;
  durationUnit?: CallDurationType;
  direction?: CallDirectionType;
  forwardingNumber?: string;
  menuOption?: string;
  extension?: string;
  voiceMailbox?: string;
  pageSize?: number;
  page?: number;
}

export interface FilterOption {
  name: string;
  title: string | undefined;
  type: string;
  values?: any[];
  inputType?: string;
  advanced?: boolean;
  renderValue?: string;
  dynamicVariable?: string;
}

export type DynamicOption = {
  id: string | number;
  value: string | number;
};

export interface DynamicSelectOptions {
  extensions?: DynamicOption[];
  virtualNumbers?: DynamicOption[];
  voiceMailboxes?: DynamicOption[];
  menus?: DynamicOption[];
  forwardingNumbers?: DynamicOption[];
}

// TODO move string to uiString
export const FILTER_OPTIONS = {
  VIRTUAL_NUMBERS: {
    name: "virtualNumbers",
    title: undefined,
    type: filterTypesEnum.DROPDOWN,
    values: [{ id: "all", value: uiStrings.VIRTUAL_NUMBER_ALL }],
    advanced: false,
    dynamicVariable: "virtualNumbers",
  },
  RESULT: {
    name: "result",
    title: undefined,
    type: filterTypesEnum.DROPDOWN,
    values: [
      { id: "all", value: uiStrings.STATUS_ALL },
      { id: "answered", value: uiStrings.ANSWERED },
      { id: "missed", value: uiStrings.MISSED },
      { id: "hangup", value: uiStrings.HANGUP },
    ],
    advanced: false,
  },
  START_DATE: {
    name: "startDate",
    title: uiStrings.START_DATE,
    type: filterTypesEnum.DATE,
    advanced: true,
  },
  END_DATE: {
    name: "endDate",
    title: uiStrings.END_DATE,
    type: filterTypesEnum.DATE,
    advanced: true,
  },
  DURATION: {
    name: "duration",
    title: "Duration",
    type: filterTypesEnum.GROUP,
    advanced: true,
    values: [
      {
        name: "durationOperator",
        type: filterTypesEnum.DROPDOWN,
        values: [
          { id: "longer", value: uiStrings.LONGER },
          { id: "shorter", value: uiStrings.SHORTER },
        ],
        advanced: true,
      },
      {
        name: "duration",
        type: filterTypesEnum.INPUT,
        title: "Duration",
        inputType: "number",
        advanced: true,
      },
      {
        name: "durationUnit",
        type: filterTypesEnum.DROPDOWN,
        values: [
          { id: "seconds", value: uiStrings.SECONDS },
          { id: "minutes", value: uiStrings.MINUTES },
          { id: "hours", value: uiStrings.HOURS }
        ],
        advanced: true,
      },
    ]
  },
  DIRECTION: {
    name: "direction",
    title: uiStrings.DIRECTION,
    type: filterTypesEnum.DROPDOWN,
    values: [
      { id: "all", value: uiStrings.ALL },
      { id: "inbound", value: uiStrings.INBOUND },
      { id: "outbound", value: uiStrings.OUTBOUND },
    ],
    advanced: true,
  },
  USER_FORWARDING_NUMBER: {
    name: "forwardingNumber",
    title: uiStrings.FORWARDING_NUMBER,
    type: filterTypesEnum.DROPDOWN,
    values: [{ id: "all", value: uiStrings.ALL }],
    advanced: true,
    dynamicVariable: "forwardingNumbers",
  },
  MENU_OPTION: {
    name: "menuOption",
    title: uiStrings.MENU_OPTIONS,
    type: filterTypesEnum.DROPDOWN,
    values: [{ id: "all", value: uiStrings.ALL }],
    advanced: true,
    dynamicVariable: "menus",
  },
  EXTENSION: {
    name: "extension",
    title: uiStrings.EXTENSION,
    type: filterTypesEnum.DROPDOWN,
    values: [{ id: "all", value: uiStrings.ALL }],
    advanced: true,
    dynamicVariable: "extensions",
  },
  VOICEMAIL_BOX: {
    name: "voiceMailbox",
    title: uiStrings.VOICE_MAILBOX,
    type: filterTypesEnum.DROPDOWN,
    values: [{ id: "all", value: uiStrings.ALL }],
    advanced: true,
    dynamicVariable: "voiceMailboxes",
  },
};

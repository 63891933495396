import React, { useState } from "react";
import {
  Select,
  MenuItem,
  makeStyles,
  SelectProps,
  createStyles,
  Theme,
  ListSubheader,
} from "@material-ui/core";
import clsx from "clsx";
import filterChevron from "@resources/icons/filter-chevron.svg";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import uiString from "../../../constants/uiString";
import CustomLabel from "./CustomLabel";
import { OptionsType } from "@models/Additional.models";

type Props = {
  options: OptionsType[];
  dropdownStyle?: any;
  lightColor?: boolean;
  smallIcon?: boolean;
  label?: string;
  innerLabel?: string;
  outlined?: boolean;
} & Omit<SelectProps, "smallIcon">;

const InlineDropdown: React.FC<Props> = ({
  className,
  lightColor = true,
  innerLabel,
  options,
  dropdownStyle,
  smallIcon,
  label,
  outlined,
  variant,
  ...props
}) => {
  const [offsetInnerLabel, setOffsetInnerLabel] = useState<number>(0);
  const classes = useStyles(offsetInnerLabel + 15);
  const customVariant = (outlined && "outlined") || variant;
  return (
    <>
      {label && <CustomLabel label={label} />}
      <Select
        {...props}
        className={clsx(
          className,
          classes.wrapper,
          outlined && classes.outlinedWrapper,
          lightColor && classes.lightColor,
          smallIcon && classes.smallIcon,
          innerLabel && classes.paddingToText
        )}
        variant={customVariant}
        IconComponent={filterChevron}
        style={{
          ...dropdownStyle,
        }}
        inputProps={{ disableUnderline: true }}
        disableUnderline
        startAdornment={
          <CustomLabel
            onCalculateWidth={(width: any) => setOffsetInnerLabel(width)}
            noMargin
            className={classes.innerLabel}
            label={innerLabel}
            disablePointerActions
          />
        }
      >
        {options.map(
          (item, index) => {
            if (item.value === uiString.SUBHEADER) {
              return (
                <ListSubheader
                  color="primary"
                  className={classes.subheader}
                  key={createKeyIndex(index, item.text)}
                >
                  {item.text}
                </ListSubheader>
              );
            } else {
              return (
                <MenuItem
                  key={createKeyIndex(index, item.text)}
                  value={item.value}
                >
                  {item.text}
                </MenuItem>
              );
            }
          }
        )}
      </Select>
    </>
  );
};

const useStyles = (paddingOffest: number) => makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minWidth: "80px",
      height: "40px",

      "& .MuiSelect-select:focus": {
        // backgroundColor: 'white'
      },
      "& .MuiSelect-icon": {
        top: "30%",
      },
      "&::selected": {
        backgroundColor: "red",
      },
      "&.MuiInputBase-root": {
        marginLeft: "0px !important",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--color-pumpkin-orange)",
      },
      "&.MuiOutlinedInput-adornedStart": {
        paddingLeft: 0,
      },
    },
    outlinedWrapper: {
      "& .MuiSelect-icon": {
        width: "8px",
        height: "8px",
        top: "40%",
      },
      "& .MuiSelect-select": {
        padding: "0 32px 0 9px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: paddingOffest ?? 0 
      },
    },
    smallIcon: {
      "& .MuiSelect-icon": {
        width: "14px",
        height: "8px",
      },
    },
    lightColor: {
      "& .MuiSelect-root": {
        fontWeight: 300,
        fontFamily: "Lato, sans-serif",
      },
    },
    subheader: {
      pointerEvents: "none",
      fontWeight: theme.typography.fontWeightBold as any, // casted to any due to jest complaining about it
    },
    label: {
      marginBottom: "6px",
    },
    innerLabel: {
      fontWeight: "bold",
      color: "#979797",
      textTransform: "uppercase",
      fontSize: "10px !important",
      marginBottom: "0px",
      position: "absolute",
      left: 10,
    },
    paddingToText: {
      "& .MuiSelect-select": {
        fontWeight: "normal !important",
      },
    },
  })
)();

export default InlineDropdown;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ConversationData, formatPhoneNumber, truncateLastMessageText } from "@services/dataClasses";
import {
    Collapse,
    createStyles,
    Link,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    withStyles,
  } from "@material-ui/core";
import uiString from "@constants/uiString";
import routes, { routeUrlBuilders } from "@constants/routes";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";

const StyledTableRowHovered = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(4n)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:nth-of-type(4n-1)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      listStyleType: "disc",
    },
    listItem: {
      padding: 0,
      display: "list-item",
      marginLeft: theme.spacing(2),
    },
    listItemText: {
      margin: 0,
    },
    blockButton: {
      width: 24,
      height: 24,
    },
    firstCol: {
      width: 200,
      fontWeight: "bold"
    },
    secondCol: {

    },
    bolder: {
        fontWeight: "bold"
    },
    bigger: {
      fontWeight: "bold",
      fontSize: "16px !important"
    },
    linkCursor: {
      cursor: 'pointer',
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 0,
    },
  })
)(TableRow);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "4px",
    },
    head: {
      backgroundColor: theme.palette.grey.A700,
      color: theme.palette.common.white,
      fontFamily: "'Lato', sans-serif",
      fontSize: 14,
    },
    body: {
      fontSize: 14,
      border: 0,
      fontFamily: "'Lato', sans-serif",
    },
  })
)(TableCell);

const uiStrings = uiString.SETTINGS_AND_PREF.TEXT_MESSAGING_HISTORY;

interface TextMessageHistoryEventProps {
    row: ConversationData;
    open: boolean;
  }
const TextMessageHistoryEvent = ({ row, open }: TextMessageHistoryEventProps) => {
    const classes = useStyles();
    const [openConfirmLink, setOpenConfirmLink] = useState(false);
    const history = useHistory();

    const handleConfirmLink = () => {
      setOpenConfirmLink(false);
      history.push({
        pathname: routeUrlBuilders.OPEN_CONVERSATION(row.conversationId)
      });
    };
  
    return (
      <StyledTableRowHovered>
        <StyledTableCell colSpan={12} style={{ paddingBottom: 0, paddingTop: 0}}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table style={{width: "100%"}}>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    className={classes.firstCol}
                    align="right"
                  >
                    {uiStrings.DETAILS.TO}
                  </StyledTableCell>
                  <StyledTableCell className={classes.secondCol}>
                    {formatPhoneNumber(row.getCallerPhoneNumber())}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    className={classes.firstCol}
                    align="right"
                  >
                    {uiStrings.DETAILS.VIRTUAL_PHONE_NUMBER}
                  </StyledTableCell>
                  <StyledTableCell className={classes.secondCol}>
                    {formatPhoneNumber(row.talkrouteNumber)}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    className={classes.firstCol}
                    align="right"
                  >
                    {uiStrings.DETAILS.TOTAL_MESSAGE_COUNT}
                  </StyledTableCell>
                  <StyledTableCell className={classes.secondCol}>
                    {row.messageCount}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    className={classes.firstCol}
                    align="right"
                  >
                    {'Link:'}
                  </StyledTableCell>
                  <StyledTableCell className={classes.secondCol}>
                    <Link
                      className={classes.linkCursor}
                      onClick={() => setOpenConfirmLink(true)}
                    >
                      {'Open Conversation'}
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
            <hr></hr>
            <Table style={{width: "100%"}}>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell
                            className={classes.bigger}
                            align="right"
                        >
                            {uiStrings.DETAILS.LAST_MESSAGE_DETAILS}
                        </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                    <StyledTableCell
                        className={classes.firstCol}
                        align="right"
                    >
                        {uiStrings.DETAILS.MESSAGE}
                    </StyledTableCell>
                    <StyledTableCell className={classes.secondCol}>
                        {truncateLastMessageText(row.lastMessage.body, 60)}
                    </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                    <StyledTableCell
                        className={classes.firstCol}
                        align="right"
                    >
                        {uiStrings.DETAILS.SENT_AT}
                    </StyledTableCell>
                    <StyledTableCell className={classes.secondCol}>
                        {row.getSimpleFormattedDateAndTime()}
                    </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                    <StyledTableCell
                        className={classes.firstCol}
                        align="right"
                    >
                        {uiStrings.DETAILS.SENT_BY}
                    </StyledTableCell>
                    <StyledTableCell className={classes.secondCol}>
                        {row.lastMessage.isIncoming() ? formatPhoneNumber(String(row.lastMessage.contactNumber)) : formatPhoneNumber(String(row.lastMessage.talkrouteNumber))}
                    </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
          </Collapse>
        </StyledTableCell>
        <ConfirmDialog
          open={openConfirmLink}
          onCancel={() => setOpenConfirmLink(false)}
          onConfirm={() => handleConfirmLink()}
          confirmButtonLabel="Proceed"
          showCancelButton={true}
          content="Clicking this link will navigate you away from the text message history page."
          header="Warning"
        />
      </StyledTableRowHovered>
    );
  };
  
  
  export default TextMessageHistoryEvent;
import React, { memo } from 'react';
import * as Library from '@material-ui/core';
import { DialogProps, makeStyles, Theme } from '@material-ui/core';
import DialogTitle from './DialogTitle';
import DialogDivider from './DialogDivider';

type Props = {
  title: string;
  onClose?: () => void;
  dismissable?: boolean;
  showLoaderNextToTitle?: boolean;
} & Omit<DialogProps, 'title'>

const Dialog: React.FC<Props> = (
  { title, className, children, showLoaderNextToTitle, onClose, dismissable = true, ...props }
) => {
  const classes = useStyles();

  return (
    <Library.Dialog
      {...props}
      onClose={onClose}
      className={`${classes.root} ${className}`}
      data-testid="dialog-wrapper"
    >
      <DialogTitle data-testid="dialog-title" showProgressIcon={showLoaderNextToTitle} title={title} onClose={onClose} dismissable={dismissable}/>
      <DialogDivider />
      {children}
    </Library.Dialog>
  )
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      padding: 0,
    },
  },
  title: {
    padding: "12px 10px 9px 26px",

    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
}));

export default memo(Dialog);

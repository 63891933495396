import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { FPNumberCreateDialog } from "./FPNFormDialog";
import {
  IForwardedPhoneNumber,
  IForwardedPhoneNumberBody,
} from "../../models/IPhoneNumbers";
import { IForwardNumberForm } from "./constants";
import ForwardingPhoneNumbersService from "@services/ForwardPhoneNumbers.service";
import { formatPhoneNumber } from "@services/dataClasses/CallData";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import { IPagination } from "../../models/IPagination";
import { CustomTablePagination } from "@components/PresentationComponents/TableComponents/CustomTablePagination";
import Loader from "@components/PresentationComponents/Loader";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import {
  TableDeleteButton,
  TableEditButton,
} from "@components/PresentationComponents/TableComponents/TableHelpers";
import InputButton from "../PresentationComponents/FormComponents/InputButton";
import RoundedInputField from "../PresentationComponents/FormComponents/RoundedInputField";
import SearchIcon from "@resources/icons/search-icon.svg";
import CloseIcon from "@material-ui/icons/Close";
import uiString, { ApiErrorMessages } from "@constants/uiString";
import { ComponentDimensions } from "@constants/appConstants";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import usePrevious from "@helpers/hooks/usePrevious.hook";
import CrossIcon from "@resources/icons/cross-small.svg"
import { Check } from "@material-ui/icons";
import { useToasts } from "react-toast-notifications";

const FPNStrings = uiString.SETTINGS_AND_PREF.FORWARDING_NUMBERS;

const mapForwardedNumberToTableData = (data: IForwardedPhoneNumber) => ({
  ...data,
  [FPNStrings.TABLE_COL_LABELS[0]]: data.number && formatPhoneNumber(data.number),
  [FPNStrings.TABLE_COL_LABELS[1]]: data.description,
  [FPNStrings.TABLE_COL_LABELS[2]]: (
    <EnabledComponent data={data} />
  ),
  number: data.number && formatPhoneNumber(data.number),
});

const EnabledComponent = ({data}: {data: IForwardNumberForm}) => {

  const styles = iconStyles();

  return (
    <div style={{display: "flex", justifyContent: "center"}}>
      {data.announceAndVoicemailEnabled ? <Check className={styles.enabled} style={{ 
        width: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
        height: ComponentDimensions.TABLE_CROSS_ICON_SIZE }}/> : <CrossIcon className={styles.disabled} style={{ 
        width: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
        height: ComponentDimensions.TABLE_CROSS_ICON_SIZE,
        }} />}
    </div>
  )
}

const ForwardPhoneNumbersView: React.FC = () => {
  const [numberList, setNumberList] = useState<IForwardedPhoneNumber[]>([]);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [editData, setEditData] = useState<IForwardedPhoneNumber | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const rowsPerPage = useAppSelector((state) => state.preferences.tableSize.forwardingPhoneNumbers);
  const componentIsLoaded = useRef<boolean>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchInput, setSearchInput] = useState<string>("");
  const filterActivated = useRef(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [numberToDelete, setNumberToDelete] = useState<IForwardedPhoneNumber | null>(null);
  const prevNumberToDelete = usePrevious(numberToDelete);
  const { addToast } = useToasts();
  const classes = useStyles();
  const {
    GetByPage,
    CreateOne,
    DeleteOne,
    UpdateOne,
  } = useService(ForwardingPhoneNumbersService);
  useEffect(() => {
    componentIsLoaded.current = true;
    return () => {componentIsLoaded.current = false}
  }, []);

  useEffect(() => {
    forwardNumberGET(1, filterActivated.current);
  }, [rowsPerPage]);

  useEffect(() => {
    if (
      filterActivated.current && searchInput === ""
    ) {
      filterActivated.current = false;
      forwardNumberGET(1);
    }
  }, [searchInput]);

  const addPhoneNumber = () => {
    setEditData(null);
    setOpenCreateModal(true);
  };

  const forwardNumberGET = async (page?: number, useFilters?: boolean) => {
    try {
      setIsLoading(true);
      const search: {phoneNumber?: string; description?: string;} = {};
      search[/^\d+$/.test(searchInput) ? "phoneNumber" : "description"] = searchInput.trim();
      const { data, pagination } = await GetByPage(
        page ?? pageState?.currentPage ?? 1,
        rowsPerPage,
        true,
        useFilters ? search : undefined
      );

      if (componentIsLoaded) {
        setNumberList(data);
        setPageState(pagination);
        setFirstLoad(false);
        setIsLoading(false);
      }
    } catch (error) {
      setFirstLoad(false);
      setIsLoading(false);
      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };

  const forwardNumberPOST = async (formData: IForwardedPhoneNumberBody) => {
    try {
      const body = formData;
      const { data } = await CreateOne(body);
      await forwardNumberGET();
      setOpenCreateModal(false);
    } catch (err) {
      throw err;
    }
  };

  const forwardNumberPUT = async (
    formData: IForwardedPhoneNumberBody,
    id: number
  ) => {
    try {
      const body = formData;
      const { data } = await UpdateOne(String(id), body);
      setNumberList((list) =>
        list.map((item) => (item.id !== id ? item : data))
      );
      setOpenCreateModal(false);
    } catch (err) {
      throw err;
    }
  };

  const forwardNumberDELETE = async (id: number) => {
    try {
      setIsDeleting(true);
      await DeleteOne(String(id));
      await forwardNumberGET();
      setIsDeleting(false);
      setNumberToDelete(null);
    } catch (error) {
      if ((error as any).response && (error as any).response.status === ErrorStatusCode.FORBIDDEN) {
        addToast(ApiErrorMessages.FORBIDDEN, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, { appearance: 'error', autoDismiss: true });
        throw error;
      }
    }
  };
  
  const submitData = (data: IForwardNumberForm) =>
    editData ? forwardNumberPUT(data, editData.id) : forwardNumberPOST(data);
  const handlePageChange = (page: number) => forwardNumberGET(page, filterActivated.current);

  const handleSearchOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      filterActivated.current = true;
      forwardNumberGET(1, filterActivated.current);
    }
  };

  const toggleSearchButton = () => {
    filterActivated.current = true;
    forwardNumberGET(1, filterActivated.current);
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  return (
    <>
      <Loader open={isLoading} />
      <UnderlinedTitle>{FPNStrings.TITLE}</UnderlinedTitle>
      {!firstLoad && <Grid className={classes.content}>
        <Grid className={classes.heading}>
          <RoundedInputField
            style={{width: ComponentDimensions.PREFERENCES_SEARCH_WIDTH, height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT}}
            endAdornment={
              searchInput ? (
                <>
                  <SearchIcon 
                    className={classes.searchReset} 
                    width="19" 
                    onClick={toggleSearchButton}
                  />
                  <CloseIcon
                    width="17"
                    className={classes.searchReset}
                    onClick={() => setSearchInput("")}
                  />
                </>
              ) : (
                <SearchIcon className={classes.searchReset} width="19" />
              )
            }
            value={searchInput}
            onKeyDown={handleSearchOnEnter}
            onChange={handleSearchOnChange}
          />
          <InputButton
            onClick={addPhoneNumber}
            label={FPNStrings.ADD_PHONE_NUMBER}
            className={classes.addBtn}
            color="primary"
            plusIcon
            minWidth={ComponentDimensions.PREFERENCES_ADD_BUTTON_WIDTH}
          />
        </Grid>
        <CustomTablePagination
          pagination={pageState}
          handlePageChange={handlePageChange}
          storeKey="forwardingPhoneNumbers"
        >
          <PlainTable
            data={numberList}
            selectColumns={FPNStrings.TABLE_COL_LABELS}
            columnSize={["200px", "30%", "auto"]}
            colSettings={[{
              index: 2,
              centerHeader: true,
            }]}
            dataMapper={mapForwardedNumberToTableData}
            actions={[
              {
                colLabel: uiString.SETTINGS,
                width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                ActionComponent: (row) => (
                  <TableEditButton
                    key={row.id}
                    row={row}
                    handler={(row: IForwardedPhoneNumber) => {
                      setEditData(row);
                      setOpenCreateModal(true);
                    }}
                  />
                ),
              },
              {
                colLabel: uiString.DELETE,
                width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                ActionComponent: (row) => (
                  <TableDeleteButton
                    key={row.id}
                    row={row}
                    handler={(row) => setNumberToDelete(row)}
                  />
                ),
              },
            ]}
            fullWidth
          />
        </CustomTablePagination>
        <FPNumberCreateDialog
          open={openCreateModal}
          close={() => setOpenCreateModal(false)}
          formData={editData}
          deleteData={forwardNumberDELETE}
          submitData={submitData}
        />
        <ConfirmDialog
          header={uiString.DELETE_FPN_CONFIRMATION_HEADER}
          content={
            <>
              {uiString.DELETE_FPN_CONFIRMATION_BODY} {" "}
              {numberToDelete?.number ?? prevNumberToDelete?.number}?
            </>
          }
          open={Boolean(numberToDelete)}
          confirmButtonLabel={uiString.DELETE}
          loading={isDeleting}
          onCancel={() => setNumberToDelete(null)}
          onConfirm={() => numberToDelete && forwardNumberDELETE(numberToDelete.id)}
        />
      </Grid>}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(1, 0),
  },
  content: {
    width: '100%',
  },
  searchReset: {
    cursor: "pointer",
    color: 'grey',
  },
  addBtn: {
    fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
    height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT
  }
}));

const iconStyles = makeStyles((theme: Theme) => ({
  enabled: {
    fill: theme.palette.common.buttonGreen
  },
  disabled: {
    fill: theme.palette.common.buttonRed
  }
}));

export default ForwardPhoneNumbersView;

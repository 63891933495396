import {
  createStyles,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import clsx from "clsx";
import { ComponentDimensions } from "@constants/appConstants";

type IActionTuple<T> = {
  colLabel: string;
  ActionComponent: (row: T) => JSX.Element | string | null;
  width?: string | number;
};

type IProps<T extends { id: string | number }> = {
  data: T[];
  style?: React.CSSProperties;
  selectColumns: string[];
  columnSize?: (string | number)[];
  dataMapper?: (row: T) => any;
  actions?: IActionTuple<T>[];
  contactsClassName?: string,
  fullWidth?: boolean;
  colSettings?: IColumnSettings[];
  groupBy?: string;
  extraDetails?: {
    header: string;
    resetTrigger?: boolean;
    DetailsComponent: (row: T, open: any) => JSX.Element;
  };
};

type IMappedTableProps<T extends { id: string | number }> = {
  row: T;
  selectColumns: string[];
  dataMapper?: (row: T) => T;
  actions?: IActionTuple<T>[];
  isGroup?: boolean;
  extraDetails?: {
    header: string;
    resetTrigger?: boolean;
    DetailsComponent: (row: T, open: any) => JSX.Element;
  };
};

type IColumnSettings = {
  index: number;
  centerHeader?: boolean;
  centerBody?: boolean;
};

const getColSettings = (settings: IColumnSettings[], index: number) =>
  settings.find((item) => item.index === index);

const partitionData = <
  T extends { id: string | number }
 >(data: T[], groupBy: string) => {
  return _.values(_.groupBy(data, groupBy));
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: ComponentDimensions.TABLE_ROW_FONT_SIZE,
      boxSizing: "border-box",
      paddingTop: 3,
      paddingBottom: 0,
      height: ComponentDimensions.TABLE_BODY_ROW_HEIGHT,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) => {
  return createStyles({
    root: {
      border: "none",
      "& > td:first-child": {
        paddingLeft: 10
      },
      "& > td:last-child": {
        paddingRight: 10,
      },
      "& > th:first-child": {
        paddingLeft: 10,
      },
      "& > th:last-child": {
        paddingRight: 10,
      },
    },
  });
})(TableRow);

const useStyles = makeStyles(theme =>
  createStyles({
    typeIconColumn: {
      paddingRight: "10%",
      whiteSpace: "nowrap",
    },
    tableContainer: {
      boxShadow: "none",
      width: "max-content",
    },
    centerContent: {
      textAlign: "center",
    },
    fullWidth: {
      width: "100% !important",
    },
    table: {
      position: "relative",
      border: "none",
      overflow: "initial",
      width: "auto",
    },
    tableHeaderText: {
      fontFamily: "'Lato', sans-serif",
      fontWeight: 600,
      color: "var(--color-charcoal)",
      fontSize: ComponentDimensions.TABLE_HEADER_FONT_SIZE,
      borderBottom: "1px solid var(--color-dark-grey)",
    },
    tableActionHeader: {
      textAlign: "center",
    },
    tableContentText: {
      fontFamily: "'Lato', sans-serif",
      fontWeight: "normal",
      color: "var(--color-charcoal)",
      fontSize: ComponentDimensions.TABLE_ROW_FONT_SIZE,
    },
    callHistoryStripedColoring: {
      "& > tr:nth-child(4n + 3)": {
        background: theme.palette.common.whiteTan,
      },
      "& > tr:nth-child(4n)": {
        background: theme.palette.common.whiteTan,
      },
    },
    stripedColoring: {
      "& > tr:nth-child(even)": {
        background: theme.palette.common.whiteTan,
      },
    },
    categoryRow: {
      background: `${theme.palette.common.lightGrey} !important`,
      "& > td": {
        fontWeight: "bold",
        fontSize: ComponentDimensions.TABLE_ROW_FONT_SIZE,
        
      }
    }
  })
);

const CategoryTableRow = ({textValue, ...rest}: {textValue: string, key: string, className: string;}) => {
  return (
    <StyledTableRow {...rest}>
      <StyledTableCell colSpan={12}>
        {textValue}
      </StyledTableCell> 
    </StyledTableRow>
  )
}

const useMappedTableRowStyles = makeStyles((theme: Theme) => ({
  text: {
    listStyle: "disc", 
    padding: "3px 0"
  },
}));
function MappedTableRow<RowType extends { id: number | string }>({
  row,
  selectColumns,
  dataMapper,
  extraDetails,
  actions
}: IMappedTableProps<RowType>) {
  const displayRow = dataMapper ? dataMapper(row) : row;
  const [showDetails, setShowDetails] = useState(false);
  const classes = useMappedTableRowStyles();

  useEffect(() => {
    setShowDetails(false);
  }, [extraDetails?.resetTrigger]);

  /**
   * @return an array of only those property values from `row` whose property key name is listed
   * as a string in `selectedColumns`
   */
  const columnTextLabelsToDisplay: string[] | string[][] | null = [
    ...selectColumns.map((key) => displayRow[key]),
  ];
  return (
    <>
      <StyledTableRow>
        {columnTextLabelsToDisplay.map((item, index) => {
          return _.isArray(item)
          ? <StyledTableCell key={createKeyIndex(index, "column-item-labels")}>
              <List disablePadding>
                {item.map((text, textIndex) => <li key={createKeyIndex(textIndex, "column-text-label")} className={classes.text}>{text}</li>)}
              </List>
            </StyledTableCell>
          : <StyledTableCell
              key={createKeyIndex(index, "column-item-labels")}
            >
                {item}
            </StyledTableCell>
        })}
        {actions?.map((action, index) => (
          <StyledTableCell
            key={createKeyIndex(action.colLabel + index, "styled-table-action")}
            align="center"
          >
            {action.ActionComponent(row)}
          </StyledTableCell>
        ))}
        {extraDetails && (
          <StyledTableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setShowDetails((prevState) => !prevState)}
            >
              {showDetails ? (
                <ExpandLess fontSize="large" style={{ cursor: "pointer" }} />
              ) : (
                <ExpandMore fontSize="large" style={{ cursor: "pointer" }} />
              )}
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
      {extraDetails?.DetailsComponent(row, showDetails)}
    </>
  );
}

/**
 * Presentation component for a plain table
 *
 * @component
 * @param style - styling passed down to TableContainer
 * @param data - an array of objects containing a unique id property
 * @param selectedColumns - an array of `data` members' key names which will make the columns of the table
 * @param actions - an array of tuples in the form of `[textLabel, actionHandler]` representing
 *  actions and their respective button labels. Will be appended to the end of every row
 * @param dataMapper - function which maps the data entry values strings or numbers.
 * @example
 * const MyComponent = () => {
 *  // example item: {id: 1, user: "Bob", email:"bob@talkroute.com", phonenumber:"12345324"}
 *  const items: UserData[] = userData;
 *  return (
 *    <div>
 *      <h3>Basic example</h3>
 *      <PlainTable
 *         data={items}
 *         // will be used as column labels as well as selectors
 *         selectedColumns={["user", "email", "phonenumber"]}
 *      />
 *      <h3> Combining multiple columns into one </h3>
 *      <PlainTable
 *        data={items}
 *        selectedColumns={["my custom table", "phonenumber"]}
 *        // item here is of type UserData
 *        dataMapper={(item) => {...item, "my custom table": item.user + '-' + item.email}}
 *      />
 *    </div>
 *  )
 * }
 */
function PlainTable<EntryType extends { id: string | number }>({
  data,
  selectColumns,
  dataMapper,
  columnSize,
  actions,
  extraDetails,
  fullWidth,
  contactsClassName,
  style,
  groupBy,
  colSettings,
}: IProps<EntryType>) {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      className={clsx(classes.tableContainer, fullWidth && classes.fullWidth, contactsClassName && contactsClassName)}
      style={style}
    >
      <Table
        stickyHeader
        role="table"
        className={clsx(classes.table, fullWidth && classes.fullWidth)}
      >
        <TableHead>
          <StyledTableRow>
            {selectColumns.map((col, index) => (
              <StyledTableCell
                className={clsx(
                  classes.tableHeaderText,
                  colSettings &&
                    getColSettings(colSettings, index)?.centerHeader &&
                    classes.centerContent
                )}
                key={createKeyIndex(index, "styled-table-cell")}
                style={{
                  width: columnSize && columnSize[index] && columnSize[index],
                }}
              >
                {_.startCase(_.camelCase(col))}
              </StyledTableCell>
            ))}
            {actions?.map((action, index) => (
              <StyledTableCell
                className={clsx(
                  classes.tableHeaderText,
                  classes.tableActionHeader
                )}
                key={createKeyIndex(index, "styled-table-cell-second")}
                style={{ width: action.width }}
              >
                {action.colLabel}
              </StyledTableCell>
            ))}
            {extraDetails && (
              <StyledTableCell
                className={clsx(
                  classes.tableHeaderText,
                  classes.tableActionHeader
                )}
                style={{
                  width: ComponentDimensions.TABLE_ACTION_COLUMN_DEFAULT_WIDTH,
                }}
              >
                {extraDetails.header}
              </StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody
          className={clsx(
            classes.tableContentText,
            extraDetails
              ? classes.callHistoryStripedColoring
              : classes.stripedColoring
          )}
        >
          {!groupBy && data.map((row: EntryType) => (
            <MappedTableRow
              key={createKeyIndex(row.id, "mapped-table-row")}
              selectColumns={selectColumns}
              row={row}
              dataMapper={dataMapper}
              actions={actions}
              extraDetails={extraDetails}
            />
          ))}
          {
            groupBy && partitionData(dataMapper ? data.map(dataMapper) : data, groupBy)
            .reduce<JSX.Element[]>((elements, group, index) => {
              return [
                ...elements,
                <CategoryTableRow 
                  textValue={String(group[0][groupBy])}
                  key={createKeyIndex(index, "mapped-table-category-row")}
                  className={classes.categoryRow}
                />,
                ...group.map((row: EntryType) => (
                  <MappedTableRow
                    key={createKeyIndex(row.id, "mapped-table-row")}
                    selectColumns={selectColumns}
                    row={row}
                    dataMapper={dataMapper}
                    actions={actions}
                    extraDetails={extraDetails}
                  />
                ))
              ]
            }, [])
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PlainTable;

import { requiredField } from "@helpers/formHelpers/formValidatorHelpers";
import {
  DayEnum,
  IHoursOfOperationBody,
  MapDayCodeToLabel,
} from "@models/HoursOfOperation.models";
import { CallDestinationType } from "@models/Menu.models";
import { IFormValidator } from "@models/form.models";
import { IAudioFile } from "@models/AudioFiles.models";
import moment from "moment";

export const MANAGE_OFF_HOUR = "Hours of Operation Settings";
export const ADD_TIME_SLOT = "Add Time Slot";
export const AUDIO_FILE = "After Hours Audio File";
export const DESTINATION_LABEL = "After Hours Destination";
export const DESTINATION = "destination";
export const WHERE_CALLERS_WILL = "Where callers will be redirected to";
export const VOICEMAIL_BOX_DESTINATION = "Select Mailbox";
export const MENU_DESTINATION = "Select Menu";
export const SAVE = "Save";
export const WHAT_CALLERS_WILL_HEAR = "What callers will hear";
export const AUDIO_FILE_ID = "audioFileId";
export const TIMEZONE = "timezone";
export const TIMEZONE_LABEL = "Timezone";
export const contentMaxWidth = '710px';

export const callResponseFormInitialValues = {
  audioFileId: { initialValue: '', validators: [requiredField()] },
  destination: { initialValue: { type: CallDestinationType.DISCONNECT, id: '0' }, validators: [] },
  timezone: { initialValue: '', validators: [requiredField()] }
}

export const mapDestinationTypeToLabel = {
  [CallDestinationType.VOICEMAIL]: "Voicemail",
  [CallDestinationType.MENU]: "Go to a menu",
  [CallDestinationType.DISCONNECT]: "Disconnect the call",
};

export const destinationDropdownOptions = [
  { value: CallDestinationType.VOICEMAIL, 
    text: mapDestinationTypeToLabel[CallDestinationType.VOICEMAIL] },
  { value: CallDestinationType.MENU,
    text: mapDestinationTypeToLabel[CallDestinationType.MENU] },
  { value: CallDestinationType.DISCONNECT,
    text: mapDestinationTypeToLabel[CallDestinationType.DISCONNECT] }
];

export type CallResponseType = {
  audioFileId: string,
  destination: { type: CallDestinationType, id: string },
  timezone: string
};

export const noneAudioFile: IAudioFile = {
  id: '0',
  link: "",
  name: "--None--",
  type: undefined,
  createdAt: null,
  usedBy: "hourOverride"
}

export type IHoursOfOperationForm = {
  startDay: DayEnum;
  endDay: DayEnum;
  startTime: IClockTime;
  endTime: IClockTime;
};

export type IClockTime = {
  hour: number;
  minute: number;
};

export enum TimeClockFormat {
  GOOD = "24h",
  BAD = "12h",
}

const formatDayColumn = (startDay: DayEnum, endDay: DayEnum) => {
  if (startDay === endDay) return MapDayCodeToLabel[startDay];

  return `${MapDayCodeToLabel[startDay]} to ${MapDayCodeToLabel[endDay]}`;
};

const formatTimeColumn = (
  hour: number,
  minute: string,
  format: TimeClockFormat
) => {
  if (format === TimeClockFormat.GOOD) {
    const _minute = Number(minute) < 10 ? `0${minute}` : minute;
    const _hour = hour;
    return `${_hour}:${_minute}`;
  } else {
    const date = 
    moment(`${hour}:${minute}`,'hh:mm').format(format === TimeClockFormat.BAD ? 'hh:mm' : 'HH:mm');
    const suffix = format === TimeClockFormat.BAD ? hour >= 12 ? 'PM' : 'AM' : '';
    return date.toString() + ' ' + suffix;
  }
};

const formToBody = (form: IHoursOfOperationForm): IHoursOfOperationBody => {
  return {
    dayStart: form.startDay,
    dayEnd: form.endDay,
    hourStart: form.startTime.hour,
    hourEnd: form.endTime.hour,
    minuteStart: String(form.startTime.minute),
    minuteEnd: String(form.endTime.minute),
  };
};

export const hoursOfOperationHelpers = {
  formatDayColumn,
  formatTimeColumn,
  formToBody,
};

/**
 * @param timezone: string of the form `(GMT-12:00) International Date Line West`
 * @returns the prefix `(GMT-12:00)`
 */
export const returnGmtPrefixFromTimezone = (timezone: string) =>
  timezone.substr(0, timezone.indexOf(")") + 1);

/**
 * @param timezone: string of the form `(GMT-12:00) International Date Line West`
 * @returns the same string with the prefix `(GMT-12:00)` sliced
 */
export const removeGmtPrefixFromTimezone = (timezone: string) =>
  timezone.substr(timezone.indexOf(")") + 1, timezone.length).trimLeft();

// temporary solution for testing purposes
export const TIMEZONES_ARRAY = [
  {
    label: "(GMT-12:00) International Date Line West",
    value: "Pacific/Kwajalein",
  },
  { label: "(GMT-11:00) Midway Island", value: "Pacific/Midway" },
  { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(GMT-09:00) Alaska", value: "America/Anchorage" },
  {
    label: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { label: "(GMT-07:00) Arizona", value: "America/Phoenix" },
  { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "America/Denver" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "America/Chicago" },
  { label: "(GMT-06:00) Central America", value: "America/Managua" },
  { label: "(GMT-06:00) Mexico City", value: "America/Mexico_City" },
  { label: "(GMT-06:00) Monterrey", value: "America/Monterrey" },
  {
    label: "(GMT-05:00) Eastern Time (US & Canada)",
    value: "America/New_York",
  },
  {
    label: "(GMT-05:00) Indiana (East)",
    value: "America/Indiana/Indianapolis",
  },
  { label: "(GMT-05:00) Bogota", value: "America/Bogota" },
  { label: "(GMT-05:00) Lima", value: "America/Lima" },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "America/Halifax" },
  { label: "(GMT-04:00) La Paz", value: "America/La_Paz" },
  { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
  { label: "(GMT-03:30) Newfoundland", value: "America/St_Johns" },
  { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  {
    label: "(GMT-03:00) Buenos Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "(GMT-03:00) Georgetown", value: "America/Argentina/Buenos_Aires" },
  { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
  { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { label: "(GMT+00:00) Casablanca", value: "Africa/Casablanca" },
  { label: "(GMT+00:00) London", value: "Europe/London" },
  { label: "(GMT+01:00) Amsterdam", value: "Europe/Amsterdam" },
  { label: "(GMT+01:00) Berlin", value: "Europe/Berlin" },
  { label: "(GMT+01:00) Stockholm", value: "Europe/Stockholm" },
  { label: "(GMT+02:00) Bucharest", value: "Europe/Bucharest" },
  { label: "(GMT+02:00) Helsinki", value: "Europe/Helsinki" },
  { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { label: "(GMT+03:00) Kuwait", value: "Asia/Kuwait" },
  { label: "(GMT+03:00) Moscow", value: "Europe/Moscow" },
  { label: "(GMT+04:00) Abu Dhabi", value: "Asia/Muscat" },
  { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
  { label: "(GMT+05:00) Ekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "(GMT+05:00) Islamabad", value: "Asia/Karachi" },
  { label: "(GMT+05:30) Chennai", value: "Asia/Kolkata" },
  { label: "(GMT+05:30) Kolkata", value: "Asia/Kolkata" },
  { label: "(GMT+05:30) Mumbai", value: "Asia/Kolkata" },
  { label: "(GMT+05:30) New Delhi", value: "Asia/Kolkata" },
  { label: "(GMT+05:45) Kathmandu", value: "Asia/Kathmandu" },
  { label: "(GMT+06:00) Astana", value: "Asia/Dhaka" },
  { label: "(GMT+06:00) Novosibirsk", value: "Asia/Novosibirsk" },
  { label: "(GMT+06:00) Sri Jayawardenepura", value: "Asia/Colombo" },
  { label: "(GMT+06:30) Rangoon", value: "Asia/Rangoon" },
  { label: "(GMT+07:00) Bangkok", value: "Asia/Bangkok" },
  { label: "(GMT+07:00) Hanoi", value: "Asia/Bangkok" },
  { label: "(GMT+08:00) Beijing", value: "Asia/Hong_Kong" },
  { label: "(GMT+08:00) Hong Kong", value: "Asia/Hong_Kong" },
  { label: "(GMT+08:00) Singapore", value: "Asia/Singapore" },
  { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { label: "(GMT+09:00) Tokyo", value: "Asia/Tokyo" },
  { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
  { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
  { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
  { label: "(GMT+10:00) Sydney", value: "Australia/Sydney" },
  { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
  { label: "(GMT+11:00) New Caledonia", value: "Asia/Magadan" },
  { label: "(GMT+11:00) Solomon Is.", value: "Asia/Magadan" },
  { label: "(GMT+12:00) Auckland", value: "Pacific/Auckland" },
  { label: "(GMT+12:00) Fiji", value: "Pacific/Fiji" },
  { label: "(GMT+12:00) Kamchatka", value: "Asia/Kamchatka" },
];

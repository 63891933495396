import { InviterOptions, Inviter, SessionState } from "sip.js";

import { normalizeCallTarget } from "@utils";

import { CallComponentInstance } from "../index";
import { setBasicSessionListeners } from "./sip-session-callback";

import * as stateUtils from "./state-utils";

/**
 * Request to start a call
 */

export const requestCall = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { numberToCall } = that.props;
};

/**
 * Hang up, if there is an active call currently
 */
export const hangUp = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { callSession } = that.state;
  const { preferencesFromPhoneNumber } = that.props;

  if (callSession) {
    if (callSession instanceof Inviter) {
      if (callSession.state === SessionState.Established) callSession.bye();
      else callSession.cancel();
    } else {
      if (callSession.state === SessionState.Established) callSession.bye();
      else callSession.reject({ statusCode: 607 });
    }
  }

  // Set the outgoing phone number to default number
  if (preferencesFromPhoneNumber) {
    that.updateOutgoingPhoneNumber(preferencesFromPhoneNumber);
  }
};

/**
 * Clear all call information
 */
export const idleCall = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { idleCall: idleCallLocal } = that.props;
  const { preferencesFromPhoneNumber } = that.props;

  idleCallLocal();
  // Set the outgoing phone number to default number
  if (preferencesFromPhoneNumber) {
    that.updateOutgoingPhoneNumber(preferencesFromPhoneNumber);
  }
};

/**
 * Start a call using numberToCall
 */
export const makeCall = (that: CallComponentInstance) => {
  if(!that._ismounted) return;
  const { isConnecting } = that.state;
  if (isConnecting) return;

  const {
    numberToCall,
    callConnecting: callConnectingLocal,
    outgoingPhoneNumber,
    impu,
  } = that.props;
  const { sipAgent } = that.state;

  const callTarget = normalizeCallTarget(numberToCall, impu);
  const fromHeader = `X-From-CallerID: <sip:${outgoingPhoneNumber}>`;

  if (sipAgent && callTarget) {
    const inviterOptions: InviterOptions = {
      sessionDescriptionHandlerOptions: {
        constraints: { audio: true, video: false },
      },
      extraHeaders: [fromHeader],
    };

    const callSession = new Inviter(sipAgent, callTarget, inviterOptions);

    setBasicSessionListeners(that, callSession!);
    callConnectingLocal();

    stateUtils.setIsConnecting(that, true);
    stateUtils.setCallSession(that, callSession);

    callSession.invite();
  }
};

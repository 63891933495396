import { RowsPerPageOptions } from "@models/AppPreference.models";
import platform from "platform";
import appConfig from "../config/index"
export const isIOS = platform.os?.family === "OS X";
export const isFirefox = platform.layout === "Gecko";
export const isSafari = platform.name === "Safari";
export const isDesktop = appConfig.clientType === "desktop";
export const JOIN_MEET_BEFORE_MINUTES = 30;
export const ROWS_PER_PAGE_DEFAULT_VALUE: RowsPerPageOptions = 25;
const factor = 0.9;
export const ComponentDimensions = {
    /**
     * Unit: px
     */
    DESKTOP_TOOLBAR_HEIGHT: 28,
    /**
     * Unit: px
     */
    NAVIGATION_MENU_WIDTH: 250,
    /**
     * Unit: px
     */
    SETTINGS_AND_PREFERENCES_HEADER_HEIGHT: ~~(113*factor),
    /**
     * Unit: px
     */
    SETTINGS_AND_PREFERENCES_HEADER_FONT_SIZE: ~~(28*factor),
    /**
     * Unit: px
     */
    SETTINGS_AND_PREFERENCES_CLOSE_ICON_SIZE: ~~(32*factor),
    /**
     * Unit: px
     */
    TABLE_ACTION_COLUMN_DEFAULT_WIDTH: 60,

    MEETINGS_MAIN_SECTION_HEADER: 60,
    // Settings & Preferences
    TABLE_ROW_FONT_SIZE: ~~(18*factor),
    TABLE_HEADER_FONT_SIZE: ~~(18*factor),
    PAGINATION_LABEL_FONT_SIZE: ~~(18*factor),
    PAGINATION_SUBCOMPONENT_HEIGHT: ~~(22*factor),
    PAGINATION_INPUT_TEXT_FONT_SIZE: ~~(14*factor),
    PREFERENCES_INPUT_FONT_SIZE: ~~(14*factor),
    PREFERENCES_PAGE_HEADER_FONT_SIZE: ~~(28*factor),
    PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN: ~~(18*factor),
    PREFERENCES_PAGE_CONTENT_TOP_PADDING: ~~(55*factor),
    PREFERENCES_CONTROL_BUTTON_ICON_SIZE: ~~(30*factor),
    TABLE_FILTER_BUTTON_HEIGHT: ~~(34*factor),
    TABLE_FILTER_LABEL: ~~(14*factor),
    TABLE_FILTER_TEXT_FIELD_HEIGHT: ~~(34*factor),
    TABLE_FILTER_DIVIDER_HEIGHT: ~~(34*factor),
    TABLE_FILTER_BUTTON_MIN_WIDTH: ~~(106*factor),
    TABLE_BODY_ROW_HEIGHT: ~~(52*factor),

    PREFERENCES_NAVIGATION_WIDTH: ~~(360*factor),
    PREFERENCES_NAVIGATION_LINK_FONT_SIZE: ~~(20*factor),
    PREFERENCES_NAVIGATION_SECTION_TITLE_FONT_SIZE: 28,
    TABLE_CROSS_ICON_SIZE: ~~(18*factor),
    PREFERENCES_NAVIGATION_PADDING_LEFT: 36,
    PREFERENCES_NAVIGATION_PADDING_RIGHT: 76,
    ADD_BUTTON_PLUS_ICON_SIZE: ~~(16*factor), // design px size is 14
    TABLE_PLAY_BUTTON_WIDTH: ~~(86*factor),
    TABLE_PLAY_BUTTON_HEIGHT: ~~(24*factor),
    TABLE_PLAY_BUTTON_FONT_SIZE: ~~(14*factor),
    TABLE_PLAY_BUTTON_ICON_SIZE: ~~(13*factor),
    TABLE_PLAY_BUTTON_ARROW_BOX_SIZE: ~~(22*factor),
    TABLE_PLAY_BUTTON_PADDING_LEFT: ~~(8*factor),
    TABLE_PLAY_BUTTON_TEXT_PADDING_LEFT: ~~(5*factor),
    TABLE_EDIT_ICON_SIZE: ~~(20*factor),
    TABLE_DELETE_ICON_SIZE: ~~(20*factor),
    PREFERENCES_ADD_BUTTON_WIDTH: ~~(166*factor),
    PREFERENCES_SEARCH_WIDTH: ~~(500*factor),
    PREFERENCES_FILTER_DROPDOWN_WIDTH: ~~(166*factor),
}
import { validateEmail } from "@helpers/formHelpers/formValidatorHelpers";
import { trimPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import { CallType } from "@models/Callhistory.models";
import { IContact, IContactsPartition } from "@models/Contacts.models";
import { IFormErrorField } from "@models/form.models";
import { CallHistoryData, ConversationData, VoicemailData } from "@services/dataClasses";

const HTTPS = "https://";
export const CONTACTS_FIRST_INDEX = 0;

/**
 * Transforms an url address and attach an https:// prefix to it. 
 * @param url www.talkroute.com
 * @returns https://www.talkroute.com
 */
export const addHttpsToUrl = (url: string) => {
  return (url !== "" && !url.includes(HTTPS)) ? `${HTTPS}${url}` : url;
};

/**
 * Remove https:// prefix from an url address.
 * @param url https://www.talkroute.com
 * @returns www.talkroute.com
 */
export const removeHttpsFromUrl = (url: string) => {
  return url.includes(HTTPS) ? url.slice(HTTPS.length) : url;
};

/**
 * Remove empty string emails from array
 */
export const trimEmails = (emails: string[]) => {
  let result: string[] | null = [];
  result = emails.filter(email => email != "");
  return result.length > 0 ? result : null;
};

export const emailValidator = (email: string) => {
  let result: IFormErrorField = {
    hasError: false,
    validatorResults: { code: null, errorMessage: ""}
  };
  if (email && email !== "") {
    result = validateEmail()(email);
  };
  return result;
};

/**
   * Display contacts which names match to search bar filter
   */
export const filterContactsByName = (contactsList: IContact[], filterByName: string) => {
  if (contactsList) {
    return contactsList
      .filter(contact => contact.name.toLocaleLowerCase()
      .includes(filterByName.toLocaleLowerCase()));
  };
  return [];
};

/**
  * Sorts contacts alphabetically based on contact name. Names beginning 
  */
 export const sortContacts = (contactsArr: IContact[], mutateArray = false) => {
  return (mutateArray ? contactsArr : [...contactsArr]).sort((alpha, beta) => {
    const aFirstLetterIndex = alpha.name[CONTACTS_FIRST_INDEX]
      .toUpperCase()
      .charCodeAt(CONTACTS_FIRST_INDEX);

    const bFirstLetterIndex = beta.name[CONTACTS_FIRST_INDEX]
      .toUpperCase()
      .charCodeAt(CONTACTS_FIRST_INDEX);
      
    let isAlphaSpecialSymbol = aFirstLetterIndex < 65 || aFirstLetterIndex > 90; 
    let isBetaSpecialSymbol = bFirstLetterIndex < 65 || bFirstLetterIndex > 90;

    if (isAlphaSpecialSymbol && isBetaSpecialSymbol) {
      return aFirstLetterIndex - bFirstLetterIndex;
    } else if (isAlphaSpecialSymbol && !isBetaSpecialSymbol) {
      return 0 - bFirstLetterIndex;
    } else if (!isAlphaSpecialSymbol && isBetaSpecialSymbol) {
      return aFirstLetterIndex - 0;
    } else {
      return aFirstLetterIndex - bFirstLetterIndex;
    };
  });
};

/**
 * Partition contacts by first letter symbol.  
 * @param contacts list of all contacts.
 * @returns {
 * {
 *  Other: [ contacts whose first name letter start with a symbol ],
 *  A: [ contacts whose first name letter start with "A" ],
 *  B: [ contacts whose first name letter start with "B" ],
 *  ...
 * }
 */
export const partitionContactsByName = (contacts: IContact[]) => {
  let result: IContactsPartition = {};
  contacts.map((contact) => {
    const currentContactNameFirstLetter = contact.name[CONTACTS_FIRST_INDEX].toUpperCase();
    const firstLetterToUpperCaseCode = currentContactNameFirstLetter
      .charCodeAt(CONTACTS_FIRST_INDEX);
    const isFirstLetterASymbol = (firstLetterToUpperCaseCode < 65 || firstLetterToUpperCaseCode > 90);

    if (isFirstLetterASymbol) {
      result["Other"] 
        ? result["Other"].push(contact) 
        : result["Other"] = [ contact ];
    } else {
      result[currentContactNameFirstLetter] 
        ? result[currentContactNameFirstLetter].push(contact) 
        : result[currentContactNameFirstLetter] = [ contact ];
    };
  });
  return result;
};


const findFirstContactWithNumber = (contact: IContact, number: string) => 
  contact.phoneNumbers.find(
    _n => trimPhoneNumber(number) === trimPhoneNumber(_n.phoneNumber)
  );

/**
 * Returns a list ofor `CallHistoryData`
 * whose contactName is set to a contact from the `contacts` list
 * @param contacts 
 * @param callHistory 
 */
export const addContactNameToCallHistory = (contacts: IContact[], callHistory: CallHistoryData[]) => {
    const improvedCallHistoryList: CallHistoryData[] = callHistory.map(call => {
        if (call.contact) return call;
            const contactInfo = contacts.find(contact => findFirstContactWithNumber(contact, call.type === CallType.INBOUND ? call.callerNumber : call.calledNumber));
        if (contactInfo) {
            return call.setContactInfo(contactInfo);
        } 
        return call;
    });
    return improvedCallHistoryList;
}

/**
 * Returns a list ofor `CallHistoryData`
 * whose contactName is set to a contact from the `contacts` list
 * @param contacts 
 * @param callHistory 
 */
 export const addContactNameToVoicemail = (contacts: IContact[], voicemailList: VoicemailData[]) => {
  const improvedCallHistoryList: VoicemailData[] = voicemailList.map(call => {
      if (call.contact) return call;
          const contactInfo = contacts.find(contact => findFirstContactWithNumber(contact, call.callerNumber));
      if (contactInfo) {
          return call.setContactInfo(contactInfo);
      } 
      return call;
  });
  return improvedCallHistoryList;
}


/**
 * Returns a list of `ConversationData`
 * whose contactName is set to a contact from the `contacts` list
 * @param contacts 
 * @param callHistory 
 */
 export const addContactNameToConversation = (contacts: IContact[], conversationList: ConversationData[]) => {
  const improvedCallHistoryList: ConversationData[] = conversationList.map(call => {
      if (call.contact) return call;
          const contactInfo = contacts.find(contact => findFirstContactWithNumber(contact, call.contactNumber));
      if (contactInfo) {
          return call.setContactInfo(contactInfo);
      } 
      return call;
  });
  return improvedCallHistoryList;
}

/**
 * Returns a list of `ConversationData`
 * whose contactName is set to a contact from the `contacts` list
 * @param contacts 
 * @param conversationList
 */
export const addContactNameToConversationHistory = (contacts: IContact[], conversationList: ConversationData[]) => {
  const improvedConversationList: ConversationData[] = conversationList.map(conversation => {
      if (conversation.contact) return conversation;
      const contactInfo = contacts.find(contact => findFirstContactWithNumber(contact, conversation.contactNumber));
      if (contactInfo) {
          return conversation.setContactInfoForTextMessageHistory(contactInfo);
      } 
      return conversation;
  });
  return improvedConversationList;
}

export const findFirstContactByNumberFromList = (numberToFind: string, contacts: IContact[]) => {
  return contacts.find(contact => contact.phoneNumbers.some(contactNumber => trimPhoneNumber(contactNumber.phoneNumber) === trimPhoneNumber(numberToFind)))
}
import getApiConstants from "@constants/apiConstants";
import { IApiResponseWithPagination, IApiResponse } from "../models/IPagination";
import { IForwardedPhoneNumberBody, IForwardedPhoneNumber } from "../models/IPhoneNumbers";
import HttpClient from "./HttpClient";

export default function ForwardingPhoneNumbersService() {

    const url = getApiConstants().endpoints.forwardingnumbersv2.BASE;

    const { Get, Put, Post, Delete } = HttpClient();

    const GetByPage = async (page: number, pageSize: number, withoutDesktopDevices: boolean = false, search?: {phoneNumber?: string; description?: string;}) => {
        try {
            if (page <= 0 || pageSize <= 0) throw new Error("Invalid parameters");
            const params = new URLSearchParams();
            params.set("page", String(page));
            params.set("pageSize", String(pageSize));
            params.set("onlyMine", "1");
            params.set("withoutDesktopDevices", withoutDesktopDevices ? "1" : "0");
            search?.phoneNumber && params.set("phoneNumber", search.phoneNumber);
            search?.description && params.set("description", search.description);
            const { data } = await Get<IApiResponseWithPagination<IForwardedPhoneNumber[]>>({ url, params })
            return data;
        } catch (err) {
            throw err
        }
    };

    const GetOne = async (id: string) => {
        try {
            const { data } = await Get<IApiResponse<IForwardedPhoneNumber>>({ url, id });
            return data;
        } catch (err) {
            throw err
        }
    };

    const CreateOne = async (body: IForwardedPhoneNumberBody) => {
        try {
            const { data } = await Post<IApiResponse<IForwardedPhoneNumber>, IForwardedPhoneNumberBody>({ url, data: body })
            return data;
        } catch (err) {
            throw err;
        }
    };

    const DeleteOne = async (id: string) => {
        try {
            await Delete<void>({ url, id })
        } catch (err) {
            throw err;
        }
    };

    const UpdateOne = async (id: string, body: IForwardedPhoneNumberBody) => {
        try {
            const { data } = await Put<IApiResponse<IForwardedPhoneNumber>, IForwardedPhoneNumberBody>({ url, id, data: body })
            return data;
        } catch (err) {
            throw err;
        }
    };
    return {
        GetByPage,
        GetOne,
        CreateOne,
        DeleteOne,
        UpdateOne
    }
}

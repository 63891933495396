import React from 'react';
import * as Library from '@material-ui/core';
import { Button, DialogTitleProps, makeStyles } from '@material-ui/core';
import CrossIcon from "@resources/icons/cross-small.svg";

type Props = {
  onClose?: () => void;
  dismissable?: boolean;
  showProgressIcon?: boolean;
} & Omit<DialogTitleProps, ''>

const DialogTitle: React.FC<Props> = ({ onClose, title, dismissable, showProgressIcon, ...props }) => {
  const classes = useStyles();

  return (
    <Library.DialogTitle 
      { ...props }
      className={classes.header}
    >
      {title}
      {dismissable && <Button className={classes.closeButton} onClick={onClose}>
        <CrossIcon className={classes.closeButtonIcon} />
      </Button>}

      {showProgressIcon && <Library.CircularProgress size={16} />}
    </Library.DialogTitle>
  );
};

const useStyles = makeStyles({
  header: {
    padding: "12px 26px",

    "& h2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
  closeButton: {
    padding: "10px",
    minWidth: "0",
    borderRadius: "6px",
    marginRight: '-10px',
  },
  closeButtonIcon: {
    width: 14,
    height: 14,
  }
});

export default DialogTitle;
import { domIds } from "./testConstants";
import NotificationsAndSounds from "@components/NotificationsAndSounds";
import ForwardPhoneNumbersView from "@components/ForwardPhoneNumbers";
import CallerID from "@components/Preferences/CallerID";
import Controls from "@components/Preferences/Controls";
import VoicemailPreferences from "@components/VoicemailPreferences";
import PreferenceUpdates from "@components/Preferences/PreferenceUpdates";
import HoursOfOperation from "@components/HoursOfOperation";
import TransferCodes from "@components/TransferCodes";
import CallHistoryView from "@components/CallHistoryView/index";
import AudioFilesPreference from "@components/GreetingsAndMusic/index";
import BlockedPhoneNumbers from "@components/BlockedPhoneNumbers";
import ContactsManagement from "@components/Contacts";
import routes from "@constants/routes";
import TextMessaging from "@components/TextMessaging";
import TextMessageHistoryView from "@components/TextMessageHistoryView/index";

export type NavigationItems = {
  [name: string] : {
    value: string
    domId: string
    desktopOnly: boolean
    Component: React.FC | null
    link: string
    adminOnly?: boolean,
    route?: string
  }
}

export const companyItems: NavigationItems = {
  ACCOUNT: {
    value: "Account",
    domId: domIds.STONLY_ACCOUNT_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/account#!/accountInformation",
    adminOnly: true,
  },
  CONTACTS: {
    value: "Contacts",
    domId: domIds.STONLY_CONTACTS_TARGET,
    desktopOnly: false,
    Component: ContactsManagement,
    link: "",
    adminOnly: false,
    route: routes.CONTACTS
  },
  BILLING: {
    value: "Billing",
    domId: domIds.STONLY_BILLING_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/account#!/accountInformation",
    adminOnly: true,
  },
  USERS_AND_PERMISSIONS: {
    value: "Users & Permissions",
    domId: domIds.STONLY_USERS_PERMISSIONS_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/account#!/users",
    adminOnly: true,
  },
  VIRTUAL_PHONE_NUMBERS: {
    value: "Virtual Phone Numbers",
    domId: domIds.STONLY_VIRTUAL_PHONE_NUMBERS_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/account#!/phoneNumbers",
    adminOnly: true,
  },
  UPDATES: {
    value: "Updates",
    domId: domIds.PREFERENCES_UPDATES_NAV_ITEM,
    desktopOnly: true,
    Component: PreferenceUpdates,
    link: "",
  },
  REFERRAL_PROGRAM: {
    value: "Referral Program",
    domId: domIds.STONLY_REFERRAL_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/referralProgram"
  },
};

export const yourProfileItems: NavigationItems = {
  USER: {
    value: "User",
    domId: domIds.STONLY_USER_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/user/profile"
  },
  CALLER_ID: {
    value: "Caller ID",
    domId: domIds.STONLY_CALLER_ID_TARGET,
    desktopOnly: false,
    Component: CallerID,
    link: "",
    route: routes.CALLER_ID
  },
  CONTROLS: {
    value: "Controls",
    domId: domIds.STONLY_CONTROLS_TARGET,
    desktopOnly: false,
    Component: Controls,
    link: "",
    route: routes.CONTROLS
  },
  NOTIFICATIONS_AND_SOUNDS: {
    value: "Notifications & Sounds",
    domId: domIds.PREFERENCES_NOTIFICATIONS_AND_SOUND_NAV_ITEM,
    desktopOnly: false,
    Component: NotificationsAndSounds,
    link: "",
    route: routes.NOTIFICATIONS_AND_SOUNDS
  },
};

export const phoneSystemItems: NavigationItems = {
  MANAGER: {
    value: "Manager",
    domId: domIds.STONLY_MANAGER_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/",
    adminOnly: true,
  },
  TEXT_MESSAGING: {
    value: "Text Messaging",
    domId: domIds.STONLY_TEXT_MESSAGING_TARGET,
    desktopOnly: false,
    Component: TextMessaging,
    link: "",
    route: routes.TEXT_MESSAGES,
  },
  FORWARDING_PHONE_NUMBERS: {
    value: "Forwarding Phone Numbers",
    domId: domIds.STONLY_FORWARDING_TARGET,
    desktopOnly: false,
    Component: ForwardPhoneNumbersView,
    link: "",
    adminOnly: true,
    route: routes.FORWARDING_PHONE_NUMBERS
  },
  TRANSFER_CODES: {
    value: "Transfer Codes",
    domId: domIds.STONLY_TRANSFER_CODES_TARGET,
    desktopOnly: false,
    Component: TransferCodes,
    link: "",
    route: routes.TRANSFER_CODES
  },
  HOURS_OF_OPERATION: {
    value: "Hours of Operation",
    domId: domIds.STONLY_HOURS_TARGET,
    desktopOnly: false,
    Component: HoursOfOperation,
    link: "",
    adminOnly: true,
    route: routes.HOURS_OF_OPERATION
  },
  GREETINGS_AND_MUSIC_ON_HOLD: {
    value: "Greetings & Music on Hold",
    domId: domIds.STONLY_GREETINGS_TARGET,
    desktopOnly: false,
    Component: AudioFilesPreference,
    adminOnly: true,
    link: "",
    route: routes.GREETINGS_AND_MUSIC_ON_HOLD
  },
  SUBMENUS: {
    value: "Submenus",
    domId: domIds.STONLY_SUBMENUS_TARGET,
    desktopOnly: false,
    Component: null,
    link: "https://cp.talkroute.com/settings#!/submenu",
    adminOnly: true,
  },
  VOICE_MAILBOX: {
    value: "Voicemail",
    domId: domIds.STONLY_VOICEMAIL_TARGET,
    desktopOnly: false,
    Component: VoicemailPreferences,
    link: "",
    adminOnly: true,
    route: routes.PREFERENCES_VOICEMAIL
  },
  BLOCKED_NUMBERS: {
    value: "Blocked Numbers",
    domId: domIds.STONLY_BLOCKED_NUMBERS_TARGET,
    desktopOnly: false,
    Component: BlockedPhoneNumbers,
    link: "",
    route: routes.BLOCKED_NUMBERS
  },
};

export const historyItems: NavigationItems = {
  CALLS: {
    value: "Call History",
    domId: domIds.STONLY_CALL_HISTORY_TARGET,
    desktopOnly: false,
    Component: CallHistoryView,
    link: "",
    route: routes.CALL_HISTORY_AND_REPORTING
  },
  TEXT_MESSAGES: {
    value: "Text Message History",
    domId: domIds.STONLY_TEXT_HISTORY_TARGET,
    desktopOnly: false,
    Component: TextMessageHistoryView,
    link: "",
    route: routes.TEXT_MESSAGE_HISTORY
  },
}

export enum FeatureIdsEnum {
  // LOCAL_NUMBER = "local_number",
  INBOUND_CALLER_ID_ROUTING = "inbound_caller_id_routing",
  REPORTING = "reporting",
  SCHEDULED_FORWARDING = "scheduled_forwarding",
  HOURS_OF_OPERATION = "hours_of_operation",
  CALL_MENU = "call_menu",
  TEXT_MESSAGES = "text_messages",
  CALL_TRANSFER = "call_transfer",
  ACCOUNT_USER = "account_user",
  VMBOX = "vmbox",
  TEXT_MESSAGING = "text_messaging_unlimited",
}

export type IAccount = {
  id: string;
  name: string;
  address1: string;
  address2: string;
  state: string;
  postal: string;
  city: string;
  country: string;
  timezone: string;
  blockAnonymousCallers: boolean;
  planId: number;
  accountAdmin: {
    enabled: boolean;
    firstName: string;
    lastName: string;
    email: string;
    lastLogin: string;
    locked: boolean;
    role: string;
  };
};

export type IAccountDetails = Pick<IAccount, "blockAnonymousCallers">;

export type IFeature = {
  id: string;
  feature_id: FeatureIdsEnum;
  name: string;
  display_name: string;
  description: string;
  long_description: string;
  allowed: boolean;
  included: number;
  used: number;
};

export type IUserDetails = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  permissions: {
    id: string;
    level: string;
  }[];
  transferCode: string;
};

export type IUserPreferences = {
  welcomeVoicemailRead: boolean;
  welcomeTextMessageRead: boolean;
  callHistoryLastVisited: number;
  device: {
    helpDialogsRead: boolean;
    defaultTextMessagingVirtualNumberId: number | null;
    defaultCallingVirtualNumberId: number | null;
  }
}
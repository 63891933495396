import MicLevelVisualizer from "@components/NotificationsAndSounds/MicLevelVisualizer";
import { AudioPlayer } from "@components/PresentationComponents/AudioPlayer";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { useRecorder } from "@helpers/hooks/useRecorder.hook";
import useTimer from "@helpers/hooks/useTimer.hook";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { memo, useState } from "react";
import uiString from "../../../constants/uiString";
import InputButton from "../FormComponents/InputButton";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import { CloudUploadSharp } from "@material-ui/icons";
import { useToasts } from "react-toast-notifications";
import { MAX_ALLOWED_FILE_SIZE_IN_MB } from "./constants";

type IProps = {
  onRecord: (file: File, url: string) => any;
  onStart?: () => any;
  onAbort?: () => any;
  onStop?: () => any;
  onError?: (isError: boolean) => void;
  audioFile?: File | null; // incase we'd like to show the preview player right after component is loaded
};

const AudioFileRecorder = ({
  onRecord,
  onAbort,
  onStop,
  onStart,
  audioFile,
  onError,
}: IProps) => {
  const classes = useStyles();
  const {
    startRecording,
    stopRecording,
    abortRecording,
    isRecording,
  } = useRecorder();
  const { getFormattedTime, startTimer, stopTimer, resetTimer } = useTimer();
  const [file, setFile] = useState<{ file: File | null; url: string | null }>({
    file: audioFile ? audioFile : null,
    url: audioFile ? window.URL.createObjectURL(audioFile) : null,
  });
  const defaultAudioInputVolume = useAppSelector(
    (state) => state.preferences.defaultAudioInputVolume
  );
  const defaultAudioInput = useAppSelector(
    (state) => state.preferences.defaultAudioInput
  );
  const micIsBlocked = useAppSelector((state) => state.micStatus.micIsBlocked);
  const { addToast } = useToasts();

  const handleRecording = async () => {
    onError && onError(false);
    if (micIsBlocked) {
      addToast(uiString.noDeviceAvailableToast, {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      try {
        onStart && onStart();
        startTimer();
        const { file, url } = await startRecording();
        setFile({ file, url });
        onRecord(file, url);
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const handleStopRecording = () => {
    if (isRecording) {
      stopRecording();
      stopTimer();
      resetTimer();
      onStop && onStop();
    }
  };

  const handleAborting = () => {
    abortRecording();
    setFile({
      file: null,
      url: null,
    });
    stopTimer();
    resetTimer();
    onAbort && onAbort();
  };

  const uploadChangeHandler = (e) => {
    const file = (e.target.files as any)[0] as File;
    if (file.size <= MAX_ALLOWED_FILE_SIZE_IN_MB) {
      const url = window.URL.createObjectURL(file);
      setFile({ file, url });
      onRecord(file, url);
      onError && onError(false);
    } else {
      onError && onError(true);
      setFile({ file: null, url: null });
    }
  };

  return (
    <>
      <div className={classes.controls}>
        {!isRecording && (
          <InputButton
            variant="outlined"
            component="label"
            color="primary"
            className={classes.uploadButton}
            startIcon={<CloudUploadSharp />}
          >
            {uiString.UPLOAD}
            <input
              type="file"
              accept="audio/mp3, audio/wav, audio/x-m4a"
              hidden
              onChange={(e) => uploadChangeHandler(e)}
            />
          </InputButton>
        )}
        {!isRecording && (
          <InputButton
            variant="outlined"
            color="primary"
            className={classes.recordButton}
            onClick={handleRecording}
            label={uiString.RECORD}
            startIcon={<KeyboardVoiceIcon />}
          />
        )}
        {isRecording && (
          <InputButton
            className={classes.controlsMargin}
            color="inherit"
            onClick={handleStopRecording}
            label={uiString.STOP}
          />
        )}
        {isRecording && (
          <InputButton
            className={classes.controlsMargin}
            color="secondary"
            onClick={handleAborting}
            label={uiString.RETRY}
          />
        )}
      </div>
      {isRecording && (
        <div className={classes.visualizer}>
          <MicLevelVisualizer
            defaultAudioInputVolume={defaultAudioInputVolume}
            defaultAudioInput={defaultAudioInput}
            isEnabled={isRecording}
          />
          {isRecording && (
            <Typography className={classes.moveTimer} variant="body1">
              {getFormattedTime()}
            </Typography>
          )}
        </div>
      )}

      <div className={classes.audioplayer}>
        {file.url && !isRecording && (
          <AudioPlayer
            elevation={0}
            download={false}
            volume={false}
            src={file.url}
            autoplay={false}
          />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visualizer: {
      display: "flex",
    },
    audioplayer: {
      marginTop: theme.spacing(2),
    },
    moveTimer: {
      marginLeft: theme.spacing(1),
    },
    controls: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    controlsMargin: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    uploadButton: {
      "& > span > span > .MuiSvgIcon-root": {
        fontSize: "16px",
      },
    },
    recordButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      "& > span > span > .MuiSvgIcon-root": {
        fontSize: "16px",
      },
    },
  })
);

export default memo(AudioFileRecorder);

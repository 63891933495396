import { CircularProgress, makeStyles, PropTypes } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import React, { memo } from "react";
import clsx from "clsx";
import { ComponentDimensions } from "@constants/appConstants";
import { Add } from "@material-ui/icons";

type Props = {
  label?: string | JSX.Element;
  minWidth?: string | number;
  isLoading?: boolean;
  plusIcon?: boolean;
  leftIcon?: JSX.Element;
  /**
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: string;
} & ButtonProps;

const InputButton: React.FC<Props> = ({
  label,
  style,
  plusIcon = false,
  variant = "contained",
  minWidth = "80px",
  color = "primary",
  startIcon,
  className,
  isLoading,
  children,
  ...props
}) => {
  const classes = useStyles();
  let buttonColor: PropTypes.Color | undefined;
  props.disabled !== true ? (buttonColor = color) : (buttonColor = undefined);
  return (
    <Button
      {...props}
      color={buttonColor}
      style={{ ...style, minWidth: minWidth }}
      variant={variant}
      className={clsx(
        classes.button,
        className,
        variant === "outlined" && classes.outlined,
        isLoading === true && classes.centerLoadingIcon
      )}
    >
      {(plusIcon || startIcon) && !isLoading && (
        <div
          className={clsx(
            "icon",
            classes.startIcon,
            plusIcon && classes.plusIconRing,
            variant !== "outlined" && classes.whitePlus
          )}
        >
          {plusIcon ? <Add /> : startIcon}
        </div>
      )}
      <span className={clsx((plusIcon || startIcon) && classes.plusIconOffset)}>
        {isLoading ? (
          color === "inherit" ||
          color === "secondary" ||
          color === "primary" ? (
            <CircularProgress style={{ color: "white" }} size={16} />
          ) : (
            <CircularProgress size={16} />
          )
        ) : children ? (
          children
        ) : (
          label
        )}
      </span>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "11px",
    lineHeight: "normal",
    height: "25px",
    textTransform: "capitalize",
    padding: "6px 4px",
    borderRadius: "4px",
    boxShadow: "none",
    fontFamily: "Lato",
    whiteSpace: "nowrap",
    color: "white",

    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },
    "& span.MuiButton-label": {
      // justifyContent: 'space-between'
    },
    "&.MuiButton-containedPrimary": {
      backgroundColor: "var(--color-pumpkin-orange)",
      "&:hover": {
        backgroundColor: "var(--color-pumpkin-orange-hover)",
      },
    },
    "&.MuiButton-containedSecondary": {
      backgroundColor: "var(--color-button-green)",
      "&:hover": {
        backgroundColor: "var(--color-button-green-hover)",
      },
    },
    "&.MuiButton-colorInherit": {
      backgroundColor: "var(--color-button-red)",
      "&:hover": {
        backgroundColor: "var(--color-button-red-hover)",
      },
    },
    "&.MuiButton-outlinedSecondary": {
      "&:hover": {
        borderColor: "var(--color-button-green-hover)",
      },
    },
    "&.MuiButton-outlinedPrimary": {
      transitionProperty:
        "background-color, box-shadow, border, borderColor, color",
      "&:hover": {
        "& .icon": {
          borderColor: "var(--color-pumpkin-orange-darker)",
        },
        color: "var(--color-pumpkin-orange)",
        borderColor: "var(--color-pumpkin-orange)",
      },
    },
  },
  plusIconOffset: {
    textAlign: "center",
    width: "100%",
    // "& > span.MuiButton-label": {
    //   marginRight: 10,
    // }
  },
  startIcon: {
    width: ComponentDimensions.ADD_BUTTON_PLUS_ICON_SIZE,
    height: ComponentDimensions.ADD_BUTTON_PLUS_ICON_SIZE,
    minWidth: ComponentDimensions.ADD_BUTTON_PLUS_ICON_SIZE,
    minHeight: ComponentDimensions.ADD_BUTTON_PLUS_ICON_SIZE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 0,
    marginLeft: 5,
    "& svg": {
      width: ComponentDimensions.ADD_BUTTON_PLUS_ICON_SIZE,
      height: ComponentDimensions.ADD_BUTTON_PLUS_ICON_SIZE,
      strokeWidth: 1,
      stroke: theme.palette.common.white,
    },
  },
  plusIconRing: {
    border: "1px solid",
    borderColor: "#4a494b",
    borderRadius: "50%",
    transition: "border 0.225s ease",
  },
  whitePlus: {
    borderColor: "white",
  },
  outlined: {
    backgroundColor: "white",
    border: "1px solid #979797",
    color: "black",
  },
  label: {
    width: "100%",
  },
  centerLoadingIcon: {
    "& span.MuiButton-label": {
      justifyContent: "center",
    },
  },
}));

export default memo(InputButton);
